import { FC } from 'react'
import { useDictionaryStore } from '../Dictionary'
import { AddCircle, CloseCircle } from 'iconsax-react'
import { useForm } from 'react-hook-form'
import { TextFieldWithoutControl } from '../../AddUser/components/text-field'
import InputAdornment from '@mui/material/InputAdornment'
import { UploadSection } from '../../_components/UploadSection'
import CustomSwitch from '../../AddUser/components/switch'
import Button, { ButtonSizes, ButtonVariants } from '../../../../_core/components/button'

interface UpdateDialogProps {
    mode: 'metrics' | 'questions'
}

const UpdateDialog: FC<UpdateDialogProps> = ({ mode }) => {
    const { setShowMetrics, setShowQuestions } = useDictionaryStore()

    const { control, watch } = useForm()

    return (
        <div
            className='fixed inset-0 flex items-center justify-center bg-black/50 z-[999999]'
            onClick={() => {
                if (mode === 'metrics') setShowMetrics(false)
                else setShowQuestions(false)
            }}
        >
            <div
                className='bg-white w-[43.75rem] h-[37.5rem] flex flex-col px-7 py-5 relative'
                onClick={(e) => e.stopPropagation()}
            >
                <div className='flex justify-between items-center border-b border-gray-100 pb-3'>
                    <div className='w-6' />

                    <div className='font-medium text-lg text-gray-700'>
                        {mode === 'metrics' ? 'دایرکتوری' : 'پرسش‌ها'}
                    </div>

                    <CloseCircle
                        onClick={() => {
                            if (mode === 'metrics') setShowMetrics(false)
                            else setShowQuestions(false)
                        }}
                        className='w-6 h-6 text-gray-700'
                    />
                </div>

                <div className='grid grid-cols-9 grow gap-7 mt-14'>
                    <div className='col-span-5 flex flex-col gap-6'>
                        <TextFieldWithoutControl label='عنوان معیار' />

                        <TextFieldWithoutControl
                            label='معادل انگلیسی'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <a
                                            href='#content'
                                            className='text-[#0079FF] underline'
                                        >
                                            تولید محتوا
                                        </a>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <TextFieldWithoutControl label='URL' />

                        <TextFieldWithoutControl
                            label='آیکون'
                            disabled
                            className='relative'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position='end'
                                        className='flex gap-2'
                                    >
                                        <div className='bg-gray-300 h-7 w-px' />

                                        <AddCircle />

                                        <UploadSection
                                            className='absolute inset-0 m-auto opacity-0 w-full'
                                            // value={value}
                                            type={'ICON'}
                                            title=''
                                            onChange={() => {}}
                                        />
                                    </InputAdornment>
                                ),
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        {watch('icon') && (
                                            <img
                                                src={watch('icon')}
                                                alt='icon'
                                                className='w-9 h-9 object-contain object-center'
                                            />
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <CustomSwitch<any>
                            label='نمایش'
                            control={control}
                            name='is_active'
                        />

                        <div className='flex gap-4 mt-5'>
                            <Button
                                // disabled={isLoading || isFetching}
                                type='submit'
                                className='w-[9.375rem] text-gray-50'
                                size={ButtonSizes.bigger}
                            >
                                {/*isLoading || isFetching ? <Refresh className='animate-spin' /> :*/ 'ثبت'}
                            </Button>

                            <Button
                                type='button'
                                size={ButtonSizes.big}
                                className='w-[9.375rem]'
                                variant={ButtonVariants.gray}
                                onClick={() => {
                                    if (mode === 'metrics') setShowMetrics(false)
                                    else setShowQuestions(false)
                                }}
                            >
                                انصراف
                            </Button>
                        </div>
                    </div>

                    <div className='col-span-4'>
                        <div className='aspect-square w-full rounded border-gray-300 border border-dashed flex items-center justify-center gap-6 text-gray-200'>
                            <AddCircle className='w-11 h-11' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpdateDialog
