import { FieldValues, Path, useFormContext } from 'react-hook-form'
import CustomCheckbox from './custom-checkbox'

interface RadioButtonProps<T extends FieldValues> {
    label: string
    name: Path<T>
    value: T[Path<T>]
}

function CustomRadioButton<T extends FieldValues>({ label, value, name }: RadioButtonProps<T>) {
    const { watch, setValue, unregister } = useFormContext()

    return (
        <CustomCheckbox
            label={label}
            className='w-fit'
            onChange={() => (watch(name) === value ? unregister(name) : setValue(name, value))}
            checked={watch(name) === value}
        />
    )
}

export default CustomRadioButton

// TODO should I use unregister?
