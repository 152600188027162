/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import CustomCheckbox from '../../../../_core/components/table/custom-checkbox'
import { useState, useRef } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { TextFieldWithoutControl } from '../../AddUser/components/text-field'
import { useFormContext } from 'react-hook-form'
import { WorkshopType } from './WorkshopForm'
import { cn } from '../../../../utils/cn'
import InputAdornment from '@mui/material/InputAdornment'
import { SearchNormal1 } from 'iconsax-react'
import { useQuery } from '@tanstack/react-query'
import { getTherapists } from '../api'

export const TherapistsSection = () => {
    const { setValue, watch } = useFormContext<WorkshopType>()

    const [searchTherapists, setSearchTherapists] = useState<string>('')

    const headCells = useRef<GridColDef[]>([
        {
            field: 'id',
            headerName: 'کد',

            flex: 1,
            align: 'right',
            disableColumnMenu: true,
            sortable: false,
            resizable: false,
        },
        {
            field: 'name',
            headerName: 'نام',

            width: 200,
            flex: 2,
            align: 'right',
            disableColumnMenu: true,
            sortable: false,
            resizable: false,
        },
        {
            field: 'treatments',
            headerName: 'رویکرد درمان',

            width: 250,
            flex: 5,
            align: 'right',
            disableColumnMenu: true,
            sortable: false,
            resizable: false,
        },
        // {
        //     field: 'actions',
        //     headerName: '',

        //     renderCell: (props) => (
        //         <button
        //             type='button'
        //             className='w-full h-full flex items-center justify-center'
        //             onClick={() => {
        //                 setSelectedItems((prev) => prev.filter((item) => item !== props.row.id))
        //             }}
        //         >
        //             <Trash />
        //         </button>
        //     ),

        //     width: 50,
        //     align: 'center',
        //     disableColumnMenu: true,
        //     sortable: false,
        //     resizable: false,
        // },
    ])

    const {
        data: therapists,
        isPending: isPendingTherapists,
        isFetching: isFetchingTherapists,
    } = useQuery({
        queryKey: ['therapists'],
        queryFn: getTherapists,
        staleTime: 0,
        gcTime: 5 * 60 * 1000,
    })

    return (
        <>
            <TextFieldWithoutControl
                label='انتخاب اساتید'
                placeholder='جستجو'
                value={searchTherapists}
                onChange={(event) => setSearchTherapists(event.target.value)}
                sx={{ width: '16rem' }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <SearchNormal1 />
                        </InputAdornment>
                    ),
                }}
            />

            <DataGrid
                loading={isPendingTherapists}
                className={cn(
                    'add-data-grid no-header-padding max-h-96 [&_.MuiDataGrid-columnHeaderTitleContainer:not(.MuiDataGrid-columnHeaderCheckbox_*)]:!justify-start [&_.MuiDataGrid-columnHeaderTitleContainer:not(.MuiDataGrid-columnHeaderCheckbox_*)]:!pr-5',
                    isFetchingTherapists && '*:opacity-50 *:pointer-events-none',
                    isPendingTherapists && '[&_.MuiDataGrid-virtualScrollerContent]:!h-0',
                )}
                rows={
                    therapists?.filter((item) => item.name.toLowerCase().includes(searchTherapists.toLowerCase())) ?? []
                }
                columns={headCells.current}
                checkboxSelection={true}
                rowSelectionModel={watch('therapists')}
                onRowSelectionModelChange={(list) =>
                    setValue(
                        'therapists',
                        list.map((item) => {
                            if (typeof item === 'number') return item
                            else return parseInt(item)
                        }),
                    )
                }
                slots={{
                    baseCheckbox: CustomCheckbox,
                    columnResizeIcon: () => <></>,
                    loadingOverlay: () => <></>,
                    noRowsOverlay: () => <></>,
                }}
                hideFooter
            />
        </>
    )
}
