export interface SingleUser {
    id?: number
    name: string
    lastname: string
    email: string
    phone: string
    url?: string
    activated: boolean
    color: string
    initial: string
    profile_picture?: string
    password?: string
    confirmPassword?: string
}

export const initialRow: SingleUser = {
    activated: false,
    color: '#000',
    email: '',
    id: undefined,
    initial: '?',
    lastname: '',
    name: "Couldn't load",
    phone: '',
    url: '',
    password: '',
    confirmPassword: '',
}
