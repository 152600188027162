import { GridColDef, GridRowId } from '@mui/x-data-grid'
import CustomCheckbox from '../../../../_core/components/table/custom-checkbox'
import { ChangeEvent } from 'react'
import { Form, AddUserRequest } from '../interfaces'

export const initialAddUserRequest: AddUserRequest = {
    forms: [
        {
            formId: 1,
            formName: 'Dashboard',
        },
        {
            formId: 2,
            formName: 'AppointmentReq',
        },
        {
            formId: 3,
            formName: 'Therapists',
        },
        {
            formId: 4,
            formName: 'AddTherapists',
        },
        {
            formId: 5,
            formName: 'ContactUs',
        },
        {
            formId: 6,
            formName: 'PaypalTransactions',
        },
        {
            formId: 7,
            formName: 'Faq',
        },
        {
            formId: 8,
            formName: 'AddFaq',
        },
        {
            formId: 9,
            formName: 'Tests',
        },
        {
            formId: 10,
            formName: 'AddTest',
        },
        {
            formId: 11,
            formName: 'Users',
        },
        {
            formId: 12,
            formName: 'AddUser',
        },
        {
            formId: 13,
            formName: 'Tasks',
        },
        {
            formId: 14,
            formName: 'Patients',
        },
        {
            formId: 15,
            formName: 'AddPatient',
        },
        {
            formId: 16,
            formName: 'DirectReservation',
        },
        {
            formId: 17,
            formName: 'Bundles',
        },
        {
            formId: 18,
            formName: 'AddBundle',
        },
        {
            formId: 19,
            formName: 'Workshops',
        },
        {
            formId: 20,
            formName: 'AddWorkshop',
        },
    ],
    user: {
        id: 0,
        name: '',
        lastname: '',
        email: '',
        phone: '',
        url: '',
        activated: false,
        color: '',
        initial: '',
        profile_picture: undefined,
        password: '',
        confirmPassword: '',
    },
}

export const getColumn: (
    handleCheckboxChange: (event: ChangeEvent<HTMLInputElement>, id: GridRowId, cellName: string) => void,
    toggleOneColumnsCell: (checked: boolean, headerField: GridRowId) => void,
    areCellRowsAllChecked: {
        access: boolean
        add: boolean
        update: boolean
        delete: boolean
    },
) => GridColDef<Form>[] = (handleCheckboxChange, toggleOneColumnsCell, areCellRowsAllChecked) => [
    {
        field: 'formName',
        headerName: 'نام فرم',

        width: 150,
        flex: 1,
        align: 'left',
        disableColumnMenu: true,
        sortable: false,
        resizable: false,
    },
    {
        field: 'access',
        headerName: 'دسترسی',
        renderHeader: (params) => (
            <div className='MuiDataGrid-columnHeaderTitle flex gap-2 items-center justify-between w-full'>
                <div>{params.colDef.headerName}</div>

                <CustomCheckbox
                    className='w-fit'
                    checked={areCellRowsAllChecked.access}
                    onChange={(e) => {
                        toggleOneColumnsCell(e.target.checked, params.colDef.field)
                    }}
                />
            </div>
        ),

        width: 150,
        flex: 1,
        align: 'center',
        type: 'boolean',
        disableColumnMenu: true,
        sortable: false,
        resizable: false,
        renderCell: (props) => (
            <CustomCheckbox
                className='w-fit mr-auto ml-[35px]'
                checked={props.value}
                onChange={(event) => handleCheckboxChange(event, props.id, props.field)}
            />
        ),
    },
    {
        field: 'add',
        headerName: 'جدید',
        renderHeader: (params) => (
            <div className='MuiDataGrid-columnHeaderTitle flex gap-2 items-center justify-between w-full'>
                <div>{params.colDef.headerName}</div>

                <CustomCheckbox
                    className='w-fit'
                    checked={areCellRowsAllChecked.add}
                    onChange={(e) => {
                        toggleOneColumnsCell(e.target.checked, params.colDef.field)
                    }}
                />
            </div>
        ),

        width: 150,
        flex: 1,
        align: 'center',
        type: 'boolean',
        disableColumnMenu: true,
        sortable: false,
        resizable: false,
        renderCell: (props) => (
            <CustomCheckbox
                className='w-fit mr-auto ml-[35px]'
                checked={props.value}
                onChange={(event) => handleCheckboxChange(event, props.id, props.field)}
            />
        ),
    },
    {
        field: 'delete',
        headerName: 'حذف',
        renderHeader: (params) => (
            <div className='MuiDataGrid-columnHeaderTitle flex gap-2 items-center justify-between w-full'>
                <div>{params.colDef.headerName}</div>

                <CustomCheckbox
                    className='w-fit'
                    checked={areCellRowsAllChecked.delete}
                    onChange={(e) => {
                        toggleOneColumnsCell(e.target.checked, params.colDef.field)
                    }}
                />
            </div>
        ),

        width: 150,
        flex: 1,
        align: 'center',
        type: 'boolean',
        disableColumnMenu: true,
        sortable: false,
        resizable: false,
        renderCell: (props) => (
            <CustomCheckbox
                className='w-fit mr-auto ml-[35px]'
                checked={props.value}
                onChange={(event) => handleCheckboxChange(event, props.id, props.field)}
            />
        ),
    },
    {
        field: 'update',
        headerName: 'ویرایش',
        renderHeader: (params) => (
            <div className='MuiDataGrid-columnHeaderTitle flex gap-2 items-center justify-between w-full'>
                <div>{params.colDef.headerName}</div>

                <CustomCheckbox
                    className='w-fit'
                    checked={areCellRowsAllChecked.update}
                    onChange={(e) => {
                        toggleOneColumnsCell(e.target.checked, params.colDef.field)
                    }}
                />
            </div>
        ),

        width: 150,
        flex: 1,
        align: 'center',
        type: 'boolean',
        disableColumnMenu: true,
        sortable: false,
        resizable: false,
        renderCell: (props) => (
            <CustomCheckbox
                className='w-fit mr-auto ml-[35px]'
                checked={props.value}
                onChange={(event) => handleCheckboxChange(event, props.id, props.field)}
            />
        ),
    },
]
