import { GridRowId } from '@mui/x-data-grid'
import { Bundle } from '../data-types'
import { ArticleTypeServer } from '../_components/ArticleForm'

export async function getArticles(): Promise<Bundle[]> {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/get_articles.php', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
        })
        const data = await response.json()
        return data.data
    } catch (error) {
        console.error('Failed to fetch initial data:', error)
        return []
    }
}

export function deleteArticles(articlesToDelete: GridRowId[]) {
    return Promise.all(
        articlesToDelete.map(async (articleId) => {
            try {
                const response = await fetch('https://stage2-api.insight-clinic.com/controller/delete_article.php', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        AuthorizationKey: localStorage.getItem('token') ?? '',
                    },
                    body: JSON.stringify({
                        id: parseInt(articleId.toString(), 10),
                    }),
                })
                const data = await response.json()

                return data
            } catch (error) {
                console.error('Failed to fetch initial data:', error)

                return undefined
            }
        }),
    )
}

export async function addArticle(article: ArticleTypeServer) {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/add_article.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify(article),
        })
        const processedRes = await response.json()
        if (processedRes.status !== 'success')
            throw new Error(processedRes.message ?? processedRes.code ?? 'SERVER_ERROR')
    } catch (error) {
        throw error
    }
}

export async function updateArticle(article: ArticleTypeServer) {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/update_article.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify(article),
        })
        const processedRes = await response.json()
        if (processedRes.status !== 'success')
            throw new Error(processedRes.message ?? processedRes.code ?? 'SERVER_ERROR')
    } catch (error) {
        throw error
    }
}

export async function getArticle(id: number) {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/get_article.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify({ id }),
        })
        const data = await response.json()

        return data.data as ArticleTypeServer
    } catch (error) {
        throw error
    }
}
