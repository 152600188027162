import { GridRowId } from '@mui/x-data-grid'
import { Edit2 } from 'iconsax-react'
import Button, { ButtonVariants, ButtonSizes } from '../../../../_core/components/button'
import { Column, ColumnVariants, Sizes } from '../../../../_core/components/table/column'
import { cn } from '../../../../utils/cn'
import { Member } from '../data-types'

const getMembersColumns = (selectionList: GridRowId[], onClick: (id?: number) => void) => [
    Column<Member>({
        field: 'name',
        headerName: 'نام و نام خانوادگی',
        valueGetter: (_, row) => `${row.name}`,
        variant: ColumnVariants.withProfile,
        generateProfile(row) {
            return row.photo
        },
        headerAlign: 'right',
        size: Sizes.small,
    }),
    Column<Member>({
        field: 'phone',
        headerName: 'شماره موبایل',
        valueGetter: (_, row) => `${row.cell_number}`,
        variant: ColumnVariants.withPhone,
        headerAlign: 'left',
        size: Sizes.small,
    }),
    Column<Member>({
        field: 'email',
        headerName: 'ایمیل',
        valueGetter: (_, row) => row.email ?? '',
        headerAlign: 'left',
        align: 'right',
        size: Sizes.normal,
    }),
    Column<Member>({
        field: 'timezone',
        headerName: 'موقعیت زمانی',
        valueGetter: (_, row) => row.timezone ?? '',
        headerAlign: 'left',
        align: 'right',
        size: Sizes.small,
    }),
    Column<Member>({
        field: 'subscriptionType',
        headerName: 'نوع اشتراک',
        valueGetter: (_, row) => row.subscriptionType ?? '',
        headerAlign: 'left',
        align: 'right',
        size: Sizes.small,
    }),
    Column<Member>({
        field: 'actions',
        headerName: '',
        valueGetter: () => '',
        renderHeader: () => (
            <>
                <Button
                    aClassName='mr-auto'
                    variant={ButtonVariants.danger}
                    size={ButtonSizes.small}
                    onClick={() => onClick()}
                    className={cn((selectionList?.length ?? 0) > 0 ? 'flex' : 'hidden')}
                >
                    حذف همه
                </Button>
            </>
        ),
        renderCell: (props) => (
            <div className='flex items-center justify-end pl-4 gap-8 w-full h-full text-gray-700'>
                {/* <button
                    title='دانلود'
                    type='button'
                >
                    <ImportCurve size={24} />
                </button> */}

                <a
                    href={'./members/update-member?id=' + props.row.id}
                    className='flex justify-center'
                >
                    <button title='Edit'>
                        <Edit2
                            width={24}
                            height={24}
                        />
                    </button>
                </a>
            </div>
        ),
        sortable: false,
        size: Sizes.small,
    }),
]

export default getMembersColumns
