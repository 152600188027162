import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { TestDetail } from '../data-types'
import { UploadSection } from '../../_components/UploadSection'

const SidebarImage: FC = () => {
    const { control } = useFormContext<TestDetail>()

    return (
        <div className='bg-white p-5 px-[1.125rem] flex justify-center items-center gap-4 flex-col'>
            <div className='grid grid-cols-2 w-full gap-y-3 gap-x-4'>
                <Controller
                    name='image'
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <UploadSection
                            className='col-span-2'
                            value={value}
                            type={'IMAGE'}
                            onChange={onChange}
                            title={'انتخاب تصویر'}
                        />
                    )}
                />

                <Controller
                    name='imageBG'
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <UploadSection
                            value={value}
                            type={'IMAGE'}
                            title={'انتخاب BG'}
                            onChange={onChange}
                        />
                    )}
                />

                <Controller
                    name='thumbnail'
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <UploadSection
                            value={value}
                            type={'IMAGE'}
                            title={'Thumbnail'}
                            onChange={onChange}
                        />
                    )}
                />
            </div>
        </div>
    )
}

export default SidebarImage
