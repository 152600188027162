/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../redux/hooks'

const AuthLayout = ({ children }: { children: React.ReactNode }) => {
    const navigate = useNavigate()
    const userAccess = useAppSelector((s) => s.userAccess.value)

    useEffect(() => {
        if (userAccess !== undefined) {
            navigate('/cms')
        }
    }, [userAccess])

    return (
        <div className={'w-full h-full flex'}>
            <div className={'w-full lg:w-1/2 h-full flex flex-col p-4 lg:mr-32'}>
                <div className={'w-full flex-[2] flex flex-col items-center justify-center'}>
                    <div className={'w-full max-w-[25rem] mt-12'}>{children}</div>
                </div>
            </div>

            <div className='hidden lg:flex w-1/2 h-full items-center justify-center ml-32'>
                <div className='w-4/5 h-3/5 flex items-center justify-center '>
                    <img
                        alt='authSlide'
                        src={'/img/logo.png'}
                        className='object-contain w-3/5 h-3/5 '
                    />
                </div>
            </div>
        </div>
    )
}

export default AuthLayout
