import { ChangeEvent } from 'react'
import { SuggestionServer } from '../SuggestionForm'

export async function addSuggestion(suggestion: SuggestionServer) {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/add_suggestion_v2.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify(suggestion),
        })
        const data = await response.json()

        return data
    } catch (error) {
        throw error
    }
}

export async function updateSuggestion(suggestion: SuggestionServer) {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/update_suggestion_v2.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify(suggestion),
        })
        const data = await response.json()

        return data
    } catch (error) {
        throw error
    }
}

export async function uploadImage(file: ChangeEvent<HTMLInputElement>) {
    try {
        const formData = new FormData()
        if (file.target.files) formData.append('file', file.target.files[0])

        console.log('request', file)

        const response = await fetch('https://stage2-api.insight-clinic.com/controller/add_media_image_v2.php', {
            method: 'POST',
            headers: {
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: formData,
        })
        const data = await response.json()
        if (data === null) throw new Error('مشکل در آپلود تصویر')

        return data.uploaded_image
    } catch (error) {
        throw error ?? 'مشکل در آپلود تصویر'
    }
}

export async function getSuggestionCategories() {
    try {
        const response = await fetch(
            'https://stage2-api.insight-clinic.com/controller/get_suggestion_categories_v2.php',
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    AuthorizationKey: localStorage.getItem('token') ?? '',
                },
            },
        )
        const data = await response.json()

        return data.data.categories as string
    } catch (error) {
        throw error
    }
}
