import { FC } from 'react'
import Section from '../../Tests/components/section'
import { ImportCurve } from 'iconsax-react'

const DownloadCVSidebar: FC = () => {
    return (
        <Section className='p-3'>
            <button
                type='button'
                className='gap-2 flex text-[#4F73F0] items-center'
            >
                <ImportCurve size={24} />

                <div className='text-sm'>دانلود رزومه</div>
            </button>
        </Section>
    )
}

export default DownloadCVSidebar
