export enum Role {
    Patient = 0,
    Therapist = 1,
    Admin = 2,
}

export enum ReservationStatus {
    Pending = 0,
    Cancelled = 1,
    Active = 2,
    Past = 3,
}

export enum PaymentStatus {
    Unpaid = 0,
    Paid = 1,
}

export interface Reservation {
    id: number
    patient_name: string
    patient_phone: string
    patient_photo: string
    date_time: string
    therapist_name: string
    therapist_phone: string
    reserver: Role
    price_dollar: number
    price_toman: number
    payment_status: PaymentStatus
    reservation_status: ReservationStatus
    canceller?: Role
    cancel_reason?: string
    cancel_date_time?: string
}

export interface Patient {
    id: number
    name: string
    phone: string
    meetingType?: string
    timeZone: string
    subscriptionType: string
}

export type EnglishWeekDays = 'Saturday' | 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday'

export interface AvailableWorkingHours {
    date: string
    day_name: EnglishWeekDays
    hours: Hour[]
}

export interface Hour {
    hour: string
    status: number
}
