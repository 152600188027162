import { FC, useMemo } from 'react'
import TestForm from '../components/test-form'
import { useQuery } from '@tanstack/react-query'
import { getTestDetail } from '../api'
import { useSearchParams } from 'react-router-dom'
import CMSLayout from '../../../../_core/components/layouts/cms-dashboard/CMSLayout'

const UpdateTest: FC = () => {
    const [searchParams] = useSearchParams()

    const id = useMemo(() => parseInt(searchParams.get('id') ?? '-1'), [searchParams])

    const { data, isFetching } = useQuery({
        queryKey: ['test-detail', id],
        queryFn: () => getTestDetail(id),
        gcTime: 0,
        staleTime: 0,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        select: ({ personalQuestions, keywords, category, ageRange, ...data }) => ({
            personalQuestions: personalQuestions?.split('|') ?? [],
            keywords: keywords?.split('|'),
            category: category?.split('|') ?? [],
            ageRange: ageRange?.split('|') ?? [],
            ...data,
        }),
    })

    if (isFetching)
        return (
            <CMSLayout>
                <div className='w-full h-full flex justify-center items-start'>
                    <span className='loader' />
                </div>
            </CMSLayout>
        )

    return (
        <TestForm
            type='UPDATE'
            test={data}
        />
    )
}

export default UpdateTest
