import { AvailableWorkingHours, Patient, Reservation } from '../data-types'
import { CancelPost } from '../components/cancel-form-dialog'
import { ReservationDetail } from '../components/reservation-form'

const demo: Reservation[] = [
    {
        id: 1,
        patient_name: 'John Doe',
        patient_phone: '555-1234',
        patient_photo: 'https://example.com/photos/johndoe.jpg',
        date_time: '2024-09-01T14:00:00Z',
        therapist_name: 'Dr. Smith',
        therapist_phone: '555-5678',
        reserver: 0,
        price_dollar: 150,
        price_toman: 1500000,
        payment_status: 1,
        reservation_status: 2,
    },
    {
        id: 2,
        patient_name: 'Jane Roe',
        patient_phone: '555-2345',
        patient_photo: 'https://example.com/photos/janeroe.jpg',
        date_time: '2024-09-02T10:00:00Z',
        therapist_name: 'Dr. Lee',
        therapist_phone: '555-6789',
        reserver: 0,
        price_dollar: 200,
        price_toman: 2000000,
        payment_status: 0,
        reservation_status: 0,
    },
    {
        id: 3,
        patient_name: 'Mark Spencer',
        patient_phone: '555-3456',
        patient_photo: 'https://example.com/photos/markspencer.jpg',
        date_time: '2024-09-03T11:00:00Z',
        therapist_name: 'Dr. White',
        therapist_phone: '555-7890',
        reserver: 1,
        price_dollar: 180,
        price_toman: 1800000,
        payment_status: 1,
        reservation_status: 1,
        canceller: 0,
        cancel_reason: 'Personal reasons',
        cancel_date_time: '2024-09-02T15:00:00Z',
    },
    {
        id: 4,
        patient_name: 'Emily Clark',
        patient_phone: '555-4567',
        patient_photo: 'https://example.com/photos/emilyclark.jpg',
        date_time: '2024-09-04T09:00:00Z',
        therapist_name: 'Dr. Brown',
        therapist_phone: '555-8901',
        reserver: 0,
        price_dollar: 250,
        price_toman: 2500000,
        payment_status: 1,
        reservation_status: 3,
    },
    {
        id: 5,
        patient_name: 'Alice Johnson',
        patient_phone: '555-5678',
        patient_photo: 'https://example.com/photos/alicejohnson.jpg',
        date_time: '2024-09-05T12:00:00Z',
        therapist_name: 'Dr. Green',
        therapist_phone: '555-9012',
        reserver: 0,
        price_dollar: 220,
        price_toman: 2200000,
        payment_status: 0,
        reservation_status: 1,
        canceller: 1,
        cancel_reason: 'Therapist unavailable',
        cancel_date_time: '2024-09-04T08:00:00Z',
    },
    {
        id: 6,
        patient_name: 'Robert Brown',
        patient_phone: '555-6789',
        patient_photo: 'https://example.com/photos/robertbrown.jpg',
        date_time: '2024-09-06T13:00:00Z',
        therapist_name: 'Dr. Davis',
        therapist_phone: '555-0123',
        reserver: 0,
        price_dollar: 160,
        price_toman: 1600000,
        payment_status: 1,
        reservation_status: 2,
    },
    {
        id: 7,
        patient_name: 'Sophia Wilson',
        patient_phone: '555-7890',
        patient_photo: 'https://example.com/photos/sophiawilson.jpg',
        date_time: '2024-09-07T14:00:00Z',
        therapist_name: 'Dr. Thompson',
        therapist_phone: '555-1234',
        reserver: 0,
        price_dollar: 190,
        price_toman: 1900000,
        payment_status: 1,
        reservation_status: 1,
        canceller: 0,
        cancel_reason: 'Schedule conflict',
        cancel_date_time: '2024-09-06T16:00:00Z',
    },
    {
        id: 8,
        patient_name: 'Daniel Martinez',
        patient_phone: '555-8901',
        patient_photo: 'https://example.com/photos/danielmartinez.jpg',
        date_time: '2024-09-08T15:00:00Z',
        therapist_name: 'Dr. Scott',
        therapist_phone: '555-2345',
        reserver: 0,
        price_dollar: 210,
        price_toman: 2100000,
        payment_status: 1,
        reservation_status: 2,
    },
    {
        id: 9,
        patient_name: 'Emma Thomas',
        patient_phone: '555-9012',
        patient_photo: 'https://example.com/photos/emmathomas.jpg',
        date_time: '2024-09-09T16:00:00Z',
        therapist_name: 'Dr. Adams',
        therapist_phone: '555-3456',
        reserver: 1,
        price_dollar: 240,
        price_toman: 2400000,
        payment_status: 0,
        reservation_status: 1,
        canceller: 1,
        cancel_reason: 'Emergency',
        cancel_date_time: '2024-09-08T11:00:00Z',
    },
    {
        id: 10,
        patient_name: 'Oliver Harris',
        patient_phone: '555-0123',
        patient_photo: 'https://example.com/photos/oliverharris.jpg',
        date_time: '2024-09-10T17:00:00Z',
        therapist_name: 'Dr. Taylor',
        therapist_phone: '555-4567',
        reserver: 0,
        price_dollar: 230,
        price_toman: 2300000,
        payment_status: 1,
        reservation_status: 2,
    },
]

export async function getReservations(): Promise<Reservation[]> {
    try {
        return demo
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/get_reservations.php', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
        })
        const data = await response.json()
        return data.data
    } catch (error) {
        console.error('Failed to fetch initial data:', error)
        return []
    }
}

export async function cancelReservations(reservationsToDelete: CancelPost) {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/cancel_reservation.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify(reservationsToDelete),
        })
        return await response.json()
    } catch (error) {
        throw error
    }
}

export async function getReservationDetail(id: number): Promise<Reservation> {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/get_reservation.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify({
                id,
            }),
        })
        const data = await response.json()
        return data.data
    } catch (error) {
        console.error('Failed to fetch initial data:', error)
        throw error
    }
}

export async function addReservationDetail(data: Reservation) {
    try {
        return await fetch('https://stage2-api.insight-clinic.com/controller/add_reservation.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify(data),
        })
    } catch (error) {
        throw error
    }
}

export async function updateReservationDetail(data: Reservation) {
    try {
        await fetch('https://stage2-api.insight-clinic.com/controller/update_reservation.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify(data),
        })
    } catch (error) {
        throw error
    }
}

const placeholderPatients: Patient[] = [
    {
        id: 0,
        name: 'باران اکبری',
        phone: '09124937446',
        meetingType: 'خارجی',
        timeZone: 'America/Los-Angeles',
        subscriptionType: 'نرمال',
    },
    {
        id: 1,
        name: 'باران اکبری',
        phone: '09124937446',
        meetingType: 'خارجی',
        timeZone: 'America/Los-Angeles',
        subscriptionType: 'نرمال',
    },
    {
        id: 2,
        name: 'باران اکبری',
        phone: '09124937446',
        meetingType: 'خارجی',
        timeZone: 'America/Los-Angeles',
        subscriptionType: 'نرمال',
    },
    {
        id: 3,
        name: 'باران اکبری',
        phone: '09124937446',
        meetingType: 'خارجی',
        timeZone: 'America/Los-Angeles',
        subscriptionType: 'نرمال',
    },
    {
        id: 4,
        name: 'باران اکبری',
        phone: '09124937446',
        meetingType: 'خارجی',
        timeZone: 'America/Los-Angeles',
        subscriptionType: 'نرمال',
    },
    {
        id: 5,
        name: 'باران اکبری',
        phone: '09124937446',
        meetingType: 'خارجی',
        timeZone: 'America/Los-Angeles',
        subscriptionType: 'نرمال',
    },
]

export async function getPatients(): Promise<Patient[]> {
    try {
        return placeholderPatients
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/get_patients.php', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
        })
        const data = await response.json()
        return data.data
    } catch (error) {
        console.error('Failed to fetch initial data:', error)
        return []
    }
}

// const placeholderAvailableWorkingHours: AvailableWorkingHours[] = [
//     {
//         date: '2024-09-10',
//         day_name: 'Monday',
//         hours: [
//             { hour: '08:00', status: 1 },
//             { hour: '09:00', status: 0 },
//             { hour: '10:00', status: 1 },
//             { hour: '11:00', status: 0 },
//             { hour: '12:00', status: 1 },
//         ],
//     },
//     {
//         date: '2024-09-11',
//         day_name: 'Tuesday',
//         hours: [
//             { hour: '08:00', status: 1 },
//             { hour: '09:00', status: 1 },
//             { hour: '10:00', status: 0 },
//             { hour: '11:00', status: 1 },
//             { hour: '12:00', status: 0 },
//         ],
//     },
//     {
//         date: '2024-09-12',
//         day_name: 'Wednesday',
//         hours: [
//             { hour: '08:00', status: 0 },
//             { hour: '09:00', status: 1 },
//             { hour: '10:00', status: 1 },
//             { hour: '11:00', status: 1 },
//             { hour: '12:00', status: 1 },
//         ],
//     },
//     {
//         date: '2024-09-13',
//         day_name: 'Thursday',
//         hours: [
//             { hour: '08:00', status: 1 },
//             { hour: '09:00', status: 0 },
//             { hour: '10:00', status: 0 },
//             { hour: '11:00', status: 1 },
//             { hour: '12:00', status: 1 },
//         ],
//     },
//     {
//         date: '2024-09-14',
//         day_name: 'Friday',
//         hours: [
//             { hour: '08:00', status: 1 },
//             { hour: '09:00', status: 1 },
//             { hour: '10:00', status: 1 },
//             { hour: '11:00', status: 0 },
//             { hour: '12:00', status: 0 },
//         ],
//     },
//     {
//         date: '2024-09-15',
//         day_name: 'Saturday',
//         hours: [
//             { hour: '08:00', status: 1 },
//             { hour: '09:00', status: 1 },
//             { hour: '10:00', status: 0 },
//             { hour: '11:00', status: 1 },
//             { hour: '12:00', status: 0 },
//         ],
//     },
//     {
//         date: '2024-09-16',
//         day_name: 'Sunday',
//         hours: [
//             { hour: '08:00', status: 0 },
//             { hour: '09:00', status: 0 },
//             { hour: '10:00', status: 1 },
//             { hour: '11:00', status: 1 },
//             { hour: '12:00', status: 1 },
//         ],
//     },
//     {
//         date: '2024-09-17',
//         day_name: 'Monday',
//         hours: [
//             { hour: '08:00', status: 1 },
//             { hour: '09:00', status: 1 },
//             { hour: '10:00', status: 1 },
//             { hour: '11:00', status: 0 },
//             { hour: '12:00', status: 1 },
//         ],
//     },
//     {
//         date: '2024-09-18',
//         day_name: 'Tuesday',
//         hours: [
//             { hour: '08:00', status: 1 },
//             { hour: '09:00', status: 1 },
//             { hour: '10:00', status: 1 },
//             { hour: '11:00', status: 1 },
//             { hour: '12:00', status: 0 },
//         ],
//     },
//     {
//         date: '2024-09-19',
//         day_name: 'Wednesday',
//         hours: [
//             { hour: '08:00', status: 0 },
//             { hour: '09:00', status: 0 },
//             { hour: '10:00', status: 1 },
//             { hour: '11:00', status: 1 },
//             { hour: '12:00', status: 1 },
//         ],
//     },
// ]

export interface GetAvailableWorkingHoursPost {
    id: number
    date: string
}

export async function getAvailableWorkingHours(data: GetAvailableWorkingHoursPost): Promise<AvailableWorkingHours[]> {
    try {
        const response = await fetch('https://reservation-api.insight-clinic.com/api/therapist/shifts', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify(data),
        })
        const processedRes = await response.json()
        if (processedRes.status !== 'success')
            throw new Error(processedRes.message ?? processedRes.code ?? 'SERVER_ERROR')
        return processedRes.data
    } catch (error) {
        throw error
    }
}

export async function bookAppointment(data: ReservationDetail) {
    try {
        const response = await fetch('https://reservation-api.insight-clinic.com/api/book-appointment', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify(data),
        })
        const processedRes = await response.json()
        if (processedRes.status !== 'success')
            throw new Error(processedRes.message ?? processedRes.code ?? 'SERVER_ERROR')
    } catch (error) {
        throw error
    }
}

export async function updateAppointment(data: ReservationDetail) {
    try {
        const response = await fetch('https://reservation-api.insight-clinic.com/api/update-appointment', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify(data),
        })
        const processedRes = await response.json()
        if (processedRes.status !== 'success')
            throw new Error(processedRes.message ?? processedRes.code ?? 'SERVER_ERROR')
    } catch (error) {
        throw error
    }
}
