import { Paypal } from '../data-types'

export enum PaypalStatus {
    All = -1,
    Validated = 1,
    NotValidated = 0,
}

export async function getPaypalData(status: PaypalStatus): Promise<Paypal> {
    try {
        return await fetch('https://stage2-api.insight-clinic.com/controller/get_paypal_transactions_v2.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify({
                read: status,
            }),
        }).then((res) => res.json())
    } catch (error) {
        console.error('Failed to fetch initial data:', error)
        throw error
    }
}
