import type { FC } from 'react'
import { updateFormItem } from '../../../../redux/form-item/form-item-slice'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { Link, useLocation } from 'react-router-dom'
import {
    Book1,
    CalendarTick,
    DeviceMessage,
    Direct,
    Logout,
    MessageQuestion,
    Paypal,
    Profile2User,
    Timer1,
    UserAdd,
    UserSearch,
} from 'iconsax-react'
import { DictionaryIcon, Speedometer } from '../../../icons'

const CMSSidebar = () => {
    // const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const userAccess = useAppSelector((s) => s.userAccess.value)

    const pathname = useLocation().pathname.split('/')[1] + useLocation().pathname.split('/')[2]
    // const [isOpenLogout, setIsOpenLogout] = useState<boolean>(false)

    var daysPersian = ['یکشنبه', 'دوشنبه', 'سه شنبه', 'چهارشنبه', 'پنجشنبه', 'جمعه', 'شنبه']
    var daysEnglish = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

    const date = new Date()

    // const logout = () => {
    //     dispatch(updateUserAccess(undefined))
    //     navigate('/auth/login')
    //     setIsOpenLogout(false)
    // }

    const pages = [
        'Dashboard',
        'Tests',
        'Bundles',
        'Workshops',
        'Articles',
        'PaypalTransactions',
        'Users',
        'Dictionary',
        'Therapists',
        'Faq',
        'Members',
        'DirectReservation',
    ]

    return (
        <div className='flex flex-col overflow-y-auto pt-6 w-60 shrink-0'>
            {userAccess?.map(
                (item, index) =>
                    item.access &&
                    pages.includes(item.formName) && (
                        <NavItem
                            onClick={() => dispatch(updateFormItem(item.formId))}
                            key={`link-key-${index}`}
                            selected={
                                pathname.replace('cmsundefined', 'cms') ===
                                item.formUrl.split('/')[1] + item.formUrl.split('/')[2]
                            }
                            to={item.formUrl}
                            name={item.formName}
                        />
                    ),
            )}

            {/* <NavItem
                selected={pathname === 'cmsdictionary'}
                to='/cms/dictionary'
                name='Dictionary'
            /> */}

            <NavItem
                selected={false}
                to='/cms/logout'
                name='Logout'
            />

            <div className='grow' />

            <div className='px-4 py-7 sticky bottom-0 bg-primary-700'>
                <p className='text-gray-50 text-xs text-center'>
                    {daysEnglish[date.getDay()]}
                    {', '}
                    {date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
                    <br />
                    {date.toLocaleDateString('fa-IR', { year: 'numeric', month: 'long', day: 'numeric' })}
                    {', '}
                    {daysPersian[date.getDay()]}
                </p>
            </div>
        </div>
    )
}

export default CMSSidebar

const converterUrls = (url: string) => {
    switch (url) {
        case 'Dashboard':
            return (
                <>
                    <Speedometer />
                    داشبورد
                </>
            )

        case 'Tests':
            return (
                <>
                    <Timer1 />
                    آزمون ها
                </>
            )

        case 'Bundles':
            return (
                <>
                    <Direct />
                    بسته‌های هدفمند
                </>
            )

        case 'Workshops':
            return (
                <>
                    <DeviceMessage />
                    کارگاه‌های روانشناسی
                </>
            )

        case 'PaypalTransactions':
            return (
                <>
                    <Paypal />
                    پی پال
                </>
            )
        case 'Users':
            return (
                <>
                    <Profile2User />
                    کاربران
                </>
            )

        case 'Therapists':
            return (
                <>
                    <UserAdd />
                    درمانگران
                </>
            )

        case 'Articles':
            return (
                <>
                    <Book1 />
                    مقالات
                </>
            )
        case 'Dictionary':
            return (
                <>
                    <DictionaryIcon />
                    دیکشنری
                </>
            )

        case 'Faq':
            return (
                <>
                    <MessageQuestion />
                    سوالات متداول
                </>
            )

        case 'Members':
            return (
                <>
                    <UserSearch />
                    درمانجویان
                </>
            )

        case 'DirectReservation':
            return (
                <>
                    <CalendarTick />
                    نوبت دهی آنلاین
                </>
            )

        case 'Logout':
            return (
                <>
                    <Logout size={24} />
                    خروج
                </>
            )
    }
}

interface NavItemProps {
    selected: boolean
    to: string
    name: string
    onClick?: () => void
}

const NavItem: FC<NavItemProps> = ({ selected, to, name }) => {
    return (
        <Link
            data-selected={selected}
            to={to}
            className='flex gap-2 group/selected'
        >
            <div className='w-2 h-full rounded-full -mr-[0.125rem] group-data-[selected=true]/selected:bg-white' />
            <div className='rounded-md items-center px-3 flex gap-3 text-sm text-white w-[12rem] h-[3.125rem] group-data-[selected=true]/selected:bg-white group-data-[selected=true]/selected:text-primary-700'>
                {converterUrls(name)}
            </div>
        </Link>
    )
}
