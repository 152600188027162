import { useMemo, type FC } from 'react'
import ListPage from '../_components/list-page'
import { deleteUsers, getUsersInGeneral } from './api/users'
import DeleteConfirmDialog from '../_components/list-page/components/dialog'
import getFullName from './utils/fullname'
import { GridRowId } from '@mui/x-data-grid'
import { Edit2, Trash } from 'iconsax-react'
import Button, { ButtonVariants, ButtonSizes } from '../../../_core/components/button'
import { Column, ColumnVariants } from '../../../_core/components/table/column'
import { cn } from '../../../utils/cn'
import { SingleUser } from './data-types/users'
import { useSelector } from 'react-redux'
import { RootState } from '../../../_core/redux/store'

const Users: FC = () => {
    const userAccessData = useSelector((state: RootState) => state.userAccess.value)

    const deleteAccess = useMemo(
        () => userAccessData?.find((itm) => itm.formName === 'Users')?.delete,
        [userAccessData],
    )

    const getUserColumns = (selectionList: GridRowId[], onClick: (id?: number) => void) => [
        Column<SingleUser>({
            field: 'fullName',
            headerName: 'نام و نام خانوادگی',
            valueGetter: (_, row) => `${row.name} ${row.lastname}`,
            variant: ColumnVariants.withProfile,
            headerAlign: 'right',
        }),
        Column<SingleUser>({
            headerAlign: 'left',
            field: 'mobileNumber',
            headerName: 'شماره موبایل',
            valueGetter: (_, row) => row.phone,
            variant: ColumnVariants.withPhone,
        }),
        Column<SingleUser>({
            headerAlign: 'left',
            field: 'email',
            headerName: 'ایمیل',
            valueGetter: (_, row) => row.email,
            sortable: false,
            align: 'right',
        }),
        Column<SingleUser>({
            headerAlign: 'center',
            field: 'accountState',
            headerName: 'وضعیت حساب',
            valueGetter: (_, row) => (row.activated ? 'فعال' : 'غیر فعال'),
            renderCell: (props) => (
                <div
                    className={cn(
                        'truncate text-sm h-full flex items-center justify-center w-full',
                        props.row.activated ? 'text-success-500' : 'text-alarm-500',
                    )}
                    title={props.value}
                >
                    {props.value}
                </div>
            ),
        }),
        Column<SingleUser>({
            field: 'accessLevel',
            headerName: 'سطح دسترسی',
            valueGetter: (_, row) => row?.url ?? '',
            sortable: false,
        }),
        Column<SingleUser>({
            field: 'actions',
            headerName: '',
            valueGetter: () => '',
            headerAlign: 'left',
            renderHeader: () => (
                <>
                    {deleteAccess && (
                        <Button
                            aClassName='mr-auto'
                            variant={ButtonVariants.danger}
                            size={ButtonSizes.small}
                            onClick={() => onClick()}
                            className={cn((selectionList?.length ?? 0) > 0 ? 'flex' : 'hidden')}
                        >
                            حذف همه
                        </Button>
                    )}
                </>
            ),
            renderCell: (props) => (
                <div className='flex items-center justify-end pl-4 gap-8 w-full h-full text-gray-700'>
                    <a
                        href={'./addUser?id=' + props.row.id}
                        className='flex justify-center'
                    >
                        <button title='Edit'>
                            <Edit2
                                width={24}
                                height={24}
                            />
                        </button>
                    </a>
                    {deleteAccess && (
                        <button
                            title='Delete'
                            onClick={() => onClick(props.row.id)}
                        >
                            <Trash
                                width={24}
                                height={24}
                            />
                        </button>
                    )}
                </div>
            ),
            sortable: false,
        }),
    ]

    return (
        <ListPage
            apiFunction={getUsersInGeneral}
            generateDialog={(dialogData, setDialogData, processedRows, selectionList) => (
                <DeleteConfirmDialog
                    onClose={() => setDialogData(false)}
                    accept={
                        dialogData === 'deleteAll'
                            ? () => deleteUsers(selectionList ?? [])
                            : dialogData !== false
                            ? () => deleteUsers([dialogData])
                            : () => null
                    }
                    open={dialogData !== false}
                    itemToBeDeleted={
                        dialogData === 'deleteAll'
                            ? undefined
                            : dialogData !== false
                            ? getFullName(processedRows.filter((row) => row.id === dialogData)[0])
                            : undefined
                    }
                />
            )}
            getColumns={getUserColumns}
            itemName={'کاربر'}
            addPageLink='./addUser'
            queryKey={['users']}
            accessName='Users'
        />
    )
}

export default Users
