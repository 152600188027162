/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../redux/hooks'

const RootLayout = ({ children }: { children: React.ReactNode }) => {
    const navigate = useNavigate()
    const userAccess = useAppSelector((s) => s.userAccess.value)

    useEffect(() => {
        if (userAccess !== undefined) {
            navigate('/cms')
        } else {
            navigate('/auth/login')
        }
    }, [userAccess])

    return (
        <div className='h-full overflow-scroll'>
            <h1>Root Layout</h1>

            <div>{children}</div>
        </div>
    )
}

export default RootLayout
