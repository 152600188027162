import { FC, useCallback, useMemo, useState } from 'react'
import ListPage from '../_components/list-page'
import { deleteFaqs, getFaqs, MoveFaq } from './api'
import DeleteConfirmDialog from '../_components/list-page/components/dialog'
import { GridRowId } from '@mui/x-data-grid'
import { Edit2, Trash } from 'iconsax-react'
import Button, { ButtonVariants, ButtonSizes } from '../../../_core/components/button'
import { Column, Sizes } from '../../../_core/components/table/column'
import { cn } from '../../../utils/cn'
import { Faq } from './data-types'
import { ArrowDown, ArrowUp } from '../../../_core/components/icons'
import { useIsFetching, useMutation, useQueryClient } from '@tanstack/react-query'
import { DialogTypes, useDialogStore } from '../_components/Dialog'
import { useSelector } from 'react-redux'
import { RootState } from '../../../_core/redux/store'

const Faqs: FC = () => {
    const queryClient = useQueryClient()

    const userAccessData = useSelector((state: RootState) => state.userAccess.value)
    const faqsAccess = useMemo(() => userAccessData?.find((itm) => itm.formName === 'Faq'), [userAccessData])

    const { setDialogState } = useDialogStore()

    const { mutate, isPending } = useMutation({
        mutationFn: MoveFaq,
        mutationKey: ['faqs'],
        onSuccess: () =>
            queryClient.invalidateQueries({
                queryKey: ['faqs'],
            }),
        onError: (error) => {
            console.error(error)
            setDialogState({
                open: true,
                type: DialogTypes.error,
                title: 'مشکل در جابه‌جا کردن سوال',
                description: 'سوال مورد نظر جا به جا نشد.',
            })
        },
    })

    const isFetchingTodos = useIsFetching({ queryKey: ['faqs'] })

    const [isDeleting, setIsDeleting] = useState<boolean>(false)

    const getFaqsColumns = useCallback(
        (selectionList: GridRowId[], onClick: (id?: number) => void) => [
            Column<Faq>({
                headerAlign: 'right',
                field: 'id',
                headerName: 'ردیف',
                valueGetter: (_, row) => row.id?.toString() ?? '-1',
                renderCell: (props) => {
                    const index = props.api.getAllRowIds().indexOf(props.row.id ?? -1)
                    const length = props.api.getRowsCount()

                    const currentRowId = props.id

                    const allRowIds = props.api.getAllRowIds()
                    const currentRowIndex = allRowIds.indexOf(currentRowId)

                    const prevRowId = allRowIds[currentRowIndex - 1]
                    const nextRowId = allRowIds[currentRowIndex + 1]
                    const prevRow = prevRowId ? props.api.getRow<Faq>(prevRowId) : null
                    const nextRow = nextRowId ? props.api.getRow<Faq>(nextRowId) : null
                    return (
                        <div className='flex items-center mr-4 w-full h-full'>
                            <button
                                type='button'
                                onClick={() =>
                                    prevRow &&
                                    prevRow.row &&
                                    props.row.id &&
                                    mutate({ id: props.row.id, row: prevRow.row })
                                }
                                disabled={index === 0}
                                className='disabled:text-gray-200 text-primary-500'
                            >
                                <ArrowUp />
                            </button>

                            <div className='w-9 text-center text-gray-700 text-sm'>{props.row.index}</div>

                            <button
                                type='button'
                                onClick={() =>
                                    nextRow &&
                                    nextRow.row &&
                                    props.row.id &&
                                    mutate({ id: props.row.id, row: nextRow.row })
                                }
                                disabled={index === length - 1}
                                className='disabled:text-gray-200 text-primary-500'
                            >
                                <ArrowDown />
                            </button>
                        </div>
                    )
                },
                size: Sizes.small,
                sortable: false,
            }),
            Column<Faq>({
                field: 'question',
                headerName: 'سوال',
                valueGetter: (_, row) => row.question,
                size: Sizes.bigger,
                sortable: false,
                headerAlign: 'right',
                align: 'left',
            }),
            Column<Faq>({
                field: 'answer',
                headerName: 'جواب ',
                valueGetter: (_, row) => row.answer ?? '',
                renderCell: (params) => <span dangerouslySetInnerHTML={{ __html: params.value ?? '' }} />,
                size: Sizes.bigger,
                sortable: false,
                headerAlign: 'right',
                align: 'left',
            }),
            Column<Faq>({
                size: Sizes.small,
                field: 'actions',
                headerName: '',
                valueGetter: () => '',
                renderHeader: () =>
                    faqsAccess?.delete && (
                        <Button
                            aClassName='mr-auto'
                            variant={ButtonVariants.danger}
                            size={ButtonSizes.small}
                            onClick={() => onClick()}
                            className={cn((selectionList?.length ?? 0) > 0 ? 'flex' : 'hidden')}
                        >
                            حذف همه
                        </Button>
                    ),
                renderCell: (props) => (
                    <div className='flex items-center justify-end gap-8 pl-4 w-full h-full text-gray-700'>
                        {faqsAccess?.update && (
                            <a
                                href={'./faq/update-faq?id=' + props.row.id}
                                className='flex justify-center'
                            >
                                <button title='Edit'>
                                    <Edit2
                                        width={24}
                                        height={24}
                                    />
                                </button>
                            </a>
                        )}

                        {faqsAccess?.delete && (
                            <button
                                title='Delete'
                                onClick={() => onClick(props.row.id)}
                            >
                                <Trash
                                    width={24}
                                    height={24}
                                />
                            </button>
                        )}
                    </div>
                ),
                sortable: false,
            }),
        ],
        [faqsAccess?.delete, faqsAccess?.update, mutate],
    )

    return (
        <div
            className={cn(
                (isFetchingTodos || isPending || isDeleting) &&
                    '[&_.MuiDataGrid-root]:opacity-50 [&_.MuiDataGrid-root]:pointer-events-none',
            )}
        >
            <ListPage
                apiFunction={getFaqs}
                getColumns={getFaqsColumns}
                itemName={'سوال'}
                addPageLink='./faq/add-faq'
                queryKey={['faqs']}
                accessName='Faq'
                generateID={(row) => row.id ?? -1}
                generateDialog={(dialogData, setDialogData, processedRows, selectionList) => (
                    <DeleteConfirmDialog
                        title='حذف سوالات'
                        itemToBeDeletedType='سوالات متداول'
                        onClose={() => setDialogData(false)}
                        accept={
                            dialogData === 'deleteAll'
                                ? () => {
                                      setIsDeleting(true)

                                      deleteFaqs(selectionList ?? [])
                                          .then((data) => {
                                              if (
                                                  (data?.[0]?.['status'] ?? '') === 'Unauthorized.' ||
                                                  (data?.[0]?.['status'] ?? '') === 'access'
                                              )
                                                  setDialogState({
                                                      open: true,
                                                      title: 'خطای دسترسی',
                                                      type: DialogTypes.error,
                                                      description: 'شما دسترسی ندارید.',
                                                  })
                                          })
                                          .finally(() => {
                                              queryClient
                                                  .invalidateQueries({
                                                      queryKey: ['faqs'],
                                                  })
                                                  .then(() => setIsDeleting(false))
                                          })
                                  }
                                : dialogData !== false
                                ? () => {
                                      setIsDeleting(true)

                                      deleteFaqs([dialogData])
                                          .then((data) => {
                                              if (
                                                  (data?.[0]?.['status'] ?? '') === 'Unauthorized.' ||
                                                  (data?.[0]?.['status'] ?? '') === 'access'
                                              )
                                                  setDialogState({
                                                      open: true,
                                                      title: 'خطای دسترسی',
                                                      type: DialogTypes.error,
                                                      description: 'شما دسترسی ندارید.',
                                                  })
                                          })
                                          .finally(() => {
                                              queryClient
                                                  .invalidateQueries({
                                                      queryKey: ['faqs'],
                                                  })
                                                  .then(() => setIsDeleting(false))
                                          })
                                  }
                                : () => null
                        }
                        open={dialogData !== false}
                        itemToBeDeleted={
                            dialogData === 'deleteAll'
                                ? undefined
                                : dialogData !== false
                                ? processedRows.filter((row) => row.id === dialogData)[0].question
                                : undefined
                        }
                    />
                )}
            />
        </div>
    )
}

export default Faqs
