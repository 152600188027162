/* eslint-disable react-hooks/exhaustive-deps */
import { AddCircle, GalleryAdd, Trash } from 'iconsax-react'
import { useState, useEffect } from 'react'
import { MediaManager } from './MediaManager'
import { cn } from '../../../utils/cn'
import { SuggestionServer } from './SuggestionForm'

type UploadSectionType = {
    type: 'ICON' | 'IMAGE' | 'SUGGESTION'
    title: string
    value?: string
    onChange: (data: string) => void
    className?: string
    oldDesign?: boolean
}

export const UploadSection = ({ type, title, value, onChange, className, oldDesign }: UploadSectionType) => {
    const [icon, setIcon] = useState<string>(value ?? '')
    const [image, setImage] = useState<string>(value ?? '')
    const [suggestion, setSuggestion] = useState<SuggestionServer | undefined>()
    const [isOpenIconModal, setIsOpenIconModal] = useState<boolean>(false)
    const [isOpenImageModal, setIsOpenImageModal] = useState<boolean>(false)
    const [isOpenSuggestionModal, setIsOpenSuggestionModal] = useState<boolean>(false)

    const ImagesStyles = cn('w-full h-full object-cover rounded-md', oldDesign && 'rounded-full')
    const buttonStyles = cn(
        'h-24 border border-dashed border-gray-300 text-gray-200 gap-2 flex flex-col justify-center items-center rounded text-xs',
        oldDesign && 'size-20 mx-auto rounded-full bg-gray-100 border-gray-600 text-gray-600 relative mb-8',
    )

    useEffect(() => {
        onChange(type === 'IMAGE' ? image : type === 'ICON' ? icon : String(suggestion?.title))
    }, [image, icon, suggestion])

    return (
        <>
            {type === 'IMAGE' && (
                <>
                    <button
                        type='button'
                        className={cn(buttonStyles, className)}
                        onClick={() => setIsOpenImageModal(true)}
                    >
                        {image ? (
                            <div className='overflow-hidden w-full h-full relative'>
                                <img
                                    alt=''
                                    src={image}
                                    className={ImagesStyles}
                                />

                                {!oldDesign && (
                                    <div className='absolute right-2 top-2 px-3 py-1 text-xs text-gray-700 uppercase rounded-sm bg-white'>
                                        {title}
                                    </div>
                                )}

                                {!oldDesign && (
                                    <div
                                        className='absolute left-2 bottom-2 p-1 text-alarm-500 rounded-sm bg-white'
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setImage('')
                                        }}
                                    >
                                        <Trash size={22} />
                                    </div>
                                )}
                            </div>
                        ) : oldDesign ? (
                            <GalleryAdd />
                        ) : (
                            <>
                                <AddCircle />
                                <span>{title}</span>
                            </>
                        )}

                        {oldDesign && (
                            <div
                                className={cn(
                                    'absolute bottom-0 translate-y-full pt-4 text-sm text-primary-500 whitespace-nowrap',
                                    image && 'text-alarm-500',
                                )}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setImage('')
                                }}
                            >
                                {image ? 'حذف تصویر' : 'بارگذاری تصویر'}
                            </div>
                        )}
                    </button>

                    {isOpenImageModal && (
                        <MediaManager
                            type='IMAGE'
                            isOpen={isOpenImageModal}
                            onChange={(newImage) => setImage(newImage as string)}
                            onClose={() => setIsOpenImageModal(false)}
                        />
                    )}
                </>
            )}

            {type === 'ICON' && (
                <>
                    <button
                        type='button'
                        className={cn('w-48 flex items-center gap-4', className)}
                        onClick={() => setIsOpenIconModal(true)}
                    >
                        {icon && (
                            <img
                                alt=''
                                src={icon}
                                className='w-10 h-10'
                            />
                        )}
                        <span>{title}</span>
                    </button>

                    {isOpenIconModal && (
                        <MediaManager
                            type='ICON'
                            isOpen={isOpenIconModal}
                            onChange={(newIcon) => setIcon(newIcon as string)}
                            onClose={() => setIsOpenIconModal(false)}
                        />
                    )}
                </>
            )}

            {type === 'SUGGESTION' && (
                <>
                    <button
                        type='button'
                        onClick={() => setIsOpenSuggestionModal(true)}
                        className={className}
                    >
                        {suggestion && <p>{suggestion.title}</p>}
                        <span>{title}</span>
                    </button>

                    {isOpenSuggestionModal && (
                        <MediaManager
                            type='SUGGESTION'
                            isOpen={isOpenSuggestionModal}
                            onClose={() => setIsOpenSuggestionModal(false)}
                            onChange={(newSuggestion) => setSuggestion(newSuggestion as SuggestionServer)}
                        />
                    )}
                </>
            )}
        </>
    )
}
