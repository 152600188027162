import { FC, useCallback, useMemo, useState } from 'react'
import { Controller, Path, useFieldArray, useFormContext } from 'react-hook-form'
import { TestDetail } from '../data-types'
import Section from './section'
import { Add, Paperclip2, Trash } from 'iconsax-react'
import SimpleControlledInput, { CustomTextField, TextFieldWithoutControl } from '../../AddUser/components/text-field'
import { DeleteButton, Edit } from '../../../../_core/components/icons'
import { InputAdornment } from '@mui/material'
import { UploadSection } from '../../_components/UploadSection'
import CustomRichTextBox from '../../_components/CustomRichTextBox'
import { MediaManager } from '../../_components/MediaManager'
import { TabChildProps } from './test-form'
import { SuggestionServer } from '../../_components/SuggestionForm'
import toEnglishNumber from '../../../../utils/to-english-number'

const Scales: FC<TabChildProps> = ({ parent }) => {
    const { control, getValues, setValue } = useFormContext<TestDetail>()

    const formPrefix = useMemo(() => (parent === 'subscales' ? 'subIndicators' : 'indicators'), [parent])

    const {
        fields: scalesFields,
        append: appendScale,
        remove: removeScale,
    } = useFieldArray({
        control,
        name: formPrefix,
        keyName: '_id',
    })

    const updateIds = useCallback(() => {
        const currentValues = getValues(formPrefix)

        currentValues?.forEach((item, index) => {
            item.id = index + 1
        })

        setValue(formPrefix, currentValues)
    }, [formPrefix, getValues, setValue])

    return (
        <>
            <Section className='p-0'>
                <button
                    type='button'
                    onClick={() => appendScale({ id: scalesFields.length + 1, index: scalesFields.length + 1 })}
                    className='flex items-center gap-2 flex-row p-[0.875rem]'
                >
                    <div className='w-9 h-9 rounded-full bg-primary-500 flex items-center justify-center'>
                        <Add
                            size={24}
                            className='text-white'
                        />
                    </div>

                    <div className='text-gray-700'>افزودن {parent === 'subscales' && 'خرده'} مقیاس جدید</div>
                </button>
            </Section>

            {scalesFields.map((field, index) => (
                <Section
                    key={field.id}
                    className='p-0'
                >
                    <div className='p-6 flex flex-col gap-11'>
                        <div className='flex justify-between items-center gap-px'>
                            <SimpleControlledInput<TestDetail>
                                control={control}
                                name={`${formPrefix}.${index}.id`}
                                className='w-24 text-center p-1 border border-gray-200 rounded'
                                readOnly
                            />

                            <button
                                type='button'
                                onClick={() => {
                                    removeScale(index)
                                    updateIds()
                                }}
                            >
                                <DeleteButton className='w-[2.625rem] h-[2.625rem]' />
                            </button>
                        </div>

                        <div
                            className='flex gap-3 w-full'
                            key={field.id}
                        >
                            <CustomTextField<TestDetail>
                                control={control}
                                name={`${formPrefix}.${index}.code`}
                                label='کد'
                                containerClassName='basis-32'
                                generateOnChange={(value) => toEnglishNumber(value.replace(/[^0-9\u06F0-\u06F9]/g, ''))}
                                generateValue={(value) => value?.toString() ?? ''}
                            />

                            <CustomTextField<TestDetail>
                                control={control}
                                name={
                                    `${formPrefix}.${index}.${
                                        formPrefix === 'indicators' ? 'indicator' : 'title'
                                    }` as Path<TestDetail>
                                }
                                label='عنوان'
                                containerClassName='grow'
                            />
                        </div>

                        <CustomTextField<TestDetail>
                            control={control}
                            name={`${formPrefix}.${index}.measurementTitle`}
                            label='عنوان اندازه‌‌گیری'
                        />

                        <CustomTextField<TestDetail>
                            control={control}
                            name={`${formPrefix}.${index}.description`}
                            label='توضیحات'
                        />

                        <CustomTextField<TestDetail>
                            control={control}
                            name={`${formPrefix}.${index}.formula`}
                            label='فرمول محاسبه'
                        />
                    </div>

                    <Results
                        index={index}
                        formPrefix={formPrefix}
                    />
                </Section>
            ))}
        </>
    )
}

export default Scales

interface ResultsProps {
    index: number
    formPrefix: 'subIndicators' | 'indicators'
}

const Results: FC<ResultsProps> = ({ index, formPrefix }) => {
    const { control, watch } = useFormContext<TestDetail>()

    const {
        fields: resultFields,
        append: appendResult,
        remove: removeResult,
    } = useFieldArray({
        control,
        name: `${formPrefix}.${index}.result`,
    })

    const singularFormPrefix = useMemo(() => {
        switch (formPrefix) {
            case 'subIndicators':
                return 'subIndicator'
            case 'indicators':
                return 'indicator'
        }
    }, [formPrefix])

    return (
        <>
            <button
                type='button'
                className='flex items-center gap-1 w-fit mx-14'
                onClick={() => appendResult({ id: resultFields.length + 1 })}
            >
                <div className='w-6 h-6 rounded-full bg-primary-500 text-white flex items-center justify-center'>
                    <Add size={19} />
                </div>

                <div className='text-sm text-gray-700'>افزودن تفسیر</div>
            </button>

            <div className='bg-gray-50 divide-y divide-gray-100 flex flex-col'>
                {resultFields.map((field, resultIndex) => (
                    <div
                        className='p-6 pt-4 relative flex flex-col gap-10'
                        key={field.id}
                    >
                        <button
                            className='absolute left-7 top-7'
                            type='button'
                            onClick={() => {
                                removeResult(resultIndex)
                            }}
                        >
                            <DeleteButton className='w-[2.625rem] h-[2.625rem]' />
                        </button>

                        <div className='grid grid-cols-3 gap-[0.875rem]'>
                            <div className='relative'>
                                <TextFieldWithoutControl
                                    label='نمره'
                                    disabled
                                />

                                <div className='absolute inset-0 flex items-center *:grow'>
                                    <SimpleControlledInput<TestDetail>
                                        control={control}
                                        name={
                                            `${formPrefix}.${index}.result.${resultIndex}.${singularFormPrefix}ResultFrom` as Path<TestDetail>
                                        }
                                        placeholder=' از'
                                        generateOnChange={(value) =>
                                            toEnglishNumber(value.replace(/[^0-9\u06F0-\u06F9]/g, ''))
                                        }
                                        generateValue={(value) => value?.toString() ?? ''}
                                        className='min-w-0 bg-transparent h-[23px] pr-[14px] py-[28px] outline-none'
                                    />

                                    <div className='max-w-px w-px h-[21px] bg-gray-700' />

                                    <SimpleControlledInput<TestDetail>
                                        control={control}
                                        name={
                                            `${formPrefix}.${index}.result.${resultIndex}.${singularFormPrefix}ResultTo` as Path<TestDetail>
                                        }
                                        placeholder=' تا'
                                        generateOnChange={(value) =>
                                            toEnglishNumber(value.replace(/[^0-9\u06F0-\u06F9]/g, ''))
                                        }
                                        generateValue={(value) => value?.toString() ?? ''}
                                        className='min-w-0 bg-transparent h-[23px] pr-[14px] py-[28px] outline-none'
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='grid grid-cols-3 gap-[0.875rem]'>
                            <CustomTextField<TestDetail>
                                control={control}
                                name={
                                    `${formPrefix}.${index}.result.${resultIndex}.${singularFormPrefix}ResultTitle` as Path<TestDetail>
                                }
                                label='عنوان تفسیر'
                            />

                            <CustomTextField<TestDetail>
                                control={control}
                                name={
                                    `${formPrefix}.${index}.result.${resultIndex}.${singularFormPrefix}ResultGageLabel` as Path<TestDetail>
                                }
                                label='عنوان طیف'
                            />

                            <Controller<TestDetail>
                                name={
                                    `${formPrefix}.${index}.result.${resultIndex}.${singularFormPrefix}ResultGageIcon` as Path<TestDetail>
                                }
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <TextFieldWithoutControl
                                            label='آیکون'
                                            disabled
                                            className='relative'
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment
                                                        position='end'
                                                        className='flex gap-2'
                                                    >
                                                        <div className='bg-gray-300 h-7 w-px' />

                                                        <Edit />

                                                        <UploadSection
                                                            className='absolute inset-0 m-auto opacity-0 w-full'
                                                            value={value}
                                                            type={'ICON'}
                                                            title=''
                                                            onChange={onChange}
                                                        />
                                                    </InputAdornment>
                                                ),
                                                startAdornment: (
                                                    <InputAdornment position='start'>
                                                        {watch(
                                                            `${formPrefix}.${index}.result.${resultIndex}.${singularFormPrefix}ResultGageIcon` as Path<TestDetail>,
                                                        ) && (
                                                            <img
                                                                src={watch(
                                                                    `${formPrefix}.${index}.result.${resultIndex}.${singularFormPrefix}ResultGageIcon` as Path<TestDetail>,
                                                                )}
                                                                alt='icon'
                                                                className='w-9 h-9 object-contain object-center'
                                                            />
                                                        )}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </>
                                )}
                            />
                        </div>

                        <CustomRichTextBox<TestDetail>
                            limit={Infinity}
                            label='تفسیر رایگان'
                            control={control}
                            name={`${formPrefix}.${index}.result.${resultIndex}.result`}
                        />

                        <ResultSuggestions
                            formPrefix={formPrefix}
                            indicatorIndex={index}
                            resultIndex={resultIndex}
                        />
                    </div>
                ))}
            </div>
        </>
    )
}

interface ResultSuggestionsProps {
    indicatorIndex: number
    resultIndex: number
    formPrefix: 'subIndicators' | 'indicators'
}

const ResultSuggestions: FC<ResultSuggestionsProps> = ({ indicatorIndex, resultIndex, formPrefix }) => {
    const { control, watch } = useFormContext<TestDetail>()

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

    const {
        fields: suggestionFields,
        append: appendSuggestion,
        remove: removeSuggestion,
    } = useFieldArray({
        control,
        name: `${formPrefix}.${indicatorIndex}.result.${resultIndex}.suggestions`,
        keyName: '_id',
    })

    return (
        <div className='flex flex-col gap-4'>
            <div className='w-fit relative'>
                <div
                    className='text-primary-500 underline cursor-pointer'
                    onClick={() => setIsDialogOpen(true)}
                >
                    + افزودن پیشنهادها
                </div>

                {isDialogOpen && (
                    <MediaManager
                        type='SUGGESTION'
                        isOpen={isDialogOpen}
                        onClose={() => setIsDialogOpen(false)}
                        onChange={(rawNewSuggestion) => {
                            const newSuggestion = rawNewSuggestion as SuggestionServer

                            if (
                                suggestionFields.filter((suggestionField) => suggestionField.id === newSuggestion.id)
                                    .length === 0
                            )
                                appendSuggestion({
                                    id: newSuggestion.id,
                                    indicator_id: watch(`${formPrefix}.${indicatorIndex}`).id,
                                    result_id: watch(`${formPrefix}.${indicatorIndex}.result.${resultIndex}`).id,
                                    suggestion: newSuggestion.id,
                                    title: newSuggestion.title,
                                    test_id: watch('id'),
                                })
                        }}
                    />
                )}
            </div>

            <div className='grid-cols-3 grid gap-4'>
                {suggestionFields.map((suggestion, suggestionIndex) => (
                    <div
                        className='bg-[#F5F5F5] rounded flex p-4 gap-4 items-center text-gray-700'
                        key={suggestion._id}
                    >
                        <div className='flex items-center grow min-w-0 gap-1 text-primary-500'>
                            <Paperclip2
                                size={20}
                                className='shrink-0'
                            />

                            <div
                                className='text-sm truncate grow'
                                title={suggestion.title}
                            >
                                {suggestion.title}
                            </div>
                        </div>

                        <button
                            type='button'
                            className='shrink-0'
                        >
                            <Trash
                                size={20}
                                onClick={() => removeSuggestion(suggestionIndex)}
                            />
                        </button>

                        {/* <button
                                type='button'
                                className='shrink-0'
                            >
                                <Edit2 size={20} />
                            </button> */}
                    </div>
                ))}
            </div>
        </div>
    )
}

// TODOs
// redesign the upload suggestion dialogs
// practical check
