import { type FC } from 'react'
import CMSLayout from '../../../_core/components/layouts/cms-dashboard/CMSLayout'
import { DataGrid } from '@mui/x-data-grid'
import { CustomTextField } from './components/text-field'
import CustomSwitch from './components/switch'
import Button, { ButtonSizes, ButtonVariants } from '../../../_core/components/button'
import { useAddUser } from './hooks'
import './table.css'
import { cn } from '../../../utils/cn'
import SuccessMessageDialog from './components/dialog'
import { Refresh } from 'iconsax-react'
import { SingleUser } from '../Users/data-types/users'
import ColorInput from './components/color-input'
import CustomPhoneInput from './components/phone-input'
import { Controller, FormProvider } from 'react-hook-form'
import { UploadSection } from '../_components/UploadSection'
import Section from '../Tests/components/section'

const AddUser: FC = () => {
    const {
        columns,
        control,
        handleSubmit,
        watch,
        register,
        setValue,
        dialogOpen,
        setDialogOpen,
        isPending,
        rows,
        loadingAllOnEdit,
        originalHandleSubmit,
        reset,
        otherFormProps,
    } = useAddUser()

    return (
        <CMSLayout className={cn(loadingAllOnEdit && '*:opacity-50 *:pointer-events-none')}>
            <FormProvider
                handleSubmit={originalHandleSubmit}
                control={control}
                setValue={setValue}
                watch={watch}
                register={register}
                reset={reset}
                {...otherFormProps}
            >
                <form
                    onSubmit={handleSubmit}
                    className='flex flex-col gap-4'
                >
                    <div className='text-sm'>
                        <span className='text-gray-400'>کاربران / </span>
                        <span className='text-gray-600 font-medium'>کاربر جدید</span>
                    </div>

                    <div className='flex flex-col gap-3'>
                        <div className='flex gap-2'>
                            <div className='bg-white basis-3/4 grid grid-cols-3 px-5 py-9 gap-8'>
                                <CustomTextField<SingleUser>
                                    name='name'
                                    label='نام'
                                    control={control}
                                />
                                <CustomTextField<SingleUser>
                                    name='lastname'
                                    label='نام خانوادگی'
                                    control={control}
                                />
                                <CustomTextField<SingleUser>
                                    dir='ltr'
                                    name='initial'
                                    label='Initial'
                                    control={control}
                                    inputProps={{ maxLength: 2 }}
                                />
                                {/* <CustomTextField<SingleUser>
                                    dir='ltr'
                                    name='phone'
                                    label='شماره موبایل'
                                    control={control}
                                    generateOnChange={(value) => '+' + value.replaceAll(/[^0-9]/g, '')}
                                    generateValue={(value) => value.replaceAll(/[^0-9]/g, '')}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment
                                                className='*:!border-none !ml-4 [&_.MuiTypography-root]:!pr-0'
                                                position='start'
                                            >
                                                +
                                            </InputAdornment>
                                        ),
                                    }}
                                /> */}
                                <CustomPhoneInput<SingleUser> name='phone' />
                                <CustomTextField<SingleUser>
                                    dir='ltr'
                                    name='email'
                                    label='ایمیل'
                                    control={control}
                                />
                                <CustomTextField<SingleUser>
                                    dir='ltr'
                                    name='url'
                                    label='آدرس پیش فرض'
                                    control={control}
                                />
                                <CustomTextField<SingleUser>
                                    dir='ltr'
                                    name='password'
                                    label='رمز عبور'
                                    control={control}
                                    type='password'
                                />
                                <CustomTextField<SingleUser>
                                    type='password'
                                    dir='ltr'
                                    name='confirmPassword'
                                    label='تکرار رمز عبور'
                                    control={control}
                                />
                            </div>

                            <div className='basis-1/4 flex flex-col gap-2'>
                                <div
                                    className='bg-white px-1 py-2 flex justify-between items-center rounded'
                                    title='فعال یا غیر فعال بودن حساب کاربر'
                                >
                                    <div className='text-[0.875rem] text-gray-700'>وضعیت حساب کاربر</div>
                                    <CustomSwitch
                                        control={control}
                                        name='activated'
                                    />
                                </div>

                                <div className='p-4 bg-white rounded'>
                                    <ColorInput<SingleUser>
                                        control={control}
                                        name='color'
                                    />
                                </div>

                                <Section className='p-4'>
                                    <Controller
                                        name='profile_picture'
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <UploadSection
                                                oldDesign
                                                value={value}
                                                type='IMAGE'
                                                title='بارگذاری تصویر'
                                                onChange={onChange}
                                            />
                                        )}
                                    />
                                </Section>
                            </div>
                        </div>

                        <div className='flex gap-2'>
                            <div className='bg-white basis-3/4 min-w-0 flex flex-col py-4 px-8 gap-4'>
                                <div className='text-[##5C5C5C] font-medium text-sm'>سطوح دسترسی</div>

                                <DataGrid
                                    className='add-data-grid no-header-padding add-user-grid max-h-96'
                                    getRowId={(row) => row.formId}
                                    disableRowSelectionOnClick
                                    getRowSpacing={() => ({ top: 4 })}
                                    rows={rows}
                                    columns={columns}
                                    slots={{
                                        columnResizeIcon: () => (
                                            <div className='w-px h-[1.5625rem] bg-gray-200 mx-[0.71875rem]' />
                                        ),
                                    }}
                                    hideFooter
                                />
                            </div>
                            <div className='basis-1/4' />
                        </div>

                        <div className='flex gap-4 mt-5'>
                            <Button
                                disabled={isPending}
                                type='submit'
                                className='w-[9.375rem] text-gray-50'
                                size={ButtonSizes.bigger}
                            >
                                {isPending ? <Refresh className='animate-spin' /> : 'ثبت'}
                            </Button>

                            <Button
                                type='button'
                                size={ButtonSizes.big}
                                className='w-[9.375rem]'
                                variant={ButtonVariants.gray}
                                href='./users'
                            >
                                انصراف
                            </Button>
                        </div>
                    </div>
                </form>
            </FormProvider>

            <SuccessMessageDialog
                onClose={() => setDialogOpen(false)}
                open={dialogOpen !== false}
                theUser={dialogOpen !== false ? watch('name') + ' ' + watch('lastname') : undefined}
                type={dialogOpen === 'added' ? 'added' : 'updated'}
            />
        </CMSLayout>
    )
}

export default AddUser
