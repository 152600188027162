import { z } from '../../../../utils/zod-translation'
import { TherapistDetailSchema } from '../components/therapist-form'

export interface Therapist {
    id: number
    name: string
    treatments: string
    isActive: boolean
    phone: string
    timezone: string
    status: boolean
}

export type TherapistDetail = z.infer<typeof TherapistDetailSchema>

export type TherapistPostServer = Omit<
    z.infer<typeof TherapistDetailSchema>,
    'treatment_ways' | 'education' | 'cv' | 'license' | 'research'
> & {
    treatment_ways?: string
    education?: string
    cv?: string
    license?: string
    research?: string
}

export interface ExcludedDate {
    date?: string
    from?: string
    to?: string
    new?: boolean
}

export interface WorkingDay {
    id?: number
    day?: number
    status?: boolean
    hours?: Hour[]
}

export interface Hour {
    from?: string
    to?: string
}

export interface Fee {
    startDate?: string
    insideFee?: string
    insideFeeUnit?: string
    outsideFee?: string
    outsideFeeUnit?: string
    new?: boolean
}

export interface TherapistPostResponse {
    pdf?: boolean
    error?: boolean
    status?: string
    message?: string
}

export interface ActiveHoliday {
    date?: string
}

export enum ReservationType {
    internal = 'داخلی',
    external = 'خارجی',
}

export enum ReservationUrgency {
    normal = 'دریافت نوبت',
    urgent = 'مشاوره فوری',
}

export enum ReservationMethod {
    online = 'آنلاین',
    inPerson = 'حضوری',
    byPhone = 'تلفنی',
}

export enum ReservationPaymentStatus {
    paid = 'پرداخت شده',
    notPaid = 'پرداخت نشده',
}

export enum ReservationStatus {
    running = 'در حال برگزاری',
    canceled = 'کنسل شده',
    done = 'برگذار شده',
}

export interface ReservationHistory {
    id?: number
    photo?: string
    name?: string
    description?: string
    phone?: string
    reserveDate?: string
    reserver?: string
    type?: ReservationType
    timezone?: string
    price?: string
    trackingCode?: string
    method?: ReservationMethod
    paymentStatus?: ReservationPaymentStatus
    status?: ReservationStatus
    cancelDate?: string
    canceler?: string
}
