export const countries = [
    { country: 'Afghanistan', calling_code: 93, id: 0, abbreviation: 'af' },
    { country: 'Albania', calling_code: 355, id: 1, abbreviation: 'al' },
    { country: 'Algeria', calling_code: 213, id: 2, abbreviation: 'dz' },
    { country: 'American Samoa', calling_code: 1684, id: 3, abbreviation: 'as' },
    { country: 'Andorra', calling_code: 376, id: 4, abbreviation: 'ad' },
    { country: 'Angola', calling_code: 244, id: 5, abbreviation: 'ao' },
    { country: 'Anguilla', calling_code: 1264, id: 6, abbreviation: 'ai' },
    { country: 'Antarctica', calling_code: 672, id: 7, abbreviation: 'aq' },
    { country: 'Antigua and Barbuda', calling_code: 1268, id: 8, abbreviation: 'ag' },
    { country: 'Argentina', calling_code: 54, id: 9, abbreviation: 'ar' },
    { country: 'Armenia', calling_code: 374, id: 10, abbreviation: 'am' },
    { country: 'Aruba', calling_code: 297, id: 11, abbreviation: 'aw' },
    { country: 'Australia', calling_code: 61, id: 12, abbreviation: 'au' },
    { country: 'Austria', calling_code: 43, id: 13, abbreviation: 'at' },
    { country: 'Azerbaijan', calling_code: 994, id: 14, abbreviation: 'az' },
    { country: 'Bahamas', calling_code: 1242, id: 15, abbreviation: 'bs' },
    { country: 'Bahrain', calling_code: 973, id: 16, abbreviation: 'bh' },
    { country: 'Bangladesh', calling_code: 880, id: 17, abbreviation: 'bd' },
    { country: 'Barbados', calling_code: 1246, id: 18, abbreviation: 'bb' },
    { country: 'Belarus', calling_code: 375, id: 19, abbreviation: 'by' },
    { country: 'Belgium', calling_code: 32, id: 20, abbreviation: 'be' },
    { country: 'Belize', calling_code: 501, id: 21, abbreviation: 'bz' },
    { country: 'Benin', calling_code: 229, id: 22, abbreviation: 'bj' },
    { country: 'Bermuda', calling_code: 1441, id: 23, abbreviation: 'bm' },
    { country: 'Bhutan', calling_code: 975, id: 24, abbreviation: 'bt' },
    { country: 'Bolivia', calling_code: 591, id: 25, abbreviation: 'bo' },
    { country: 'Bosnia and Herzegovina', calling_code: 387, id: 26, abbreviation: 'ba' },
    { country: 'Botswana', calling_code: 267, id: 27, abbreviation: 'bw' },
    { country: 'Bouvet Island', calling_code: 55, id: 28, abbreviation: 'bv' },
    { country: 'Brazil', calling_code: 55, id: 29, abbreviation: 'br' },
    { country: 'British Indian Ocean Territory', calling_code: 246, id: 30, abbreviation: 'io' },
    { country: 'Brunei', calling_code: 673, id: 31, abbreviation: 'bn' },
    { country: 'Bulgaria', calling_code: 359, id: 32, abbreviation: 'bg' },
    { country: 'Burkina Faso', calling_code: 226, id: 33, abbreviation: 'bf' },
    { country: 'Burundi', calling_code: 257, id: 34, abbreviation: 'bi' },
    { country: 'Cambodia', calling_code: 855, id: 35, abbreviation: 'kh' },
    { country: 'Cameroon', calling_code: 237, id: 36, abbreviation: 'cm' },
    { country: 'Canada', calling_code: 1, id: 37, abbreviation: 'ca' },
    { country: 'Cape Verde', calling_code: 238, id: 38, abbreviation: 'cv' },
    { country: 'Cayman Islands', calling_code: 1345, id: 39, abbreviation: 'ky' },
    { country: 'Central African Republic', calling_code: 236, id: 40, abbreviation: 'cf' },
    { country: 'Chad', calling_code: 235, id: 41, abbreviation: 'td' },
    { country: 'Chile', calling_code: 56, id: 42, abbreviation: 'cl' },
    { country: 'China', calling_code: 86, id: 43, abbreviation: 'cn' },
    { country: 'Christmas Island', calling_code: 61, id: 44, abbreviation: 'cx' },
    { country: 'Cocos (Keeling) Islands', calling_code: 61, id: 45, abbreviation: 'cc' },
    { country: 'Colombia', calling_code: 57, id: 46, abbreviation: 'co' },
    { country: 'Comoros', calling_code: 269, id: 47, abbreviation: 'km' },
    { country: 'Congo', calling_code: 242, id: 48, abbreviation: 'cg' },
    { country: 'Cook Islands', calling_code: 682, id: 49, abbreviation: 'ck' },
    { country: 'Costa Rica', calling_code: 506, id: 50, abbreviation: 'cr' },
    { country: 'Croatia', calling_code: 385, id: 51, abbreviation: 'hr' },
    { country: 'Cuba', calling_code: 53, id: 52, abbreviation: 'cu' },
    { country: 'Cyprus', calling_code: 357, id: 53, abbreviation: 'cy' },
    { country: 'Czech Republic', calling_code: 420, id: 54, abbreviation: 'cz' },
    { country: 'Denmark', calling_code: 45, id: 55, abbreviation: 'dk' },
    { country: 'Djibouti', calling_code: 253, id: 56, abbreviation: 'dj' },
    { country: 'Dominica', calling_code: 1767, id: 57, abbreviation: 'dm' },
    { country: 'Dominican Republic', calling_code: 1849, id: 58, abbreviation: 'do' },
    { country: 'East Timor', calling_code: 670, id: 59, abbreviation: 'tp' },
    { country: 'Ecuador', calling_code: 593, id: 60, abbreviation: 'ec' },
    { country: 'Egypt', calling_code: 20, id: 61, abbreviation: 'eg' },
    { country: 'El Salvador', calling_code: 503, id: 62, abbreviation: 'sv' },
    { country: 'Equatorial Guinea', calling_code: 240, id: 63, abbreviation: 'gq' },
    { country: 'Eritrea', calling_code: 291, id: 64, abbreviation: 'er' },
    { country: 'Estonia', calling_code: 372, id: 65, abbreviation: 'ee' },
    { country: 'Eswatini', calling_code: 268, id: 66, abbreviation: 'sz' },
    { country: 'Ethiopia', calling_code: 251, id: 67, abbreviation: 'et' },
    { country: 'Falkland Islands', calling_code: 500, id: 68, abbreviation: 'fk' },
    { country: 'Faroe Islands', calling_code: 298, id: 69, abbreviation: 'fo' },
    { country: 'Fiji Islands', calling_code: 679, id: 70, abbreviation: 'fj' },
    { country: 'Finland', calling_code: 358, id: 71, abbreviation: 'fi' },
    { country: 'France', calling_code: 33, id: 72, abbreviation: 'fr' },
    { country: 'French Guiana', calling_code: 594, id: 73, abbreviation: 'gf' },
    { country: 'French Polynesia', calling_code: 689, id: 74, abbreviation: 'pf' },
    { country: 'French Southern territories', calling_code: 262, id: 75, abbreviation: 'tf' },
    { country: 'Gabon', calling_code: 241, id: 76, abbreviation: 'ga' },
    { country: 'Gambia', calling_code: 220, id: 77, abbreviation: 'gm' },
    { country: 'Georgia', calling_code: 995, id: 78, abbreviation: 'ge' },
    { country: 'Germany', calling_code: 49, id: 79, abbreviation: 'de' },
    { country: 'Ghana', calling_code: 233, id: 80, abbreviation: 'gh' },
    { country: 'Gibraltar', calling_code: 350, id: 81, abbreviation: 'gi' },
    { country: 'Greece', calling_code: 30, id: 82, abbreviation: 'gr' },
    { country: 'Greenland', calling_code: 299, id: 83, abbreviation: 'gl' },
    { country: 'Grenada', calling_code: 1473, id: 84, abbreviation: 'gd' },
    { country: 'Guadeloupe', calling_code: 590, id: 85, abbreviation: 'gp' },
    { country: 'Guam', calling_code: 1671, id: 86, abbreviation: 'gu' },
    { country: 'Guatemala', calling_code: 502, id: 87, abbreviation: 'gt' },
    { country: 'Guernsey', calling_code: 44, id: 88, abbreviation: 'gg' },
    { country: 'Guinea', calling_code: 224, id: 89, abbreviation: 'gn' },
    { country: 'Guinea-Bissau', calling_code: 245, id: 90, abbreviation: 'gw' },
    { country: 'Guyana', calling_code: 592, id: 91, abbreviation: 'gy' },
    { country: 'Haiti', calling_code: 509, id: 92, abbreviation: 'ht' },
    { country: 'Heard Island and McDonald Islands', calling_code: 672, id: 93, abbreviation: 'hm' },
    { country: 'Holy See (Vatican City State)', calling_code: 379, id: 94, abbreviation: 'va' },
    { country: 'Honduras', calling_code: 504, id: 95, abbreviation: 'hn' },
    { country: 'Hong Kong', calling_code: 852, id: 96, abbreviation: 'hk' },
    { country: 'Hungary', calling_code: 36, id: 97, abbreviation: 'hu' },
    { country: 'Iceland', calling_code: 354, id: 98, abbreviation: 'is' },
    { country: 'India', calling_code: 91, id: 99, abbreviation: 'in' },
    { country: 'Indonesia', calling_code: 62, id: 100, abbreviation: 'id' },
    { country: 'Iran', calling_code: 98, id: 101, abbreviation: 'ir' },
    { country: 'Iraq', calling_code: 964, id: 102, abbreviation: 'iq' },
    { country: 'Ireland', calling_code: 353, id: 103, abbreviation: 'ie' },
    { country: 'Isle of Man', calling_code: 44, id: 104, abbreviation: 'im' },
    { country: 'Israel', calling_code: 972, id: 105, abbreviation: 'il' },
    { country: 'Italy', calling_code: 39, id: 106, abbreviation: 'it' },
    { country: 'Ivory Coast', calling_code: 225, id: 107, abbreviation: 'ci' },
    { country: 'Jamaica', calling_code: 1876, id: 108, abbreviation: 'jm' },
    { country: 'Japan', calling_code: 81, id: 109, abbreviation: 'jp' },
    { country: 'Jersey', calling_code: 44, id: 110, abbreviation: 'je' },
    { country: 'Jordan', calling_code: 962, id: 111, abbreviation: 'jo' },
    { country: 'Kazakhstan', calling_code: 7, id: 112, abbreviation: 'kz' },
    { country: 'Kenya', calling_code: 254, id: 113, abbreviation: 'ke' },
    { country: 'Kiribati', calling_code: 686, id: 114, abbreviation: 'ki' },
    { country: 'Kuwait', calling_code: 965, id: 115, abbreviation: 'kw' },
    { country: 'Kyrgyzstan', calling_code: 996, id: 116, abbreviation: 'kg' },
    { country: 'Laos', calling_code: 856, id: 117, abbreviation: 'la' },
    { country: 'Latvia', calling_code: 371, id: 118, abbreviation: 'lv' },
    { country: 'Lebanon', calling_code: 961, id: 119, abbreviation: 'lb' },
    { country: 'Lesotho', calling_code: 266, id: 120, abbreviation: 'ls' },
    { country: 'Liberia', calling_code: 231, id: 121, abbreviation: 'lr' },
    { country: 'Libya', calling_code: 218, id: 122, abbreviation: 'ly' },
    { country: 'Liechtenstein', calling_code: 423, id: 123, abbreviation: 'li' },
    { country: 'Lithuania', calling_code: 370, id: 124, abbreviation: 'lt' },
    { country: 'Luxembourg', calling_code: 352, id: 125, abbreviation: 'lu' },
    { country: 'Macao', calling_code: 853, id: 126, abbreviation: 'mo' },
    { country: 'North Macedonia', calling_code: 389, id: 127, abbreviation: 'mk' },
    { country: 'Madagascar', calling_code: 261, id: 128, abbreviation: 'mg' },
    { country: 'Malawi', calling_code: 265, id: 129, abbreviation: 'mw' },
    { country: 'Malaysia', calling_code: 60, id: 130, abbreviation: 'my' },
    { country: 'Maldives', calling_code: 960, id: 131, abbreviation: 'mv' },
    { country: 'Mali', calling_code: 223, id: 132, abbreviation: 'ml' },
    { country: 'Malta', calling_code: 356, id: 133, abbreviation: 'mt' },
    { country: 'Marshall Islands', calling_code: 692, id: 134, abbreviation: 'mh' },
    { country: 'Martinique', calling_code: 596, id: 135, abbreviation: 'mq' },
    { country: 'Mauritania', calling_code: 222, id: 136, abbreviation: 'mr' },
    { country: 'Mauritius', calling_code: 230, id: 137, abbreviation: 'mu' },
    { country: 'Mayotte', calling_code: 262, id: 138, abbreviation: 'yt' },
    { country: 'Mexico', calling_code: 52, id: 139, abbreviation: 'mx' },
    { country: 'Micronesia, Federated States of', calling_code: 691, id: 140, abbreviation: 'fm' },
    { country: 'Moldova', calling_code: 373, id: 141, abbreviation: 'md' },
    { country: 'Monaco', calling_code: 377, id: 142, abbreviation: 'mc' },
    { country: 'Mongolia', calling_code: 976, id: 143, abbreviation: 'mn' },
    { country: 'Montenegro', calling_code: 382, id: 144, abbreviation: 'me' },
    { country: 'Montserrat', calling_code: 1664, id: 145, abbreviation: 'ms' },
    { country: 'Morocco', calling_code: 212, id: 146, abbreviation: 'ma' },
    { country: 'Mozambique', calling_code: 258, id: 147, abbreviation: 'mz' },
    { country: 'Myanmar', calling_code: 95, id: 148, abbreviation: 'mm' },
    { country: 'Namibia', calling_code: 264, id: 149, abbreviation: 'na' },
    { country: 'Nauru', calling_code: 674, id: 150, abbreviation: 'nr' },
    { country: 'Nepal', calling_code: 977, id: 151, abbreviation: 'np' },
    { country: 'Netherlands', calling_code: 31, id: 152, abbreviation: 'nl' },
    { country: 'Netherlands Antilles', calling_code: 599, id: 153, abbreviation: 'an' },
    { country: 'New Caledonia', calling_code: 687, id: 154, abbreviation: 'nc' },
    { country: 'New Zealand', calling_code: 64, id: 155, abbreviation: 'nz' },
    { country: 'Nicaragua', calling_code: 505, id: 156, abbreviation: 'ni' },
    { country: 'Niger', calling_code: 227, id: 157, abbreviation: 'ne' },
    { country: 'Nigeria', calling_code: 234, id: 158, abbreviation: 'ng' },
    { country: 'Niue', calling_code: 683, id: 159, abbreviation: 'nu' },
    { country: 'Norfolk Island', calling_code: 672, id: 160, abbreviation: 'nf' },
    { country: 'North Korea', calling_code: 850, id: 161, abbreviation: 'kp' },
    { country: 'Northern Ireland', calling_code: 44, id: 162, abbreviation: 'gb' },
    { country: 'Northern Mariana Islands', calling_code: 1670, id: 163, abbreviation: 'mp' },
    { country: 'Norway', calling_code: 47, id: 164, abbreviation: 'no' },
    { country: 'Oman', calling_code: 968, id: 165, abbreviation: 'om' },
    { country: 'Pakistan', calling_code: 92, id: 166, abbreviation: 'pk' },
    { country: 'Palau', calling_code: 680, id: 167, abbreviation: 'pw' },
    { country: 'Palestine', calling_code: 970, id: 168, abbreviation: 'ps' },
    { country: 'Panama', calling_code: 507, id: 169, abbreviation: 'pa' },
    { country: 'Papua New Guinea', calling_code: 675, id: 170, abbreviation: 'pg' },
    { country: 'Paraguay', calling_code: 595, id: 171, abbreviation: 'py' },
    { country: 'Peru', calling_code: 51, id: 172, abbreviation: 'pe' },
    { country: 'Philippines', calling_code: 63, id: 173, abbreviation: 'ph' },
    { country: 'Pitcairn', calling_code: 64, id: 174, abbreviation: 'pn' },
    { country: 'Poland', calling_code: 48, id: 175, abbreviation: 'pl' },
    { country: 'Portugal', calling_code: 351, id: 176, abbreviation: 'pt' },
    { country: 'Puerto Rico', calling_code: 1939, id: 177, abbreviation: 'pr' },
    { country: 'Qatar', calling_code: 974, id: 178, abbreviation: 'qa' },
    { country: 'Reunion', calling_code: 262, id: 179, abbreviation: 're' },
    { country: 'Romania', calling_code: 40, id: 180, abbreviation: 'ro' },
    { country: 'Russia', calling_code: 7, id: 181, abbreviation: 'ru' },
    { country: 'Rwanda', calling_code: 250, id: 182, abbreviation: 'rw' },
    { country: 'Saint Helena', calling_code: 290, id: 183, abbreviation: 'sh' },
    { country: 'Saint Kitts and Nevis', calling_code: 1869, id: 184, abbreviation: 'kn' },
    { country: 'Saint Lucia', calling_code: 1758, id: 185, abbreviation: 'lc' },
    { country: 'Saint Pierre and Miquelon', calling_code: 508, id: 186, abbreviation: 'pm' },
    { country: 'Saint Vincent and the Grenadines', calling_code: 1784, id: 187, abbreviation: 'vc' },
    { country: 'Samoa', calling_code: 685, id: 188, abbreviation: 'ws' },
    { country: 'San Marino', calling_code: 378, id: 189, abbreviation: 'sm' },
    { country: 'Sao Tome and Principe', calling_code: 239, id: 190, abbreviation: 'st' },
    { country: 'Saudi Arabia', calling_code: 966, id: 191, abbreviation: 'sa' },
    { country: 'Senegal', calling_code: 221, id: 192, abbreviation: 'sn' },
    { country: 'Serbia', calling_code: 381, id: 193, abbreviation: 'rs' },
    { country: 'Seychelles', calling_code: 248, id: 194, abbreviation: 'sc' },
    { country: 'Sierra Leone', calling_code: 232, id: 195, abbreviation: 'sl' },
    { country: 'Singapore', calling_code: 65, id: 196, abbreviation: 'sg' },
    { country: 'Slovakia', calling_code: 421, id: 197, abbreviation: 'sk' },
    { country: 'Slovenia', calling_code: 386, id: 198, abbreviation: 'si' },
    { country: 'Solomon Islands', calling_code: 677, id: 199, abbreviation: 'sb' },
    { country: 'Somalia', calling_code: 252, id: 200, abbreviation: 'so' },
    { country: 'South Africa', calling_code: 27, id: 201, abbreviation: 'za' },
    { country: 'South Georgia and the South Sandwich Islands', calling_code: 500, id: 202, abbreviation: 'gs' },
    { country: 'South Korea', calling_code: 82, id: 203, abbreviation: 'kr' },
    { country: 'South Sudan', calling_code: 211, id: 204, abbreviation: 'ss' },
    { country: 'Spain', calling_code: 34, id: 205, abbreviation: 'es' },
    { country: 'Sri Lanka', calling_code: 94, id: 206, abbreviation: 'lk' },
    { country: 'Sudan', calling_code: 249, id: 207, abbreviation: 'sd' },
    { country: 'Suriname', calling_code: 597, id: 208, abbreviation: 'sr' },
    { country: 'Svalbard and Jan Mayen', calling_code: 47, id: 209, abbreviation: 'sj' },
    { country: 'Sweden', calling_code: 46, id: 210, abbreviation: 'se' },
    { country: 'Switzerland', calling_code: 41, id: 211, abbreviation: 'ch' },
    { country: 'Syria', calling_code: 963, id: 212, abbreviation: 'sy' },
    { country: 'Tajikistan', calling_code: 992, id: 213, abbreviation: 'tj' },
    { country: 'Tanzania', calling_code: 255, id: 214, abbreviation: 'tz' },
    { country: 'Thailand', calling_code: 66, id: 215, abbreviation: 'th' },
    { country: 'The Democratic Republic of Congo', calling_code: 243, id: 216, abbreviation: 'cd' },
    { country: 'Timor-Leste', calling_code: 670, id: 217, abbreviation: 'tl' },
    { country: 'Togo', calling_code: 228, id: 218, abbreviation: 'tg' },
    { country: 'Tokelau', calling_code: 690, id: 219, abbreviation: 'tk' },
    { country: 'Tonga', calling_code: 676, id: 220, abbreviation: 'to' },
    { country: 'Trinidad and Tobago', calling_code: 1868, id: 221, abbreviation: 'tt' },
    { country: 'Tunisia', calling_code: 216, id: 222, abbreviation: 'tn' },
    { country: 'Turkey', calling_code: 90, id: 223, abbreviation: 'tr' },
    { country: 'Turkmenistan', calling_code: 993, id: 224, abbreviation: 'tm' },
    { country: 'Turks and Caicos Islands', calling_code: 1649, id: 225, abbreviation: 'tc' },
    { country: 'Tuvalu', calling_code: 688, id: 226, abbreviation: 'tv' },
    { country: 'Uganda', calling_code: 256, id: 227, abbreviation: 'ug' },
    { country: 'Ukraine', calling_code: 380, id: 228, abbreviation: 'ua' },
    { country: 'United Arab Emirates', calling_code: 971, id: 229, abbreviation: 'ae' },
    { country: 'United Kingdom', calling_code: 44, id: 230, abbreviation: 'uk' },
    { country: 'United States', calling_code: 1, id: 231, abbreviation: 'us' },
    { country: 'United States Minor Outlying Islands', calling_code: 1, id: 232, abbreviation: 'um' },
    { country: 'Uruguay', calling_code: 598, id: 233, abbreviation: 'uy' },
    { country: 'Uzbekistan', calling_code: 998, id: 234, abbreviation: 'uz' },
    { country: 'Vanuatu', calling_code: 678, id: 235, abbreviation: 'vu' },
    { country: 'Venezuela', calling_code: 58, id: 236, abbreviation: 've' },
    { country: 'Vietnam', calling_code: 84, id: 237, abbreviation: 'vn' },
    { country: 'Virgin Islands, British', calling_code: 1, id: 238, abbreviation: 'vg' },
    { country: 'Virgin Islands, U.S.', calling_code: 1, id: 239, abbreviation: 'vi' },
    { country: 'Wallis and Futuna', calling_code: 681, id: 240, abbreviation: 'wf' },
    { country: 'Western Sahara', calling_code: 212, id: 241, abbreviation: 'eh' },
    { country: 'Yemen', calling_code: 967, id: 242, abbreviation: 'ye' },
    { country: 'Zambia', calling_code: 260, id: 243, abbreviation: 'zm' },
    { country: 'Zimbabwe', calling_code: 263, id: 244, abbreviation: 'zw' },
]
