import CMSLayout from '../../_core/components/layouts/cms-dashboard/CMSLayout'
import { Link } from 'react-router-dom'
import { FaPlus } from 'react-icons/fa'
import { MdManageAccounts } from 'react-icons/md'
import { DictionaryType, headCellsType } from '../../_core/components/custom-table/CustomTable.type'
import axios from 'axios/index'
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../_core/redux/hooks'
import { UserAccessType } from '../../_core/components/model/user-access-type'
import { CustomTable } from '../../_core/components/custom-table/CustomTable'



const AccountPage = () => {


    return (
        <CMSLayout>
            <div className='page-header-style'>
                <h2 className='title-page-style'>
                    <MdManageAccounts className='text-3xl text-inherit' />
                    حساب
                </h2>

                <Link
                    to={'#'}
                    className='new-link-page'
                >
                    <FaPlus />
                    حساب&nbsp;جدید
                </Link>
            </div>




        </CMSLayout>
    )
}

export default AccountPage
