function replaceWithPersianDigits(number: number) {
    const digits = number.toString().split('')
    const persianDigits = digits.map((digit) => {
        if (digit === '0') return '۰'
        if (digit === '1') return '۱'
        if (digit === '2') return '۲'
        if (digit === '3') return '۳'
        if (digit === '4') return '۴'
        if (digit === '5') return '۵'
        if (digit === '6') return '۶'
        if (digit === '7') return '۷'
        if (digit === '8') return '۸'
        if (digit === '9') return '۹'
        return ''
    })
    return persianDigits.join('')
}

export default replaceWithPersianDigits
