/* eslint-disable react-hooks/exhaustive-deps */
import CMSLayout from '../../../../_core/components/layouts/cms-dashboard/CMSLayout'
import { WorkshopForm } from '../_components/WorkshopForm'

const AddWorkshopPage = () => {
    return (
        <CMSLayout>
            <WorkshopForm type='ADD' />
        </CMSLayout>
    )
}

export default AddWorkshopPage
