import { MuiColorInput } from 'mui-color-input'
import { CacheProvider, ThemeProvider } from '@emotion/react'
import { theme } from '../../../../../_core/theme'
import createCache from '@emotion/cache'
import rtlPlugin from 'stylis-plugin-rtl'
import { prefixer } from 'stylis'
import { FieldValues, Control, Path, Controller } from 'react-hook-form'
import './index.css'

const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
})

interface ColorInputProps<T extends FieldValues> {
    control: Control<T, keyof T>
    name: Path<T>
}

function ColorInput<T extends FieldValues>({ control, name }: ColorInputProps<T>) {
    return (
        <CacheProvider value={cacheRtl}>
            <ThemeProvider theme={theme}>
                <div
                    dir='rtl'
                    className='color-input'
                >
                    <Controller
                        name={name ?? ''}
                        control={control}
                        render={({ field: { onChange, value, ref, ...otherFields }, fieldState: { error } }) => (
                            <MuiColorInput
                                fallbackValue='#EDEDED'
                                isAlphaHidden
                                inputProps={{ onChange: undefined, readOnly: true }}
                                label='انتخاب رنگ'
                                format='hex'
                                value={value}
                                onChange={onChange}
                                ref={ref}
                                error={!!error}
                                helperText={error?.message}
                                {...otherFields}
                            />
                        )}
                    />
                </div>
            </ThemeProvider>
        </CacheProvider>
    )
}

export default ColorInput
