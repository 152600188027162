import { ArrowDown2 } from 'iconsax-react'
import { Controller, FieldError, FieldValues, Path, useFormContext } from 'react-hook-form'
import { cn } from '../../../utils/cn'
import { ReactNode, useEffect, useState } from 'react'

export interface ControlledSelectProps<T extends FieldValues> extends SelectProps {
    name: Path<T>
}

function ControlledSelect<T extends FieldValues>({ name, ...selectProps }: ControlledSelectProps<T>) {
    const { control } = useFormContext<T>()

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
                <Select
                    value={value}
                    onChange={onChange}
                    error={error}
                    {...selectProps}
                />
            )}
        />
    )
}

export interface SelectProps {
    values: string[]
    generateListItem?: (value: string) => ReactNode
    generateValue?: (value: string) => ReactNode
    className?: string
}

export interface SelectSpecificProps {
    value: string
    onChange: (value: string) => void
    error?: FieldError
}

function Select({
    values: rawValues,
    onChange,
    value: selectedValue,
    generateListItem,
    className,
    generateValue,
    error,
}: SelectProps & SelectSpecificProps) {
    const [isListOpen, setIsListOpen] = useState<boolean>(false)
    const [values, setValues] = useState<string[]>(rawValues)

    useEffect(() => {
        setValues(() => {
            const res = removeDuplicates(rawValues)
            if (!res.includes(selectedValue)) res.push(selectedValue)
            return res
        })
    }, [rawValues, selectedValue])

    return (
        <div className={cn('relative', className)}>
            <button
                type='button'
                className='flex items-center px-2 py-1 gap-2 bg-gray-100 rounded w-full'
                onClick={() => setIsListOpen((prev) => !prev)}
            >
                <ArrowDown2 className={cn('size-4 transition-transform', isListOpen && 'rotate-180')} />

                <div className='text-xs'>{generateValue?.(selectedValue) ?? selectedValue}</div>
            </button>

            <div
                className={cn(
                    'bg-gray-50 right-0 max-h-64 overflow-y-auto left-0 mt-1 flex flex-col gap-2 py-5 px-3 absolute rounded-b rounded-l opacity-0 transition-opacity pointer-events-none',
                    isListOpen && 'opacity-100 pointer-events-auto',
                )}
            >
                {values.map((value) => (
                    <button
                        type='button'
                        onClick={() => {
                            onChange(value)
                            setIsListOpen(false)
                        }}
                        className={cn(
                            'py-1 px-2 text-gray-700 text-sm transition-colors text-start',
                            value === selectedValue && 'bg-[#B5DEDD2E]',
                        )}
                        key={value}
                    >
                        {generateListItem?.(value) ?? value}
                    </button>
                ))}
            </div>
        </div>
    )
}

function removeDuplicates<T>(array: T[]): T[] {
    const uniqueArray: T[] = []
    for (let i = 0; i < array.length; i++) {
        if (uniqueArray.indexOf(array[i]) === -1) uniqueArray.push(array[i])
    }
    return uniqueArray
}

export { ControlledSelect, Select }
