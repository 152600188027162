import CMSLayout from '../../../../_core/components/layouts/cms-dashboard/CMSLayout'
import { ArticleForm } from '../_components/ArticleForm'

const AddArticlePage = () => {
    return (
        <CMSLayout>
            <ArticleForm type='ADD' />
        </CMSLayout>
    )
}

export default AddArticlePage
