import { FC, useMemo } from 'react'
import TherapistForm from '../components/therapist-form'
import { useQuery } from '@tanstack/react-query'
import { getTherapistsDetail } from '../api'
import { useSearchParams } from 'react-router-dom'
import CMSLayout from '../../../../_core/components/layouts/cms-dashboard/CMSLayout'

const UpdateTherapist: FC = () => {
    const [searchParams] = useSearchParams()

    const id = useMemo(() => parseInt(searchParams.get('id') ?? '-1'), [searchParams])

    const { data, isPending } = useQuery({
        queryKey: ['therapists-detail', id],
        queryFn: () => getTherapistsDetail(id),
        select: ({ treatment_ways, education, cv, license, research, ...data }) => ({
            education: education?.split('|'),
            cv: cv?.split('|'),
            license: license?.split('|'),
            research: research?.split('|'),
            treatment_ways: treatment_ways?.split('|'),
            ...data,
        }),
    })

    if (isPending)
        return (
            <CMSLayout>
                <div className='w-full h-full flex justify-center items-start'>
                    <span className='loader' />
                </div>
            </CMSLayout>
        )

    return (
        <TherapistForm
            type='UPDATE'
            therapists={data}
        />
    )
}

export default UpdateTherapist
