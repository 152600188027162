import { FC } from 'react'
import { Transaction, TransactionPaymentStatus } from '../data-types'
import Section from '../../Tests/components/section'
import { CloseSquare, TickSquare } from 'iconsax-react'

const fakeFields: Transaction[] = [
    {
        id: 1,
        paymentStatus: TransactionPaymentStatus.notPaid,
        trackingCode: 'TC10001',
        description: 'تراکنش درخواست: مبلغ 35,000 تومان از طریق بانک ملی در تاریخ 1403/04/31 15:10:17 پرداخت نشده است.',
    },
    {
        id: 2,
        paymentStatus: TransactionPaymentStatus.paid,
        trackingCode: 'TC10002',
        description: 'تراکنش درخواست: مبلغ 50,000 تومان از طریق بانک سپه در تاریخ 1403/04/30 14:05:22 پرداخت شده است.',
    },
    {
        id: 3,
        paymentStatus: TransactionPaymentStatus.paid,
        trackingCode: 'TC10003',
        description:
            'تراکنش درخواست: مبلغ 12,000 تومان از طریق بانک پارسیان در تاریخ 1403/05/01 10:20:35 پرداخت شده است.',
    },
    {
        id: 4,
        paymentStatus: TransactionPaymentStatus.notPaid,
        trackingCode: 'TC10004',
        description:
            'تراکنش درخواست: مبلغ 20,000 تومان از طریق بانک تجارت در تاریخ 1403/04/29 09:45:00 پرداخت نشده است.',
    },
    {
        id: 5,
        paymentStatus: TransactionPaymentStatus.paid,
        trackingCode: 'TC10005',
        description:
            'تراکنش درخواست: مبلغ 75,000 تومان از طریق بانک سامان در تاریخ 1403/05/02 16:30:45 پرداخت شده است.',
    },
    {
        id: 6,
        paymentStatus: TransactionPaymentStatus.notPaid,
        trackingCode: 'TC10006',
        description:
            'تراکنش درخواست: مبلغ 22,000 تومان از طریق بانک سرمایه در تاریخ 1403/04/28 08:55:10 پرداخت نشده است.',
    },
    {
        id: 7,
        paymentStatus: TransactionPaymentStatus.paid,
        trackingCode: 'TC10007',
        description:
            'تراکنش درخواست: مبلغ 40,000 تومان از طریق بانک مهر اقتصاد در تاریخ 1403/05/03 11:47:53 پرداخت شده است.',
    },
    {
        id: 8,
        paymentStatus: TransactionPaymentStatus.notPaid,
        trackingCode: 'TC10008',
        description:
            'تراکنش درخواست: مبلغ 18,000 تومان از طریق بانک صادرات در تاریخ 1403/04/27 13:50:31 پرداخت نشده است.',
    },
    {
        id: 9,
        paymentStatus: TransactionPaymentStatus.paid,
        trackingCode: 'TC10009',
        description:
            'تراکنش درخواست: مبلغ 95,000 تومان از طریق بانک کشاورزی در تاریخ 1403/05/04 12:10:50 پرداخت شده است.',
    },
    {
        id: 10,
        paymentStatus: TransactionPaymentStatus.notPaid,
        trackingCode: 'TC10010',
        description: 'تراکنش درخواست: مبلغ 30,000 تومان از طریق بانک شهر در تاریخ 1403/04/26 17:20:00 پرداخت نشده است.',
    },
]

const TransactionsTab: FC = () => {
    return (
        <>
            {fakeFields.map((field) => (
                <Section
                    className='flex-row gap-3 items-center text-xs text-gray-800'
                    key={field.id}
                >
                    {field.paymentStatus === TransactionPaymentStatus.paid && (
                        <TickSquare
                            size={24}
                            className='text-primary-500'
                        />
                    )}

                    {field.paymentStatus === TransactionPaymentStatus.notPaid && (
                        <CloseSquare
                            size={24}
                            className='text-alarm-500'
                        />
                    )}

                    {field.description}

                    <div className='grow' />

                    <div className='text-gray-500'>شماره پیگیری:</div>

                    <div className='font-medium'>{field.trackingCode}</div>

                    {field.paymentStatus === TransactionPaymentStatus.paid && (
                        <div className='text-primary-500 bg-[#DAF6E8] rounded-full py-2 px-3'>پرداخت شده</div>
                    )}

                    {field.paymentStatus === TransactionPaymentStatus.notPaid && (
                        <div className='text-alarm-500 bg-[#FFE6E6] rounded-full py-2 px-3'>پرداخت نشده</div>
                    )}
                </Section>
            ))}
        </>
    )
}

export default TransactionsTab
