import { GridRowId } from '@mui/x-data-grid'
import { Edit2, Trash } from 'iconsax-react'
import Button, { ButtonVariants, ButtonSizes } from '../../../../_core/components/button'
import { Column, Sizes } from '../../../../_core/components/table/column'
import { cn } from '../../../../utils/cn'
import { Bundle } from '../data-types'

const getWorkshopColumns = (selectionList: GridRowId[], onClick: (id?: number) => void) => [
    Column<Bundle>({
        field: 'title',
        headerName: 'عنوان کارگاه',
        valueGetter: (_, row) => row.title ?? '',
        align: 'left',
        headerAlign: 'right',
    }),
    Column<Bundle>({
        field: 'datetime',
        headerName: 'تاریخ و زمان شروع',
        valueGetter: (_, row) => row.datetime,
        size: Sizes.big,
        align: 'left',
        headerAlign: 'right',
    }),
    Column<Bundle>({
        field: 'is_active',
        headerName: 'وضعیت',
        valueGetter: (_, row) => (row.is_active ? 'فعال' : 'غیر فعال'),
        renderCell: (props) => (
            <div
                className={cn(
                    'truncate text-sm h-full flex items-center justify-start w-full',
                    props.row.is_active ? 'text-success-500' : 'text-gray-800',
                )}
                title={props.value}
            >
                {props.value}
            </div>
        ),
        align: 'left',
        headerAlign: 'right',
    }),
    Column<Bundle>({
        field: 'actions',
        headerName: '',
        valueGetter: () => '',
        renderHeader: () => (
            <>
                <Button
                    aClassName='mr-auto'
                    variant={ButtonVariants.danger}
                    size={ButtonSizes.small}
                    onClick={() => onClick()}
                    className={cn((selectionList?.length ?? 0) > 0 ? 'flex' : 'hidden')}
                >
                    حذف همه
                </Button>
            </>
        ),
        renderCell: (props) => (
            <div className='flex items-center justify-end pl-4 gap-8 w-full h-full text-gray-700'>
                <a
                    href={'./workshops/update-workshop?id=' + props.row.id}
                    className='flex justify-center'
                >
                    <button title='Edit'>
                        <Edit2
                            width={24}
                            height={24}
                        />
                    </button>
                </a>
                <button
                    title='Delete'
                    onClick={() => onClick(props.row.id)}
                >
                    <Trash
                        width={24}
                        height={24}
                    />
                </button>
            </div>
        ),
        sortable: false,
    }),
]

export default getWorkshopColumns
