/* eslint-disable react-hooks/exhaustive-deps */
import CMSTopbar from './cms-topbar/CMSTopbar'
import CMSSidebar from './cms-sidebar/CMSSidebar'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { updateFormItem } from '../../../redux/form-item/form-item-slice'
import { updateUserAccess } from '../../../redux/user-access/user-access-slice'
import { cn } from '../../../../utils/cn'

const CMSLayout = ({ children, className }: { children: React.ReactNode; className?: string }) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const userAccess = useAppSelector((s) => s.userAccess.value)

    const currentDate = new Date().getTime()
    const expiredDate = Number(localStorage.getItem('expiredDate'))

    useEffect(() => {
        if (userAccess === undefined) {
            navigate('/auth/login')
        }
    }, [userAccess])

    useEffect(() => {
        if (currentDate > expiredDate) {
            dispatch(updateUserAccess(undefined))
            dispatch(updateFormItem(undefined))
            localStorage.removeItem('userId')
            localStorage.removeItem('expiredDate')
            localStorage.removeItem('fullName')
            navigate('/auth/login')
        }
    }, [expiredDate])

    return (
        <div className='h-screen w-screen bg-primary-700 flex flex-col overflow-hidden'>
            <CMSTopbar />

            <div className='flex h-[calc(100vh-61.25px)] overflow-hidden'>
                <CMSSidebar />

                <div
                    className={cn(
                        'grow bg-primary-50 rounded-tr-2xl p-8 pl-16 min-w-0 overflow-y-auto scroll-smooth',
                        className,
                    )}
                >
                    {children}
                </div>
            </div>
        </div>
    )
}

export default CMSLayout
