import { FC, KeyboardEventHandler, useCallback } from 'react'
import CMSLayout from '../../../../_core/components/layouts/cms-dashboard/CMSLayout'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import Button, { ButtonSizes, ButtonVariants } from '../../../../_core/components/button'
import { Refresh } from 'iconsax-react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { addFaqDetail, updateFaqDetail } from '../api'
import { DialogTypes, useDialogStore } from '../../_components/Dialog'
import { Faq } from '../data-types'
import { z } from '../../../../utils/zod-translation'
import Section from '../../Tests/components/section'
import { CustomTextField } from '../../AddUser/components/text-field'
import CustomRichTextBox from '../../_components/CustomRichTextBox'
import { richTextSchema } from '../../../../utils/removeTags'

export const FaqSchema = z.object({
    id: z.coerce.number().optional(),
    row: z.coerce.number().optional(),
    index: z.coerce.number().optional(),
    question: z.coerce.string().min(3, { message: 'حداقل ۳ کاراکتر وارد کنید' }),
    answer: richTextSchema,
})

interface FaqFormProps {
    type: 'ADD' | 'UPDATE'
    faq?: Faq
    isLoading?: boolean
}

const FaqForm: FC<FaqFormProps> = ({ type, faq }) => {
    const navigate = useNavigate()

    const { control, handleSubmit } = useForm<Faq>({
        defaultValues: faq,
        resolver: zodResolver(FaqSchema),
    })

    const { setDialogState } = useDialogStore()

    const { mutate: addFaq, isPending: isAddingFaqPending } = useMutation({
        mutationKey: ['faqs'],
        mutationFn: addFaqDetail,
        onSuccess: () => {
            setDialogState({
                open: true,
                title: 'ثبت سوال متداول جدید',
                type: DialogTypes.success,
                description: 'سوال متداول شما با موفقیت ثبت شد.',
            })
            navigate('/cms/faq')
        },
        onError: () =>
            setDialogState({
                open: true,
                title: 'خطا در ثبت سوال متداول',
                type: DialogTypes.error,
                description: 'سوال متداول ثبت نشد.',
            }),
    })

    const { mutate: updateFaq, isPending: isUpdatingFaqPending } = useMutation({
        mutationKey: ['faqs'],
        mutationFn: updateFaqDetail,
        onSuccess: () => {
            setDialogState({
                open: true,
                title: 'ویرایش سوال متداول',
                type: DialogTypes.success,
                description: 'اطلاعات سوال متداول با موفقیت ویرایش شد.',
            })
            navigate('/cms/faq')
        },
        onError: () =>
            setDialogState({
                open: true,
                title: 'خطا در ویرایش سوال متداول',
                type: DialogTypes.error,
                description: 'سوال متداول ویرایش نشد.',
            }),
    })

    const onSubmit = useCallback(
        (data: Faq) => {
            if (type === 'ADD') addFaq(data)
            if (type === 'UPDATE') updateFaq(data)
        },
        [addFaq, type, updateFaq],
    )

    const onError = useCallback(
        (e: any) => {
            console.log(e)
            setDialogState({
                open: true,
                title: 'فرم ناقص',
                type: DialogTypes.error,
                description: 'اطلاعات فرم را چک کنید.',
            })
        },
        [setDialogState],
    )

    const handleKeyDown: KeyboardEventHandler<HTMLFormElement> = (e) => {
        if (e.key === 'Enter') e.preventDefault()
    }

    return (
        <CMSLayout>
            <div className='text-sm mb-5'>
                <span className='text-gray-400'>سوالات متداول / </span>
                <span className='text-gray-600 font-medium'>{type === 'ADD' ? 'سوال جدید' : 'ویرایش سوال'}</span>
            </div>

            <form
                onSubmit={handleSubmit(onSubmit, onError)}
                onKeyDown={handleKeyDown}
            >
                <Section>
                    <CustomTextField<Faq>
                        control={control}
                        name='question'
                        label='سوال'
                    />

                    <CustomRichTextBox<Faq>
                        control={control}
                        name='answer'
                        label='جواب'
                    />
                </Section>

                <div className='flex gap-4 mt-5'>
                    <Button
                        disabled={isAddingFaqPending || isUpdatingFaqPending}
                        type='submit'
                        className='w-[9.375rem] text-gray-50'
                        size={ButtonSizes.bigger}
                    >
                        {isAddingFaqPending || isUpdatingFaqPending ? (
                            <Refresh className='animate-spin' />
                        ) : type === 'ADD' ? (
                            'ثبت نهایی'
                        ) : (
                            'ثبت نهایی ویرایش'
                        )}{' '}
                    </Button>

                    <Button
                        type='button'
                        size={ButtonSizes.big}
                        className='w-[9.375rem]'
                        variant={ButtonVariants.gray}
                        onClick={() => navigate('/cms/faq')}
                    >
                        انصراف
                    </Button>
                </div>
            </form>
        </CMSLayout>
    )
}

export default FaqForm
