import { useState, useCallback, useEffect } from 'react'

/**
 * A custom hook that works like useState but includes both delayed and instant state update capabilities.
 * @param initialValue The initial state value.
 * @param delay The delay in milliseconds before the state updates.
 * @returns An array with the current state, a function to set the state with a delay, and a function for immediate state updates.
 */
function useDelayedState<T>(initialValue: T, delay: number): [T, (newValue: T) => void, (newValue: T) => void] {
    const [state, setState] = useState<T>(initialValue)
    const [tempValue, setTempValue] = useState<T>(initialValue)

    const setDelayedState = useCallback((newValue: T) => {
        setTempValue(newValue)
    }, [])

    const setImmediateState = useCallback((newValue: T) => {
        setState(newValue)
        // Also reset tempValue to prevent the delayed execution from overriding the immediate change
        setTempValue(newValue)
    }, [])

    useEffect(() => {
        const timer = setTimeout(() => {
            setState(tempValue)
        }, delay)

        // Clear the timer on component unmount or when tempValue changes
        return () => clearTimeout(timer)
    }, [tempValue, delay])

    return [state, setDelayedState, setImmediateState]
}

export default useDelayedState
