import { FC, useMemo, useState } from 'react'
import ListPage from '../_components/list-page'
import { deleteBundles, getBundles } from './api'
import DeleteConfirmDialog from '../_components/list-page/components/dialog'
import { DialogTypes, useDialogStore } from '../_components/Dialog'
import { useQueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { RootState } from '../../../_core/redux/store'
import { GridRowId } from '@mui/x-data-grid'
import { Edit2, Trash } from 'iconsax-react'
import Button, { ButtonVariants, ButtonSizes } from '../../../_core/components/button'
import { Column, Sizes } from '../../../_core/components/table/column'
import { cn } from '../../../utils/cn'
import { Bundle } from './data-types'

const Bundles: FC = () => {
    const queryClient = useQueryClient()

    const userAccessData = useSelector((state: RootState) => state.userAccess.value)
    const bundlesAccess = useMemo(() => userAccessData?.find((itm) => itm.formName === 'Bundles'), [userAccessData])

    const getBundleColumns = (selectionList: GridRowId[], onClick: (id?: number) => void) => [
        Column<Bundle>({
            field: 'bundle_title',
            headerName: 'عنوان بسته',
            valueGetter: (_, row) => row.bundle_title ?? '',
            size: Sizes.big,
            headerAlign: 'right',
            align: 'left',
        }),
        Column<Bundle>({
            field: 'measurement_title',
            headerName: 'عنوان اندازه گیری',
            valueGetter: (_, row) => row.measurement_title ?? '',
            headerAlign: 'right',
            align: 'left',
        }),
        Column<Bundle>({
            field: 'measurements',
            headerName: 'موارد اندازه گیری',
            valueGetter: (_, row) => row.measurements,
            headerAlign: 'right',
            align: 'left',
            size: Sizes.big,
        }),
        Column<Bundle>({
            field: 'categories',
            headerName: 'دسته بندی',
            valueGetter: (_, row) => row.categories,
            headerAlign: 'right',
            align: 'left',
            size: Sizes.big,
        }),
        Column<Bundle>({
            field: 'is_active',
            headerName: 'وضعیت',
            valueGetter: (_, row) => (row.is_active ? 'فعال' : 'غیر فعال'),
            renderCell: (props) => (
                <div
                    className={cn(
                        'truncate text-sm h-full flex items-center justify-start w-full',
                        props.row.is_active ? 'text-success-500' : 'text-alarm-500',
                    )}
                    title={props.value}
                >
                    {props.value}
                </div>
            ),
            headerAlign: 'right',
            align: 'left',
        }),
        Column<Bundle>({
            field: 'actions',
            headerName: '',
            valueGetter: () => '',
            renderHeader: () =>
                bundlesAccess?.delete && (
                    <Button
                        aClassName='mr-auto'
                        variant={ButtonVariants.danger}
                        size={ButtonSizes.small}
                        onClick={() => onClick()}
                        className={cn((selectionList?.length ?? 0) > 0 ? 'flex' : 'hidden')}
                    >
                        حذف همه
                    </Button>
                ),
            renderCell: (props) => (
                <div className='flex items-center justify-end pl-4 gap-8 w-full h-full text-gray-700'>
                    {bundlesAccess?.delete && (
                        <a
                            href={'./bundles/update-bundle?id=' + props.row.id}
                            className='flex justify-center'
                        >
                            <button title='Edit'>
                                <Edit2
                                    width={24}
                                    height={24}
                                />
                            </button>
                        </a>
                    )}

                    {bundlesAccess?.delete && (
                        <button
                            title='Delete'
                            onClick={() => onClick(props.row.id)}
                        >
                            <Trash
                                width={24}
                                height={24}
                            />
                        </button>
                    )}
                </div>
            ),
            sortable: false,
        }),
    ]

    const { setDialogState } = useDialogStore()

    const [isDeleting, setIsDeleting] = useState<boolean>(false)

    return (
        <div className={cn(isDeleting && '[&_.MuiDataGrid-root]:opacity-50 [&_.MuiDataGrid-root]:pointer-events-none')}>
            <ListPage
                apiFunction={getBundles}
                generateDialog={(dialogData, setDialogData, processedRows, selectionList) => (
                    <DeleteConfirmDialog
                        title='حذف بسته‌های هدفمند'
                        itemToBeDeletedType='بسته‌ها'
                        onClose={() => setDialogData(false)}
                        accept={
                            dialogData === 'deleteAll'
                                ? () => {
                                      setIsDeleting(true)

                                      deleteBundles(selectionList ?? [])
                                          .then((data) => {
                                              if (
                                                  (data?.[0]?.['status'] ?? '') === 'Unauthorized.' ||
                                                  (data?.[0]?.['status'] ?? '') === 'access'
                                              )
                                                  setDialogState({
                                                      open: true,
                                                      title: 'خطای دسترسی',
                                                      type: DialogTypes.error,
                                                      description: 'شما دسترسی ندارید.',
                                                  })
                                          })
                                          .finally(() => {
                                              queryClient
                                                  .invalidateQueries({
                                                      queryKey: ['bundles'],
                                                  })
                                                  .then(() => setIsDeleting(false))
                                          })
                                  }
                                : dialogData !== false
                                ? () => {
                                      setIsDeleting(true)

                                      deleteBundles([dialogData])
                                          .then((data) => {
                                              if (
                                                  (data?.[0]?.['status'] ?? '') === 'Unauthorized.' ||
                                                  (data?.[0]?.['status'] ?? '') === 'access'
                                              )
                                                  setDialogState({
                                                      open: true,
                                                      title: 'خطای دسترسی',
                                                      type: DialogTypes.error,
                                                      description: 'شما دسترسی ندارید.',
                                                  })
                                          })
                                          .finally(() => {
                                              queryClient
                                                  .invalidateQueries({
                                                      queryKey: ['bundles'],
                                                  })
                                                  .then(() => setIsDeleting(false))
                                          })
                                  }
                                : () => null
                        }
                        open={dialogData !== false}
                        itemToBeDeleted={
                            dialogData === 'deleteAll'
                                ? undefined
                                : dialogData !== false
                                ? processedRows.filter((row) => row.id === dialogData)[0].bundle_title
                                : undefined
                        }
                    />
                )}
                getColumns={getBundleColumns}
                itemName={'بسته'}
                addPageLink='./bundles/add-bundle'
                queryKey={['bundles']}
                accessName='Bundles'
            />
        </div>
    )
}

export default Bundles
