import { GridRowId, GridColDef } from '@mui/x-data-grid'
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { getColumn, initialAddUserRequest } from '../constants'
import { Form, AddUserResult } from '../interfaces'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { addUser, updateUser } from '../api/add-user'
import { useMutation, useQuery } from '@tanstack/react-query'
import { SingleUser } from '../../Users/data-types/users'
import { getSingleUser, getUserAccess } from '../../Users/api/users'
import { toast } from 'react-toastify'

const userSchema: z.ZodType<SingleUser> = z
    .object({
        id: z.number(),
        name: z.string().min(1, 'این فیلد اجباری است'),
        lastname: z.string().min(1, 'این فیلد اجباری است'),
        initial: z.string().min(1, 'این فیلد اجباری است'),
        email: z.string().min(1, 'این فیلد اجباری است').email('ایمیل اشتباه است'),
        phone: z
            .string()
            .min(1, 'این فیلد اجباری است')
            .refine((value) => {
                const phone = isValidPhoneNumber('+98' + value.replaceAll('+', ''))
                if (phone) return true
                else return isValidPhoneNumber(value)
            }, 'شماره موبایل اشتباه است'),
        url: z.string().optional(),
        profile_picture: z.string().optional(),
        activated: z.boolean(),
        color: z.string(),
        password: z.string().min(6, 'پسورد باید حداقل 6 کاراکتر باشد').or(z.string().length(0)),
        confirmPassword: z.string().min(6, 'پسورد باید حداقل 6 کاراکتر باشد').or(z.string().length(0)),
    })

    .refine((data) => data.password === data.confirmPassword, {
        message: 'پسوردها یکی نیستند',
        path: ['confirmPassword'],
    })

export function useAddUser() {
    const searchParams = useRef<URLSearchParams>(new URLSearchParams(window.location.search))
    const userId = useRef<number>(parseInt(searchParams.current.get('id') ?? '-1'))
    const isEditMode = useRef<boolean>(userId.current !== -1)

    const { data: userData, isFetching: isFetchingUser } = useQuery({
        queryKey: ['singleUser', userId.current],
        queryFn: () => getSingleUser(userId.current),
        enabled: isEditMode.current,
        staleTime: 0,
        gcTime: 5 * 60 * 1000,
    })

    const { handleSubmit, control, setValue, watch, register, reset, ...otherFormProps } = useForm<SingleUser>({
        resolver: zodResolver(userSchema),
        defaultValues: isEditMode.current && userData ? userData : initialAddUserRequest.user,
    })

    useEffect(() => {
        if (isEditMode.current && userData) {
            reset(userData)
        }
    }, [reset, userData])

    const [rows, setRows] = useState<Form[]>(initialAddUserRequest.forms)

    const { data: formsData, isFetching: isFetchingForms } = useQuery({
        queryKey: ['singleUserAccess', userId.current],
        queryFn: () => getUserAccess(userId.current),
        enabled: isEditMode.current,
        staleTime: 0,
        gcTime: 5 * 60 * 1000,
    })

    useEffect(() => {
        if (formsData !== undefined && isEditMode.current) setRows(formsData)
    }, [formsData])

    const handleCheckboxChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>, id: GridRowId, cellName: string) => {
            setRows((currentRows) =>
                currentRows.map((row) => {
                    return id === row.formId ? { ...row, [cellName]: event.target.checked } : row
                }),
            )
        },
        [],
    )

    const toggleOneColumnsCell = useCallback((checked: boolean, headerField: GridRowId) => {
        setRows((currentRows) =>
            currentRows.map(({ ...row }) => {
                return { ...row, [headerField]: checked }
            }),
        )
    }, [])

    const areCellRowsAllChecked = useMemo(() => {
        let res = { access: true, add: true, update: true, delete: true }

        rows.forEach((row) => {
            if (!row.access) res.access = false
            if (!row.add) res.add = false
            if (!row.update) res.update = false
            if (!row.delete) res.delete = false
        })

        return res
    }, [rows])

    const columns: GridColDef<Form>[] = useMemo(
        () => getColumn(handleCheckboxChange, toggleOneColumnsCell, areCellRowsAllChecked),
        [handleCheckboxChange, toggleOneColumnsCell, areCellRowsAllChecked],
    )

    const [dialogOpen, setDialogOpen] = useState<false | 'added' | 'updated'>(false)

    // const queryClient = useQueryClient()

    // useEffect(() => {
    //     queryClient.clear()
    //     queryClient.invalidateQueries()
    // }, [queryClient])

    const onUserAddSuccess = useCallback((res: AddUserResult) => {
        if (res.status === 'success') {
            if (isEditMode.current) setDialogOpen('updated')
            else setDialogOpen('added')
        } else toast.error(res.status)
    }, [])

    const {
        mutate: addUserMutate,
        isPending: addUserIsPending,
        isError: addUserIsError,
        error: addUserError,
    } = useMutation({ mutationFn: addUser, onSuccess: onUserAddSuccess, mutationKey: ['users'] })

    const {
        mutate: updateUserMutate,
        isPending: updateUserIsPending,
        isError: updateUserIsError,
        error: updateUserError,
    } = useMutation({ mutationFn: updateUser, onSuccess: onUserAddSuccess, mutationKey: ['users'] })

    const onSubmit = useCallback(() => {
        if (isEditMode.current)
            updateUserMutate({
                forms: rows,
                user: {
                    ...watch(),
                    profile_picture: watch('profile_picture'),
                    id: userId.current,
                },
            })
        else
            addUserMutate({
                forms: rows,
                user: {
                    ...watch(),
                    profile_picture: watch('profile_picture'),
                },
            })
    }, [addUserMutate, rows, updateUserMutate, watch])

    useEffect(() => {
        if (addUserIsError) console.log(addUserError)
        if (updateUserIsError) console.log(updateUserError)
    }, [addUserError, addUserIsError, updateUserError, updateUserIsError])

    const [mediaManagerOpen, setMediaManagerOpen] = useState(false)

    return {
        columns,
        handleSubmit: handleSubmit(onSubmit),
        control,
        watch,
        register,
        setValue,
        setDialogOpen,
        dialogOpen,
        isPending: isEditMode.current ? updateUserIsPending : addUserIsPending,
        rows,
        loadingAllOnEdit: (isFetchingForms || isFetchingUser) && isEditMode.current,
        mediaManagerOpen,
        setMediaManagerOpen,
        originalHandleSubmit: handleSubmit,
        reset,
        otherFormProps,
    }
}
