import { CheckboxProps } from '@mui/material'
import { BaseCheckboxPropsOverrides } from '@mui/x-data-grid'
import { cn } from '../../../utils/cn'
import { Minus } from 'iconsax-react'

interface CustomCheckboxProps extends CheckboxProps, BaseCheckboxPropsOverrides {
    label?: string
}

function CustomCheckbox({
    onChange,
    checked,
    defaultChecked,
    disabled,
    className,
    label,
    indeterminate,
}: CustomCheckboxProps) {
    return (
        <label className={cn('group/checkbox w-full h-full flex items-center justify-center relative', className)}>
            <input
                className='absolute opacity-0 cursor-pointer h-0 w-0'
                type='checkbox'
                checked={checked}
                onChange={(e) => {
                    if (onChange) onChange(e, e.currentTarget.checked)
                }}
                defaultChecked={defaultChecked}
                disabled={disabled}
            />
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                fill='none'
            >
                <rect
                    x='.5'
                    y='.5'
                    width='19'
                    height='19'
                    rx='5.5'
                    fill='#fff'
                    className='group-has-[input[type=checkbox]:checked]/checkbox:[fill:#f5f7ff]'
                />
                <g
                    className='group-has-[input[type=checkbox]:checked]/checkbox:[stroke:#006962]'
                    stroke='#D0D5DD'
                >
                    <rect
                        x='.5'
                        y='.5'
                        width='19'
                        height='19'
                        rx='5.5'
                    />

                    {!indeterminate && (
                        <path
                            d='M14.667 6.5l-6.417 6.417L5.334 10'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            className='group-has-[input[type=checkbox]:checked]/checkbox:block hidden'
                        />
                    )}
                </g>
            </svg>

            {indeterminate && <Minus className='size-5 absolute inset-0 m-auto text-primary-700' />}

            {label && <span className='text-gray-700 text-xs mr-2'>{label}</span>}
        </label>
    )
}

export default CustomCheckbox
