import { FC } from 'react'
import ListPage from '../_components/list-page'
import getTherapistColumns from './constants'
import { getTherapists } from './api'

const Therapists: FC = () => {
    return (
        <ListPage
            apiFunction={getTherapists}
            generateDialog={() => <></>}
            getColumns={getTherapistColumns}
            itemName={'درمانگر'}
            addPageLink='./therapists/add-therapist'
            queryKey={['therapists']}
            accessName='Therapists'
        />
    )
}

export default Therapists
