import { FC, useMemo } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { TherapistDetail } from '../data-types'
import Section from '../../Tests/components/section'
import { Add, Trash } from 'iconsax-react'
import { format } from 'date-fns-jalali'
import { faIR } from 'date-fns/locale'
import AutoCompleteTextField from '../../_components/AutoCompleteTextField'

const RightsTab: FC = () => {
    const { control } = useFormContext<TherapistDetail>()

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'fees',
    })

    const disabled = useMemo(() => fields.length >= 1, [fields.length])

    return (
        <>
            <Section className='flex items-center gap-2 flex-row p-[0.875rem]'>
                <button
                    onClick={() =>
                        !disabled &&
                        append({
                            new: true,
                            startDate: getFormattedPersianDate(),
                            insideFeeUnit: 'تومان',
                            outsideFeeUnit: 'دلار',
                        })
                    }
                    disabled={disabled}
                    type='button'
                    className='flex gap-2 items-center disabled:opacity-50 disabled:pointer-events-none'
                >
                    <div className='w-9 h-9 rounded-full bg-primary-500 flex items-center justify-center'>
                        <Add
                            size={24}
                            className='text-white'
                        />
                    </div>

                    <div className='text-gray-700'>افزودن فی جدید</div>
                </button>
            </Section>

            {fields.map((field, index) => (
                <Section
                    key={field.id}
                    className='relative'
                >
                    <div className='flex items-center gap-1 justify-between'>
                        <div className='flex gap-3 items-center'>
                            <div>تاریخ شروع:</div>

                            <input
                                type='text'
                                className='w-28 text-center border border-gray-200 rounded p-1 flex items-center justify-center text-sm text-gray-700'
                                readOnly
                                value={field.startDate}
                            />
                        </div>

                        <button
                            type='button'
                            className='w-9 h-9 rounded-xl bg-gray-100 flex items-center justify-center text-[#5C5C5C]'
                            onClick={() => remove(index)}
                        >
                            <Trash size={20} />
                        </button>
                    </div>

                    <div className='grid grid-cols-2 gap-8'>
                        <AutoCompleteTextField<TherapistDetail>
                            control={control}
                            name={`fees.${index}.insideFee`}
                            autocompleteName={`fees.${index}.insideFeeUnit`}
                            autocompleteOptions={['تومان', 'دلار']}
                            type='number'
                            label='فی داخلی هر جلسه'
                        />

                        <AutoCompleteTextField<TherapistDetail>
                            control={control}
                            name={`fees.${index}.outsideFee`}
                            autocompleteName={`fees.${index}.outsideFeeUnit`}
                            autocompleteOptions={['تومان', 'دلار']}
                            type='number'
                            label='فی خارجی هر جلسه'
                        />
                    </div>
                </Section>
            ))}
        </>
    )
}

export default RightsTab

function getFormattedPersianDate() {
    const now = new Date()
    return format(now, 'yyyy/M/d', { locale: faIR })
}
