import CMSLayout from '../../../../_core/components/layouts/cms-dashboard/CMSLayout'
import { useMemo } from 'react'
import { WorkshopForm } from '../_components/WorkshopForm'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getWorkshop } from '../api'

const UpdateWorkshopPage = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const id = useMemo(() => parseInt(searchParams.get('id') ?? ''), [searchParams])

    if (isNaN(id)) {
        navigate('/cms/workshops')
    }

    const { data, isFetching } = useQuery({
        queryKey: ['workshops'],
        queryFn: () => getWorkshop(id),
        staleTime: 0,
        gcTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
        select: ({ syllabi, datetime, ...data }) => ({
            syllabi: syllabi.split('|').map((item, index) => ({ value: item, id: index })),
            datetime: datetime.map((item, index) => ({ value: item, id: index })),
            ...data,
        }),
    })

    return (
        <CMSLayout>
            {isFetching ? (
                <div className='w-full h-full flex justify-center items-start'>
                    <span className='loader'></span>
                </div>
            ) : (
                <WorkshopForm
                    type='UPDATE'
                    workshop={data}
                />
            )}
        </CMSLayout>
    )
}

export default UpdateWorkshopPage
