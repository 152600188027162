import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import { CustomTextFieldProps, TextFieldWithoutControl } from '../AddUser/components/text-field'
import { TextFieldProps } from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import { Calendar2 } from 'iconsax-react'
import DatePicker, { DateObject } from 'react-multi-date-picker'
import persian from 'react-date-object/calendars/persian'
import persian_fa from 'react-date-object/locales/persian_fa'
import TimePicker from 'react-multi-date-picker/plugins/time_picker'

const weekDays = ['ش', 'ی', 'د', 'س', 'چ', 'پ', 'ج']

interface CustomDatePickerProps<T extends FieldValues>
    extends Omit<TextFieldProps, 'variant'>,
        Omit<CustomTextFieldProps, 'generateOnChange' | 'generateValue'> {
    control: Control<T, keyof T>
    name: Path<T>
    noIcon?: boolean
    hasTime?: boolean
    format?: string
    generateOnChange?: (value: DateObject | null) => string
    generateValue?: (value: string) => string
    disableDayPicker?: boolean
}

function CustomDatePicker<T extends FieldValues>({
    control,
    name,
    noIcon,
    format,
    generateOnChange,
    generateValue,
    disableDayPicker,
    hasTime,
    ...props
}: CustomDatePickerProps<T>) {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <DatePicker
                    disableDayPicker={disableDayPicker}
                    format={disableDayPicker ? 'HH:mm' : format}
                    {...(hasTime && { plugins: [<TimePicker hideSeconds />] })}
                    weekDays={weekDays}
                    calendar={persian}
                    locale={persian_fa}
                    onChange={(date) => field.onChange(generateOnChange ? generateOnChange(date) : date?.toString())}
                    render={(_, openCalendar) => (
                        <TextFieldWithoutControl
                            onFocus={openCalendar}
                            {...(!noIcon && {
                                InputProps: {
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <Calendar2 size={24} />
                                        </InputAdornment>
                                    ),
                                },
                            })}
                            {...field}
                            {...props}
                            value={generateValue ? generateValue(field.value) : field.value}
                            onChange={undefined}
                        />
                    )}
                />
            )}
        />
    )
}

export default CustomDatePicker
