/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios'
import SuggestionForm, { SuggestionServer, SuggestionType } from './SuggestionForm'
import { DialogTypes, useDialogStore } from './Dialog'
import { Dialog } from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react'
import { create } from 'zustand'
import { Add, CloseCircle } from 'iconsax-react'
import { EditButton, UploadIcon } from '../../../_core/components/icons'
import { cn } from '../../../utils/cn'

type SuggestionsStore = {
    suggestions: SuggestionType[]
    setSuggestions: (state: SuggestionType[]) => void
}

export const useSuggestionsStore = create<SuggestionsStore>((set) => ({
    suggestions: [],
    setSuggestions: (state) => set({ suggestions: state }),
}))

type MediaManagerType = {
    type: 'ICON' | 'IMAGE' | 'SUGGESTION'
    isOpen: boolean
    onClose: () => void
    onChange: (file: string | SuggestionServer) => void
}

export const MediaManager = ({ type, isOpen, onClose, onChange }: MediaManagerType) => {
    const { setDialogState } = useDialogStore()

    const [icons, setIcons] = useState<string[]>([])
    const [images, setImages] = useState<string[]>([])
    const [isReload, setIsReload] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [suggestions, setSuggestions] = useState<SuggestionServer[]>([])
    const [suggestionEdit, setSuggestionEdit] = useState<SuggestionServer>()
    const [isUploadLoading, setIsUploadLoading] = useState<boolean>(false)
    const [isOpenSuggestionModal, setIsOpenSuggestionModal] = useState<boolean>(false)
    const [isOpenSuggestionEditModal, setIsOpenSuggestionEditModal] = useState<boolean>(false)

    const { setSuggestions: setSuggestionsStore } = useSuggestionsStore()

    const getMedias = async () => {
        try {
            const response = await axios
                .get(`https://stage2-api.insight-clinic.com/controller/get_medias_v2.php`, {
                    headers: { AuthorizationKey: localStorage.getItem('token') ?? '' },
                })
                .then((res) => {
                    return res
                })
                .finally(() => {
                    setIsLoading(false)
                })
            const data = await response.data
            if (type === 'ICON') setIcons(data.icons)
            if (type === 'IMAGE') setImages(data.images)
        } catch (error) {
            console.log('error get medias', error)
        }
    }

    const getSuggestions = async () => {
        try {
            const response = await axios.get(
                `https://stage2-api.insight-clinic.com/controller/get_suggestions_v2.php`,
                {
                    headers: { AuthorizationKey: localStorage.getItem('token') ?? '' },
                },
            )
            const data = await response.data
            setSuggestions(data.data)
            setSuggestionsStore(data.data)
            setIsLoading(false)
            setIsReload(false)
        } catch (error) {
            console.log('error get suggestions', error)
        }
    }

    useEffect(() => {
        getMedias()
        getSuggestions()
    }, [])

    useEffect(() => {
        if (isReload) {
            setIsLoading(true)
            getSuggestions()
        }
    }, [isReload])

    useEffect(() => {
        getMedias()
    }, [isUploadLoading])

    const uploadIcon = async (file: ChangeEvent<HTMLInputElement>) => {
        setIsUploadLoading(true)
        const formData = new FormData()
        if (file.target.files) formData.append('file', file.target.files[0])

        try {
            const response = await axios.post(
                `https://stage2-api.insight-clinic.com/controller/add_media_icon_v2.php`,
                formData,
                {
                    headers: { AuthorizationKey: localStorage.getItem('token') ?? '' },
                },
            )
            const data = await response.data
            if (data === null || data.code === 0 || data.uploaded === false)
                setDialogState({
                    open: true,
                    title: 'خطا در آپلود',
                    type: DialogTypes.error,
                    description: 'مشکلی پیش آمده است.',
                })
            if (data.uploaded)
                setDialogState({
                    open: true,
                    title: 'آپلود موفق',
                    type: DialogTypes.success,
                    description: 'با موفقیت آپلود شد.',
                })
            setIsLoading(true)
        } catch (error) {
            console.log('error add icons', error)
            setDialogState({
                open: true,
                title: 'خطا در آپلود',
                type: DialogTypes.error,
                description: 'مشکلی پیش آمده است.',
            })
        } finally {
            setIsUploadLoading(false)
        }
    }

    const uploadImage = async (file: ChangeEvent<HTMLInputElement>) => {
        setIsUploadLoading(true)
        const formData = new FormData()
        if (file.target.files) formData.append('file', file.target.files[0])

        try {
            const response = await axios.post(
                `https://stage2-api.insight-clinic.com/controller/add_media_image_v2.php`,
                formData,
                {
                    headers: { AuthorizationKey: localStorage.getItem('token') ?? '' },
                },
            )
            const data = await response.data
            if (data === null || data.code === 0 || data.uploaded === false)
                setDialogState({
                    open: true,
                    title: 'خطا در آپلود',
                    type: DialogTypes.error,
                    description: 'مشکلی پیش آمده است.',
                })
            if (data.uploaded)
                setDialogState({
                    open: true,
                    title: 'آپلود موفق',
                    type: DialogTypes.success,
                    description: 'با موفقیت آپلود شد.',
                })
            setIsLoading(true)
        } catch (error) {
            console.log('error add images', error)
            setDialogState({
                open: true,
                title: 'خطا در آپلود',
                type: DialogTypes.error,
                description: 'مشکلی پیش آمده است.',
            })
        } finally {
            setIsUploadLoading(false)
        }
    }

    return (
        <>
            <Dialog
                open={isOpen}
                className='!w-[774px] mx-auto [&_.MuiDialog-paper]:!m-0 [&_.MuiDialog-paper]:!max-w-full [&_.MuiDialog-paper]:!w-full !z-[99999999]'
            >
                <div
                    className={cn(
                        'w-full flex items-center justify-between gap-1',
                        type === 'ICON' && 'border-b border-gray-100',
                    )}
                >
                    {type === 'ICON' && <div className='text-base font-medium text-center p-4'>آیکون‌ها</div>}

                    {type === 'IMAGE' && <div className='text-base font-medium text-center p-4'>عکس‌ها</div>}

                    {type === 'SUGGESTION' && (
                        <button
                            type='button'
                            className='flex m-7 items-center px-2 py-1 rounded bg-primary-500 text-white font-medium mb-0'
                            onClick={() => setIsOpenSuggestionModal(true)}
                        >
                            <Add size={20} />
                            <div>پیشنهاد جدید</div>
                        </button>
                    )}

                    <button
                        type='button'
                        onClick={onClose}
                        className='mr-auto ml-7 mt-auto text-gray-700 mb-3'
                    >
                        <CloseCircle size={24} />
                    </button>
                </div>

                <div className='max-h-[500px] overflow-y-auto mt-5'>
                    {isLoading ? (
                        <div className='min-w-[500px] w-full h-[200px] flex items-center justify-center'>
                            <span className='loader'></span>
                        </div>
                    ) : (
                        <>
                            {type === 'ICON' && (
                                <div className={'flex flex-col gap-5'}>
                                    <div className='border border-dashed border-gray-200 hover:border-gray-300 mx-auto rounded h-[110px] w-[121px] relative overflow-hidden cursor-pointer flex justify-center items-center flex-col gap-4'>
                                        {isUploadLoading ? (
                                            <span className='loader'></span>
                                        ) : (
                                            <>
                                                <input
                                                    type='file'
                                                    id='avatar'
                                                    accept='.svg'
                                                    onChange={uploadIcon}
                                                    className={
                                                        'absolute inset-0 opacity-0 bg-transparent cursor-pointer'
                                                    }
                                                />
                                                <UploadIcon
                                                    height={24}
                                                    className='text-gray-300'
                                                />
                                                <div className='text-xs text-gray-300'>بارگذاری فایل جدید</div>
                                            </>
                                        )}
                                    </div>

                                    <div className='text-gray-500 text-xs text-center'>
                                        * تصاویر باید در فرمت svg باشند
                                    </div>

                                    <div className='grid grid-cols-8 gap-5 p-5'>
                                        {icons.map((icon, index) => (
                                            <button
                                                type='button'
                                                key={`key-of-icon-${index}`}
                                                className='aspect-square overflow-hidden bg-gray-100 rounded-3xl flex justify-center items-center'
                                                onClick={() => {
                                                    onChange(icon)
                                                    onClose()
                                                }}
                                            >
                                                <img
                                                    src={icon}
                                                    alt={String(index)}
                                                    className='block w-full h-full object-contain object-center'
                                                />
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {type === 'IMAGE' && (
                                <div className={'flex flex-col gap-5'}>
                                    <div className='border border-dashed border-gray-200 hover:border-gray-300 mx-auto rounded h-[110px] w-[121px] relative overflow-hidden cursor-pointer flex justify-center items-center flex-col gap-4'>
                                        {isUploadLoading ? (
                                            <span className='loader'></span>
                                        ) : (
                                            <>
                                                <input
                                                    type='file'
                                                    id='avatar'
                                                    accept='image/*'
                                                    onChange={uploadImage}
                                                    className={
                                                        'absolute inset-0 opacity-0 bg-transparent cursor-pointer'
                                                    }
                                                />

                                                <UploadIcon
                                                    height={24}
                                                    className='text-gray-300'
                                                />
                                                <div className='text-xs text-gray-300'>بارگذاری فایل جدید</div>
                                            </>
                                        )}
                                    </div>

                                    <div className='grid grid-cols-4 gap-5 p-5'>
                                        {images.map((image, index) => (
                                            <button
                                                type='button'
                                                key={`key-of-image-${index}`}
                                                className='aspect-square overflow-hidden bg-gray-100 rounded flex justify-center items-center'
                                                onClick={() => {
                                                    onChange(image)
                                                    onClose()
                                                }}
                                            >
                                                <img
                                                    src={image}
                                                    alt={String(index)}
                                                    className='block w-full h-full object-cover object-center'
                                                />
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {type === 'SUGGESTION' && (
                                <div className='p-7 pt-2 flex flex-col gap-2'>
                                    {isLoading && (
                                        <div className='w-full h-full flex justify-center items-center'>
                                            <span className='loader' />
                                        </div>
                                    )}

                                    {suggestions.map((suggestion, index) => (
                                        <div
                                            key={`key-of-suggestion-${index}`}
                                            className='bg-gray-50 rounded-lg p-[1.125rem] gap-[1.125rem] flex w-full hover:bg-gray-100 cursor-pointer'
                                            onClick={() => {
                                                onChange(suggestion)
                                                onClose()
                                            }}
                                        >
                                            <img
                                                className='w-32 min-w-32 h-fit object-contain object-top bg-white rounded-lg border border-gray-100 aspect-square'
                                                src={suggestion.image ?? ''}
                                                alt='suggestion'
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null
                                                    currentTarget.src =
                                                        "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='80' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='m21.68 16.96-3.13-7.31c-1.06-2.48-3.01-2.58-4.32-.22l-1.89 3.41c-.96 1.73-2.75 1.88-3.99.33l-.22-.28c-1.29-1.62-3.11-1.42-4.04.43l-1.72 3.45C1.16 19.17 2.91 22 5.59 22h12.76c2.6 0 4.35-2.65 3.33-5.04ZM6.97 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z' stroke='%23697689' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/svg%3E"
                                                }}
                                            />

                                            <div className='flex flex-col gap-2 text-xs text-gray-700 w-full'>
                                                <div className='flex items-center gap-2'>
                                                    <div>کد: </div>
                                                    <div className='text-gray-800 font-medium'>
                                                        {suggestion.id} -{' '}
                                                        {suggestion.category?.split('|').join(' , ') ??
                                                            'بدون دسته بندی'}
                                                    </div>
                                                </div>

                                                <div className='flex items-center gap-2'>
                                                    <div>نام کتاب: </div>
                                                    <div className='text-gray-800 font-medium'>{suggestion.title}</div>
                                                </div>

                                                <div>{suggestion.description}</div>

                                                <div className='grow' />

                                                <div className='flex justify-between items-end gap-3'>
                                                    <div className='flex gap-3 items-center flex-wrap'>
                                                        {suggestion?.keywords
                                                            ?.split('|')
                                                            .filter((itm) => itm !== '')
                                                            .map((itm, index) => (
                                                                <div
                                                                    key={index}
                                                                    className='rounded-full line-clamp-1 py-[0.125rem] px-[0.375rem] bg-gray-100 text-[0.625rem]'
                                                                >
                                                                    {itm}
                                                                </div>
                                                            ))}
                                                    </div>

                                                    <div className='flex items-center gap-3'>
                                                        {/* <button type='button'>
                                                            <DeleteButton className='w-8 h-8' />
                                                        </button> */}

                                                        <button
                                                            type='button'
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                setSuggestionEdit(suggestion)
                                                                setIsOpenSuggestionEditModal(true)
                                                            }}
                                                        >
                                                            <EditButton className='w-8 h-8' />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </>
                    )}
                </div>
            </Dialog>

            {isOpenSuggestionModal && (
                <SuggestionForm
                    isOpen={isOpenSuggestionModal}
                    onChange={() => setIsReload(true)}
                    onClose={() => setIsOpenSuggestionModal(false)}
                    type='ADD'
                />
            )}

            {isOpenSuggestionEditModal && suggestionEdit && (
                <SuggestionForm
                    suggestion={{
                        ...suggestionEdit,
                        keywords: suggestionEdit.keywords.split('|'),
                        category: suggestionEdit.category.split('|'),
                    }}
                    onChange={() => setIsReload(true)}
                    isOpen={isOpenSuggestionEditModal}
                    onClose={() => setIsOpenSuggestionEditModal(false)}
                    type='UPDATE'
                />
            )}
        </>
    )
}
