export default function getDate(rawDate: string) {
    const date = new Date(Date.parse(rawDate))

    const formattedDate = date.toLocaleString('fa-IR-u-ca-persian-nu-arab', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        calendar: 'persian',
        numberingSystem: 'arab',
    })

    if (formattedDate === 'Invalid Date') return ''

    const parts = formattedDate.split(', ')
    const datePart = parts[0]?.replace(/\//g, '/') ?? ''
    const timePart = parts[1]?.replace(':', ':').replace(' ', ' ساعت ') ?? ''

    const result = `${datePart}- ساعت ${timePart}`

    return result
}
