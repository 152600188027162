import { GridRowId } from '@mui/x-data-grid'
import { SingleUser, initialRow } from '../data-types/users'
import { Form } from '../../AddUser/interfaces'
import { initialAddUserRequest } from '../../AddUser/constants'

export async function getSingleUser(id: number): Promise<SingleUser> {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/get_user_v2.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify({
                id: id,
            }),
        })
        const data = await response.json()
        return data.data
    } catch (error) {
        console.error('Failed to fetch initial data:', error)
        return initialRow
    }
}

export async function getUsersInGeneral(): Promise<SingleUser[]> {
    try {
        // TODO hide AuthorizationKey
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/get_users_v2.php', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
        })
        const data = await response.json()
        return data.data
    } catch (error) {
        console.error('Failed to fetch initial data:', error)
        return [initialAddUserRequest.user]
    }
}

export function deleteUsers(usersToDelete: GridRowId[]) {
    usersToDelete.map((userToBeDeleted) => console.log('removed', userToBeDeleted))
}

export async function getUserAccess(id: number): Promise<Form[]> {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/get_user_access_v3.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify({
                id: id,
            }),
        })
        const data = await response.json()
        return data.data
    } catch (error) {
        console.error('Failed to fetch initial data:', error)
        return initialAddUserRequest.forms
    }
}
