import CMSLayout from '../../../../_core/components/layouts/cms-dashboard/CMSLayout'
import { BundleForm } from '../_components/BundleForm'

const AddBundlePage = () => {
    return (
        <CMSLayout>
            <BundleForm type='ADD' />
        </CMSLayout>
    )
}

export default AddBundlePage
