import { createSlice } from '@reduxjs/toolkit'
import { UserAccessType } from '../../components/model/user-access-type'
import type { PayloadAction } from '@reduxjs/toolkit'

export type InitialState = {
    value: number | undefined
}

const initialState: InitialState = {
    value: undefined,
}

export const formItemSlice = createSlice({
    name: 'formItem',
    initialState,
    reducers: {
        updateFormItem: (state, action: PayloadAction<number | undefined>) => {
            state.value = action.payload
        },
    },
})

export const { updateFormItem } = formItemSlice.actions
export default formItemSlice.reducer
