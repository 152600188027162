import { FC, useMemo } from 'react'
import DialogUI, { NecessaryDialogProps } from '../../../../_core/components/dialog-ui'
import { useQuery } from '@tanstack/react-query'
import { PaymentStatus, Reservation, ReservationStatus, Role } from '../data-types'
import { getReservations } from '../api'
import { cn } from '../../../../utils/cn'
import formatDate from '../../../../utils/format-date'
import addCommas from '../../../../utils/add-commas'

export const generateReservationStatusText = (reservation_status?: ReservationStatus) => {
    if (reservation_status === ReservationStatus.Pending) return 'برگزار نشده'
    else if (reservation_status === ReservationStatus.Cancelled) return 'لغو شده'
    else if (reservation_status === ReservationStatus.Active) return 'درحال برگزاری'
    else if (reservation_status === ReservationStatus.Past) return 'برگزار شده'
    else return 'نامشخص'
}

const DetailDialog: FC<NecessaryDialogProps> = ({ compactDialogState, setCompactDialogState }) => {
    const { data: reservations } = useQuery<Reservation[]>({ queryKey: ['reservations'], queryFn: getReservations })

    const selectedReservation = useMemo(
        () => reservations?.find((res) => res.id === compactDialogState?.[0]),
        [compactDialogState, reservations],
    )

    const title = useMemo(
        () => generateReservationStatusText(selectedReservation?.reservation_status),
        [selectedReservation],
    )

    const reserver = useMemo(() => {
        if (selectedReservation?.reserver === Role.Admin) return 'ادمین'
        else if (selectedReservation?.reserver === Role.Therapist) return 'درمانگر'
        else if (selectedReservation?.reserver === Role.Patient) return 'درمانجو'
        else return 'نامشخص'
    }, [selectedReservation])

    const canceller = useMemo(() => {
        if (selectedReservation?.canceller === Role.Admin) return 'ادمین'
        else if (selectedReservation?.canceller === Role.Therapist) return 'درمانگر'
        else if (selectedReservation?.canceller === Role.Patient) return 'درمانجو'
        else return 'نامشخص'
    }, [selectedReservation])

    const paymentStatus = useMemo(() => {
        if (selectedReservation?.payment_status === PaymentStatus.Unpaid) return 'پرداخت نشده'
        else if (selectedReservation?.payment_status === PaymentStatus.Paid) return 'پرداخت شده'
        else return 'نامشخص'
    }, [selectedReservation])

    return (
        <DialogUI
            dialogTitle={'جزئیات نوبت ' + title}
            generateTitle={(title) => (
                <div
                    className={cn(
                        'text-gray-400',
                        selectedReservation?.reservation_status === ReservationStatus.Pending && 'text-alarm-500',
                        selectedReservation?.reservation_status === ReservationStatus.Cancelled && 'text-gray-400',
                        selectedReservation?.reservation_status === ReservationStatus.Active && 'text-[#FA8D34]',
                        selectedReservation?.reservation_status === ReservationStatus.Past && 'text-success-500',
                    )}
                >
                    {title}
                </div>
            )}
            compactDialogState={compactDialogState}
            setCompactDialogState={setCompactDialogState}
            className='h-[515px] h-max-[515px] [&_hr]:mb-0'
        >
            <Detail
                detailKey='نام درمانجو:'
                value={selectedReservation?.patient_name}
            />

            <Detail
                detailKey='شماره تماس درمانجو:'
                value={selectedReservation?.patient_phone}
            />

            <Detail
                detailKey='نام درمانگر:'
                value={selectedReservation?.therapist_name}
            />

            <Detail
                detailKey='شماره تماس درمانگر:'
                value={selectedReservation?.therapist_phone}
            />

            <Detail
                detailKey='تاریخ و ساعت رزرو نوبت:'
                value={formatDate(selectedReservation?.date_time ?? '')}
            />

            <Detail
                detailKey='رزرو کننده نوبت:'
                value={reserver}
            />

            <Detail
                detailKey='هزینه نوبت (تومان):'
                value={addCommas(selectedReservation?.price_toman) + ' تومان'}
            />

            <Detail
                detailKey='هزینه نوبت (دلار):'
                value={addCommas(selectedReservation?.price_dollar) + '$'}
            />

            <Detail
                detailKey='وضعیت پرداخت:'
                value={paymentStatus}
                className={cn(
                    selectedReservation?.payment_status === PaymentStatus.Unpaid && 'text-alarm-500',
                    selectedReservation?.payment_status === PaymentStatus.Paid && 'text-success-500',
                )}
            />

            <Detail
                detailKey='وضعیت نوبت:'
                value={title}
                className={cn(
                    'text-gray-400',
                    selectedReservation?.reservation_status === ReservationStatus.Pending && 'text-alarm-500',
                    selectedReservation?.reservation_status === ReservationStatus.Cancelled && 'text-gray-400',
                    selectedReservation?.reservation_status === ReservationStatus.Active && 'text-[#FA8D34]',
                    selectedReservation?.reservation_status === ReservationStatus.Past && 'text-success-500',
                )}
            />

            <Detail
                detailKey='تاریخ و ساعت لغو نوبت:'
                value={formatDate(selectedReservation?.cancel_date_time ?? '')}
            />

            <Detail
                detailKey='لغو کننده نوبت:'
                value={canceller}
            />

            <Detail
                detailKey='علت لغو نوبت:'
                value={selectedReservation?.cancel_reason}
            />
        </DialogUI>
    )
}

export default DetailDialog

interface DetailProps {
    value?: string
    detailKey: string
    className?: string
}

const Detail: FC<DetailProps> = ({ value, detailKey, className }) => {
    if (value)
        return (
            <div className='pt-6 pb-2 border-b border-dashed border-gray-100 flex items-center justify-between gap-1 last-of-type:border-none'>
                <div className='text-gray-500'>{detailKey}</div>
                <div
                    className={cn('text-gray-800 truncate', className)}
                    title={value}
                >
                    {value}
                </div>
            </div>
        )

    return null
}
