import { FC, KeyboardEventHandler, useCallback, useMemo } from 'react'
import CMSLayout from '../../../../_core/components/layouts/cms-dashboard/CMSLayout'
import { TestDetail } from '../data-types'
import { Tabs, TabsContent, TabsTrigger, useTabStore } from '../../../../_core/components/tabs'
import QuestionnaireSpecifications from './questionnaire-specifications'
import Settings from './settings'
import { Control, FormProvider, useForm } from 'react-hook-form'
import { z } from '../../../../utils/zod-translation'
import { zodResolver } from '@hookform/resolvers/zod'
import Button, { ButtonSizes, ButtonVariants } from '../../../../_core/components/button'
import { Refresh } from 'iconsax-react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import { addTestDetail, getAgeRangeCategories, updateTestDetail } from '../api'
import Questions from './questions'
import { cn } from '../../../../utils/cn'
import SidebarLink from './sidebar-link'
import SidebarImage from './sidebar-image'
import Scales from './scales'
import { DialogTypes, useDialogStore } from '../../_components/Dialog'

export const Suggestion2Schema = z.object({
    id: z.coerce.number().optional(),
    test_id: z.coerce.number().optional(),
    indicator_id: z.coerce.number().optional(),
    result_id: z.coerce.number().optional(),
    suggestion: z.coerce.number().optional(),
    title: z.coerce.string().optional(),
})

export const IndicatorResultSchema = z.object({
    id: z.coerce.number().optional(),
    test_id: z.coerce.number().optional(),
    indicator_id: z.coerce.number().optional(),
    indicatorResultFrom: z.coerce.number().optional(),
    indicatorResultTo: z.coerce.number().optional(),
    result: z.coerce.string().optional(),
    indicatorResultGageIcon: z.coerce.string().optional(),
    indicatorResultGageLabel: z.coerce.string().optional(),
    paidResult: z.coerce.string().optional(),
    indicatorResultTitle: z.coerce.string().optional(),
    suggestions: z.array(Suggestion2Schema).optional(),
})

export const SubIndicatorResultSchema = IndicatorResultSchema.omit({
    indicatorResultFrom: true,
    indicatorResultTo: true,
    indicatorResultGageIcon: true,
    indicatorResultGageLabel: true,
    indicatorResultTitle: true,
}).extend({
    subIndicatorResultFrom: z.coerce.number().optional(),
    subIndicatorResultTo: z.coerce.number().optional(),
    subIndicatorResultGageIcon: z.coerce.string().optional(),
    subIndicatorResultGageLabel: z.coerce.string().optional(),
    subIndicatorResultTitle: z.coerce.string().optional(),
})

export const IndicatorSchema = z.object({
    id: z.coerce.number(),
    test_id: z.coerce.number().optional(),
    index: z.coerce.number(),
    code: z.coerce.string().optional(),
    indicator: z.coerce.string().optional(),
    description: z.coerce.string().optional(),
    formula: z.coerce.string().optional(),
    measurementTitle: z.coerce.string().optional(),
    result: z.array(IndicatorResultSchema).optional(),
})

export const SubIndicatorSchema = IndicatorSchema.omit({ result: true, indicator: true }).extend({
    result: z.array(SubIndicatorResultSchema).optional(),
    title: z.coerce.string().optional(),
})

export const SuggestionSchema = z.object({
    id: z.coerce.number().optional(),
    test_id: z.coerce.number().optional(),
    overall_score_id: z.coerce.number().optional(),
    suggestion: z.coerce.number().optional(),
})

export const OverallScoreSchema = z.object({
    id: z.coerce.number().optional(),
    test_id: z.coerce.number().optional(),
    title: z.coerce.string().optional(),
    overallScoreFrom: z.coerce.number().optional(),
    overallScoreTo: z.coerce.number().optional(),
    result: z.coerce.string().optional(),
    overallScoreGageIcon: z.coerce.string().optional(),
    overallScoreGageLabel: z.coerce.string().optional(),
    paidResult: z.coerce.string().optional(),
    overallTitle: z.coerce.string().optional(),
    suggestions: z.array(SuggestionSchema).optional(),
})

export const AnswerSchema = z.object({
    id: z.coerce.number().optional(),
    test_id: z.coerce.number().optional(),
    question_id: z.coerce.number().optional(),
    index: z.coerce.number().optional(),
    answer: z.coerce.string().optional(),
    answerType: z.any().optional(),
    answer1: z.any().optional(),
    answerType1: z.any().optional(),
    point: z.coerce.number().optional(),
})

export const QuestionSchema = z.object({
    id: z.coerce.number(),
    test_id: z.coerce.number().optional(),
    index: z.coerce.number().optional(),
    question: z.coerce.string().optional(),
    questionType: z.any().optional(),
    question2: z.any().optional(),
    questionType2: z.any().optional(),
    shouldAnswer: z.coerce.boolean().optional(),
    answers: z.array(AnswerSchema).optional(),
})

export const TestDetailSchema = z.object({
    myData: z.coerce.boolean().optional(),
    id: z.coerce.number().optional(),
    date: z.coerce.string().optional(),
    user: z.coerce.number().optional(),
    last_updated: z.coerce.string().optional(),
    title: z.string().min(1, 'عنوان پرسشنامه اجباری است'),
    description: z.string().min(1),
    keywords: z.array(z.coerce.string()).optional(),
    metaDescription: z.string().min(1),
    intro: z.string().min(1),
    // TODO check the fields that are rich text box properly
    questionCount: z.coerce.number().optional(),
    showAnsweredCount: z.coerce.boolean().optional(),
    showBackNextButtons: z.coerce.boolean().optional(),
    showBar: z.coerce.boolean().optional(),
    showIndicatorGraph: z.coerce.boolean().optional(),
    showPageLinks: z.coerce.boolean().optional(),
    showPageNumber: z.coerce.boolean().optional(),
    showSkip: z.coerce.boolean().optional(),
    showSubIndicatorGraph: z.coerce.boolean().optional(),
    showTimer: z.coerce.boolean().optional(),
    style: z.coerce.string().optional(),
    indicatorStyle: z.coerce.string().optional(),
    time: z
        .number({ message: 'الزامی' })
        .or(z.string().min(1))
        .transform((val) => val.toString()),
    testUrl: z.coerce.string().optional(),
    overallScoreFormula: z.coerce.string().optional(),
    category: z.array(z.coerce.string()).min(1),
    ageRange: z.array(z.coerce.string()).min(1),
    instruction: z.string().min(1),
    ageRangeFrom: z.number({ message: 'الزامی' }),
    ageRangeTo: z.number({ message: 'الزامی' }),
    personalQuestions: z.array(z.coerce.string()).min(1),
    image: z.coerce.string().optional(),
    imageBG: z.any().optional(),
    thumbnail: z.any().optional(),
    priceRial: z.any().optional(),
    priceDollar: z.any().optional(),
    discount: z.any().optional(),
    isTherapistOnly: z.coerce.number().optional(),
    isAiAvailable: z.coerce.number().optional(),
    status: z.coerce.string().optional(),
    isFeatured: z.coerce.number().optional(),
    indicatorsMeasurement: z.coerce.string().optional(),
    subIndicatorsMeasurement: z.coerce.string().optional(),
    questionCountNumber: z.any().optional(),
    questions: z.array(QuestionSchema).optional(),
    overallScores: z.array(OverallScoreSchema).optional(),
    indicators: z.array(IndicatorSchema).optional(),
    subIndicators: z.array(SubIndicatorSchema).optional(),
})

export interface TabChildProps {
    control: Control<TestDetail>
    parent: string
}

interface TabType {
    title: string
    value: string
    Children: FC<TabChildProps>
    sidebars: FC[]
}

const tabs: TabType[] = [
    {
        title: 'مشخصات پرسشنامه',
        value: 'questionnaireSpecifications',
        Children: QuestionnaireSpecifications,
        sidebars: [SidebarLink, SidebarImage],
    },
    {
        title: 'سوالات',
        value: 'questions',
        Children: Questions,
        sidebars: [],
    },
    {
        title: 'مقیاس‌ها',
        value: 'scales',
        Children: Scales,
        sidebars: [],
    },
    {
        title: 'خرده مقیاس‌ها',
        value: 'subscales',
        Children: Scales,
        sidebars: [],
    },
    {
        title: 'تنظیمات',
        value: 'settings',
        Children: Settings,
        sidebars: [],
    },
]

interface TestFormProps {
    type: 'ADD' | 'UPDATE'
    test?: TestDetail
    isLoading?: boolean
}

const disableFullWidth = false

const TestForm: FC<TestFormProps> = ({ type, test }) => {
    const navigate = useNavigate()

    useQuery({
        queryKey: ['age-range-categories'],
        queryFn: getAgeRangeCategories,
    })

    const { control, handleSubmit, watch, ...otherFormStuff } = useForm<TestDetail>({
        // values: test,
        defaultValues: test,
        resolver: zodResolver(TestDetailSchema),
        // shouldUnregister: true,
    })

    const { setDialogState } = useDialogStore()

    const { mutate: addTest, isPending: isAddingTestPending } = useMutation({
        mutationKey: ['test', 'test-detail'],
        mutationFn: addTestDetail,
        onSuccess: () => {
            setDialogState({
                open: true,
                title: 'ثبت آزمون جدید',
                type: DialogTypes.success,
                description: 'آزمون شما با موفقیت ثبت شد.',
            })
            navigate('/cms/tests')
        },
        onError: () =>
            setDialogState({
                open: true,
                title: 'خطا در ثبت آزمون',
                type: DialogTypes.error,
                description: 'آزمون شما ثبت نشد.',
            }),
    })

    const { mutate: updateTest, isPending: isUpdatingTestPending } = useMutation({
        mutationKey: ['test', 'test-detail'],
        mutationFn: updateTestDetail,
        onSuccess: () => {
            setDialogState({
                open: true,
                title: 'ویرایش آزمون',
                type: DialogTypes.success,
                description: 'اطلاعات آزمون با موفقیت ویرایش شد.',
            })
            navigate('/cms/tests')
        },
        onError: () =>
            setDialogState({
                open: true,
                title: 'خطا در ویرایش سوال',
                type: DialogTypes.error,
                description: 'آزمون شما ویرایش نشد.',
            }),
    })

    const onSubmit = useCallback(
        ({ personalQuestions, keywords, category, ageRange, ...data }: TestDetail) => {
            const transformedData = {
                keywords: keywords?.join('|'),
                personalQuestions: personalQuestions?.join('|'),
                category: category?.join('|'),
                ageRange: ageRange?.join('|'),
                ...data,
            }
            if (type === 'ADD') addTest(transformedData)
            if (type === 'UPDATE') updateTest(transformedData)
        },
        [addTest, type, updateTest],
    )

    const { value, setValue } = useTabStore()

    const currentTabsSidebarsLength = useMemo(
        () =>
            disableFullWidth
                ? 1
                : tabs.find((tab) => tab.value === value)?.sidebars.filter((sidebar) => sidebar).length,
        [value],
    )

    const isTheLastTabSelected = useMemo(
        () => tabs.findIndex((tab) => tab.value === value) === tabs.length - 1,
        [value],
    )

    const onError = useCallback(
        (e: any) => {
            console.log(e)
            setValue(tabs[0].value)
            setDialogState({
                open: true,
                title: 'فرم ناقص',
                type: DialogTypes.error,
                description: 'اطلاعات فرم را چک کنید.',
            })
        },
        [setDialogState, setValue],
    )

    const handleKeyDown: KeyboardEventHandler<HTMLFormElement> = (e) => {
        if (e.key === 'Enter') e.preventDefault()
    }

    return (
        <CMSLayout>
            <div className='text-sm mb-5'>
                <span className='text-gray-400'>آزمون‌ها / </span>
                <span className='text-gray-600 font-medium'>{type === 'ADD' ? 'آزمون جدید' : 'ویرایش آزمون'}</span>
            </div>

            <Tabs initialValue={tabs[0].value}>
                <FormProvider
                    control={control}
                    watch={watch}
                    handleSubmit={handleSubmit}
                    {...otherFormStuff}
                >
                    <form
                        className='grid grid-cols-4 gap-4'
                        onSubmit={handleSubmit(onSubmit, onError)}
                        onKeyDown={handleKeyDown}
                    >
                        <div
                            className={cn(
                                currentTabsSidebarsLength && currentTabsSidebarsLength > 0
                                    ? 'col-span-3'
                                    : 'col-span-4',
                            )}
                        >
                            <div className='flex mb-3 gap-2'>
                                {tabs.map((tab) => (
                                    <TabsTrigger
                                        className={cn(
                                            'basis-full rounded border border-[#EDEDED] p-3 text-center',
                                            'data-[open=false]:bg-white data-[open=true]:bg-primary-500 data-[open=false]:text-gray-300 data-[open=true]:text-white',
                                        )}
                                        key={tab.value}
                                        value={tab.value}
                                    >
                                        {tab.title}
                                    </TabsTrigger>
                                ))}
                            </div>

                            {tabs.map(({ Children, ...tab }) => (
                                <TabsContent
                                    className='flex flex-col gap-3'
                                    key={tab.value}
                                    value={tab.value}
                                >
                                    <Children
                                        control={control}
                                        parent={tab.value}
                                    />
                                </TabsContent>
                            ))}

                            {isTheLastTabSelected && (
                                <div className='flex gap-4 mt-5'>
                                    <Button
                                        disabled={isAddingTestPending || isUpdatingTestPending}
                                        type='submit'
                                        className='w-[9.375rem] text-gray-50'
                                        size={ButtonSizes.bigger}
                                    >
                                        {isAddingTestPending || isUpdatingTestPending ? (
                                            <Refresh className='animate-spin' />
                                        ) : type === 'ADD' ? (
                                            'ثبت نهایی'
                                        ) : (
                                            'ثبت نهایی ویرایش'
                                        )}
                                    </Button>

                                    <Button
                                        type='button'
                                        size={ButtonSizes.big}
                                        className='w-[9.375rem]'
                                        variant={ButtonVariants.gray}
                                        onClick={() => navigate('/cms/tests')}
                                    >
                                        انصراف
                                    </Button>
                                </div>
                            )}
                        </div>

                        <div className='col-span-1 flex flex-col gap-2'>
                            {tabs.map(({ sidebars, ...tab }) => (
                                <TabsContent
                                    className='flex flex-col gap-2'
                                    key={tab.value}
                                    value={tab.value}
                                >
                                    {sidebars.map((Sidebar) => (
                                        <Sidebar key={Sidebar.name} />
                                    ))}
                                </TabsContent>
                            ))}
                        </div>
                    </form>
                </FormProvider>
            </Tabs>
        </CMSLayout>
    )
}

export default TestForm
