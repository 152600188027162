import { toEnglishString } from './to-english-number'
import jalaali from 'jalaali-js'

function shamsiToGregorian(year: number, month: number, day: number): string {
    const gregorianDate = jalaali.toGregorian(year, month, day)
    return `${gregorianDate.gy}-${gregorianDate.gm}-${gregorianDate.gd}`
}

export default function toGregorian(shamsiDate: string, inputSeparator: string) {
    const englishDate = toEnglishString(shamsiDate)
        .toString()
        .split(inputSeparator)
        .map((str) => parseInt(str))
    return shamsiToGregorian(englishDate[0], englishDate[1], englishDate[2])
}

export function toJalaliDate(date: string | Date, inputSeparator: string, outputSeparator: string) {
    let processedDate
    if (typeof date === 'string') processedDate = date.split(inputSeparator).map((str) => parseInt(str))
    else processedDate = [date.getFullYear(), date.getMonth() + 1, date.getDate()]

    const jalaliDate = jalaali.toJalaali(processedDate[0], processedDate[1], processedDate[2])
    return `${jalaliDate.jy}${outputSeparator}${String(jalaliDate.jm).padStart(2, '0')}${outputSeparator}${String(
        jalaliDate.jd,
    ).padStart(2, '0')}`
}
