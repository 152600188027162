import { AddCircle, CloseCircle, Refresh } from 'iconsax-react'
import { Dialog, IconButton } from '@mui/material'
import { useCallback, useMemo } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { toast } from 'react-toastify'
import { CustomAutocomplete, CustomTextField } from '../AddUser/components/text-field'
import { z } from '../../../utils/zod-translation'
import { zodResolver } from '@hookform/resolvers/zod'
import { DialogTypes, useDialogStore } from './Dialog'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getKeywords } from '../workshops/api'
import {
    addSuggestion as addSuggestionAPI,
    getSuggestionCategories,
    updateSuggestion as updateSuggestionAPI,
    uploadImage as uploadImageAPI,
} from './api'
import CustomRichTextBox from './CustomRichTextBox'
import Button, { ButtonSizes, ButtonVariants } from '../../../_core/components/button'
import { cn } from '../../../utils/cn'

export const SuggestionSchemaServer = z.object({
    id: z.coerce.number().optional(),
    title: z.string().min(1),
    category: z.array(z.string()).min(1),
    description: z.string().min(1),
    keywords: z.array(z.string()).min(1),
    image: z.string().min(1),
    html: z.string().min(1),
})

export type SuggestionType = z.infer<typeof SuggestionSchemaServer>

export type SuggestionServer = Omit<SuggestionType, 'keywords' | 'category'> & {
    keywords: string
    category: string
}

type SuggestionFormType = {
    isOpen: boolean
    onClose: () => void
    onChange: () => void
    suggestion?: SuggestionType
    type: 'ADD' | 'UPDATE'
}

const ImagesStyles = 'w-full h-full object-cover rounded-md'
const inputStyles = 'w-full h-full opacity-0 absolute'

const SuggestionForm = ({ suggestion, isOpen, onClose, onChange, type }: SuggestionFormType) => {
    const { control, handleSubmit, watch, setValue, ...otherFormThings } = useForm<SuggestionType>({
        defaultValues: suggestion,
        resolver: zodResolver(SuggestionSchemaServer),
    })

    const { setDialogState } = useDialogStore()

    const { data: categories, isPending: isPendingCategories } = useQuery({
        queryKey: ['categories'],
        queryFn: getSuggestionCategories,
        staleTime: 0,
        gcTime: 5 * 60 * 1000,
        select: (data) => data.split('|'),
    })

    const { data: keywords, isPending: isPendingKeywords } = useQuery({
        queryKey: ['keywords'],
        queryFn: getKeywords,
        staleTime: 0,
        gcTime: 5 * 60 * 1000,
    })

    const { mutate: addSuggestion, isPending: isAddingSuggestionPending } = useMutation({
        mutationKey: ['suggestions'],
        mutationFn: addSuggestionAPI,
        onSuccess: () => {
            onClose()
            onChange()
            setDialogState({
                open: true,
                title: 'ثبت پیشنهاد جدید',
                type: DialogTypes.success,
                description: 'پیشنهاد شما با موفقیت ثبت شد.',
            })
        },
        onError: () =>
            setDialogState({
                open: true,
                title: 'خطا در ثبت پیشنهاد',
                type: DialogTypes.error,
                description: 'پیشنهاد ثبت نشد.',
            }),
    })

    const { mutate: updateSuggestion, isPending: isUpdatingSuggestionPending } = useMutation({
        mutationKey: ['suggestions'],
        mutationFn: updateSuggestionAPI,
        onSuccess: () => {
            onClose()
            onChange()
            setDialogState({
                open: true,
                title: 'ویرایش پیشنهاد',
                type: DialogTypes.success,
                description: 'اطلاعات پیشنهاد با موفقیت ویرایش شد.',
            })
        },
        onError: () =>
            setDialogState({
                open: true,
                title: 'خطا در ویرایش پیشنهاد',
                type: DialogTypes.error,
                description: 'پیشنهاد ویرایش نشد.',
            }),
    })

    const submitForm = useCallback(
        ({ keywords, category, ...data }: SuggestionType) => {
            const transformedData = {
                keywords: keywords.join('|'),
                category: category.join('|'),
                ...data,
            }
            if (type === 'ADD') addSuggestion(transformedData)
            if (type === 'UPDATE') updateSuggestion(transformedData)
        },
        [addSuggestion, type, updateSuggestion],
    )

    const { mutate: uploadImage, isPending: isUploadingImagePending } = useMutation({
        mutationKey: ['suggestionImages'],
        mutationFn: uploadImageAPI,
        onSuccess: (data) => {
            setValue('image', data)
            otherFormThings.trigger('image')
        },
        onError: (e) => {
            console.log('error', e)
            toast.error('مشکل در آپلود تصویر')
        },
    })

    const pending = useMemo(
        () => isAddingSuggestionPending || isUpdatingSuggestionPending,
        [isAddingSuggestionPending, isUpdatingSuggestionPending],
    )

    const imageErrors = useMemo(() => otherFormThings.getFieldState('image').error?.message, [otherFormThings])

    return (
        <FormProvider
            control={control}
            handleSubmit={handleSubmit}
            watch={watch}
            setValue={setValue}
            {...otherFormThings}
        >
            <Dialog
                sx={{ zIndex: 999999999 }}
                className='!min-w-[700px]'
                open={isOpen}
            >
                <form
                    onSubmit={(e) => {
                        e.stopPropagation()
                        handleSubmit(submitForm, (e) => {
                            console.log('error', e)
                            setDialogState({
                                open: true,
                                title: 'خطا در ثبت پیشنهاد',
                                type: DialogTypes.error,
                                description: 'فرم را کامل پر کنید.',
                            })
                        })(e)
                    }}
                    className='max-w-[511.078px] px-7 py-[1.125rem] min-h-[250px]'
                >
                    <div className='w-full flex items-center justify-between gap-1 border-b border-gray-100 pb-[1.125rem]'>
                        <span className='text-center grow text-gray-700 font-medium'>
                            {suggestion ? 'ویرایش پیشنهاد' : 'پیشنهاد جدید'}
                        </span>

                        <IconButton
                            type='button'
                            onClick={onClose}
                            className='!mr-auto'
                        >
                            <CloseCircle size={24} />
                        </IconButton>
                    </div>

                    <button
                        type='button'
                        className={cn(
                            'relative mx-auto mt-6 w-32 h-32 rounded border border-dashed border-gray-300 text-gray-200 flex flex-col justify-center items-center gap-4',
                            imageErrors && 'border-red-500',
                        )}
                    >
                        {isUploadingImagePending ? (
                            <span className='loader'></span>
                        ) : (
                            <>
                                <input
                                    type='file'
                                    accept='image/*'
                                    onChange={uploadImage}
                                    className={inputStyles}
                                />

                                {watch('image') ? (
                                    <img
                                        alt=''
                                        src={watch('image')}
                                        className={ImagesStyles}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null
                                            currentTarget.src =
                                                "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='80' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='m21.68 16.96-3.13-7.31c-1.06-2.48-3.01-2.58-4.32-.22l-1.89 3.41c-.96 1.73-2.75 1.88-3.99.33l-.22-.28c-1.29-1.62-3.11-1.42-4.04.43l-1.72 3.45C1.16 19.17 2.91 22 5.59 22h12.76c2.6 0 4.35-2.65 3.33-5.04ZM6.97 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z' stroke='%23697689' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/svg%3E"
                                        }}
                                    />
                                ) : (
                                    <>
                                        <AddCircle
                                            size={24}
                                            className='pointer-events-none'
                                        />
                                        <div className='text-xs pointer-events-none'>انتخاب تصویر</div>
                                    </>
                                )}
                            </>
                        )}
                    </button>

                    <div className='mb-6 text-center text-alarm-500 text-xs mt-1'>{imageErrors}</div>

                    <CustomAutocomplete<SuggestionType>
                        multiple
                        placeholder='با زدن Enter دسته بندی جدید اضافه کنید'
                        freeSolo
                        control={control}
                        listElevated={true}
                        name='category'
                        options={categories ?? []}
                        isPending={isPendingCategories}
                        label='دسته بندی'
                        containerClassName='mb-11'
                    />

                    <CustomTextField<SuggestionType>
                        control={control}
                        label='عنوان'
                        name='title'
                        containerClassName='mb-11'
                    />

                    <CustomTextField<SuggestionType>
                        control={control}
                        label='توضیحات'
                        name='description'
                        containerClassName='mb-11'
                    />

                    <CustomAutocomplete<SuggestionType>
                        placeholder='با زدن Enter کلمه کلیدی جدید اضافه کنید'
                        freeSolo
                        listElevated={true}
                        containerClassName='mb-11'
                        control={control}
                        label='کلمات کلیدی'
                        name='keywords'
                        multiple
                        options={keywords ?? []}
                        isPending={isPendingKeywords}
                    />

                    <CustomRichTextBox<SuggestionType>
                        name='html'
                        label='متن'
                        control={control}
                    />

                    <div className='flex gap-4 mt-5 pb-4'>
                        <Button
                            disabled={pending}
                            type='submit'
                            className='w-[9.375rem] text-gray-50'
                            size={ButtonSizes.bigger}
                        >
                            {pending ? <Refresh className='animate-spin' /> : 'ثبت'}
                        </Button>

                        <Button
                            type='button'
                            size={ButtonSizes.big}
                            className='w-[9.375rem]'
                            variant={ButtonVariants.gray}
                            onClick={onClose}
                        >
                            انصراف
                        </Button>
                    </div>
                </form>
            </Dialog>
        </FormProvider>
    )
}

export default SuggestionForm

// TODO validate the image
