import { Therapist, TherapistPostResponse, TherapistPostServer } from '../data-types'

export async function getTherapists(): Promise<Therapist[]> {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/get_therapists_v2.php', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
        })
        const data = await response.json()
        return data.data
    } catch (error) {
        console.error('Failed to fetch initial data:', error)
        return []
    }
}

export async function getTherapistsDetail(id: number): Promise<TherapistPostServer> {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/get_therapist.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify({
                id,
            }),
        })
        const data = await response.json()
        return data.data
    } catch (error) {
        console.error('Failed to fetch initial data:', error)
        throw error
    }
}

export async function addTherapist(body: TherapistPostServer): Promise<TherapistPostResponse> {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/add_therapist.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify(body),
        })
        const data = await response.json()
        return data
    } catch (error) {
        console.error('Failed to fetch initial data:', error)
        throw error
    }
}

export async function updateTherapist(body: TherapistPostServer): Promise<TherapistPostResponse> {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/update_therapist.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify(body),
        })
        const data = await response.json()
        return data
    } catch (error) {
        console.error('Failed to fetch initial data:', error)
        throw error
    }
}
