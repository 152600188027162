import { FC } from 'react'
import Section from '../../Tests/components/section'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { TherapistDetail } from '../data-types'
import { CustomAutocomplete } from '../../AddUser/components/text-field'
import CustomSwitch from '../../AddUser/components/switch'
import { AddCircle, Trash } from 'iconsax-react'
import CustomDatePicker from '../../_components/DatePicker'
import MUITimePicker from '../../_components/MUITimePicker'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../../../../_core/components/accordion'
import { useQueries, UseQueryOptions } from '@tanstack/react-query'
import { Month, PersianCalender } from '../../../../_core/interfaces/persian-calender'
import Fade from '@mui/material/Fade'
import LinearProgress from '@mui/material/LinearProgress'
import { cn } from '../../../../utils/cn'

const weekDays = ['شنبه', 'یکشنبه', 'دوشنبه', 'سه‌شنبه', 'چهار‌شنبه', 'پنج‌شنبه', 'جمعه']

const StatusTab: FC = () => {
    const { control, watch, setValue } = useFormContext<TherapistDetail>()

    const disabled = !watch('accountStatus')

    return (
        <>
            <Section className={cn('gap-4', disabled && '*:opacity-50 pointer-events-none')}>
                <CustomAutocomplete<TherapistDetail>
                    disabled={disabled}
                    control={control}
                    name='timezone'
                    label='موقعیت زمانی (Timezone)'
                    options={[
                        'Africa/Abidjan',
                        'Africa/Accra',
                        'Africa/Addis-Ababa',
                        'Africa/Algiers',
                        'Africa/Asmara',
                    ]}
                />

                <div className='text-gray-700 text-sm'>
                    سیستم براساس این موقعیت تاریخ جلسات را به موقعیت درمانگر و درمانجو تبدیل میکند.
                </div>
            </Section>

            <Section
                title='وضعیت فعالیت درمانگر بر اساس دوره هفتگی'
                className={cn(disabled && '*:opacity-50 pointer-events-none')}
            >
                {weekDays.map((day, index) => (
                    <CustomSwitch<TherapistDetail>
                        key={index}
                        control={control}
                        name={`workingDays.${index}.status`}
                        label={day}
                        disabled={disabled}
                        onClick={() => setValue(`workingDays.${index}.day`, index)}
                    />
                ))}
            </Section>

            <Section
                title='غیر فعال کردن روزهای کاری بر‌ اساس تاریخ'
                className={cn('gap-4', disabled && '*:opacity-50 pointer-events-none')}
            >
                <div className='text-gray-700 text-sm'>
                    اگر درمانگر در تاریخ مشخصی فعالیت نمی‌کند, می‌توانید تاریخ موردنظر را از تقویم سیستم نوبت دهی در این
                    قسمت حذف کنید.هیج تاریخی از سیستم نوبت دهی حذف نشده است. برای ثبت تاریخ جدید کلید جدید را فشار دهید.
                </div>

                <ExcludeDays />
            </Section>

            <Section
                title='وضعیت فعالیت درمانگر در تعطیلات رسمی'
                className={cn('gap-4', disabled && '*:opacity-50 pointer-events-none')}
            >
                <div className='text-gray-700 text-sm'>
                    تعطیلات رسمی بصورت پیش‌فرض از سیستم نوبت‌دهی حذف شده‌اند. اگر درمانگر در تاریخ‌های نمایش داده شده در
                    لیست زیر فعالیت دارد لطفا آنرا مشخص کنید.{' '}
                </div>

                <ActiveHolidays />
            </Section>
        </>
    )
}

export default StatusTab

const ExcludeDays: FC = () => {
    const { control, watch } = useFormContext<TherapistDetail>()

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'excludedDates',
    })

    const disabled = !watch('accountStatus')

    return (
        <>
            <button
                className='flex items-center gap-3 mt-3 w-fit'
                type='button'
                disabled={disabled}
                onClick={() => append({ new: true })}
            >
                <AddCircle
                    variant='Bold'
                    size={30}
                    className='text-primary-500'
                />
                <div>ثبت تاریخ جدید</div>
            </button>

            {fields.map((field, index) => (
                <div
                    className='flex items-center gap-[0.625rem]'
                    key={field.id}
                >
                    <div className='shrink-0 text-gray-700 text-sm'>{index + 1}</div>

                    <div className='grow grid grid-cols-3 gap-4'>
                        <CustomDatePicker<TherapistDetail>
                            disabled={disabled}
                            control={control}
                            name={`excludedDates.${index}.date`}
                            label='تاریخ'
                            noIcon
                        />

                        <MUITimePicker<TherapistDetail>
                            name={`excludedDates.${index}.from`}
                            label='از ساعت'
                        />

                        <MUITimePicker<TherapistDetail>
                            name={`excludedDates.${index}.to`}
                            label='تا ساعت'
                        />
                    </div>

                    <button
                        disabled={disabled}
                        type='button'
                        className='w-9 h-9 rounded-xl bg-gray-100 flex items-center justify-center text-[#5C5C5C]'
                        onClick={() => remove(index)}
                    >
                        <Trash size={20} />
                    </button>
                </div>
            ))}
        </>
    )
}

const years = [1401, 1402, 1403]

const getPersianCalendar = async (year: number): Promise<PersianCalender> => {
    try {
        const response = await fetch('https://persian-calendar-api.sajjadth.workers.dev/?year=' + year, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })

        if (!response.ok) throw new Error(`Error fetching data: ${response.statusText}`)

        const data = await response.json()
        return data
    } catch (error) {
        console.error('Failed to fetch Persian calendar:', error)
        throw error
    }
}

const ActiveHolidays: FC = () => {
    const calendar = useQueries<UseQueryOptions<PersianCalender>[]>({
        queries: years.map((year) => ({
            queryKey: ['persian-calendar', year],
            queryFn: () => getPersianCalendar(year),
            staleTime: 0,
            gcTime: 5 * 60 * 1000,
            retry: 10,
        })),
    })

    return (
        <>
            <Accordion
                type='single'
                collapsible={true}
                className='gap-3 flex flex-col'
            >
                {years.map((year, index) => (
                    <AccordionItem
                        key={index}
                        value={year.toString()}
                        className={cn(
                            'bg-[#F5F5F5] rounded overflow-hidden',
                            calendar[index].isPending && 'opacity-50 pointer-events-none',
                        )}
                        disabled={calendar[index].isPending}
                    >
                        <Fade
                            in={calendar[index].isFetching}
                            timeout={{ enter: 0, exit: 1000 }}
                        >
                            <LinearProgress sx={{ scale: '-1 1' }} />
                        </Fade>

                        <AccordionTrigger className='p-4'>سال {year}</AccordionTrigger>

                        <AccordionContent className='p-4 pt-2 grid grid-cols-3 grid-rows-4 gap-4'>
                            {calendar[index].data?.map((month, index) => (
                                <CalenderMonth
                                    key={index}
                                    month={month}
                                    monthIndex={index}
                                    year={year}
                                />
                            ))}
                        </AccordionContent>
                    </AccordionItem>
                ))}
            </Accordion>
        </>
    )
}

interface CalenderMonthProps {
    month: Month
    monthIndex: number
    year: number
}

const CalenderMonth: FC<CalenderMonthProps> = ({ month, monthIndex, year }) => {
    const { control, watch } = useFormContext<TherapistDetail>()

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'activeHolidays',
    })

    const disabled = !watch('accountStatus')

    return (
        <div
            className='bg-white rounded-lg flex flex-col overflow-hidden'
            style={{
                boxShadow:
                    '0px 2.94px 29.42px 0px #EEEEEE, 0px 2.94px 5.88px 0px #42474C0D, 0px 0px 0.37px 0px #42474C52',
            }}
        >
            <div className='p-3 text-gray-700 text-xs font-semibold text-center border-b border-gray-100'>
                {month.header.jalali.split(' ')[1]}
            </div>

            <div className='py-2 px-3 flex *:grow'>
                {weekDays.map((day, index) => (
                    <div
                        key={index}
                        className='text-gray-500 text-[0.625rem] font-semibold text-center'
                    >
                        {day.split('')[0]}
                    </div>
                ))}
            </div>

            <div className='py-2 px-3 grid grid-cols-7 gap-y-2'>
                {month.days.map((day, index) => {
                    const isActiveHoliday =
                        fields.filter((field) => field.date === `${year}/${monthIndex + 1}/${day.day.jalali}`).length >
                        0

                    return (
                        <button
                            type='button'
                            disabled={!day.events.isHoliday || disabled}
                            onClick={() =>
                                isActiveHoliday
                                    ? remove(
                                          fields.findIndex(
                                              (field) => field.date === `${year}/${monthIndex + 1}/${day.day.jalali}`,
                                          ),
                                      )
                                    : append({ date: `${year}/${monthIndex + 1}/${day.day.jalali}` })
                            }
                            key={index}
                            className={cn(
                                'flex items-center justify-center text-gray-700 text-xs text-center m-auto w-6 h-6',
                                day.events.isHoliday && !isActiveHoliday && 'text-white bg-[#ED775B] rounded-full',
                                isActiveHoliday && 'border border-[#ED775B] rounded-full',
                                day.disabled && 'opacity-0',
                            )}
                        >
                            {day.day.jalali.split(' ')[0]}
                        </button>
                    )
                })}
            </div>
        </div>
    )
}
