import { CloseCircle } from 'iconsax-react'
import { FC } from 'react'
import { create } from 'zustand'
import { cn } from '../../../utils/cn'
import Button, { ButtonSizes, ButtonVariants } from '../../../_core/components/button'

export enum DialogTypes {
    success = 'success',
    error = 'error',
}

interface DialogState {
    open: boolean
    type: DialogTypes
    title: string
    description: string
}

interface DialogStore {
    dialogState: DialogState
    setDialogState: (dialogState: DialogState) => void
}

export const useDialogStore = create<DialogStore>()((set) => ({
    dialogState: { open: false, type: DialogTypes.success, title: '', description: '' },
    setDialogState: (dialogState) => set(() => ({ dialogState })),
}))

const Dialog: FC = () => {
    const { dialogState, setDialogState } = useDialogStore()

    return (
        <div
            className={cn(
                'fixed inset-0 z-[9999999999999999] transition-all bg-black/50 flex items-center justify-center',
                !dialogState?.open && 'opacity-0 pointer-events-none',
            )}
        >
            <div
                className='w-[23.0625rem] h-[12.75rem] p-6 bg-white rounded flex flex-col'
                style={{ boxShadow: '0px 1px 3px 1px #00000026, 0px 1px 2px 0px #0000004D' }}
            >
                <div className='flex justify-between items-center pb-3 border-b border-gray-100'>
                    <div className='w-6' />

                    <div
                        className={cn(
                            'font-medium text-base',
                            dialogState?.type === DialogTypes.success && 'text-primary-500',
                            dialogState?.type === DialogTypes.error && 'text-alarm-500',
                        )}
                    >
                        {dialogState?.title}
                    </div>

                    <button>
                        <CloseCircle
                            onClick={() => setDialogState({ ...dialogState, open: false })}
                            className='text-gray-700 w-6 h-6'
                        />
                    </button>
                </div>

                <div className='text-gray-800 text-lg grow flex items-center justify-center'>
                    {dialogState?.description}
                </div>

                <div className='flex gap-4'>
                    {dialogState?.type === DialogTypes.success && (
                        <Button
                            size={ButtonSizes.bigger}
                            aClassName='w-full'
                            className='w-full'
                            onClick={() => setDialogState({ ...dialogState, open: false })}
                        >
                            متوجه شدم
                        </Button>
                    )}

                    {dialogState?.type === DialogTypes.error && (
                        <Button
                            size={ButtonSizes.bigger}
                            variant={ButtonVariants.neutral}
                            aClassName='w-full'
                            className='w-full'
                            onClick={() => setDialogState({ ...dialogState, open: false })}
                        >
                            دوباره تلاش کنید
                        </Button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Dialog
