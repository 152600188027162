import { GridColDef, GridRowId } from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import { useCallback, useMemo, useState } from 'react'
import { useDictionaryStore } from '../../../dictionary/Dictionary'
import { GridBaseColDef } from '@mui/x-data-grid/internals'

// false means closed, 'deleteAll' deletes whatever is selected and one GridRowId deletes that particular item
export type DialogData = false | 'deleteAll' | GridRowId

interface useListPageProps<T extends { [key: string]: any }> {
    apiFunction: (page?: number) => Promise<T[] | unknown>
    getColumns?: (selectionList: GridRowId[], updateDialogOpen: (id?: GridRowId) => void) => GridColDef<T>[]
    queryKey: string[]
    generateID?: (row: T) => GridRowId
    generateRows?: (rows: unknown) => T[]

    getColumnMetrics?: (
        selectionList: GridRowId[],
        onClick: (id?: number) => void,
        onEditMetrics: (id?: number) => void,
    ) => GridBaseColDef<T, any, any>[]
    getColumnQuestions?: (
        selectionList: GridRowId[],
        onClick: (id?: number) => void,
        onEditQuestions: (id?: number) => void,
    ) => GridBaseColDef<T, any, any>[]
    isDictionaryMetrics?: boolean
    isDictionaryQuestions?: boolean
}

export default function useListPage<T extends { [key: string]: any }>({
    apiFunction,
    getColumns,
    queryKey,
    generateID = (row) => row.id,
    generateRows,

    getColumnMetrics,
    getColumnQuestions,
    isDictionaryMetrics,
    isDictionaryQuestions,
}: useListPageProps<T>) {
    const { setShowMetrics, setShowQuestions, setMetricsID, setQuestionsID } = useDictionaryStore()

    const [currentPage, setCurrentPage] = useState(0)

    const [dialogData, setDialogData] = useState<DialogData>(false)

    const updateDialogOpen = useCallback((id?: GridRowId) => {
        if (id) setDialogData(id)
        else setDialogData('deleteAll')
    }, [])

    const [selectionList, setSelectionList] = useState<GridRowId[]>()

    const updateSelection = useCallback((rowSelectionModel: GridRowId[]) => {
        setSelectionList(rowSelectionModel)
    }, [])

    const columns: GridColDef<T>[] | undefined = useMemo(
        () =>
            getColumns?.(selectionList ?? [], updateDialogOpen) ??
            (isDictionaryMetrics
                ? getColumnMetrics?.(selectionList ?? [], updateDialogOpen, (id) => {
                      setShowMetrics(true)
                      setMetricsID(id)
                  })
                : isDictionaryQuestions
                ? getColumnQuestions?.(selectionList ?? [], updateDialogOpen, (id) => {
                      setShowQuestions(true)
                      setQuestionsID(id)
                  })
                : undefined),
        [
            getColumnMetrics,
            getColumnQuestions,
            getColumns,
            isDictionaryMetrics,
            isDictionaryQuestions,
            selectionList,
            setMetricsID,
            setQuestionsID,
            setShowMetrics,
            setShowQuestions,
            updateDialogOpen,
        ],
    )

    const {
        data: rows,
        isPending,
        isFetching,
    } = useQuery({
        queryKey: [...queryKey],
        queryFn: () => apiFunction(currentPage) as Promise<T[]>,
        staleTime: 0,
        gcTime: 5 * 60 * 1000,
    })

    const generatedRows = useMemo(() => generateRows?.(rows) ?? rows, [generateRows, rows])

    const processedRows = useMemo(
        () => generatedRows?.filter((row) => generateID(row) !== undefined) ?? [],
        [generateID, generatedRows],
    )

    return {
        processedRows,
        isPending,
        isFetching,
        columns,
        updateSelection,
        dialogData,
        selectionList,
        setDialogData,
        currentPage,
        setCurrentPage,
        rawData: rows,
    }
}
