'use client'

import {
    ButtonHTMLAttributes,
    createContext,
    DetailedHTMLProps,
    FC,
    HTMLAttributes,
    ReactNode,
    useContext,
    useEffect,
} from 'react'
import { cn } from '../../../utils/cn'
import { create } from 'zustand'

type TabStore = {
    value?: string
    setValue: (state: string) => void
}

export const useTabStore = create<TabStore>((set) => ({
    setValue: (state) => set({ value: state }),
}))

interface TabsProps {
    children: ReactNode
    initialValue: string
}

const SharedValueContext = createContext<[string, (state: string) => void]>(['', () => ''])

const useSharedValue = () => useContext(SharedValueContext)

const Tabs = ({ children, initialValue }: TabsProps) => {
    const { value: sharedValue, setValue: setSharedValue } = useTabStore()

    useEffect(() => {
        setSharedValue(initialValue)
    }, [initialValue, setSharedValue])

    return (
        <SharedValueContext.Provider value={[sharedValue ?? initialValue, setSharedValue]}>
            {children}
        </SharedValueContext.Provider>
    )
}

interface TabsTriggerProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    value: string
}

const TabsTrigger: FC<TabsTriggerProps> = ({ value, children, className, ...otherProps }) => {
    const [sharedValue, setSharedValue] = useSharedValue()

    return (
        <button
            type='button'
            className={cn('text-start', className)}
            data-open={sharedValue === value}
            onClick={() => {
                setSharedValue(value)
            }}
            {...otherProps}
        >
            {children}
        </button>
    )
}

interface TabsContentProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    value: string
}

const TabsContent: FC<TabsContentProps> = ({ value, className, ...otherProps }) => {
    const [sharedValue] = useSharedValue()

    return (
        <div
            data-open={sharedValue === value}
            className={cn('data-[open=false]:hidden', className)}
            {...otherProps}
        />
    )
}

export { Tabs, TabsTrigger, TabsContent }
