export default function toEnglishNumber(number: string) {
    let res = number.replaceAll('۱', '1')
    res = res.replaceAll('۲', '2')
    res = res.replaceAll('۳', '3')
    res = res.replaceAll('۴', '4')
    res = res.replaceAll('۵', '5')
    res = res.replaceAll('۶', '6')
    res = res.replaceAll('۷', '7')
    res = res.replaceAll('۸', '8')
    res = res.replaceAll('۹', '9')
    res = res.replaceAll('۰', '0')
    if (!Number.isNaN(parseInt(res))) return parseInt(res)
    return ''
}

export function toEnglishString(number: string) {
    let res = number.replaceAll('۱', '1')
    res = res.replaceAll('۲', '2')
    res = res.replaceAll('۳', '3')
    res = res.replaceAll('۴', '4')
    res = res.replaceAll('۵', '5')
    res = res.replaceAll('۶', '6')
    res = res.replaceAll('۷', '7')
    res = res.replaceAll('۸', '8')
    res = res.replaceAll('۹', '9')
    res = res.replaceAll('۰', '0')
    return res
}
