import { FC } from 'react'
import Section from '../../Tests/components/section'
import { useFormContext } from 'react-hook-form'
import { TherapistDetail } from '../data-types'
import CustomSwitch from '../../AddUser/components/switch'

const StateSidebar: FC = () => {
    const { control } = useFormContext<TherapistDetail>()

    return (
        <Section className='gap-1 p-3'>
            <CustomSwitch<TherapistDetail>
                control={control}
                name='accountStatus'
                label='وضعیت فعالیت'
            />

            <div className='text-[#FA8D34] text-xs'>
                درصورتی‌که وضعیت‌کاری غیر فعال باشد اطلاعات و رزومه درمانگر در سایت نمایش داده نمی‌شود.
            </div>
        </Section>
    )
}

export default StateSidebar
