import { useCallback, useState } from 'react'

export default function useSearch<T extends { [key: string]: any }>() {
    const [searchedValue, setSearchedValue] = useState('')

    const getSearchResult = useCallback(
        (source: T[]) => {
            return source.filter((item) =>
                Object.values(item).some((value) => typeof value === 'string' && value.includes(searchedValue)),
            )
        },
        [searchedValue],
    )

    return { setSearchedValue, getSearchResult }
}
