import type { FC } from 'react'
import { CircleFlag } from 'react-circle-flags'
import { getCountryCode } from '../../../utils/get-country-code'

interface CellForPhoneProps {
    value: string
}

const CellWithPhone: FC<CellForPhoneProps> = ({ value }) => {
    return (
        <div
            className='flex gap-2 items-center justify-center [direction:ltr] h-full mr-auto w-fit'
            title={value}
        >
            {value && (
                <CircleFlag
                    countryCode={getCountryCode(value) ?? 'xx'}
                    height={16}
                    width={16}
                />
            )}
            <div className='truncate text-sm text-gray-800'>{value}</div>
        </div>
    )
}

export default CellWithPhone
