import { FieldValues, Path, PathValue, useFormContext } from 'react-hook-form'
import { CustomTextField } from '../text-field'
import InputAdornment from '@mui/material/InputAdornment'
import Autocomplete from '@mui/material/Autocomplete'
import { countries } from '../../../../../utils/countries'
import TextField from '@mui/material/TextField'
import { ArrowDown2 } from 'iconsax-react'
import { CircleFlag } from 'react-circle-flags'
import { useCallback, useState } from 'react'

/// phone number must be string

const initialCountry = countries.filter((item) => item.abbreviation === 'ir')[0]

interface CustomPhoneInputProps<T extends FieldValues> {
    name: Path<T>
}

function CustomPhoneInput<T extends FieldValues>({ name }: CustomPhoneInputProps<T>) {
    const { control, watch, setValue, trigger } = useFormContext<T>()

    const [selectedItem, setSelectedItem] = useState<(typeof countries)[0]>(initialCountry)

    const manualSet = useCallback(
        (newValue: (typeof countries)[0]) => {
            setSelectedItem(newValue)

            setValue(
                name,
                ('+' +
                    newValue.calling_code.toString() +
                    (watch(name) ?? '')
                        .replace(selectedItem.calling_code.toString(), '')
                        .replace('+', '')) as PathValue<T, Path<T>>,
            )

            trigger(name)
        },
        [name, selectedItem.calling_code, setValue, trigger, watch],
    )

    return (
        <CustomTextField<T>
            dir='ltr'
            name={name}
            label='شماره موبایل'
            control={control}
            generateOnChange={(value) => '+' + selectedItem.calling_code + value.replaceAll(/[^0-9]/g, '')}
            generateValue={(value) => value?.replace(selectedItem.calling_code.toString(), '').replace('+', '')}
            InputProps={{
                startAdornment: (
                    <InputAdornment position='start'>
                        <Autocomplete
                            value={selectedItem}
                            options={countries}
                            onChange={(_, newValue) => manualSet(newValue)}
                            popupIcon={<ArrowDown2 size={18} />}
                            defaultValue={initialCountry}
                            getOptionLabel={(option) => option.calling_code.toString()}
                            disableClearable={true}
                            filterOptions={(options, params) =>
                                options.filter((option) => {
                                    if (params.inputValue === '') return true

                                    const optionValue = option.calling_code.toString()
                                    return optionValue.indexOf(params.inputValue) !== -1
                                })
                            }
                            renderOption={({ className, ...props }, option) => (
                                <li
                                    {...props}
                                    className={className + ' flex gap-2'}
                                    dir='ltr'
                                >
                                    <CircleFlag
                                        countryCode={option.abbreviation}
                                        width={20}
                                        height={20}
                                    />
                                    <div>+{option.calling_code}</div>
                                </li>
                            )}
                            renderInput={({ InputProps, ...params }) => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    onChange={(e) => {
                                        const value = countries.filter(
                                            (country) => country.calling_code.toString() === e.target.value,
                                        )[0]

                                        if (value) manualSet(value)
                                    }}
                                    InputProps={{
                                        ...InputProps,
                                        style: { padding: '2px 8px' },
                                        startAdornment: (
                                            <>
                                                {InputProps.startAdornment}
                                                <CircleFlag
                                                    countryCode={selectedItem?.abbreviation}
                                                    width={20}
                                                    height={20}
                                                />
                                                <div className='ml-2'>+</div>
                                            </>
                                        ),
                                    }}
                                />
                            )}
                            sx={{
                                '& fieldset': { border: 'none' },
                                '.MuiAutocomplete-input': {
                                    minWidth: '40px !important',
                                },
                                '.MuiAutocomplete-endAdornment': {
                                    position: 'static',
                                    marginTop: '24px',
                                    borderLeft: '1px solid #B3B3B3',
                                    paddingLeft: '8px',
                                    marginLeft: '8px',
                                },
                            }}
                        />
                    </InputAdornment>
                ),
            }}
        />
    )
}

export default CustomPhoneInput
