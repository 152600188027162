import { FC } from 'react'
import ListPage from '../_components/list-page'
import { deleteMembers, getMembers } from './api'
import DeleteConfirmDialog from '../_components/list-page/components/dialog'
import getMembersColumns from './constants'

const Members: FC = () => {
    return (
        <ListPage
            apiFunction={getMembers}
            getColumns={getMembersColumns}
            itemName={'درمانجو'}
            addPageLink='./members/add-member'
            queryKey={['members']}
            accessName='Members'
            generateID={(row) => row.id}
            generateDialog={(dialogData, setDialogData, processedRows, selectionList) => (
                <DeleteConfirmDialog
                    onClose={() => setDialogData(false)}
                    accept={
                        dialogData === 'deleteAll'
                            ? () => deleteMembers(selectionList ?? [])
                            : dialogData !== false
                            ? () => deleteMembers([dialogData])
                            : () => null
                    }
                    open={dialogData !== false}
                    itemToBeDeleted={
                        dialogData === 'deleteAll'
                            ? undefined
                            : dialogData !== false
                            ? processedRows.filter((row) => row.id === dialogData)[0].name
                            : undefined
                    }
                />
            )}
        />
    )
}

export default Members

// TODO change the name to patients
