import { Dialog } from '@mui/material'
import { CloseCircle } from 'iconsax-react'
import { FC, useEffect } from 'react'
import Button, { ButtonSizes, ButtonVariants } from '../../../../../_core/components/button'
import useDelayedState from '../../../../../_core/components/hooks/use-delayed-state'

interface CustomDialogProps {
    open: boolean
    onClose: () => void
    theUser?: string
    type: 'updated' | 'added'
}

// TODO fix the type glitch when the dialog is closing

const SuccessMessageDialog: FC<CustomDialogProps> = ({ open, onClose, theUser: theUserInitial, type }) => {
    const [addedUser, setAddedUser, setAddedUserInstantly] = useDelayedState(theUserInitial, 225)

    useEffect(() => {
        if (theUserInitial === undefined || theUserInitial === '') setAddedUser('')
        else setAddedUserInstantly(theUserInitial)
    }, [theUserInitial, setAddedUser, setAddedUserInstantly])

    return (
        <Dialog
            onClose={onClose}
            open={open}
        >
            <div className='flex flex-col p-4 bg-white rounded w-[23.0625rem] h-[14.0625rem]'>
                <div className='flex justify-between items-center p-2 border-b pb-4 border-gray-100'>
                    <div className='w-6' />
                    <div className='font-medium text-primary-500 text-base'>ثبت کاربر جدید</div>
                    <button>
                        <CloseCircle
                            onClick={onClose}
                            className='text-gray-700'
                            width={24}
                            height={24}
                        />
                    </button>
                </div>
                <div className='text-gray-800 text-lg grow flex items-center justify-center'>
                    "{addedUser}" با موفقیت {type === 'added' ? 'ثبت' : 'ویرایش'} شد.
                </div>
                <div className='flex gap-4 w-full'>
                    <Button
                        aClassName='w-full'
                        size={ButtonSizes.normal}
                        className='w-full !text-lg'
                        variant={ButtonVariants.primary}
                        onClick={() => {
                            onClose()
                        }}
                    >
                        متوجه شدم
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}

export default SuccessMessageDialog
