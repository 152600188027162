import { FC } from 'react'
import { TabChildProps } from './member-form'
import Section from '../../Tests/components/section'
import { useFormContext } from 'react-hook-form'
import { Member } from '../data-types'
import { CustomAutocomplete, CustomTextField } from '../../AddUser/components/text-field'
import CustomPhoneInput from '../../AddUser/components/phone-input'
import CustomDatePicker from '../../_components/DatePicker'

const TabBasic: FC<TabChildProps> = () => {
    const { control } = useFormContext<Member>()

    return (
        <Section className='grid grid-cols-3 gap-6'>
            <CustomTextField<Member>
                control={control}
                name='name'
                label='نام و نام خانوادگی'
                preventNumber
            />

            <CustomAutocomplete<Member>
                label='جنسیت'
                name='gender'
                control={control}
                options={['زن', 'مرد']}
            />

            <CustomPhoneInput<Member> name='cell_number' />

            <CustomTextField<Member>
                control={control}
                name='email'
                label='ایمیل'
            />

            <CustomDatePicker<Member>
                control={control}
                name='dob'
                label='تاریخ تولد'
            />

            <CustomDatePicker<Member>
                control={control}
                name='since'
                label='تاریخ عضویت'
            />

            <CustomAutocomplete<Member>
                label='مدت درمان'
                name='treatmentDuration'
                control={control}
                options={['کوتاه مدت', 'بلند مدت']}
            />

            <CustomAutocomplete<Member>
                label='نوع اشتراک'
                name='subscriptionType'
                control={control}
                options={['دانشجو', 'آزاد']}
            />

            <CustomAutocomplete<Member>
                label='موقعیت زمانی'
                name='timezone'
                control={control}
                options={[
                    'Africa/Abidjan',
                    'Africa/Accra',
                    'Africa/Addis-Ababa',
                    'Africa/Algiers',
                    'Africa/Asmara',
                    'Iran/Tehran',
                ]}
            />

            <CustomAutocomplete<Member>
                freeSolo
                multiple
                placeholder='با زدن Enter نحوه آشنایی مد نظرتان را اضافه کنید'
                label='نحوه آشنایی'
                name='introductionMethod'
                control={control}
                options={['گوگل', 'غیره']}
            />
        </Section>
    )
}

export default TabBasic
