import { FC, useState } from 'react'
import {
    ReservationHistory,
    ReservationMethod,
    ReservationPaymentStatus,
    ReservationStatus,
    ReservationType,
} from '../data-types'
import Section from '../../Tests/components/section'
import { Reservations } from '../../../../_core/components/icons'
import { cn } from '../../../../utils/cn'

const fakeFields: ReservationHistory[] = [
    {
        id: 101,
        photo: 'https://placehold.co/100x100?text=placeholder',
        name: 'Reza Mousavi',
        description: 'مشاوره کودک؛ مشاوره نوجوان',
        phone: '+989121234567',
        reserveDate: '2024-07-01T14:00:00Z',
        reserver: 'Reza Mousavi',
        type: ReservationType.internal,
        timezone: 'Asia/Tehran',
        price: '150,000 تومان',
        trackingCode: 'TRK1234567890',
        method: ReservationMethod.online,
        status: ReservationStatus.canceled,
        paymentStatus: ReservationPaymentStatus.notPaid,
    },
    {
        id: 102,
        photo: 'https://placehold.co/100x100?text=placeholder',
        name: 'Sara Ahmadi',
        description: 'مشاوره کودک؛ مشاوره نوجوان',
        phone: '+989331234567',
        reserveDate: '2024-07-02T16:00:00Z',
        reserver: 'Reza Mousavi',
        type: ReservationType.external,
        timezone: 'Asia/Tehran',
        price: '300,000 تومان',
        trackingCode: 'TRK0987654321',
        method: ReservationMethod.inPerson,
        status: ReservationStatus.running,
        paymentStatus: ReservationPaymentStatus.paid,
    },
    {
        id: 103,
        photo: 'https://placehold.co/100x100?text=placeholder',
        name: 'Ali Rezaei',
        description: 'مشاوره کودک؛ مشاوره نوجوان',
        phone: '+989351234567',
        reserveDate: '2024-06-30T10:00:00Z',
        reserver: 'Reza Mousavi',
        type: ReservationType.internal,
        timezone: 'Asia/Tehran',
        price: '200,000 تومان',
        trackingCode: 'TRK1234561234',
        method: ReservationMethod.online,
        status: ReservationStatus.canceled,
        cancelDate: '2024-06-29T09:00:00Z',
        canceler: 'Customer',
        paymentStatus: ReservationPaymentStatus.notPaid,
    },
    {
        id: 104,
        photo: 'https://placehold.co/100x100?text=placeholder',
        name: 'Mahnaz Afshar',
        description: 'مشاوره کودک؛ مشاوره نوجوان',
        phone: '+989361234567',
        reserveDate: '2024-07-03T18:00:00Z',
        reserver: 'Reza Mousavi',
        type: ReservationType.external,
        timezone: 'Asia/Tehran',
        price: '250,000 تومان',
        trackingCode: 'TRK0987612345',
        method: ReservationMethod.inPerson,
        status: ReservationStatus.running,
        paymentStatus: ReservationPaymentStatus.paid,
    },
    {
        id: 105,
        photo: 'https://placehold.co/100x100?text=placeholder',
        name: 'Farhad Majidi',
        description: 'مشاوره کودک؛ مشاوره نوجوان',
        phone: '+989371234567',
        reserveDate: '2024-07-04T20:00:00Z',
        reserver: 'Reza Mousavi',
        type: ReservationType.internal,
        timezone: 'Asia/Tehran',
        price: '120,000 تومان',
        trackingCode: 'TRK1209856743',
        method: ReservationMethod.online,
        status: ReservationStatus.done,
        paymentStatus: ReservationPaymentStatus.paid,
    },
    {
        id: 106,
        photo: 'https://placehold.co/100x100?text=placeholder',
        name: 'Leila Hatami',
        description: 'مشاوره کودک؛ مشاوره نوجوان',
        phone: '+989381234567',
        reserveDate: '2024-07-05T13:00:00Z',
        reserver: 'Reza Mousavi',
        type: ReservationType.external,
        timezone: 'Asia/Tehran',
        price: '400,000 تومان',
        trackingCode: 'TRK5678901234',
        method: ReservationMethod.inPerson,
        status: ReservationStatus.running,
        paymentStatus: ReservationPaymentStatus.paid,
    },
    {
        id: 107,
        photo: 'https://placehold.co/100x100?text=placeholder',
        name: 'Mohammad Reza Golzar',
        description: 'مشاوره کودک؛ مشاوره نوجوان',
        phone: '+989391234567',
        reserveDate: '2024-07-06T15:00:00Z',
        reserver: 'Reza Mousavi',
        type: ReservationType.internal,
        timezone: 'Asia/Tehran',
        price: '220,000 تومان',
        trackingCode: 'TRK2354678910',
        method: ReservationMethod.online,
        status: ReservationStatus.canceled,
        cancelDate: '2024-07-05T12:00:00Z',
        canceler: 'Admin',
        paymentStatus: ReservationPaymentStatus.notPaid,
    },
    {
        id: 108,
        photo: 'https://placehold.co/100x100?text=placeholder',
        name: 'Niki Karimi',
        description: 'مشاوره کودک؛ مشاوره نوجوان',
        phone: '+989302345678',
        reserveDate: '2024-07-07T17:00:00Z',
        reserver: 'Reza Mousavi',
        type: ReservationType.external,
        timezone: 'Asia/Tehran',
        price: '350,000 تومان',
        trackingCode: 'TRK8765432190',
        method: ReservationMethod.inPerson,
        status: ReservationStatus.canceled,
        paymentStatus: ReservationPaymentStatus.paid,
    },
    {
        id: 109,
        photo: 'https://placehold.co/100x100?text=placeholder',
        name: 'Bahram Radan',
        description: 'مشاوره کودک؛ مشاوره نوجوان',
        phone: '+989312345678',
        reserveDate: '2024-07-08T19:00:00Z',
        reserver: 'Reza Mousavi',
        type: ReservationType.internal,
        timezone: 'Asia/Tehran',
        price: '180,000 تومان',
        trackingCode: 'TRK2345678901',
        method: ReservationMethod.online,
        status: ReservationStatus.running,
        paymentStatus: ReservationPaymentStatus.paid,
    },
    {
        id: 110,
        photo: 'https://placehold.co/100x100?text=placeholder',
        name: 'Taraneh Alidoosti',
        description: 'مشاوره کودک؛ مشاوره نوجوان',
        phone: '+989322345678',
        reserveDate: '2024-07-09T11:00:00Z',
        reserver: 'Reza Mousavi',
        type: ReservationType.external,
        timezone: 'Asia/Tehran',
        price: '280,000 تومان',
        trackingCode: 'TRK3456789012',
        method: ReservationMethod.inPerson,
        status: ReservationStatus.running,
        paymentStatus: ReservationPaymentStatus.notPaid,
    },
    {
        id: 111,
        photo: 'https://placehold.co/100x100?text=placeholder',
        name: 'Peyman Moaadi',
        description: 'مشاوره کودک؛ مشاوره نوجوان',
        phone: '+989332345678',
        reserveDate: '2024-07-10T12:00:00Z',
        reserver: 'Reza Mousavi',
        type: ReservationType.internal,
        timezone: 'Asia/Tehran',
        price: '160,000 تومان',
        trackingCode: 'TRK4567890123',
        method: ReservationMethod.online,
        status: ReservationStatus.canceled,
        cancelDate: '2024-07-09T10:00:00Z',
        canceler: 'Customer',
        paymentStatus: ReservationPaymentStatus.paid,
    },
    {
        id: 112,
        photo: 'https://placehold.co/100x100?text=placeholder',
        name: 'Hedieh Tehrani',
        description: 'مشاوره کودک؛ مشاوره نوجوان',
        phone: '+989342345678',
        reserveDate: '2024-07-11T14:00:00Z',
        reserver: 'Reza Mousavi',
        type: ReservationType.external,
        timezone: 'Asia/Tehran',
        price: '310,000 تومان',
        trackingCode: 'TRK5678901234',
        method: ReservationMethod.inPerson,
        status: ReservationStatus.done,
        paymentStatus: ReservationPaymentStatus.notPaid,
    },
]

interface Filter {
    name: string
    filter: (value: ReservationHistory, index: number, array: ReservationHistory[]) => boolean
}

const filters: Filter[] = [
    {
        name: 'همه',
        filter: () => true,
    },
    {
        name: 'در حال برگذاری',
        filter: (value) => value.status === ReservationStatus.running,
    },
    {
        name: 'برگذار شده',
        filter: (value) => value.status === ReservationStatus.done,
    },
    {
        name: 'کنسل شده',
        filter: (value) => value.status === ReservationStatus.canceled,
    },
    {
        name: 'حضوری',
        filter: (value) => value.method === ReservationMethod.inPerson,
    },
    {
        name: 'آنلاین',
        filter: (value) => value.method === ReservationMethod.online,
    },
    {
        name: 'پرداخت شده',
        filter: (value) => value.paymentStatus === ReservationPaymentStatus.paid,
    },
    {
        name: 'پرداخت نشده',
        filter: (value) => value.paymentStatus === ReservationPaymentStatus.notPaid,
    },
]

const HistoryTab: FC = () => {
    // const { control } = useFormContext<TherapistDetail>()

    // const { fields } = useFieldArray({ control, name: 'reservationsHistory' })

    const [selectedFilter, setSelectedFilter] = useState(filters[0])

    return (
        <>
            {fakeFields.length === 0 && (
                <Section className='flex items-center justify-center gap-14 h-96'>
                    <Reservations />
                    <div className='text-gray-700 text-lg'>درمانگر هیچ نوبتی را ثبت نکرده است.</div>
                </Section>
            )}

            {fakeFields.length > 0 && (
                <Section className='flex-row gap-2 items-center px-6 py-4'>
                    <div className='text-gray-800 text-sm font-semibold'>وضعیت و نوع نوبت:</div>

                    {filters.map((filter) => (
                        <div
                            key={filter.name}
                            onClick={() => setSelectedFilter(filter)}
                            className={cn(
                                'rounded-full px-4 py-2 text-sm border cursor-pointer text-[#B3B3B3]',
                                filter.name === selectedFilter.name && 'border-primary-500 text-primary-500',
                            )}
                        >
                            {filter.name}
                        </div>
                    ))}
                </Section>
            )}

            {fakeFields.filter(selectedFilter.filter).map((field, index) => {
                const statusColor =
                    field.status === ReservationStatus.done
                        ? 'green'
                        : field.status === ReservationStatus.canceled
                        ? 'red'
                        : field.status === ReservationStatus.running
                        ? 'yellow'
                        : 'none'

                const paymentStatusColor =
                    field.paymentStatus === ReservationPaymentStatus.paid
                        ? 'green'
                        : field.paymentStatus === ReservationPaymentStatus.notPaid
                        ? 'red'
                        : 'none'

                return (
                    <div
                        key={index}
                        className='bg-white pt-5 px-6 pb-7 flex gap-4 relative'
                    >
                        <div className='absolute flex gap-2 top-5 left-5'>
                            <Stat type='green'>{field.method ?? ''}</Stat>

                            <Stat type={paymentStatusColor}>{field.paymentStatus ?? ''}</Stat>

                            <Stat type={statusColor}>{field.status ?? ''}</Stat>
                        </div>

                        <img
                            src={field.photo}
                            className='w-16 h-16 rounded-full object-cover object-center bg-gray-100'
                            alt={field.name}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null
                                currentTarget.src =
                                    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10ZM9.17 14.83l5.66-5.66M14.83 14.83 9.17 9.17' stroke='%23d32f2f' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/svg%3E"
                            }}
                        />

                        <div className='flex flex-col gap-1 w-full'>
                            <div className='text-sm'>
                                <span className='text-gray-500'>درمانگر: </span>
                                <span className='text-gray-800 font-semibold'>{field.name}</span>
                            </div>

                            <div className='text-sm text-gray-500 mb-4'>{field.description}</div>

                            <div className='grid grid-cols-3 gap-4'>
                                <div className='text-xs'>
                                    <span className='text-gray-500'>تاریخ و زمان رزرو نوبت: </span>
                                    <span className='text-gray-800 font-semibold'>
                                        {formatToPersianDateTime(field.reserveDate ?? '')}
                                    </span>
                                </div>

                                <div className='text-xs'>
                                    <span className='text-gray-500'>رزرو کننده نوبت: </span>
                                    <span className='text-gray-800 font-semibold'>{field.reserver}</span>
                                </div>

                                <div className='text-xs'>
                                    <span className='text-gray-500'>نوع جلسه: </span>
                                    <span className='text-gray-800 font-semibold'>{field.type}</span>
                                </div>

                                <div className='text-xs'>
                                    <span className='text-gray-500'>موقعیت زمانی درمانگر: </span>
                                    <span className='text-gray-800 font-semibold'>{field.timezone}</span>
                                </div>

                                <div className='text-xs'>
                                    <span className='text-gray-500'>مبلغ مشاوره: </span>
                                    <span className='text-gray-800 font-semibold'>{field.price}</span>
                                </div>

                                <div className='text-xs'>
                                    <span className='text-gray-500'>کد رهگیری: </span>
                                    <span className='text-gray-800 font-semibold'>{field.trackingCode}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </>
    )
}

export default HistoryTab

interface StatProps {
    type: 'green' | 'red' | 'yellow' | 'none'
    children: string
}

const Stat: FC<StatProps> = ({ children, type }) => (
    <div
        className={cn(
            'text-xs px-4 py-1 rounded-full',
            type === 'green' && 'bg-[#DAF6E8] text-primary-500',
            type === 'red' && 'bg-[#FFE6E6] text-alarm-500',
            type === 'yellow' && 'bg-[#FFF0E3] text-[#FA8D34]',
        )}
    >
        {children}
    </div>
)

function formatToPersianDateTime(dateStr: string): string {
    const date = new Date(dateStr)

    const dateOptions: Intl.DateTimeFormatOptions = {
        calendar: 'persian',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        localeMatcher: 'best fit',
    }

    const timeOptions: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    }

    const dateFormatter = new Intl.DateTimeFormat('fa-IR', dateOptions)

    const timeFormatter = new Intl.DateTimeFormat('fa-IR-u-ca-persian', timeOptions)

    const formattedDate = dateFormatter.format(date)
    const formattedTime = timeFormatter.format(date)

    return `${formattedDate} - ${formattedTime}`
}
