import { GridFooterContainerProps, FooterPropsOverrides } from '@mui/x-data-grid'
import { FC } from 'react'
import { cn } from '../../../utils/cn'

interface CustomFooterProps extends GridFooterContainerProps, FooterPropsOverrides {
    index: number
    shouldHide?: boolean
}

const CustomFooter: FC<CustomFooterProps> = ({ index, className, shouldHide, ...otherProps }) => {
    if (shouldHide) return null

    return (
        <div
            className={cn(
                'h-[3.75rem] text-gray-700 font-medium py-[1.125rem] w-full shrink-0 flex items-center justify-center bg-white shadow-[0_-4px_0] shadow-primary-50 relative',
                className,
            )}
            {...otherProps}
        >
            نمایش {index} صفحه
        </div>
    )
}

export default CustomFooter
