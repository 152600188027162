import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { CustomTextField } from '../../AddUser/components/text-field'
import { TestDetail } from '../data-types'
import InputAdornment from '@mui/material/InputAdornment'
import { DocumentCopy } from 'iconsax-react'
import useCopyToClipboard from '../../../../_core/components/hooks/use-copy'

const SidebarLink: FC = () => {
    const { control } = useFormContext<TestDetail>()

    const [inputRefToBeCopied, copyToClipboard] = useCopyToClipboard()

    return (
        <div className='bg-white p-[1.125rem] rounded'>
            <CustomTextField<TestDetail>
                readOnly={true}
                helperText='این فیلد قابل ویرایش نیست'
                label='لینک انگلیسی آزمون'
                className='iransans_without_numbers_class opacity-50'
                name='testUrl'
                control={control}
                inputRef={inputRefToBeCopied}
                placeholder='فقط به انگلیسی'
                generateOnChange={(value) => value.replace(/[^A-Za-z0-9-?/&:=.]/gi, '')}
                InputProps={{
                    endAdornment: (
                        <InputAdornment
                            position='start'
                            className='cursor-pointer'
                            onClick={copyToClipboard}
                        >
                            <DocumentCopy className='shadow-[8px_0px_16px_8px_#fff]' />
                        </InputAdornment>
                    ),
                }}
            />
        </div>
    )
}

export default SidebarLink
