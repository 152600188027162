import { Select, SelectSpecificProps, SelectProps } from '.'

function SelectTime({ ...selectProps }: Omit<SelectProps, 'values'> & SelectSpecificProps) {
    return (
        <Select
            {...selectProps}
            values={[...Array(24)].map((_, index) => make2Digits(index) + ':00')}
            generateListItem={(value) => (
                <div className='flex flex-row-reverse justify-between items-center w-full'>
                    <div>{value.split(':')[0]}</div>
                    <div>:</div>
                    <div>{value.split(':')[1]}</div>
                </div>
            )}
        />
    )
}

export default SelectTime

export function make2Digits(num: number) {
    return num < 10 ? '0' + num : num
}
