import HomePage from './pages/home/Home'
import LoginPage from './pages/auth/login/Login'
import AccountPage from './pages/cms/Accounts'
import BundlesPage from './pages/cms/bundles'
import NotFoundPage from './pages/not-found/NotFound'
import WorkshopsPage from './pages/cms/workshops'
import AddWorkshopPage from './pages/cms/workshops/add-workshop/AddWorkshop'
import CreatePasswordPage from './pages/auth/create-password/CreatePassword'
import UpdateWorkshopPage from './pages/cms/workshops/update-workshop/UpdateWorkshop'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import PaypalTransactions from './pages/cms/paypal/Paypal'
import Users from './pages/cms/Users'
import AddUser from './pages/cms/AddUser'
import ArticlesPage from './pages/cms/articles/'
import DictionaryPage from './pages/cms/dictionary/Dictionary'
import { QueryClient, QueryClientProvider, dehydrate, hydrate } from '@tanstack/react-query'
import LogoutPage from './pages/cms/logout'
import AddBundlePage from './pages/cms/bundles/add-bundle/AddBundle'
import UpdateBundlePage from './pages/cms/bundles/update-bundle/UpdateBundle'
import AddArticlePage from './pages/cms/articles/add-article/AddArticle'
import UpdateArticlePage from './pages/cms/articles/update-article/UpdateArticle'
import Tests from './pages/cms/Tests'
import AddTest from './pages/cms/Tests/add-test'
import UpdateTest from './pages/cms/Tests/update-test'
import Therapists from './pages/cms/therapists'
import AddTherapist from './pages/cms/therapists/add-therapist'
import UpdateTherapist from './pages/cms/therapists/update-therapist'
import Faqs from './pages/cms/Faq'
import UpdateFaq from './pages/cms/Faq/update-faq'
import AddFaq from './pages/cms/Faq/add-faq'
import Members from './pages/cms/Members'
import AddMember from './pages/cms/Members/add-member'
import UpdateMember from './pages/cms/Members/update-member'
import AddReservation from './pages/cms/directReservation/add'
import UpdateReservation from './pages/cms/directReservation/update'
import Reservations from './pages/cms/directReservation'

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 0,
            refetchOnWindowFocus: false,
            refetchOnReconnect: 'always',
            gcTime: 5 * 60 * 1000,
            refetchOnMount: 'always',
        },
    },
})

const persistedData = localStorage.getItem('queryCache')
if (persistedData) {
    hydrate(queryClient, JSON.parse(persistedData))
}

window.addEventListener('beforeunload', () => {
    const dehydratedState = dehydrate(queryClient)
    localStorage.setItem('queryCache', JSON.stringify(dehydratedState))
})

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <Routes>
                    <Route
                        path='/'
                        element={<HomePage />}
                    />

                    <Route
                        path='/auth/login'
                        element={<LoginPage />}
                    />

                    <Route
                        path='/auth/create-password'
                        element={<CreatePasswordPage />}
                    />
                    <Route
                        path='/cms/dictionary'
                        element={<DictionaryPage />}
                    />

                    <Route
                        path='/cms'
                        element={<AccountPage />}
                    />
                    {/*  */}
                    <Route
                        path='/cms/bundles'
                        element={<BundlesPage />}
                    />

                    <Route
                        path='/cms/bundles/add-bundle'
                        element={<AddBundlePage />}
                    />

                    <Route
                        path='/cms/bundles/update-bundle'
                        element={<UpdateBundlePage />}
                    />

                    <Route
                        path='/cms/workshops'
                        element={<WorkshopsPage />}
                    />

                    <Route
                        path='/cms/workshops/add-workshop'
                        element={<AddWorkshopPage />}
                    />

                    <Route
                        path='/cms/workshops/update-workshop'
                        element={<UpdateWorkshopPage />}
                    />

                    <Route
                        path='/cms/paypalTransactions'
                        element={<PaypalTransactions />}
                    />

                    <Route
                        path='/cms/users'
                        element={<Users />}
                    />

                    <Route
                        path='/cms/addUser'
                        element={<AddUser />}
                    />

                    <Route
                        path='/cms/editUser'
                        element={<AddUser />}
                    />

                    <Route
                        path='*'
                        element={<NotFoundPage />}
                    />
                    <Route
                        path='/cms/articles'
                        element={<ArticlesPage />}
                    />

                    <Route
                        path='/cms/articles/add-article'
                        element={<AddArticlePage />}
                    />

                    <Route
                        path='/cms/articles/update-article'
                        element={<UpdateArticlePage />}
                    />

                    <Route
                        path='/cms/tests'
                        element={<Tests />}
                    />

                    <Route
                        path='/cms/tests/add-test'
                        element={<AddTest />}
                    />

                    <Route
                        path='/cms/tests/update-test'
                        element={<UpdateTest />}
                    />

                    <Route
                        path='/cms/therapists'
                        element={<Therapists />}
                    />

                    <Route
                        path='/cms/therapists/add-therapist'
                        element={<AddTherapist />}
                    />

                    <Route
                        path='/cms/therapists/update-therapist'
                        element={<UpdateTherapist />}
                    />

                    <Route
                        path='/cms/faq'
                        element={<Faqs />}
                    />

                    <Route
                        path='/cms/faq/add-faq'
                        element={<AddFaq />}
                    />

                    <Route
                        path='/cms/faq/update-faq'
                        element={<UpdateFaq />}
                    />

                    <Route
                        path='/cms/members'
                        element={<Members />}
                    />

                    <Route
                        path='/cms/members/add-member'
                        element={<AddMember />}
                    />

                    <Route
                        path='/cms/members/update-member'
                        element={<UpdateMember />}
                    />

                    <Route
                        path='/cms/directReservation'
                        element={<Reservations />}
                    />

                    <Route
                        path='/cms/directReservation/add'
                        element={<AddReservation />}
                    />

                    <Route
                        path='/cms/directReservation/update'
                        element={<UpdateReservation />}
                    />

                    <Route
                        path='/cms/logout'
                        element={<LogoutPage />}
                    />
                </Routes>
            </BrowserRouter>
        </QueryClientProvider>
    )
}

export default App
