import { z } from './zod-translation'

export default function stripHtmlTags(html: string): string {
    // Create a new DOMParser instance
    const parser = new DOMParser()

    // Parse the HTML string into a document
    const doc = parser.parseFromString(html, 'text/html')

    // Use the textContent property to get only the text, without HTML tags
    return doc.body.textContent || ''
}

export const richTextSchema = z.coerce.string().refine(
    (value: string) => {
        const strippedText = stripHtmlTags(value)
        return strippedText.length >= 3
    },
    { message: 'حداقل ۳ کاراکتر وارد کنید' },
)
