function addCommas(string?: string | number) {
    string = string ?? ''
    string = string.toString()

    let res

    if (string.split('.').length > 1) {
        res = string.split('.').map((part) => core(part))
        return res.join('.')
    }

    return core(string)
}

function core(string: string) {
    string = string ?? '0'

    string = string.replace(/[^0-9\u06F0-\u06F9]/g, '')

    const reversedString = string.split('').reverse().join('')

    let withCommas = ''
    for (let i = 0; i < reversedString.length; i++) {
        if (i > 0 && i % 3 === 0) {
            withCommas += ','
        }
        withCommas += reversedString[i]
    }

    return withCommas.split('').reverse().join('')
}

export default addCommas
