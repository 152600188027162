import { FC, SVGProps } from 'react'
import ListPage from '../_components/list-page'
import { getPaypalColumns } from './constants'
import { Tabs, TabsContent, TabsTrigger } from '../../../_core/components/tabs'
import { PaypalData, Paypal } from './data-types'
import { getPaypalData, PaypalStatus } from './api'
import { PriceSumStatIcon, RemainsStatIcon, SumOutStatIcon, SumStatIcon } from '../../../_core/components/icons'
import formatMoney from '../../../utils/format-money'

const PaypalPage: FC = () => {
    return (
        <Tabs initialValue='all'>
            <TabsContent value='all'>
                <ListPage<PaypalData, Paypal>
                    apiFunction={() => getPaypalData(PaypalStatus.All)}
                    generateRows={(data) => (data as Paypal)?.data ?? []}
                    getColumns={getPaypalColumns}
                    generateTitle={CustomTabTriggerSection}
                    itemName={'تراکنش‌'}
                    addPageLink={() => null}
                    queryKey={['paypal2']}
                    generateID={(row) => row[0]}
                    generateDialog={() => null}
                    HeaderComponent={headerStats}
                    accessName='PaypalTransactions'
                />
            </TabsContent>

            <TabsContent value='validated'>
                <ListPage<PaypalData, Paypal>
                    apiFunction={() => getPaypalData(PaypalStatus.All)}
                    generateRows={(data) => (data as Paypal)?.data.filter((row) => row[11] === 1) ?? []}
                    getColumns={getPaypalColumns}
                    generateTitle={CustomTabTriggerSection}
                    itemName={'تراکنش‌'}
                    addPageLink={() => null}
                    queryKey={['paypal2']}
                    generateID={(row) => row[0]}
                    generateDialog={() => null}
                    HeaderComponent={headerStats}
                    accessName='PaypalTransactions'
                />
            </TabsContent>

            <TabsContent value='notValidated'>
                <ListPage<PaypalData, Paypal>
                    apiFunction={() => getPaypalData(PaypalStatus.All)}
                    generateRows={(data) => (data as Paypal)?.data.filter((row) => row[11] === 0) ?? []}
                    getColumns={getPaypalColumns}
                    generateTitle={CustomTabTriggerSection}
                    itemName={'تراکنش‌'}
                    addPageLink={() => null}
                    queryKey={['paypal2']}
                    generateID={(row) => row[0]}
                    generateDialog={() => null}
                    HeaderComponent={headerStats}
                    accessName='PaypalTransactions'
                />
            </TabsContent>
        </Tabs>
    )
}

export default PaypalPage

const CustomTabTriggerSection: FC<Paypal> = ({ data }) => {
    return (
        <div className='flex items-center gap-3'>
            <CustomTabTrigger
                children={`کلیه تراکنش‌ها (${data?.length ?? '۰'})`}
                value='all'
            />

            <CustomTabTrigger
                children={`تراکنش‌های تایید شده (${data?.filter((data) => data[11] === 1).length ?? '۰'})`}
                value='validated'
            />

            <CustomTabTrigger
                children={`تراکنش‌های تایید نشده (${data?.filter((data) => data[11] === 0).length ?? '۰'})`}
                value='notValidated'
            />
        </div>
    )
}

interface CustomTabTriggerProps {
    children: string
    value: string
}

const CustomTabTrigger: FC<CustomTabTriggerProps> = ({ children, value }) => {
    return (
        <div className='flex items-center gap-3'>
            <TabsTrigger
                className='w-44 p-1 text-center bg-white text-gray-300 text-sm flex items-center justify-center h-11 rounded data-[open=true]:bg-primary-500 data-[open=true]:text-gray-50'
                value={value}
            >
                {children}
            </TabsTrigger>
        </div>
    )
}

const headerStats: FC<Paypal> = ({ count }) => {
    return (
        <div className='grid grid-cols-4 gap-5 mb-6'>
            <Stat
                Icon={SumStatIcon}
                title='جمع کل دریافتی'
                price={count.sum_positive}
            />
            <Stat
                Icon={SumOutStatIcon}
                title='جمع کل پرداختی'
                price={count.sum_negative}
            />
            <Stat
                Icon={PriceSumStatIcon}
                title='جمع هزینه ها'
                price={count.fees}
            />
            <Stat
                Icon={RemainsStatIcon}
                title='مانده حساب'
                price={count.net}
            />
        </div>
    )
}

const Stat = ({ Icon, title, price }: { Icon: FC<SVGProps<SVGSVGElement>>; title: string; price: number }) => {
    return (
        <div className='bg-white rounded p-4 flex flex-col justify-between gap-3'>
            <div className='flex items-center gap-2 text-sm text-gray-700'>
                <Icon />
                {title}
            </div>

            <div className='text-xl font-medium text-gray-700 ml-auto [direction:ltr]'>
                {formatMoney(price.toString(), 2)} $
            </div>
        </div>
    )
}
