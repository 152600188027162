import { GridRowId } from '@mui/x-data-grid'
import { Bundle } from '../data-types'
import { WorkshopTypeServer } from '../_components/WorkshopForm'
import { Therapist } from '../../therapists/data-types'

export async function getWorkshops(): Promise<Bundle[]> {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/get_workshops.php', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
        })
        const data = await response.json()
        return data.data
    } catch (error) {
        console.error('Failed to fetch initial data:', error)
        return []
    }
}

export function deleteWorkshops(bundlesToDelete: GridRowId[]) {
    return Promise.all(
        bundlesToDelete.map(async (bundleId) => {
            try {
                const response = await fetch('https://stage2-api.insight-clinic.com/controller/delete_workshop.php', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        AuthorizationKey: localStorage.getItem('token') ?? '',
                    },
                    body: JSON.stringify({
                        id: parseInt(bundleId.toString(), 10),
                    }),
                })
                const data = await response.json()

                return data
            } catch (error) {
                console.error('Failed to fetch initial data:', error)

                return undefined
            }
        }),
    )
}

export async function addWorkshop(workshop: WorkshopTypeServer) {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/add_workshop.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify(workshop),
        })
        const data = await response.json()

        return data
    } catch (error) {
        throw error
    }
}

export async function updateWorkshop(workshop: WorkshopTypeServer) {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/update_workshop.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify(workshop),
        })
        const data = await response.json()

        return data
    } catch (error) {
        throw error
    }
}

export async function getCategories() {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/get_categories.php', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
        })
        const data = await response.json()

        return data as string[]
    } catch (error) {
        throw error
    }
}

export async function getMeasurements() {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/get_measurements.php', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
        })
        const data = await response.json()

        return data as string[]
    } catch (error) {
        throw error
    }
}

export async function getKeywords() {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/get_keywords.php', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
        })
        const data = await response.json()

        return data as string[]
    } catch (error) {
        throw error
    }
}

export async function getTherapists() {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/get_therapists_v2.php', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
        })
        const data = await response.json()

        return data.data as Therapist[]
    } catch (error) {
        throw error
    }
}

export async function getWorkshop(id: number) {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/get_workshop.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify({
                id,
            }),
        })
        const data = await response.json()

        return data.data as WorkshopTypeServer
    } catch (error) {
        throw error
    }
}
