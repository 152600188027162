import addCommas from './add-commas'

export default function formatMoney(rawAmount: string, decimals: number = 0) {
    const amount = rawAmount.toString()

    let res = amount?.split('.')[0]

    if (!res) return ''

    if (decimals > 0) {
        res += '.' + (amount.split('.')[1]?.slice(0, decimals) ?? '0')
    }

    return addCommas(res)
}
