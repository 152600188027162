import { GridRowId } from '@mui/x-data-grid'
import { MemberServer } from '../data-types'

export async function getMembers(): Promise<MemberServer[]> {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/get_members.php', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
        })
        const data = await response.json()
        console.log(data)
        return data.data
    } catch (error) {
        console.error('Failed to fetch initial data:', error)
        return []
    }
}

export function deleteMembers(membersToDelete: GridRowId[]) {
    membersToDelete.map((membersToDelete) => console.log('removed', membersToDelete))
}

export async function getMemberDetail(id: number): Promise<MemberServer> {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/get_member.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify({
                id,
            }),
        })
        const data = await response.json()
        return data.data
    } catch (error) {
        console.error('Failed to fetch initial data:', error)
        throw error
    }
}

export async function addMemberDetail(data: MemberServer) {
    try {
        return await fetch('https://stage2-api.insight-clinic.com/controller/add_member.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify(data),
        })
    } catch (error) {
        throw error
    }
}

export async function updateMemberDetail(data: MemberServer) {
    try {
        await fetch('https://stage2-api.insight-clinic.com/controller/update_member.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify(data),
        })
    } catch (error) {
        throw error
    }
}
