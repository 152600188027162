import { GridRowId } from '@mui/x-data-grid'
import { Bundle } from '../data-types'
import { BundleTypeServer } from '../_components/BundleForm'
import { TestDetailsType } from '../../../../_core/components/custom-table/CustomTable.type'

export async function getBundles(): Promise<Bundle[]> {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/get_bundles.php', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
        })
        const data = await response.json()
        return data.data
    } catch (error) {
        console.error('Failed to fetch initial data:', error)
        return []
    }
}

export function deleteBundles(bundlesToDelete: GridRowId[]) {
    return Promise.all(
        bundlesToDelete.map(async (bundleId) => {
            try {
                const response = await fetch('https://stage2-api.insight-clinic.com/controller/delete_bundle.php', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        AuthorizationKey: localStorage.getItem('token') ?? '',
                    },
                    body: JSON.stringify({
                        id: parseInt(bundleId.toString(), 10),
                    }),
                })
                const data = await response.json()

                return data
            } catch (error) {
                console.error('Failed to fetch initial data:', error)

                return undefined
            }
        }),
    )
}

export async function addBundleDetail(data: BundleTypeServer) {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/add_bundle.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify(data),
        })
        const processedRes = await response.json()
        if (processedRes.status !== 'success')
            throw new Error(processedRes.message ?? processedRes.code ?? 'SERVER_ERROR')
    } catch (error) {
        throw error
    }
}

export async function updateBundleDetail(data: BundleTypeServer) {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/update_bundle.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify(data),
        })
        const processedRes = await response.json()
        if (processedRes.status !== 'success')
            throw new Error(processedRes.message ?? processedRes.code ?? 'SERVER_ERROR')
    } catch (error) {
        throw error
    }
}

export async function getTestsBundle(): Promise<TestDetailsType[]> {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/get_tests_bundle.php', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
        })
        const processedRes = await response.json()
        if (!processedRes.data) throw new Error(processedRes.message ?? processedRes.code ?? 'SERVER_ERROR')
        return processedRes.data
    } catch (error) {
        throw error
    }
}

export async function getBundleDetails(id: number): Promise<BundleTypeServer> {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/get_bundle.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify({ id }),
        })
        const processedRes = await response.json()
        if (!processedRes.data) throw new Error(processedRes.message ?? processedRes.code ?? 'SERVER_ERROR')
        return processedRes.data
    } catch (error) {
        throw error
    }
}
