import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import RichTextBox from './RichTextBox'
import { cn } from '../../../utils/cn'

interface CustomRichTextBoxProps<T extends FieldValues> {
    control: Control<T, keyof T>
    name: Path<T>
    label?: string
    className?: string
    limit?: number
}

function CustomRichTextBox<T extends FieldValues>({
    control,
    name,
    label,
    className,
    limit,
}: CustomRichTextBoxProps<T>) {
    return (
        <div className={cn('relative', className)}>
            <Controller
                control={control}
                name={name}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <RichTextBox
                        onUpdate={(newValue) => onChange(newValue.editor.getHTML())}
                        onBlur={onBlur}
                        content={value}
                        error={error?.message}
                        limit={limit}
                    />
                )}
            />

            {label && (
                <label className='MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeMedium MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeMedium MuiInputLabel-outlined absolute text-xs -top-2 right-4 bg-white z-10 px-1 select-none'>
                    {label}
                </label>
            )}
        </div>
    )
}

export default CustomRichTextBox
