import storage from 'redux-persist/es/storage'
import persistStore from 'redux-persist/es/persistStore'
import userAccessSlice from './user-access/user-access-slice'
import { persistReducer } from 'redux-persist'
import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import formItemSlice from './form-item/form-item-slice'

export const reducers = combineReducers({
    userAccess: userAccessSlice,
    formItem: formItemSlice,
})

const persistConfig = {
    key: 'root',
    storage: storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
    reducer: persistedReducer,
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export const persistor = persistStore(store)
