import { z } from '../../../../utils/zod-translation'
import { MemberSchema } from '../components/member-form'

export type Member = z.infer<typeof MemberSchema>

export type MemberServer = Omit<Member, 'introductionMethod'> & { introductionMethod?: string }

export enum TransactionPaymentStatus {
    paid = 'پرداخت شده',
    notPaid = 'پرداخت نشده',
}

export interface Transaction {
    id: number
    paymentStatus: TransactionPaymentStatus
    trackingCode: string
    description: string
}
