import { GridRowId } from '@mui/x-data-grid'
import { Dictionary } from '../data-types'

export async function getDictionaries(page: number): Promise<Dictionary> {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/get_dictionary.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify({
                page,
            }),
        })
        const data = await response.json().then((data) => data.data as Dictionary)
        const dataWithUniqueIdInMetrics = data.metrics.map((item, index) => ({ ...item, id: index }))
        const dataWithUniqueIdInQuestions = data.questions.map((item, index) => ({ ...item, id: index }))
        return { metrics: dataWithUniqueIdInMetrics, questions: dataWithUniqueIdInQuestions }
    } catch (error) {
        console.error('Failed to fetch initial data:', error)
        throw error
    }
}

export function deleteDictionaries(dictionariesToDelete: GridRowId[]) {
    dictionariesToDelete.map((dictionaryToDelete) => console.log('removed', dictionaryToDelete))
}
