import { FC, useCallback } from 'react'
import CMSLayout from '../../../../_core/components/layouts/cms-dashboard/CMSLayout'
import { Member } from '../data-types'
import { Tabs, TabsContent, TabsTrigger } from '../../../../_core/components/tabs'
import { FormProvider, useForm } from 'react-hook-form'
import { z } from '../../../../utils/zod-translation'
import { zodResolver } from '@hookform/resolvers/zod'
import Button, { ButtonSizes, ButtonVariants } from '../../../../_core/components/button'
import { Refresh } from 'iconsax-react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { cn } from '../../../../utils/cn'
import { DialogTypes, useDialogStore } from '../../_components/Dialog'
import { addMemberDetail, updateMemberDetail } from '../api'
import TabBasic from './tab-basic'
import { isValidPhoneNumber } from 'libphonenumber-js'
import HistoryTab from './history-tab'
import TransactionsTab from './tab-transactions'

export const MemberSchema = z.object({
    id: z.coerce.number(),
    name: z.coerce.string(),
    gender: z.coerce.string(),
    dob: z.coerce.string(),
    country: z.coerce.string(),
    city: z.coerce.string(),
    email: z.coerce.string().email(),
    text_service: z.coerce.boolean(),
    email_service: z.coerce.boolean(),
    photo: z.coerce.string(),
    since: z.coerce.string(),
    education: z.coerce.string(),

    cell_number: z.coerce
        .string()
        .min(1, 'این فیلد اجباری است')
        .refine((value) => isValidPhoneNumber(value), 'شماره موبایل اشتباه است'),
    treatmentDuration: z.coerce.string(),
    introductionMethod: z.array(z.coerce.string()).optional(),

    subscriptionType: z.coerce.string(),
    timezone: z.coerce.string(),
})

export interface TabChildProps {
    parent?: string
}

interface TabType {
    title: string
    value: string
    Children: FC<TabChildProps>
}

const tabs: TabType[] = [
    { Children: TabBasic, title: 'اطلاعات پایه', value: 'basic' },
    { Children: HistoryTab, title: 'سوابق نوبت‌دهی', value: 'history' },
    { Children: TransactionsTab, title: 'تراکنش‌ها', value: 'transactions' },
]

interface MemberFormProps {
    type: 'ADD' | 'UPDATE'
    member?: Member
    isLoading?: boolean
}

const MemberForm: FC<MemberFormProps> = ({ type, member }) => {
    const navigate = useNavigate()

    const { control, handleSubmit, watch, ...otherFormStuff } = useForm<Member>({
        defaultValues: member,
        resolver: zodResolver(MemberSchema),
    })

    const { setDialogState } = useDialogStore()

    const { mutate: addMember, isPending: isAddingMemberPending } = useMutation({
        mutationKey: ['members'],
        mutationFn: addMemberDetail,
        onSuccess: () =>
            setDialogState({
                open: true,
                title: 'ثبت عضو جدید',
                type: DialogTypes.success,
                description: 'عضو جدید با موفقیت ثبت شد.',
            }),
        onError: () =>
            setDialogState({
                open: true,
                title: 'خطا در ثبت عضو',
                type: DialogTypes.error,
                description: 'عضو جدید ثبت نشد.',
            }),
    })

    const { mutate: updateMember, isPending: isUpdatingMemberPending } = useMutation({
        mutationKey: ['members'],
        mutationFn: updateMemberDetail,
        onSuccess: () =>
            setDialogState({
                open: true,
                title: 'ویرایش عضو',
                type: DialogTypes.success,
                description: 'اطلاعات عوض با موفقیت ویرایش شد.',
            }),
        onError: () =>
            setDialogState({
                open: true,
                title: 'خطا در ویرایش اطلاعات عضو',
                type: DialogTypes.error,
                description: 'اطلاعات عضو ویرایش نشد.',
            }),
    })

    const onSubmit = useCallback(
        ({ introductionMethod, ...data }: Member) => {
            const transformedData = {
                introductionMethod: introductionMethod?.join('|'),
                ...data,
            }
            if (type === 'ADD') addMember(transformedData)
            if (type === 'UPDATE') updateMember(transformedData)
        },
        [addMember, type, updateMember],
    )

    return (
        <CMSLayout>
            <div className='text-sm mb-5'>
                <span className='text-gray-400'>درمانجویان / </span>
                <span className='text-gray-600 font-medium'>{type === 'ADD' ? 'درمانجو جدید' : 'ویرایش درمانجو'}</span>
            </div>

            <Tabs initialValue={tabs[0].value}>
                <FormProvider
                    control={control}
                    watch={watch}
                    handleSubmit={handleSubmit}
                    {...otherFormStuff}
                >
                    <div className='flex mb-3 gap-2'>
                        {tabs.map((tab) => (
                            <TabsTrigger
                                className={cn(
                                    'basis-40 rounded border border-[#EDEDED] p-3 text-center',
                                    'data-[open=false]:bg-white data-[open=true]:bg-primary-500 data-[open=false]:text-gray-300 data-[open=true]:text-white',
                                )}
                                key={tab.value}
                                value={tab.value}
                            >
                                {tab.title}
                            </TabsTrigger>
                        ))}
                    </div>

                    <form
                        className='grid grid-cols-4 gap-4'
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div className='col-span-4'>
                            {tabs.map(({ Children, ...tab }) => (
                                <TabsContent
                                    className='flex flex-col gap-3'
                                    key={tab.value}
                                    value={tab.value}
                                >
                                    <Children parent={tab.value} />
                                </TabsContent>
                            ))}

                            <div className='flex gap-4 mt-5'>
                                <Button
                                    disabled={isAddingMemberPending || isUpdatingMemberPending}
                                    type='submit'
                                    className='w-[9.375rem] text-gray-50'
                                    size={ButtonSizes.bigger}
                                >
                                    {isAddingMemberPending || isUpdatingMemberPending ? (
                                        <Refresh className='animate-spin' />
                                    ) : (
                                        'ثبت'
                                    )}
                                </Button>

                                <Button
                                    type='button'
                                    size={ButtonSizes.big}
                                    className='w-[9.375rem]'
                                    variant={ButtonVariants.gray}
                                    onClick={() => navigate('/cms/members')}
                                >
                                    انصراف
                                </Button>
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </Tabs>
        </CMSLayout>
    )
}

export default MemberForm
