import type { FC, ReactNode } from 'react'
import { cn } from '../../../utils/cn'

export enum ButtonVariants {
    primary = 'primary',
    danger = 'danger',
    neutral = 'neutral',
    gray = 'gray',
}

export enum ButtonSizes {
    normal = 'normal',
    small = 'small',
    big = 'big',
    bigger = 'bigger',
}

interface ButtonProps
    extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    icon?: ReactNode
    variant?: ButtonVariants
    size?: ButtonSizes
    href?: string
    aClassName?: string
}

type Variants = {
    [K in ButtonVariants]: string
}

type Sizes = {
    [K in ButtonSizes]: string
}

const Button: FC<ButtonProps> = ({
    size = ButtonSizes.normal,
    variant = ButtonVariants.primary,
    className,
    icon,
    children,
    href,
    aClassName,
    ...otherProps
}) => {
    const sizesClassNames: Sizes = {
        normal: 'py-2 px-5 text-base',
        small: 'py-1 px-4 text-sm',
        big: 'h-[3.25rem] text-base font-medium',
        bigger: 'h-[3.25rem] text-lg font-medium',
    }

    const variantsClassNames: Variants = {
        danger: 'bg-alarm-500',
        primary: 'bg-primary-500',
        neutral: 'bg-gray-100 text-gray-300',
        gray: 'bg-gray-300 text-gray-50',
    }

    return (
        <a
            href={href}
            className={aClassName}
        >
            <button
                className={cn(
                    'flex items-center justify-center gap-1 rounded text-white font-medium',
                    sizesClassNames[size],
                    variantsClassNames[variant],
                    className,
                )}
                {...otherProps}
            >
                {icon}
                {children}
            </button>
        </a>
    )
}

export default Button
