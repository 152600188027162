import { CircularProgress } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect, type FC } from 'react'
import './index.css'

const LogoutPage: FC = () => {
    const queryClient = useQueryClient()

    useEffect(() => {
        queryClient.clear()
        queryClient.invalidateQueries()
        localStorage.removeItem('token')
        localStorage.removeItem('fullName')
        localStorage.removeItem('persist:root')
        localStorage.removeItem('userId')
        localStorage.removeItem('expiredDate')
        localStorage.removeItem('ally-supports-cache')
        localStorage.removeItem('appStore')
        window.location.href = '/auth/login'
    }, [queryClient])

    return (
        <div className='logout-page w-screen h-screen bg-primary-700 flex items-center justify-center text-white flex-col gap-4'>
            <div>در حال خروج...</div>
            <CircularProgress />
        </div>
    )
}

export default LogoutPage
