import { useNavigate } from 'react-router-dom'
import { KeyboardEventHandler, useCallback, useMemo } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { UploadSection } from '../../_components/UploadSection'
import { CustomTextField } from '../../AddUser/components/text-field'
import CustomSwitch from '../../AddUser/components/switch'
import InputAdornment from '@mui/material/InputAdornment'
import { DocumentCopy, Refresh } from 'iconsax-react'
import useCopyToClipboard from '../../../../_core/components/hooks/use-copy'
import Button, { ButtonSizes, ButtonVariants } from '../../../../_core/components/button'
import addCommas from '../../../../utils/add-commas'
import CustomRichTextBox from '../../_components/CustomRichTextBox'
import toEnglishNumber from '../../../../utils/to-english-number'
import { z } from '../../../../utils/zod-translation'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { addBundleDetail, updateBundleDetail } from '../api'
import { DialogTypes, useDialogStore } from '../../_components/Dialog'
import { TestsTable } from './TestsTable'
import { optionalParseInt } from '../../../../utils/optional-parse-number'

export const bundleSchema = z.object({
    id: z.coerce.number().optional(),
    measurement_title: z.coerce.string().min(1, { message: 'الزامی' }),
    test_ids: z.array(z.coerce.string()).optional(),
    measurements: z.array(z.string()).optional(),
    categories: z.array(z.string()).optional(),
    age_range_from: z.coerce.number().optional(),
    age_range_to: z.coerce.number().optional(),
    original_price_rial: z.coerce.number().optional(),
    original_price_dollar: z.coerce.number().optional(),
    price_rial: z.coerce.number().optional(),
    price_dollar: z.coerce.number().optional(),
    tests_count: z.coerce.number().optional(),
    bundle_title: z.coerce.string().min(1, { message: 'الزامی' }),
    description: z.coerce.string().optional(),
    meta: z.coerce.string().optional(),
    url: z.coerce.string().optional(),
    is_active: z.coerce.boolean().optional(),
    is_featured: z.coerce.boolean().optional(),
    image: z.coerce.string().optional(),
    bg: z.coerce.string().optional(),
    thumbnail: z.coerce.string().optional(),
    tests_list: z.array(z.coerce.number()).optional(),
})

export type BundleType = z.infer<typeof bundleSchema>

export type BundleTypeServer = Omit<BundleType, 'test_ids' | 'measurements' | 'categories'> & {
    test_ids?: string
    measurements: string
    categories: string
}

type BundleFormType = {
    bundle?: BundleType
    type: 'ADD' | 'UPDATE'
    isLoading?: boolean
}

export const BundleForm = ({ bundle, type }: BundleFormType) => {
    const navigate = useNavigate()

    const { control, handleSubmit, watch, ...otherFormStuff } = useForm<BundleType>({
        defaultValues: bundle,
        resolver: zodResolver(bundleSchema),
    })

    const { setDialogState } = useDialogStore()

    const { mutate: addBundle, isPending: isAddingBundlePending } = useMutation({
        mutationKey: ['bundles'],
        mutationFn: addBundleDetail,
        onSuccess: () => {
            setDialogState({
                open: true,
                title: 'ثبت بسته هدفمند جدید',
                type: DialogTypes.success,
                description: 'بسته شما با موفقیت ثبت شد.',
            })
            navigate('/cms/bundles')
        },
        onError: () =>
            setDialogState({
                open: true,
                title: 'خطا در ثبت بسته هدفمند',
                type: DialogTypes.error,
                description: 'بسته شما ثبت نشد.',
            }),
    })

    const { mutate: updateBundle, isPending: isUpdatingBundlePending } = useMutation({
        mutationKey: ['bundles'],
        mutationFn: updateBundleDetail,
        onSuccess: () => {
            setDialogState({
                open: true,
                title: 'ویرایش بسته هدفمند',
                type: DialogTypes.success,
                description: 'اطلاعات بسته با موفقیت ویرایش شد.',
            })
            navigate('/cms/bundles')
        },
        onError: () =>
            setDialogState({
                open: true,
                title: 'خطا در ویرایش بسته هدفمند',
                type: DialogTypes.error,
                description: 'بسته شما ویرایش نشد.',
            }),
    })

    const isLoading = useMemo(
        () => isAddingBundlePending || isUpdatingBundlePending,
        [isAddingBundlePending, isUpdatingBundlePending],
    )

    const onSubmit = useCallback(
        ({ test_ids, measurements, categories, tests_count, tests_list, ...data }: BundleType) => {
            const transformedData = {
                test_ids: test_ids?.join('|') ?? '',
                measurements: measurements?.join('|') ?? '',
                categories: categories?.join('|') ?? '',
                tests_count: test_ids?.length ?? 0,
                tests_list: test_ids?.map((testId) => optionalParseInt(testId)) ?? [],
                ...data,
            }
            if (type === 'ADD') addBundle(transformedData)
            if (type === 'UPDATE') updateBundle(transformedData)
        },
        [addBundle, type, updateBundle],
    )

    const onError = useCallback(
        (e: any) => {
            console.log(e)
            setDialogState({
                open: true,
                title: 'فرم ناقص',
                type: DialogTypes.error,
                description: 'اطلاعات فرم را چک کنید.',
            })
        },
        [setDialogState],
    )

    const handleKeyDown: KeyboardEventHandler<HTMLFormElement> = (e) => {
        if (e.key === 'Enter') e.preventDefault()
    }

    const [inputRefToBeCopied, copyToClipboard] = useCopyToClipboard()

    return (
        <FormProvider
            control={control}
            watch={watch}
            handleSubmit={handleSubmit}
            {...otherFormStuff}
        >
            <form
                onSubmit={handleSubmit(onSubmit, onError)}
                className='flex flex-col gap-5'
                onKeyDown={handleKeyDown}
            >
                <div className='text-sm mb-5'>
                    <span className='text-gray-400'>بسته هدفمند / </span>
                    <span className='text-gray-600 font-medium'>{type === 'ADD' ? 'بسته جدید' : 'ویرایش بسته'}</span>
                </div>

                <div className='flex gap-4'>
                    <div className='w-3/4 max-3/4 flex flex-col gap-3'>
                        <div className='bg-white p-7 flex flex-col gap-10'>
                            <CustomTextField<BundleType>
                                label='عنوان تحلیل بسته'
                                name='measurement_title'
                                control={control}
                            />
                        </div>

                        <div className='bg-white p-7 flex flex-col'>
                            <TestsTable />
                        </div>

                        <div className='bg-white p-7 flex flex-col gap-10'>
                            <CustomTextField<BundleType>
                                label='عنوان بسته'
                                name='bundle_title'
                                control={control}
                            />

                            <CustomRichTextBox<BundleType>
                                control={control}
                                name='description'
                                label='مقدمه'
                                limit={Infinity}
                            />

                            <CustomTextField<BundleType>
                                name='meta'
                                control={control}
                                label='Meta Description'
                            />
                        </div>

                        <div className='bg-white p-7'>
                            <div className='flex gap-5 items-center  *:grow'>
                                <CustomTextField<BundleType>
                                    name='original_price_rial'
                                    control={control}
                                    label='قیمت داخلی بسته'
                                    adornment='تومان'
                                    generateOnChange={(value) =>
                                        toEnglishNumber(value.replace(/[^0-9\u06F0-\u06F9]/g, ''))
                                    }
                                    generateValue={(value) => addCommas(value?.toString() ?? '')}
                                />

                                <CustomTextField<BundleType>
                                    name='original_price_dollar'
                                    control={control}
                                    label='قیمت خارجی بسته'
                                    adornment='دلار'
                                    generateOnChange={(value) =>
                                        toEnglishNumber(value.replace(/[^0-9\u06F0-\u06F9]/g, ''))
                                    }
                                    generateValue={(value) => addCommas(value?.toString() ?? '')}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='w-1/4 flex-col flex gap-2'>
                        <div className='bg-white p-5 flex flex-col gap-1'>
                            <div className='flex justify-between gap-px items-center'>
                                <div className='text-[0.875rem] text-gray-700'>وضعیت</div>
                                <CustomSwitch
                                    control={control}
                                    name='is_active'
                                />
                            </div>

                            <div className='flex justify-between gap-px items-center'>
                                <div className='text-[0.875rem] text-gray-700'>نمایش</div>
                                <CustomSwitch
                                    control={control}
                                    name='is_featured'
                                />
                            </div>
                        </div>

                        <div className='bg-white p-5 px-[1.125rem]'>
                            <CustomTextField<BundleType>
                                readOnly={true}
                                helperText='این فیلد قابل ویرایش نیست'
                                label='لینک انگلیسی بسته'
                                name='url'
                                control={control}
                                inputRef={inputRefToBeCopied}
                                placeholder='فقط به انگلیسی'
                                generateOnChange={(value) => value.replace(/[^A-Za-z0-9-?/&:=.]/gi, '')}
                                className='iransans_without_numbers_class opacity-50'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            position='start'
                                            className='cursor-pointer'
                                            onClick={copyToClipboard}
                                        >
                                            <DocumentCopy className='shadow-[8px_0px_16px_8px_#fff]' />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>

                        <div className='bg-white p-5 px-[1.125rem] flex justify-center items-center gap-4 flex-col'>
                            <div className='grid grid-cols-2 w-full gap-y-3 gap-x-4'>
                                <Controller
                                    name='image'
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <UploadSection
                                            className='col-span-2'
                                            value={value}
                                            type={'IMAGE'}
                                            onChange={onChange}
                                            title={'انتخاب تصویر'}
                                        />
                                    )}
                                />

                                <Controller
                                    name='bg'
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <UploadSection
                                            value={value}
                                            type={'IMAGE'}
                                            title={'انتخاب BG'}
                                            onChange={onChange}
                                        />
                                    )}
                                />

                                <Controller
                                    name='thumbnail'
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <UploadSection
                                            value={value}
                                            type={'IMAGE'}
                                            title={'Thumbnail'}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex gap-4 mt-5'>
                    <Button
                        disabled={isLoading}
                        type='submit'
                        className='w-[9.375rem] text-gray-50'
                        size={ButtonSizes.bigger}
                    >
                        {isLoading ? (
                            <Refresh className='animate-spin' />
                        ) : type === 'ADD' ? (
                            'ثبت نهایی'
                        ) : (
                            'ثبت نهایی ویرایش'
                        )}
                    </Button>

                    <Button
                        type='button'
                        size={ButtonSizes.big}
                        className='w-[9.375rem]'
                        variant={ButtonVariants.gray}
                        onClick={() => navigate('/cms/bundles')}
                    >
                        انصراف
                    </Button>
                </div>
            </form>
        </FormProvider>
    )
}
