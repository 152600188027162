import { useCallback } from 'react'
import * as XLSX from 'xlsx'

function useExcelExport<T>(tableData: T[]) {
    const exportToExcel = useCallback(() => {
        const worksheet = XLSX.utils.json_to_sheet(tableData)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
        XLSX.writeFile(workbook, 'DataGridData.xlsx')
    }, [tableData])

    return { exportToExcel }
}

export default useExcelExport
