import { FC, useMemo } from 'react'
import MemberForm from '../components/member-form'
import { useQuery } from '@tanstack/react-query'
import { getMemberDetail } from '../api'
import { useSearchParams } from 'react-router-dom'
import CMSLayout from '../../../../_core/components/layouts/cms-dashboard/CMSLayout'

const UpdateMember: FC = () => {
    const [searchParams] = useSearchParams()

    const id = useMemo(() => parseInt(searchParams.get('id') ?? '-1'), [searchParams])

    const { data, isPending } = useQuery({
        queryKey: ['members', id],
        queryFn: () => getMemberDetail(id),
        refetchOnWindowFocus: false,
        select: ({ introductionMethod, ...data }) => ({
            introductionMethod: introductionMethod?.split('|'),
            ...data,
        }),
    })

    if (isPending)
        return (
            <CMSLayout>
                <div className='w-full h-full flex justify-center items-start'>
                    <span className='loader' />
                </div>
            </CMSLayout>
        )

    return (
        <MemberForm
            type='UPDATE'
            member={data}
        />
    )
}

export default UpdateMember
