import { createSlice } from '@reduxjs/toolkit'
import { UserAccessType } from '../../components/model/user-access-type'
import type { PayloadAction } from '@reduxjs/toolkit'

export type InitialState = {
    value: UserAccessType[] | undefined
}

const initialState: InitialState = {
    value: undefined,
}

export const userAccessSlice = createSlice({
    name: 'userAccess',
    initialState,
    reducers: {
        updateUserAccess: (state, action: PayloadAction<UserAccessType[] | undefined>) => {
            state.value = action.payload
        },
    },
})

export const { updateUserAccess } = userAccessSlice.actions
export default userAccessSlice.reducer
