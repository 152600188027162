import { GridRowId } from '@mui/x-data-grid'
import { Test, TestDetailServer } from '../data-types'
import { AgeRangeCategories } from '../data-types/age-range-categories'

export async function getTests(): Promise<Test[]> {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/get_tests.php', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
        })
        const data = await response.json()
        return data.data
    } catch (error) {
        console.error('Failed to fetch initial data:', error)
        return []
    }
}

export function deleteTests(testsToDelete: GridRowId[]) {
    return Promise.all(
        testsToDelete.map(async (testID) => {
            try {
                const response = await fetch('https://stage2-api.insight-clinic.com/controller/delete_test.php', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        AuthorizationKey: localStorage.getItem('token') ?? '',
                    },
                    body: JSON.stringify({
                        id: parseInt(testID.toString(), 10),
                    }),
                })
                const data = await response.json()

                return data
            } catch (error) {
                console.error('Failed to fetch initial data:', error)

                return undefined
            }
        }),
    )
}

export async function getTestDetail(id: number): Promise<TestDetailServer> {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/get_test.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify({
                id,
            }),
        })
        const data = await response.json()
        return data.data
    } catch (error) {
        console.error('Failed to fetch initial data:', error)
        throw error
    }
}

export async function addTestDetail(data: TestDetailServer) {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/add_test.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify(data),
        })
        const processedRes = await response.json()
        if (processedRes.status !== 'success')
            throw new Error(processedRes.message ?? processedRes.code ?? 'SERVER_ERROR')
    } catch (error) {
        throw error
    }
}

export async function updateTestDetail(data: TestDetailServer) {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/update_test.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify(data),
        })
        const processedRes = await response.json()
        if (processedRes.status !== 'success')
            throw new Error(processedRes.message ?? processedRes.code ?? 'SERVER_ERROR')
    } catch (error) {
        throw error
    }
}

export async function getAgeRangeCategories(): Promise<AgeRangeCategories> {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/get_ageRanges_categories.php', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
        })

        const data = await response.json()
        return data.data
    } catch (error) {
        throw error
    }
}
