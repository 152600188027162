import { Dialog } from '@mui/material'
import { type FC, useEffect } from 'react'
import { CloseCircle } from 'iconsax-react'
import Button, { ButtonSizes, ButtonVariants } from '../../../../../_core/components/button'
import useDelayedState from '../../../../../_core/components/hooks/use-delayed-state'
import addQuote from '../../../../../utils/add-quote'

interface CustomDialogProps {
    open: boolean
    accept: () => void
    onClose: () => void
    itemToBeDeleted?: string
    hint?: string
    title?: string
    itemToBeDeletedType?: string
    generateMessage?: (itemsToBeDeleted: string) => string
    acceptButtonsTitle?: string
}

const DeleteConfirmDialog: FC<CustomDialogProps> = ({
    open,
    onClose,
    accept,
    itemToBeDeleted,
    hint,
    title,
    generateMessage,
    acceptButtonsTitle,
    itemToBeDeletedType,
}) => {
    const [itemsToBeDeleted, setItemsToBeDeleted, setItemsToBeDeletedInstantly] = useDelayedState(
        addQuote(itemToBeDeleted) ?? `همه ${itemToBeDeletedType ?? 'کاربران'}`,
        225,
    )

    useEffect(() => {
        if (itemToBeDeleted) setItemsToBeDeletedInstantly(addQuote(itemToBeDeleted) ?? '')
        else setItemsToBeDeleted(`همه ${itemToBeDeletedType ?? 'کاربران'}`)
    }, [setItemsToBeDeleted, setItemsToBeDeletedInstantly, itemToBeDeleted, itemToBeDeletedType])

    return (
        <Dialog
            onClose={onClose}
            open={open}
            style={{ zIndex: 99999 }}
        >
            <div className='flex flex-col p-4 bg-white rounded w-[23.0625rem] min-h-[14.0625rem]'>
                <div className='flex justify-between items-center p-2 pb-4'>
                    <div className='w-6' />
                    <div className='font-medium text-alarm-500 text-base'>
                        {title ?? `حذف ${itemToBeDeletedType ?? 'کاربران'}`}
                    </div>
                    <button>
                        <CloseCircle
                            onClick={onClose}
                            className='text-gray-700'
                            width={24}
                            height={24}
                        />
                    </button>
                </div>

                {hint && <div className='text-gray-800 text-xs pb-3 text-center'>{hint}</div>}

                <div className='border-b border-gray-100' />

                <div className='text-gray-800 text-lg grow flex items-center justify-center py-2'>
                    {generateMessage?.(itemsToBeDeleted) ?? `آیا از حذف ${itemsToBeDeleted} مطمئن هستید؟`}
                </div>
                <div className='flex gap-4'>
                    <Button
                        aClassName='w-full flex'
                        size={ButtonSizes.big}
                        className='grow'
                        variant={ButtonVariants.danger}
                        onClick={() => {
                            accept()
                            onClose()
                        }}
                    >
                        {acceptButtonsTitle ?? 'حذف'}
                    </Button>
                    <Button
                        aClassName='w-full flex'
                        size={ButtonSizes.big}
                        className='grow'
                        variant={ButtonVariants.neutral}
                        onClick={onClose}
                    >
                        انصراف
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}

export default DeleteConfirmDialog
