import type { FC } from 'react'

interface CellWithProfileProps {
    value: string
    initial: string
    color: string
    image: string
}

const CellWithProfile: FC<CellWithProfileProps> = ({ image, color = '#000', initial, value }) => {
    return (
        <div
            className='flex gap-3 items-center w-full h-full'
            title={value}
        >
            <div
                className='uppercase relative overflow-hidden bg-primary-500 rounded-full w-6 h-6 flex pt-[3px] items-center justify-center text-white text-[0.625rem] shrink-0'
                style={{ backgroundColor: color }}
            >
                {initial}
                {image && image !== 'Array' && (
                    <img
                        className='absolute inset-0 w-full h-full object-cover object-center'
                        src={image}
                        alt={value}
                    />
                )}
            </div>
            <div className='truncate text-sm text-gray-800'>{value}</div>
        </div>
    )
}

export default CellWithProfile
