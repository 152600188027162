import CMSLayout from '../../../../_core/components/layouts/cms-dashboard/CMSLayout'
import { useMemo } from 'react'
import { BundleForm } from '../_components/BundleForm'
import { useSearchParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getBundleDetails } from '../api'

const UpdateBundlePage = () => {
    const [searchParams] = useSearchParams()

    const id = useMemo(() => parseInt(searchParams.get('id') ?? '-1'), [searchParams])

    const { data, isFetching } = useQuery({
        queryKey: ['bundles', id],
        queryFn: () => getBundleDetails(id),
        gcTime: 0,
        staleTime: 0,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        select: ({ test_ids, measurements, categories, ...data }) => ({
            test_ids: test_ids?.split('|') ?? [],
            measurements: measurements.split('|') ?? [],
            categories: categories.split('|') ?? [],
            ...data,
        }),
    })

    if (isFetching)
        return (
            <CMSLayout>
                <div className='w-full h-full flex justify-center items-start'>
                    <span className='loader'></span>
                </div>
            </CMSLayout>
        )

    return (
        <CMSLayout>
            <BundleForm
                type='UPDATE'
                bundle={data}
            />
        </CMSLayout>
    )
}

export default UpdateBundlePage
