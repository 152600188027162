import { GridRowId } from '@mui/x-data-grid'
import { Edit2, Trash } from 'iconsax-react'
import Button, { ButtonVariants, ButtonSizes } from '../../../../_core/components/button'
import { Column, Sizes } from '../../../../_core/components/table/column'
import { cn } from '../../../../utils/cn'
import { Metric, Question } from '../data-types'
// import getDate from '../../../../utils/get-date'

export const getMetricsColumns = (
    selectionList: GridRowId[],
    onClick: (id?: number) => void,
    onEditMetrics: (id?: number) => void,
) => [
    Column<Metric>({
        field: 'title',
        headerName: 'عنوان معیار',
        valueGetter: (_, row) => row.title ?? '',
        size: Sizes.big,
        align: 'left',
    }),
    Column<Metric>({
        field: 'english',
        headerName: 'معادل انگلیسی',
        valueGetter: (_, row) => row.english ?? '',
    }),
    Column<Metric>({
        field: 'url',
        headerName: 'URL',
        valueGetter: (_, row) => row.url ?? '',
    }),
    Column<Metric>({
        field: 'show',
        headerName: 'نمایش',
        valueGetter: (_, row) => (row.is_featured ? 'فعال' : 'غیر فعال'),
        renderCell: (props) => (
            <div
                className={cn(
                    'truncate text-sm h-full flex items-center justify-center w-full',
                    props.row.is_featured ? 'text-success-500' : 'text-gray-800',
                )}
                title={props.value}
            >
                {props.value}
            </div>
        ),
    }),
    Column<Metric>({
        field: 'image',
        headerName: 'تصویر',
        valueGetter: (_, row) => row.image ?? '',
    }),
    Column<Metric>({
        field: 'icon',
        headerName: 'آیکون',
        valueGetter: (_, row) => row.icon ?? '',
        renderCell: (props) => (
            <div className='w-full h-full flex items-center justify-center'>
                <img
                    src={props.value}
                    alt='icon'
                    className='w-9 h-9 object-contain object-center bg-gray-100 rounded-lg overflow-hidden text-[0px]'
                />
            </div>
        ),
    }),
    Column<Metric>({
        field: 'actions',
        headerName: '',
        valueGetter: () => '',
        renderHeader: () => (
            <>
                <Button
                    variant={ButtonVariants.danger}
                    size={ButtonSizes.small}
                    onClick={() => onClick()}
                    className={cn('mr-auto', (selectionList?.length ?? 0) > 0 ? 'flex' : 'hidden')}
                >
                    حذف همه
                </Button>
            </>
        ),
        renderCell: (props) => (
            <div className='flex items-center justify-center gap-8 w-full h-full text-gray-700'>
                <button
                    title='Edit'
                    className='flex justify-center'
                    onClick={() => {
                        onEditMetrics(props.row.id)
                    }}
                >
                    <Edit2
                        width={24}
                        height={24}
                    />
                </button>
                <button
                    title='Delete'
                    onClick={() => onClick(props.row.id)}
                >
                    <Trash
                        width={24}
                        height={24}
                    />
                </button>
            </div>
        ),
        sortable: false,
    }),
]

export const getQuestionsColumns = (
    selectionList: GridRowId[],
    onClick: (id?: number) => void,
    onEditQuestions: (id?: number) => void,
) => [
    Column<Question>({
        field: 'title',
        headerName: 'عنوان معیار',
        valueGetter: (_, row) => row.title ?? '',
        size: Sizes.big,
        align: 'left',
    }),
    Column<Question>({
        field: 'english',
        headerName: 'معادل انگلیسی',
        valueGetter: (_, row) => row.english ?? '',
    }),
    Column<Question>({
        field: 'url',
        headerName: 'URL',
        valueGetter: (_, row) => row.url ?? '',
    }),
    Column<Question>({
        field: 'show',
        headerName: 'نمایش',
        valueGetter: (_, row) => (row.is_featured ? 'فعال' : 'غیر فعال'),
        renderCell: (props) => (
            <div
                className={cn(
                    'truncate text-sm h-full flex items-center justify-center w-full',
                    props.row.is_featured ? 'text-success-500' : 'text-gray-800',
                )}
                title={props.value}
            >
                {props.value}
            </div>
        ),
    }),
    Column<Question>({
        field: 'image',
        headerName: 'تصویر',
        valueGetter: (_, row) => row.image ?? '',
    }),
    Column<Question>({
        field: 'icon',
        headerName: 'آیکون',
        valueGetter: (_, row) => row.icon ?? '',
        renderCell: (props) => (
            <div className='w-full h-full flex items-center justify-center'>
                <img
                    src={props.value}
                    alt='icon'
                    className='w-9 h-9 object-contain object-center bg-gray-100 rounded-lg overflow-hidden text-[0px]'
                />
            </div>
        ),
    }),
    Column<Question>({
        field: 'actions',
        headerName: '',
        valueGetter: () => '',
        renderHeader: () => (
            <>
                <Button
                    variant={ButtonVariants.danger}
                    size={ButtonSizes.small}
                    onClick={() => onClick()}
                    className={cn('mr-auto', (selectionList?.length ?? 0) > 0 ? 'flex' : 'hidden')}
                >
                    حذف همه
                </Button>
            </>
        ),
        renderCell: (props) => (
            <div className='flex items-center justify-center gap-8 w-full h-full text-gray-700'>
                <button
                    title='Edit'
                    className='flex justify-center'
                    onClick={() => {
                        onEditQuestions(props.row.id)
                    }}
                >
                    <Edit2
                        width={24}
                        height={24}
                    />
                </button>
                <button
                    title='Delete'
                    onClick={() => onClick(props.row.id)}
                >
                    <Trash
                        width={24}
                        height={24}
                    />
                </button>
            </div>
        ),
        sortable: false,
    }),
]
