import Button, { ButtonVariants } from '../../../../_core/components/button'
import Table from '../../../../_core/components/table'
import { SearchNormal1 } from 'iconsax-react'
import { Dispatch, FC, ReactNode, SetStateAction, useCallback, useMemo } from 'react'
import { Plus } from '../../../../_core/components/icons'
import CMSLayout from '../../../../_core/components/layouts/cms-dashboard/CMSLayout'
import useExcelExport from './hooks/use-excel-export'
import useSearch from './hooks/use-search'
import useListPage, { DialogData } from './hooks/use-list-page'
import { GridCallbackDetails, GridColDef, GridRowId, GridRowSelectionModel } from '@mui/x-data-grid'
import { GridBaseColDef } from '@mui/x-data-grid/internals'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../_core/redux/store'
import { Fade, LinearProgress } from '@mui/material'

interface ListPageProps<T extends { [key: string]: any }, V = any> {
    apiFunction: (page?: number) => Promise<T[] | unknown>
    generateDialog: (
        dialogData: DialogData,
        setDialogData: Dispatch<SetStateAction<DialogData>>,
        processedRows: T[],
        selectionList: GridRowId[] | undefined,
    ) => ReactNode
    getColumns?: (selectionList: GridRowId[], updateDialogOpen: (id?: GridRowId) => void) => GridColDef<T>[]
    itemName: string
    addPageLink: string | (() => void)
    queryKey: string[]
    generateID?: (row: T) => GridRowId
    generateRows?: (rows: unknown) => T[]
    generateTitle?: ReactNode | FC<V>
    pagination?: boolean
    HeaderComponent?: FC<V>
    accessName: string

    getColumnMetrics?: (
        selectionList: GridRowId[],
        onClick: (id?: number) => void,
        onEditMetrics: (id?: number) => void,
    ) => GridBaseColDef<T, any, any>[]
    getColumnQuestions?: (
        selectionList: GridRowId[],
        onClick: (id?: number) => void,
        onEditQuestions: (id?: number) => void,
    ) => GridBaseColDef<T, any, any>[]
    isDictionaryMetrics?: boolean
    isDictionaryQuestions?: boolean
}

function ListPage<T extends { [key: string]: any }, V = any>({
    generateDialog,
    itemName,
    apiFunction,
    getColumns,
    addPageLink,
    queryKey,
    generateID = (row) => row.id,
    generateRows,
    generateTitle: GenerateTitle,
    pagination,
    HeaderComponent,
    accessName,

    getColumnMetrics,
    getColumnQuestions,
    isDictionaryMetrics,
    isDictionaryQuestions,
}: ListPageProps<T, V>) {
    const {
        columns,
        processedRows,
        dialogData,
        isPending,
        updateSelection,
        selectionList,
        setDialogData,
        currentPage,
        setCurrentPage,
        rawData,
        isFetching,
    } = useListPage<T>({
        apiFunction,
        getColumns,
        queryKey,
        generateID,
        generateRows,

        getColumnMetrics,
        getColumnQuestions,
        isDictionaryMetrics,
        isDictionaryQuestions,
    })

    const userAccessData = useSelector((state: RootState) => state.userAccess.value)
    const addAccess = useMemo(
        () => userAccessData?.find((itm) => itm.formName === accessName)?.add,
        [userAccessData, accessName],
    )

    const { getSearchResult, setSearchedValue } = useSearch<T>()

    const { exportToExcel } = useExcelExport<T>(processedRows)

    const handleNext = useCallback(() => setCurrentPage(currentPage + 1), [currentPage, setCurrentPage])
    const handlePrevious = useCallback(() => setCurrentPage(currentPage - 1), [currentPage, setCurrentPage])

    return (
        <CMSLayout className='flex flex-col'>
            <div className='flex mb-6 gap-2'>
                {addAccess && GenerateTitle === undefined && (
                    <Button
                        {...(typeof addPageLink !== 'string' && { onClick: addPageLink })}
                        {...(typeof addPageLink === 'string' && { href: addPageLink })}
                        icon={<Plus />}
                    >
                        {itemName} جدید
                    </Button>
                )}

                {typeof GenerateTitle !== 'function' && GenerateTitle}

                {typeof GenerateTitle === 'function' && <GenerateTitle {...(rawData as JSX.IntrinsicAttributes & V)} />}

                <div className='grow' />

                <div className='relative'>
                    <input
                        className='w-[18.375rem] bg-white rounded p-3 pr-11 placeholder:text-gray-300 placeholder:text-sm text-gray-700 text-sm disabled:opacity-50'
                        placeholder='جستجو کنید...'
                        onChange={(e) => setSearchedValue(e.target.value)}
                        disabled={isPending}
                    />
                    <SearchNormal1
                        width={22}
                        height={22}
                        className='text-gray-300 absolute inset-y-0 my-auto right-3'
                    />
                </div>

                <button
                    className='py-1 px-5 bg-white rounded text-gray-600 text-sm disabled:opacity-50'
                    onClick={exportToExcel}
                    disabled={isPending}
                >
                    خروجی اکسل
                </button>
            </div>

            {HeaderComponent && rawData !== undefined && (
                <HeaderComponent {...(rawData as JSX.IntrinsicAttributes & V)} />
            )}

            <Fade
                in={isPending === false ? isFetching : false}
                timeout={{ enter: 0, exit: 1000 }}
            >
                <LinearProgress sx={{ scale: '-1 1' }} />
            </Fade>
            <Table
                rowSelectionModel={selectionList}
                onRowSelectionModelChange={
                    updateSelection as unknown as (
                        rowSelectionModel: GridRowSelectionModel,
                        details: GridCallbackDetails,
                    ) => void
                }
                loading={isPending}
                getRowId={(row) => generateID(row)}
                rows={isPending ? [] : getSearchResult(processedRows)}
                columns={columns ?? []}
                checkboxSelection={true}
                noFooter={pagination}
                className='list-page-table'
            />

            {pagination && (
                <div className='h-[3.75rem] gap-3 text-gray-700 font-medium py-[1.125rem] px-6 w-full shrink-0 flex items-center justify-end bg-white shadow-[0_-4px_0] shadow-primary-50 relative'>
                    <Button
                        onClick={handlePrevious}
                        variant={ButtonVariants.gray}
                        disabled={currentPage === 0}
                        className='disabled:opacity-50'
                    >
                        صفحه قبل
                    </Button>
                    <div>صفحه: {currentPage + 1}</div>
                    <Button onClick={handleNext}>صفحه بعد</Button>
                </div>
            )}

            {generateDialog?.(dialogData, setDialogData, processedRows, selectionList)}
        </CMSLayout>
    )
}

export default ListPage

// TODO check access
