import { GridRowId } from '@mui/x-data-grid'
import { Faq } from '../data-types'

export async function getFaqs(): Promise<Faq[]> {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/get_faqs.php', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
        })
        const data = (await response.json()).data as Faq[]
        return data.map((item, index) => ({ ...item, index: data.length - index }))
    } catch (error) {
        console.error('Failed to fetch initial data:', error)
        return []
    }
}

export async function getFaq(id: number): Promise<Faq> {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/get_faq.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify({
                id,
            }),
        })
        const data = await response.json()
        console.log(data)
        return data.data
    } catch (error) {
        console.error('Failed to fetch initial data:', error)
        throw error
    }
}

export function deleteFaqs(faqsToDelete: GridRowId[]) {
    return Promise.all(
        faqsToDelete.map(async (faqID) => {
            try {
                const response = await fetch('https://stage2-api.insight-clinic.com/controller/delete_faq.php', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        AuthorizationKey: localStorage.getItem('token') ?? '',
                    },
                    body: JSON.stringify({
                        id: parseInt(faqID.toString(), 10),
                    }),
                })
                const data = await response.json()

                return data
            } catch (error) {
                console.error('Failed to fetch initial data:', error)

                return undefined
            }
        }),
    )
}

export async function addFaqDetail(data: Faq) {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/add_faq.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify(data),
        })
        const processedRes = await response.json()
        if (processedRes.status !== 'success')
            throw new Error(processedRes.message ?? processedRes.code ?? 'SERVER_ERROR')
    } catch (error) {
        throw error
    }
}

export async function updateFaqDetail(data: Faq) {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/update_faq.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify(data),
        })
        const processedRes = await response.json()
        if (processedRes.status !== 'success')
            throw new Error(processedRes.message ?? processedRes.code ?? 'SERVER_ERROR')
    } catch (error) {
        throw error
    }
}

export async function MoveFaq({ id, row }: { id: number; row: number }) {
    try {
        const response = await fetch('https://stage2-api.insight-clinic.com/controller/set_faq_order.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                AuthorizationKey: localStorage.getItem('token') ?? '',
            },
            body: JSON.stringify({ id, row }),
        })
        const processedRes = await response.json()
        if (processedRes.status !== 'success')
            throw new Error(processedRes.message ?? processedRes.code ?? 'SERVER_ERROR')
    } catch (error) {
        throw error
    }
}
