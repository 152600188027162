import { useFieldArray, useFormContext } from 'react-hook-form'
import { ArticleType } from './ArticleForm'
import { useCallback } from 'react'
import { AddCircle } from 'iconsax-react'
import { CustomTextField } from '../../AddUser/components/text-field'
import { DeleteButton } from '../../../../_core/components/icons'

const ResourcesSection = () => {
    const { control, getValues, setValue } = useFormContext<ArticleType>()

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'resources',
    })

    const updateIds = useCallback(() => {
        const currentValues = getValues('resources')

        currentValues?.forEach((item, index) => {
            if (item) item.id = index + 1
        })

        setValue('resources', currentValues)
    }, [getValues, setValue])

    return (
        <div className='flex flex-col gap-4'>
            <button
                type='button'
                onClick={() => append({ title: '', link: '', id: fields.length })}
                className='flex items-center gap-3 w-fit'
            >
                <AddCircle
                    size={30}
                    variant='Bold'
                    className='text-primary-500'
                />

                <div className='text-gray-700 text-sm'>افزودن منبع</div>
            </button>

            {fields.map((resource, index) => (
                <div
                    key={resource.id}
                    className='grid grid-cols-4 gap-3'
                >
                    <CustomTextField<ArticleType>
                        label='عنوان منبع'
                        control={control}
                        name={`resources.${index}.title`}
                    />

                    <CustomTextField<ArticleType>
                        containerClassName='col-span-3'
                        label='وب سایت‌'
                        control={control}
                        name={`resources.${index}.link`}
                        InputProps={{
                            endAdornment: (
                                <button
                                    type='button'
                                    onClick={() => {
                                        remove(index)
                                        updateIds()
                                    }}
                                >
                                    <DeleteButton />
                                </button>
                            ),
                        }}
                    />
                </div>
            ))}
        </div>
    )
}

export default ResourcesSection
