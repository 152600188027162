import { z } from './zod-translation'

interface zFileOptions {
    maxSize: number
    allowedTypes: string[]
    sizeError: string
    typeError: string
}

function zFile({ maxSize, sizeError, allowedTypes, typeError }: zFileOptions) {
    return z
        .union([z.undefined(), z.instanceof(File)])
        .refine((file) => (file ? allowedTypes.includes(file.type) : true), typeError)
        .refine((file) => (file ? file.size <= maxSize : true), sizeError)
}

export default zFile
