import { Controller, FieldValues, Path, PathValue, useFormContext } from 'react-hook-form'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { TextFieldWithoutControl } from '../AddUser/components/text-field'
import { useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'

interface MUITimePickerProps<T extends FieldValues> {
    name: Path<T>
    label?: string
}

function MUITimePicker<T extends FieldValues>({ name, label }: MUITimePickerProps<T>) {
    const { control, setValue } = useFormContext<T>()
    const [open, setOpen] = useState(false)

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
                control={control}
                name={name}
                render={({ field: { value } }) => (
                    <TimePicker
                        label={label}
                        format='HH:mm'
                        value={value ? dayjs(value, 'HH:mm') : null}
                        onChange={(newValue: Dayjs | null) => {
                            const formattedTime = newValue ? newValue.format('HH:mm') : ''
                            setValue(name, formattedTime as PathValue<T, Path<T>>)
                        }}
                        open={open}
                        onClose={() => setOpen(false)}
                        closeOnSelect={false}
                        slots={{
                            textField: TextFieldWithoutControl,
                        }}
                        ampm={false}
                        views={['minutes', 'hours']}
                        slotProps={{
                            desktopPaper: { className: 'custom-time-picker' },
                            popper: { placement: 'bottom-end' },
                            actionBar: { actions: [] },
                            textField: {
                                spellCheck: false,
                                onClick: () => setOpen(true),
                                InputProps: { endAdornment: null },
                            },
                        }}
                    />
                )}
            />
        </LocalizationProvider>
    )
}

export default MUITimePicker
