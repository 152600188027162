import { cn } from '../../../../utils/cn'
import { useNavigate } from 'react-router-dom'
import { UploadSection } from '../../_components/UploadSection'
import { TherapistsSection } from './TherapistsSection'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { DocumentCopy, Refresh } from 'iconsax-react'
import { InputAdornment } from '@mui/material'
import { CustomAutocomplete, CustomTextField } from '../../AddUser/components/text-field'
import CustomSwitch from '../../AddUser/components/switch'
import useCopyToClipboard from '../../../../_core/components/hooks/use-copy'
import Button, { ButtonSizes, ButtonVariants } from '../../../../_core/components/button'
import CustomRichTextBox from '../../_components/CustomRichTextBox'
import addCommas from '../../../../utils/add-commas'
import { z } from '../../../../utils/zod-translation'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery } from '@tanstack/react-query'
import { DialogTypes, useDialogStore } from '../../_components/Dialog'
import {
    addWorkshop as addWorkshopAPI,
    getCategories,
    getKeywords,
    getMeasurements,
    updateWorkshop as updateWorkshopAPI,
} from '../api'
import { useCallback, useMemo } from 'react'
import zStringToNumber from '../../../../utils/z-string-to-number'
import DatetimeSection from './DatetimeSection'
import SyllabiSection from './SyllabiSection'

export const WorkshopSchema = z.object({
    id: z.coerce.number().optional(),
    title: z.coerce.string(),
    session_count: zStringToNumber(),
    session_duration: zStringToNumber(),
    capacity: zStringToNumber().nullable(),
    timezone: z.coerce.string(),
    datetime: z.array(
        z.object({
            id: z.coerce.number(),
            value: z.coerce.string(),
        }),
    ),
    therapists: z.array(z.coerce.number()),
    description: z.coerce.string(),
    categories: z.coerce.string(),
    measurements: z.coerce.string(),
    syllabi: z.array(
        z.object({
            id: z.coerce.number(),
            value: z.coerce.string(),
        }),
    ),
    audience: z.coerce.string(),
    why: z.coerce.string(),
    price_rial: zStringToNumber(),
    price_dollar: zStringToNumber(),
    discount: zStringToNumber(),
    keywords: z.coerce.string(),
    meta_description: z.coerce.string(),
    url: z.coerce.string(),
    is_active: z.coerce.boolean(),
    is_featured: z.coerce.boolean(),
    image: z.coerce.string(),
    bg: z.coerce.string(),
    thumbnail: z.coerce.string(),
})

export type WorkshopType = z.infer<typeof WorkshopSchema>

export type WorkshopTypeServer = Omit<z.infer<typeof WorkshopSchema>, 'datetime' | 'syllabi'> & {
    datetime: string[]
    syllabi: string
}

type WorkshopFormType = {
    workshop?: WorkshopType
    type: 'ADD' | 'UPDATE'
    isLoading?: boolean
}

export const WorkshopForm = ({ workshop, type }: WorkshopFormType) => {
    const navigate = useNavigate()

    const { control, handleSubmit, watch, ...otherFormThings } = useForm<WorkshopType>({
        defaultValues: workshop,
        resolver: zodResolver(WorkshopSchema),
    })

    const { setDialogState } = useDialogStore()

    const { mutate: addWorkshop, isPending: isAddingWorkshopPending } = useMutation({
        mutationKey: ['workshops'],
        mutationFn: addWorkshopAPI,
        onSuccess: () =>
            setDialogState({
                open: true,
                title: 'ثبت کارگاه جدید',
                type: DialogTypes.success,
                description: 'کارگاه شما با موفقیت ثبت شد.',
            }),
        onError: () =>
            setDialogState({
                open: true,
                title: 'خطا در ثبت کارگاه',
                type: DialogTypes.error,
                description: 'کارگاه ثبت نشد.',
            }),
    })

    const { mutate: updateWorkshop, isPending: isUpdatingWorkshopPending } = useMutation({
        mutationKey: ['workshops'],
        mutationFn: updateWorkshopAPI,
        onSuccess: () =>
            setDialogState({
                open: true,
                title: 'ویرایش کارگاه',
                type: DialogTypes.success,
                description: 'اطلاعات کارگاه با موفقیت ویرایش شد.',
            }),
        onError: () =>
            setDialogState({
                open: true,
                title: 'خطا در ویرایش کارگاه',
                type: DialogTypes.error,
                description: 'کارگاه ویرایش نشد.',
            }),
    })

    const submitForm = useCallback(
        ({ datetime, syllabi, ...data }: WorkshopType) => {
            const transformedData = {
                ...data,
                datetime: datetime.map((item) => item.value),
                syllabi: syllabi.map((item) => item.value).join('|'),
            }

            if (type === 'ADD') addWorkshop(transformedData)
            if (type === 'UPDATE') updateWorkshop(transformedData)
        },
        [addWorkshop, type, updateWorkshop],
    )

    const { data: categories, isPending: isPendingCategories } = useQuery({
        queryKey: ['categories'],
        queryFn: getCategories,
        staleTime: 0,
        gcTime: 5 * 60 * 1000,
    })

    const { data: measurements, isPending: isPendingMeasurements } = useQuery({
        queryKey: ['measurements'],
        queryFn: getMeasurements,
        staleTime: 0,
        gcTime: 5 * 60 * 1000,
    })

    const { data: keywords, isPending: isPendingKeywords } = useQuery({
        queryKey: ['keywords'],
        queryFn: getKeywords,
        staleTime: 0,
        gcTime: 5 * 60 * 1000,
    })

    const [inputRefToBeCopied, copyToClipboard] = useCopyToClipboard()

    const pending = useMemo(
        () => isAddingWorkshopPending || isUpdatingWorkshopPending,
        [isAddingWorkshopPending, isUpdatingWorkshopPending],
    )

    return (
        <FormProvider
            control={control}
            handleSubmit={handleSubmit}
            watch={watch}
            {...otherFormThings}
        >
            <form
                onSubmit={handleSubmit(submitForm, () =>
                    setDialogState({
                        open: true,
                        title: 'خطا در ویرایش کارگاه',
                        type: DialogTypes.error,
                        description: 'فرم را کامل کنید.',
                    }),
                )}
                className={cn('flex flex-col gap-5', pending && '*:opacity-50 *:pointer-events-none')}
            >
                <div className='text-sm mb-5'>
                    <span className='text-gray-400'>کارگاه روانشناسی / </span>
                    <span className='text-gray-600 font-medium'>
                        {type === 'ADD' ? 'کارگاه جدید' : 'ویرایش کارگاه'}
                    </span>
                </div>

                <div className='flex gap-4'>
                    <div className='basis-3/4 flex flex-col gap-3'>
                        <div className='bg-white p-7 flex flex-col gap-10'>
                            <CustomTextField<WorkshopType>
                                label='عنوان کارگاه'
                                name='title'
                                control={control}
                            />

                            <div className='flex gap-6 *:grow'>
                                <CustomTextField<WorkshopType>
                                    control={control}
                                    name='session_count'
                                    label='تعداد جلسات'
                                    adornment='جلسه'
                                    generateOnChange={(value) => value.replace(/[^0-9\u06F0-\u06F9]/g, '')}
                                />

                                <CustomTextField<WorkshopType>
                                    control={control}
                                    name='session_duration'
                                    generateOnChange={(value) => value.replace(/[^0-9\u06F0-\u06F9]/g, '')}
                                    label='مدت زمان هر جلسه'
                                    adornment='دقیقه'
                                />

                                <CustomTextField<WorkshopType>
                                    name='capacity'
                                    generateOnChange={(value) => value.replace(/[^0-9\u06F0-\u06F9]/g, '')}
                                    label='گنجایش'
                                    control={control}
                                    adornment='نفر'
                                />
                            </div>

                            <DatetimeSection />
                        </div>

                        <div className='bg-white p-7 flex flex-col gap-7'>
                            <TherapistsSection />
                        </div>

                        <div className='bg-white p-7 flex flex-col gap-10'>
                            <CustomRichTextBox<WorkshopType>
                                control={control}
                                name='description'
                                label='شرح'
                            />

                            <CustomAutocomplete<WorkshopType>
                                control={control}
                                name='categories'
                                multiple
                                options={categories ?? []}
                                label='دسته بندی'
                                isPending={isPendingCategories}
                            />

                            <CustomAutocomplete<WorkshopType>
                                control={control}
                                name='measurements'
                                multiple
                                options={measurements ?? []}
                                label='مقادیر اندازه‌گیری'
                                isPending={isPendingMeasurements}
                            />

                            <CustomTextField<WorkshopType>
                                label='مخاطبین کارگاه'
                                name='audience'
                                control={control}
                                placeholder='زوج‌های دارای فرزند, و دانشجویان رشته‌های مشاوره می‌توانند در این دوره شرکت کنند.'
                            />

                            <CustomTextField<WorkshopType>
                                label='چرا این کارگاه'
                                name='why'
                                control={control}
                                placeholder='کمک به والدین برای تربیت و درک بهتر فرزندان'
                                multiline
                                rows={2}
                            />

                            <CustomAutocomplete<WorkshopType>
                                multiple
                                options={keywords ?? []}
                                control={control}
                                name='keywords'
                                label='کلمات کلیدی'
                                isPending={isPendingKeywords}
                            />

                            <CustomTextField<WorkshopType>
                                name='meta_description'
                                control={control}
                                label='Meta Description'
                            />

                            <SyllabiSection />
                        </div>

                        <div className='bg-white p-7'>
                            <div className='flex gap-5 items-center *:grow'>
                                <CustomTextField<WorkshopType>
                                    generateOnChange={(value) => value.replace(/[^0-9\u06F0-\u06F9]/g, '')}
                                    generateValue={(value) => addCommas(value)}
                                    name='price_rial'
                                    control={control}
                                    label='قیمت داخلی کارگاه'
                                    adornment='تومان'
                                />

                                <CustomTextField<WorkshopType>
                                    generateOnChange={(value) => value.replace(/[^0-9\u06F0-\u06F9]/g, '')}
                                    generateValue={(value) => addCommas(value)}
                                    name='price_dollar'
                                    control={control}
                                    label='قیمت خارجی کارگاه'
                                    adornment='دلار'
                                />

                                <CustomTextField<WorkshopType>
                                    generateOnChange={(value) => {
                                        const newVal = value.replace(/[^0-9\u06F0-\u06F9]/g, '')
                                        if (parseInt(newVal) > 100) return 100
                                        else if (parseInt(newVal) < 0) return 0
                                        return newVal
                                    }}
                                    name='discount'
                                    control={control}
                                    label='تخفیف کارگاه'
                                    adornment='درصد'
                                />
                            </div>
                        </div>
                    </div>

                    <div className='basis-1/4 flex-col flex gap-2'>
                        <div className='bg-white p-5 flex flex-col gap-1'>
                            <div className='flex justify-between gap-px items-center'>
                                <div className='text-[0.875rem] text-gray-700'>وضعیت</div>
                                <CustomSwitch
                                    control={control}
                                    name='is_active'
                                />
                            </div>

                            <div className='flex justify-between gap-px items-center'>
                                <div className='text-[0.875rem] text-gray-700'>نمایش</div>
                                <CustomSwitch
                                    control={control}
                                    name='is_featured'
                                />
                            </div>
                        </div>

                        <div className='bg-white p-5 px-[1.125rem]'>
                            <CustomTextField<WorkshopType>
                                label='لینک انگلیسی کارگاه'
                                name='url'
                                control={control}
                                inputRef={inputRefToBeCopied}
                                placeholder='فقط به انگلیسی'
                                generateOnChange={(value) => value.replace(/[^A-Za-z0-9-?/&:=.]/gi, '')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            position='start'
                                            className='cursor-pointer text-[var(--border-color,#999999)]'
                                            onClick={copyToClipboard}
                                        >
                                            <DocumentCopy />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>

                        <div className='bg-white p-5 px-[1.125rem] flex justify-center items-center gap-4 flex-col'>
                            <div className='grid grid-cols-2 w-full gap-y-3 gap-x-4'>
                                <Controller
                                    name='image'
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <UploadSection
                                            className='col-span-2'
                                            value={value}
                                            type={'IMAGE'}
                                            onChange={onChange}
                                            title={'انتخاب تصویر'}
                                        />
                                    )}
                                />

                                <Controller
                                    name='bg'
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <UploadSection
                                            value={value}
                                            type={'IMAGE'}
                                            title={'انتخاب BG'}
                                            onChange={onChange}
                                        />
                                    )}
                                />

                                <Controller
                                    name='thumbnail'
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <UploadSection
                                            value={value}
                                            type={'IMAGE'}
                                            title={'Thumbnail'}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex gap-4 mt-5'>
                    <Button
                        disabled={pending}
                        type='submit'
                        className='w-[9.375rem] text-gray-50'
                        size={ButtonSizes.bigger}
                    >
                        {pending ? <Refresh className='animate-spin' /> : 'ثبت'}
                    </Button>

                    <Button
                        type='button'
                        size={ButtonSizes.big}
                        className='w-[9.375rem]'
                        variant={ButtonVariants.gray}
                        onClick={() => navigate('/cms/workshops')}
                    >
                        انصراف
                    </Button>
                </div>
            </form>
        </FormProvider>
    )
}
