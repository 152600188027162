import { FC, useEffect, useMemo, useState } from 'react'
import Section from './section'
import { TabChildProps } from './test-form'
import SimpleControlledInput, {
    CustomAutocomplete,
    CustomTextField,
    TextFieldWithoutControl,
} from '../../AddUser/components/text-field'
import { TestDetail } from '../data-types'
import { useQuery } from '@tanstack/react-query'
import { AgeRangeCategories } from '../data-types/age-range-categories'
import { useFormContext } from 'react-hook-form'
import InputAdornment from '@mui/material/InputAdornment'
import CustomRichTextBox from '../../_components/CustomRichTextBox'
import toEnglishNumber from '../../../../utils/to-english-number'

const QuestionnaireSpecifications: FC<TabChildProps> = ({ control }) => {
    const { data: ageRangeCategories, isFetching: areAgeRangeCategoriesPending } = useQuery<AgeRangeCategories>({
        queryKey: ['age-range-categories'],
    })

    const categories = useMemo(() => {
        const uniqueCategories = new Set(ageRangeCategories?.categories.split('|').filter(Boolean))
        return Array.from(uniqueCategories)
    }, [ageRangeCategories])

    const ageRanges = useMemo(() => {
        const uniqueAgeRanges = new Set(ageRangeCategories?.ageRanges.split('|').filter(Boolean))
        return Array.from(uniqueAgeRanges)
    }, [ageRangeCategories])

    const {
        formState: { errors },
    } = useFormContext<TestDetail>()

    const [ageRangesError, setAgeRangesError] = useState<null | string>(null)

    useEffect(() => {
        if (errors.ageRangeFrom?.message && errors.ageRangeTo?.message)
            setAgeRangesError(errors.ageRangeFrom.message /* + ' | ' + errors.ageRangeTo.message*/)
        else if (errors.ageRangeFrom?.message) setAgeRangesError(errors.ageRangeFrom.message)
        else if (errors.ageRangeTo?.message) setAgeRangesError(errors.ageRangeTo.message)
        else setAgeRangesError(null)
    }, [errors.ageRangeFrom?.message, errors.ageRangeTo?.message])

    return (
        <>
            <Section>
                <CustomTextField<TestDetail>
                    name='title'
                    label='عنوان پرسشنامه'
                    control={control}
                />

                <CustomAutocomplete<TestDetail>
                    freeSolo
                    placeholder='با زدن Enter دسته بندی جدید اضافه کنید'
                    multiple
                    control={control}
                    name='category'
                    label='دسته بندی'
                    options={categories}
                    isPending={areAgeRangeCategoriesPending}
                />

                <div className='grid grid-cols-3 gap-3'>
                    <CustomAutocomplete<TestDetail>
                        multiple
                        control={control}
                        name='ageRange'
                        label='رده سنی'
                        options={ageRanges}
                        isPending={areAgeRangeCategoriesPending}
                    />

                    <div className='relative h-14'>
                        <TextFieldWithoutControl
                            label='سن'
                            disabled
                            helperText={ageRangesError}
                            error={ageRangesError ? true : false}
                        />

                        <div className='absolute inset-0 flex items-center *:grow'>
                            <SimpleControlledInput<TestDetail>
                                control={control}
                                name='ageRangeFrom'
                                placeholder=' از'
                                generateOnChange={(value) => toEnglishNumber(value.replace(/[^0-9\u06F0-\u06F9]/g, ''))}
                                generateValue={(value) => value?.toString() ?? ''}
                                className='min-w-0 bg-transparent h-[23px] pr-[14px] py-[28px] outline-none'
                            />

                            <div className='w-px h-[21px] bg-gray-700' />

                            <SimpleControlledInput<TestDetail>
                                control={control}
                                name='ageRangeTo'
                                placeholder=' تا'
                                generateOnChange={(value) => toEnglishNumber(value.replace(/[^0-9\u06F0-\u06F9]/g, ''))}
                                generateValue={(value) => value?.toString() ?? ''}
                                className='min-w-0 bg-transparent h-[23px] pr-[14px] py-[28px] outline-none'
                            />
                        </div>
                    </div>

                    <CustomTextField<TestDetail>
                        name='time'
                        label='زمان مورد نیاز'
                        control={control}
                        adornment='دقیقه'
                        generateOnChange={(value) => toEnglishNumber(value.replace(/[^0-9\u06F0-\u06F9]/g, ''))}
                        generateValue={(value) => value?.toString() ?? ''}
                    />
                </div>
            </Section>

            <Section>
                <CustomTextField<TestDetail>
                    name='description'
                    label='توضیحات'
                    control={control}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <a
                                    href='#content'
                                    className='text-[#0079FF] underline'
                                >
                                    تولید محتوا
                                </a>
                            </InputAdornment>
                        ),
                    }}
                />

                <CustomRichTextBox<TestDetail>
                    control={control}
                    name='intro'
                    label='مقدمه'
                    limit={Infinity}
                />

                <CustomRichTextBox<TestDetail>
                    control={control}
                    name='instruction'
                    label='دستور عمل آزمون‌'
                    limit={Infinity}
                />

                <CustomAutocomplete<TestDetail>
                    multiple
                    control={control}
                    name='personalQuestions'
                    label='سوالات مربوطه'
                    options={[]}
                    freeSolo
                    placeholder='با زدن Enter سوال جدید اضافه کنید'
                />

                <CustomAutocomplete<TestDetail>
                    freeSolo
                    multiple
                    control={control}
                    name='keywords'
                    label='کلمات کلیدی'
                    options={[]}
                    placeholder='با زدن Enter کلمه کلیدی جدید اضافه کنید'
                />

                <CustomTextField<TestDetail>
                    name='metaDescription'
                    label='Meta Description'
                    control={control}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <a
                                    href='#content'
                                    className='text-[#0079FF] underline'
                                >
                                    تولید محتوا
                                </a>
                            </InputAdornment>
                        ),
                    }}
                />
            </Section>
        </>
    )
}

export default QuestionnaireSpecifications
