import { Column, Sizes } from '../../../../_core/components/table/column'
import { PaypalData } from '../data-types'
import { cn } from '../../../../utils/cn'
import { FaCheck } from 'react-icons/fa'
import { FaXmark } from 'react-icons/fa6'
import Button, { ButtonSizes, ButtonVariants } from '../../../../_core/components/button'
import { GridRowId } from '@mui/x-data-grid'

export const getPaypalColumns = (selectionList: GridRowId[], onClick: (id?: number) => void) => [
    Column<PaypalData>({
        field: 'name',
        headerName: 'نام',
        valueGetter: (_, row) => row[1] ?? '',
        size: Sizes.normal,
        align: 'left',
        headerAlign: 'right',
    }),
    Column<PaypalData>({
        field: 'email',
        headerName: 'ایمیل',
        valueGetter: (_, row) => row[2] ?? '',
        size: Sizes.big,
        align: 'left',
        headerAlign: 'right',
    }),
    Column<PaypalData>({
        field: 'date',
        headerName: 'تاریخ',
        valueGetter: (_, row) => row[4] ?? '',
        size: Sizes.small,
        align: 'left',
        headerAlign: 'right',
    }),
    Column<PaypalData>({
        field: 'amount',
        headerName: 'مبلغ',
        valueGetter: (_, row) => row[5] ?? '',
        size: Sizes.small,
        align: 'left',
        headerAlign: 'right',
    }),
    Column<PaypalData>({
        field: 'price',
        headerName: 'هزینه',
        valueGetter: (_, row) => row[6] ?? '',
        renderCell: (params) => <div className='![direction:ltr]'>{params.value}</div>,
        size: Sizes.small,
        align: 'left',
        headerAlign: 'right',
    }),
    Column<PaypalData>({
        field: 'remains',
        headerName: 'مانده',
        valueGetter: (_, row) => row[7] ?? '',
        size: Sizes.small,
        align: 'left',
        headerAlign: 'right',
    }),
    Column<PaypalData>({
        field: 'description',
        headerName: 'توضیحات',
        valueGetter: (_, row) => row[8] ?? '',
        size: Sizes.small,
        align: 'left',
        headerAlign: 'right',
    }),
    Column<PaypalData>({
        field: 'approvedDate',
        headerName: 'تاریخ تایید',
        valueGetter: (_, row) => row[12] ?? '',
        size: Sizes.small,
        align: 'left',
        headerAlign: 'right',
    }),
    Column<PaypalData>({
        field: 'validated',
        headerName: '',
        valueGetter: (_, row) => row[11].toString(),
        size: Sizes.small,
        sortable: false,
        renderHeader: () => (
            <>
                <Button
                    aClassName='mr-auto'
                    variant={ButtonVariants.danger}
                    size={ButtonSizes.small}
                    onClick={() => onClick()}
                    className={cn((selectionList?.length ?? 0) > 0 ? 'flex' : 'hidden')}
                >
                    حذف همه
                </Button>
            </>
        ),

        renderCell: (params) => {
            const success = params.value === '1'
            return (
                <div className='flex items-center justify-end h-full w-full'>
                    <div
                        className={cn(
                            'rounded-full flex gap-[0.375rem] items-center py-1 px-2 text-[0.8125rem] w-fit h-6',
                            success && 'bg-[#DCF2DD] text-success-500',
                            !success && 'bg-[#FCE3E3] text-alarm-500',
                        )}
                    >
                        {success && (
                            <>
                                <FaCheck size='8' />
                                تأیید شده
                            </>
                        )}

                        {!success && (
                            <>
                                <FaXmark size='8' />
                                تأیید نشده
                            </>
                        )}
                    </div>
                </div>
            )
        },
    }),
]
