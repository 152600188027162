export default function createURL(input?: File[]) {
    try {
        if (input)
            if (input.length > 0)
                if (typeof input !== 'string') return URL.createObjectURL(input[0])
                else return input
    } catch {
        return input
    }
}
