import { useNavigate } from 'react-router-dom'
import { KeyboardEventHandler, useCallback, useMemo } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { InputAdornment } from '@mui/material'
import { UploadSection } from '../../_components/UploadSection'
import { cn } from '../../../../utils/cn'
import { CustomAutocomplete, CustomTextField } from '../../AddUser/components/text-field'
import { DocumentCopy, Refresh } from 'iconsax-react'
import Button, { ButtonSizes, ButtonVariants } from '../../../../_core/components/button'
import CustomSwitch from '../../AddUser/components/switch'
import useCopyToClipboard from '../../../../_core/components/hooks/use-copy'
import CustomRichTextBox from '../../_components/CustomRichTextBox'
import { z } from '../../../../utils/zod-translation'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery } from '@tanstack/react-query'
import { DialogTypes, useDialogStore } from '../../_components/Dialog'
import { addArticle as addArticleAPI, updateArticle as updateArticleAPI } from '../api'
import { getCategories, getKeywords, getMeasurements } from '../../workshops/api'
import ResourcesSection from './ResourcesSection'
import SuggestionsSection from './SuggestionsSection'

const SuggestionsTypeSchema = z.object({
    id: z.coerce.number().optional(),
    value: z.coerce.string().optional(),
})
const ResourcesTypeSchema = z.object({
    id: z.coerce.number().optional(),
    title: z.coerce.string().optional(),
    link: z.coerce.string().optional(),
})
const ArticleSchema = z.object({
    id: z.coerce.number().optional(),
    title: z.coerce.string().optional(),
    subtitle: z.coerce.string().optional(),
    categories: z.coerce.string().optional(),
    author: z.coerce.string().optional(),
    type: z.coerce.string().optional(),
    content: z.coerce.string().optional(),
    resources: z.array(ResourcesTypeSchema.optional()).optional(),
    suggestions: z.array(SuggestionsTypeSchema.optional()).optional(),
    keywords: z.coerce.string().optional(),
    measurements: z.coerce.string().optional(),
    meta_description: z.coerce.string().optional(),
    url: z.coerce.string().optional(),
    is_active: z.boolean().optional(),
    is_featured: z.boolean().optional(),
    image: z.coerce.string().optional(),
    thumbnail: z.coerce.string().optional(),
    bg: z.coerce.string().optional(),
    user_id: z.coerce.number().optional(),
    date: z.coerce.string().optional(),
    date_updated: z.coerce.string().optional(),
})

export type SuggestionsType = z.infer<typeof SuggestionsTypeSchema>
export type ResourcesType = z.infer<typeof ResourcesTypeSchema>
export type ArticleType = z.infer<typeof ArticleSchema>

export type ArticleTypeServer = Omit<z.infer<typeof ArticleSchema>, 'suggestions'> & {
    suggestions?: string[]
}

type ArticleFormType = {
    article?: ArticleType
    type: 'ADD' | 'UPDATE'
    isLoading?: boolean
}

const articleTypes = ['داستان کوتاه', 'مقاله']
const articleAuthors = ['نویسنده ۱', 'نویسنده ۲']

export const ArticleForm = ({ article, type }: ArticleFormType) => {
    const navigate = useNavigate()

    const { control, handleSubmit, watch, ...otherFormThings } = useForm<ArticleType>({
        defaultValues: article,
        resolver: zodResolver(ArticleSchema),
    })

    const { setDialogState } = useDialogStore()

    const { mutate: addArticle, isPending: isAddingArticlePending } = useMutation({
        mutationKey: ['articles'],
        mutationFn: addArticleAPI,
        onSuccess: () => {
            setDialogState({
                open: true,
                title: 'ثبت مقاله جدید',
                type: DialogTypes.success,
                description: 'مقاله شما با موفقیت ثبت شد.',
            })
            navigate('/cms/articles')
        },
        onError: () =>
            setDialogState({
                open: true,
                title: 'خطا در ثبت مقاله',
                type: DialogTypes.error,
                description: 'مقاله ثبت نشد.',
            }),
    })

    const { mutate: updateArticle, isPending: isUpdatingArticlePending } = useMutation({
        mutationKey: ['articles'],
        mutationFn: updateArticleAPI,
        onSuccess: () => {
            setDialogState({
                open: true,
                title: 'ویرایش مقاله',
                type: DialogTypes.success,
                description: 'اطلاعات مقاله با موفقیت ویرایش شد.',
            })
            navigate('/cms/articles')
        },
        onError: () =>
            setDialogState({
                open: true,
                title: 'خطا در ویرایش مقاله',
                type: DialogTypes.error,
                description: 'مقاله ویرایش نشد.',
            }),
    })

    const onSubmit = useCallback(
        ({ suggestions, ...data }: ArticleType) => {
            const transformedData = {
                suggestions: suggestions?.map((item) => item?.value ?? '') ?? [],
                ...data,
            }

            if (type === 'ADD') addArticle(transformedData)
            if (type === 'UPDATE') updateArticle(transformedData)
        },
        [addArticle, type, updateArticle],
    )

    const { data: categories, isPending: isPendingCategories } = useQuery({
        queryKey: ['categories'],
        queryFn: getCategories,
        staleTime: 0,
        gcTime: 5 * 60 * 1000,
    })

    const { data: measurements, isPending: isPendingMeasurements } = useQuery({
        queryKey: ['measurements'],
        queryFn: getMeasurements,
        staleTime: 0,
        gcTime: 5 * 60 * 1000,
    })

    const { data: keywords, isPending: isPendingKeywords } = useQuery({
        queryKey: ['keywords'],
        queryFn: getKeywords,
        staleTime: 0,
        gcTime: 5 * 60 * 1000,
    })

    const [inputRefToBeCopied, copyToClipboard] = useCopyToClipboard()

    const pending = useMemo(
        () => isAddingArticlePending || isUpdatingArticlePending,
        [isAddingArticlePending, isUpdatingArticlePending],
    )

    const onError = useCallback(
        (e: any) => {
            console.log(e)
            setDialogState({
                open: true,
                title: 'فرم ناقص',
                type: DialogTypes.error,
                description: 'اطلاعات فرم را چک کنید.',
            })
        },
        [setDialogState],
    )

    const handleKeyDown: KeyboardEventHandler<HTMLFormElement> = (e) => {
        if (e.key === 'Enter') e.preventDefault()
    }

    return (
        <FormProvider
            handleSubmit={handleSubmit}
            {...otherFormThings}
            control={control}
            watch={watch}
        >
            <form
                onSubmit={handleSubmit(onSubmit, onError)}
                onKeyDown={handleKeyDown}
                className={cn('flex flex-col gap-5', pending && '*:opacity-50 *:pointer-events-none')}
            >
                <div className='text-sm mb-5'>
                    <span className='text-gray-400'>مقالات / </span>
                    <span className='text-gray-600 font-medium'>{type === 'ADD' ? 'مقاله جدید' : 'ویرایش مقاله'}</span>
                </div>

                <div className='flex gap-4'>
                    <div className='basis-3/4 flex flex-col gap-3'>
                        <div className='bg-white p-7 flex flex-col gap-10'>
                            <CustomTextField<ArticleType>
                                label='عنوان مقاله'
                                name='title'
                                control={control}
                            />

                            <CustomTextField<ArticleType>
                                label='زیر نویس مقاله'
                                name='subtitle'
                                control={control}
                            />

                            <CustomAutocomplete<ArticleType>
                                label='دسته بندی'
                                multiple
                                name='categories'
                                control={control}
                                options={categories ?? []}
                                isPending={isPendingCategories}
                                freeSolo
                                placeholder='با زدن Enter دسته بندی جدید اضافه کنید'
                            />
                        </div>

                        <div className='bg-white p-7 flex flex-col gap-10'>
                            <div className='grid grid-cols-2 gap-5'>
                                <CustomAutocomplete<ArticleType>
                                    options={articleAuthors}
                                    label='نویسنده'
                                    name='author'
                                    control={control}
                                    freeSolo
                                />

                                <CustomAutocomplete<ArticleType>
                                    options={articleTypes}
                                    label='نوع مقاله'
                                    name='type'
                                    control={control}
                                    freeSolo
                                />
                            </div>

                            <CustomRichTextBox<ArticleType>
                                name='content'
                                control={control}
                                label='محتوا'
                            />

                            <CustomAutocomplete<ArticleType>
                                multiple
                                options={keywords ?? []}
                                isPending={isPendingKeywords}
                                label='کلمات کلیدی'
                                name='keywords'
                                control={control}
                                freeSolo
                                placeholder='با زدن Enter کلمه کلیدی جدید اضافه کنید'
                            />

                            <CustomAutocomplete<ArticleType>
                                multiple
                                options={measurements ?? []}
                                isPending={isPendingMeasurements}
                                label='مقادیر اندازه‌گیری'
                                name='measurements'
                                control={control}
                                freeSolo
                                placeholder='با زدن Enter مقدار اندازه‌گیری جدید اضافه کنید'
                            />

                            <CustomTextField<ArticleType>
                                name='meta_description'
                                control={control}
                                label='Meta Description'
                            />

                            <ResourcesSection />

                            <SuggestionsSection />
                        </div>
                    </div>

                    <div className='basis-1/4 flex-col flex gap-2'>
                        <div className='bg-white p-5 flex flex-col gap-1'>
                            <div className='flex justify-between gap-px items-center'>
                                <div className='text-[0.875rem] text-gray-700'>وضعیت</div>
                                <CustomSwitch
                                    control={control}
                                    name='is_active'
                                />
                            </div>

                            <div className='flex justify-between gap-px items-center'>
                                <div className='text-[0.875rem] text-gray-700'>نمایش</div>
                                <CustomSwitch
                                    control={control}
                                    name='is_featured'
                                />
                            </div>
                        </div>

                        <div className='bg-white p-5 px-[1.125rem]'>
                            <CustomTextField<ArticleType>
                                readOnly={true}
                                helperText='این فیلد قابل ویرایش نیست'
                                className='iransans_without_numbers_class opacity-50'
                                label='لینک انگلیسی مقاله'
                                name='url'
                                control={control}
                                inputRef={inputRefToBeCopied}
                                placeholder='فقط به انگلیسی'
                                generateOnChange={(value) => value.replace(/[^A-Za-z0-9-?/&:=.]/gi, '')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            position='start'
                                            className='cursor-pointer'
                                            onClick={copyToClipboard}
                                        >
                                            <DocumentCopy />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>

                        <div className='bg-white p-5 px-[1.125rem] flex justify-center items-center gap-4 flex-col'>
                            <div className='grid grid-cols-2 w-full gap-y-3 gap-x-4'>
                                <Controller
                                    name='image'
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <UploadSection
                                            className='col-span-2'
                                            value={value}
                                            type={'IMAGE'}
                                            onChange={onChange}
                                            title={'انتخاب تصویر'}
                                        />
                                    )}
                                />

                                <Controller
                                    name='bg'
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <UploadSection
                                            value={value}
                                            type={'IMAGE'}
                                            title={'انتخاب BG'}
                                            onChange={onChange}
                                        />
                                    )}
                                />

                                <Controller
                                    name='thumbnail'
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <UploadSection
                                            value={value}
                                            type={'IMAGE'}
                                            title={'Thumbnail'}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex gap-4 mt-5'>
                    <Button
                        disabled={pending}
                        type='submit'
                        className='w-[9.375rem] text-gray-50'
                        size={ButtonSizes.bigger}
                    >
                        {isAddingArticlePending || isUpdatingArticlePending ? (
                            <Refresh className='animate-spin' />
                        ) : type === 'ADD' ? (
                            'ثبت نهایی'
                        ) : (
                            'ثبت نهایی ویرایش'
                        )}
                    </Button>

                    <Button
                        type='button'
                        size={ButtonSizes.big}
                        className='w-[9.375rem]'
                        variant={ButtonVariants.gray}
                        onClick={() => navigate('/cms/articles')}
                    >
                        انصراف
                    </Button>
                </div>
            </form>
        </FormProvider>
    )
}
