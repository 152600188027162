import { FC, useCallback, useEffect } from 'react'
import DialogUI, { NecessaryDialogProps, useCompactDialog } from '../../../../_core/components/dialog-ui'
import { z } from '../../../../utils/zod-translation'
import { FormProvider, useForm } from 'react-hook-form'
import { CustomAutocomplete } from '../../AddUser/components/text-field'
import { zodResolver } from '@hookform/resolvers/zod'
import CustomRichTextBox from '../../_components/CustomRichTextBox'
import Button, { ButtonSizes, ButtonVariants } from '../../../../_core/components/button'
import { useMutation } from '@tanstack/react-query'
import { cancelReservations } from '../api'
import { DialogTypes, useDialogStore } from '../../_components/Dialog'
import { Refresh } from 'iconsax-react'

const CancelPostSchema = z.object({
    id: z.array(z.number().or(z.string().min(1))).min(1),
    canceller: z.string().min(1),
    reason: z.string(),
})

export type CancelPost = z.infer<typeof CancelPostSchema>

const CancelFormDialog: FC<NecessaryDialogProps> = ({ compactDialogState, setCompactDialogState }) => {
    const { control, handleSubmit, setValue, ...otherFormProps } = useForm<CancelPost>({
        resolver: zodResolver(CancelPostSchema),
    })

    useEffect(() => {
        setValue('id', compactDialogState ?? [])
    }, [compactDialogState, setValue])

    const { close } = useCompactDialog({ compactDialogState, setCompactDialogState })

    const { setDialogState } = useDialogStore()

    const { mutate: cancel, isPending: isCancelling } = useMutation({
        mutationKey: ['reservations'],
        mutationFn: cancelReservations,
        onSuccess: () =>
            setDialogState({
                open: true,
                title: 'لغو نوبت',
                type: DialogTypes.success,
                description: 'نوبت با موفقیت لغو شد.',
            }),
        onError: () =>
            setDialogState({
                open: true,
                title: 'خطا در لغو نوبت',
                type: DialogTypes.error,
                description: 'نوبت لغو نشد.',
            }),
    })

    const onSubmit = useCallback((data: CancelPost) => cancel(data), [cancel])

    const onError = useCallback(
        () =>
            setDialogState({
                open: true,
                title: 'خطا در لغو نوبت',
                type: DialogTypes.error,
                description: 'فرم را کامل کنید.',
            }),
        [setDialogState],
    )

    return (
        <DialogUI
            dialogTitle='لغو نوبت'
            compactDialogState={compactDialogState}
            setCompactDialogState={setCompactDialogState}
        >
            <FormProvider
                control={control}
                setValue={setValue}
                handleSubmit={handleSubmit}
                {...otherFormProps}
            >
                <form
                    onSubmit={handleSubmit(onSubmit, onError)}
                    className='flex flex-col gap-4 grow h-full'
                >
                    <CustomAutocomplete<CancelPost>
                        listElevated
                        control={control}
                        name='canceller'
                        options={['ادمین', 'درمانگر', 'درمانجو']}
                        label='لغو کننده نوبت'
                    />

                    <CustomRichTextBox<CancelPost>
                        control={control}
                        name='reason'
                        label='علت لغو نوبت'
                    />

                    <div className='grow ' />

                    <div className='flex gap-4'>
                        <Button
                            disabled={isCancelling}
                            type='submit'
                            className='w-[9.375rem] text-gray-50'
                            size={ButtonSizes.bigger}
                        >
                            {isCancelling ? <Refresh className='animate-spin' /> : 'ثبت'}
                        </Button>

                        <Button
                            type='button'
                            size={ButtonSizes.big}
                            className='w-[9.375rem]'
                            variant={ButtonVariants.gray}
                            onClick={close}
                        >
                            انصراف
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </DialogUI>
    )
}

export default CancelFormDialog
