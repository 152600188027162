import * as yup from 'yup'
import axios from 'axios'
import AuthLayout from '../../../_core/components/layouts/auth-layout/AuthLayout'
import { toast } from 'react-toastify'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppDispatch } from '../../../_core/redux/hooks'
import { updateUserAccess } from '../../../_core/redux/user-access/user-access-slice'
import { Controller, useForm } from 'react-hook-form'

interface IFormInput {
    email: string
    password: string
}

const LoginPage = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const getUserAccess = async (id: string | number) => {
        try {
            const response = await axios.post(
                `https://stage2-api.insight-clinic.com/controller/get_user_access_v3.php`,
                { id },
                {
                    headers: {
                        AuthorizationKey: '133079ba-9b43-11ee-b9d1-0242ac120002',
                    },
                },
            )
            const data = await response
            console.log('response user access', data.data.data)
            dispatch(updateUserAccess(data.data.data))
        } catch (error) {
            console.log('error user access', error)
            toast.error('error user access!')
        }
    }

    const schema = yup
        .object({
            email: yup.string().required('ایمیل اجباری است.'),
            password: yup.string().required('پسورد اجباری است.'),
        })
        .required()

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<IFormInput>({ resolver: yupResolver(schema) })

    const onSubmit = async (formData: IFormInput) => {
        setIsLoading(true)

        const newData = {
            email: formData.email,
            password: formData.password,
        }

        try {
            const response = await axios.post(
                `https://stage2-api.insight-clinic.com/controller/login_v2.php`,
                newData,
                {
                    headers: {
                        AuthorizationKey: '133079ba-9b43-11ee-b9d1-0242ac120002',
                    },
                },
            )
            const data = await response
            console.log('response', data.data.action)
            if (data.data.action === 'VERIFIED_ACCOUNT') {
                localStorage.setItem('userId', data.data.user.User)
                localStorage.setItem('expiredDate', String(new Date().getTime() + 432000000))
                localStorage.setItem('fullName', data.data.user.Name + ',' + data.data.user.LastName)
                localStorage.setItem('token', data.data.user.token)
                await getUserAccess(data.data.user.User)
                navigate('/cms')
            }
            if (data.data.action === 'WRONG_ACCOUNT') {
                toast.error('ایمیل یا پسورد نادرست می باشد!')
            }
            if (data.data.action === 'SET_PASSWORD') {
                navigate(`/auth/create-password?id=${data.data.user.User}`)
            }
        } catch (error) {
            console.log('error', error)
        } finally {
            setIsLoading(false)
        }
    }

    const isDisabled = isLoading || errors?.email !== undefined || errors.password !== undefined

    return (
        <AuthLayout>
            <h1 className='text-2xl text-black '>ورود کاربر</h1>
            <p className='text-xl text-gray-800 mt-12'>برای ورود لطفا ایمیل و پسورد خود را وارد کنید.</p>

            <form
                className='w-full'
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className='mt-10'>
                    <label className='w-full block text-gray-400 mb-2'>ایمیل</label>
                    <Controller
                        name='email'
                        control={control}
                        render={({ field: { name, onChange, value } }) => (
                            <>
                                <input
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    placeholder=' ایمیل را وارد کنید ...'
                                    className='w-full h-12 flex px-4 items-center border border-gray-300 outline-none rounded-lg'
                                />
                                <p className='h-5 text-xs text-red-500 mt-1'>{errors.email?.message}</p>
                            </>
                        )}
                    />
                </div>

                <div className='mt-10'>
                    <label className='w-full block text-gray-400 mb-2'>پسورد</label>
                    <Controller
                        name='password'
                        control={control}
                        render={({ field: { name, onChange, value } }) => (
                            <>
                                <input
                                    type='password'
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    placeholder=' پسورد را وارد کنید ...'
                                    className='w-full h-12 flex px-4 items-center border border-gray-300 outline-none rounded-lg'
                                />
                                <p className='h-5 text-xs text-red-500 mt-1'>{errors.email?.message}</p>
                            </>
                        )}
                    />
                </div>

                <div className='mt-12'>
                    <button
                        type='submit'
                        disabled={isDisabled}
                        className={`block w-full h-12 rounded-lg ${
                            isDisabled ? 'bg-gray-300' : 'bg-insight-300 hover:opacity-75'
                        }`}
                    >
                        {isLoading ? 'در حال ...' : 'ورود'}
                    </button>
                </div>
            </form>
        </AuthLayout>
    )
}

export default LoginPage
