import { FC } from 'react'
import ListPage from '../_components/list-page'
import { deleteDictionaries, getDictionaries } from './api'
import DeleteConfirmDialog from '../_components/list-page/components/dialog'
import { getMetricsColumns, getQuestionsColumns } from './constants'
import { Dictionary, Metric, Question } from './data-types'
import { Tabs, TabsContent, TabsTrigger } from '../../../_core/components/tabs'
import UpdateDialog from './parts/update-dialog'
import { create } from 'zustand'

type DictionaryStore = {
    metricsID?: number
    setMetricsID: (id?: number) => void
    showMetrics: boolean
    setShowMetrics: (show: boolean) => void
    questionsID?: number
    setQuestionsID: (id?: number) => void
    showQuestions: boolean
    setShowQuestions: (show: boolean) => void
}

export const useDictionaryStore = create<DictionaryStore>((set) => ({
    showMetrics: false,
    setShowMetrics: (show) => set({ showMetrics: show }),
    showQuestions: false,
    setShowQuestions: (show) => set({ showQuestions: show }),
    setMetricsID: (id) => set({ metricsID: id }),
    setQuestionsID: (id) => set({ questionsID: id }),
}))

const DictionaryPage: FC = () => {
    const { showMetrics, setShowMetrics, showQuestions, setShowQuestions } = useDictionaryStore()

    return (
        <Tabs initialValue='metrics'>
            <TabsContent value='metrics'>
                <ListPage<Metric>
                    pagination
                    apiFunction={(page) => getDictionaries(page ?? 0)}
                    generateRows={(data) => (data as Dictionary)?.metrics ?? []}
                    isDictionaryMetrics={true}
                    getColumnMetrics={getMetricsColumns}
                    generateTitle={<CustomTabTriggerSection />}
                    itemName={'دایرکتوری'}
                    addPageLink={() => {
                        setShowMetrics(true)
                    }}
                    queryKey={['dictionaries']}
                    accessName='Dictionaries'
                    generateID={(row) => row.id}
                    generateDialog={(dialogData, setDialogData, processedRows, selectionList) => (
                        <DeleteConfirmDialog
                            onClose={() => setDialogData(false)}
                            accept={
                                dialogData === 'deleteAll'
                                    ? () => deleteDictionaries(selectionList ?? [])
                                    : dialogData !== false
                                    ? () => deleteDictionaries([dialogData])
                                    : () => null
                            }
                            open={dialogData !== false}
                            itemToBeDeleted={
                                dialogData === 'deleteAll'
                                    ? undefined
                                    : dialogData !== false
                                    ? processedRows.filter((row) => row.id === dialogData)[0].title
                                    : undefined
                            }
                        />
                    )}
                />

                {showMetrics && <UpdateDialog mode='metrics' />}
            </TabsContent>

            <TabsContent value='questions'>
                <ListPage<Question>
                    pagination
                    apiFunction={(page) => getDictionaries(page ?? 0)}
                    generateRows={(data) => (data as Dictionary)?.questions ?? []}
                    isDictionaryQuestions={true}
                    getColumnQuestions={getQuestionsColumns}
                    generateTitle={<CustomTabTriggerSection />}
                    itemName={'پرسش‌ها'}
                    addPageLink={() => {
                        setShowQuestions(true)
                    }}
                    queryKey={['dictionaries']}
                    accessName='Dictionaries'
                    generateID={(row) => row.id}
                    generateDialog={(dialogData, setDialogData, processedRows, selectionList) => (
                        <DeleteConfirmDialog
                            onClose={() => setDialogData(false)}
                            accept={
                                dialogData === 'deleteAll'
                                    ? () => deleteDictionaries(selectionList ?? [])
                                    : dialogData !== false
                                    ? () => deleteDictionaries([dialogData])
                                    : () => null
                            }
                            open={dialogData !== false}
                            itemToBeDeleted={
                                dialogData === 'deleteAll'
                                    ? undefined
                                    : dialogData !== false
                                    ? processedRows.filter((row) => row.id === dialogData)[0].title
                                    : undefined
                            }
                        />
                    )}
                />

                {showQuestions && <UpdateDialog mode='questions' />}
            </TabsContent>
        </Tabs>
    )
}

export default DictionaryPage

const CustomTabTriggerSection: FC = () => {
    return (
        <div className='flex items-center gap-3'>
            <CustomTabTrigger
                children='دایرکتوری'
                value='metrics'
            />

            <CustomTabTrigger
                children='پرسش‌ها'
                value='questions'
            />
        </div>
    )
}

interface CustomTabTriggerProps {
    children: string
    value: string
}

const CustomTabTrigger: FC<CustomTabTriggerProps> = ({ children, value }) => {
    return (
        <div className='flex items-center gap-3'>
            <TabsTrigger
                className='w-32 p-1 text-center bg-white text-gray-300 text-sm flex items-center justify-center h-11 rounded data-[open=true]:bg-primary-500 data-[open=true]:text-gray-50'
                value={value}
            >
                {children}
            </TabsTrigger>
        </div>
    )
}
