import { useState, useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { BundleType } from './BundleForm'
import { TextFieldWithoutControl } from '../../AddUser/components/text-field'
import InputAdornment from '@mui/material/InputAdornment'
import { SearchNormal1 } from 'iconsax-react'
import { DataGrid } from '@mui/x-data-grid/DataGrid'
import { cn } from '../../../../utils/cn'
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid'
import CustomCheckbox from '../../../../_core/components/table/custom-checkbox'
import formatMoney from '../../../../utils/format-money'
import { useQuery } from '@tanstack/react-query'
import { getTestsBundle } from '../api'
import { optionalParseInt } from '../../../../utils/optional-parse-number'

type AgeRangeType = { from: number; to: number }

export const TestsTable = () => {
    const { watch, setValue } = useFormContext<BundleType>()

    const testIds = (watch('test_ids') ?? []).map((testId) => optionalParseInt(testId))
    const measurements = watch('measurements') ?? []
    const categories = watch('categories') ?? []
    const price_dollar = watch('price_dollar') ?? 0
    const price_rial = watch('price_rial') ?? 0

    const headCells: GridColDef[] = useMemo(
        () => [
            {
                field: 'title',
                headerName: 'نام آزمون',

                minWidth: 50,
                flex: 3,
                align: 'center',
                disableColumnMenu: true,
                sortable: false,
                resizable: false,
            },
            {
                field: 'measurementTitle',
                headerName: 'موارد اندازه‌گیری',

                minWidth: 50,
                flex: 4,
                align: 'center',
                disableColumnMenu: true,
                sortable: false,
                resizable: false,
            },
            {
                field: 'category',
                headerName: 'دسته‌بندی',

                minWidth: 50,
                flex: 3,
                align: 'center',
                disableColumnMenu: true,
                sortable: false,
                resizable: false,
            },
            {
                field: 'ageRangeFrom',
                headerName: 'از (سن)',

                minWidth: 50,
                flex: 3,
                align: 'center',
                disableColumnMenu: true,
                sortable: false,
                resizable: false,
            },
            {
                field: 'ageRangeTo',
                headerName: 'تا (سن)',

                minWidth: 50,
                flex: 3,
                align: 'center',
                disableColumnMenu: true,
                sortable: false,
                resizable: false,
            },
            {
                field: 'is_active',
                headerName: 'وضعیت',

                minWidth: 50,
                flex: 3,
                align: 'center',
                disableColumnMenu: true,
                sortable: false,
                resizable: false,
            },
            {
                field: 'priceDollar',
                headerName: 'قیمت (دلار)',

                renderCell: ({ value }) => <div dir='ltr'>{(formatMoney(value) ?? '0') + ' $'}</div>,

                minWidth: 50,
                flex: 4,
                align: 'center',
                disableColumnMenu: true,
                sortable: false,
                resizable: false,
            },
            {
                field: 'priceRial',
                headerName: 'قیمت (تومان)',

                renderCell: ({ value }) => <>{(formatMoney(value) ?? '0') + ' تومان'}</>,

                minWidth: 50,
                flex: 4,
                align: 'center',
                disableColumnMenu: true,
                sortable: false,
                resizable: false,
            },
            // {
            //     field: 'actions',
            //     headerName: '',

            //     renderCell: (props) => (
            //         <button
            //             type='button'
            //             className='w-full h-full flex items-center justify-center'
            //             onClick={() => {
            //                 setSelectedItems((prev) => prev.filter((item) => item !== props.row.id))
            //             }}
            //         >
            //             <Trash />
            //         </button>
            //     ),

            //     width: 50,
            //     align: 'center',
            //     disableColumnMenu: true,
            //     sortable: false,
            //     resizable: false,
            // },
        ],
        [],
    )

    const { data: testsBundle, isFetching } = useQuery({
        queryKey: ['tests-bundle'],
        queryFn: getTestsBundle,
        refetchOnWindowFocus: false,
    })

    const onChange = useCallback(
        (list: GridRowSelectionModel) => {
            const newItems = list.map((item) => optionalParseInt(item))

            let newCategories: string[] = []
            testsBundle
                ?.filter((testBundle) => newItems.includes(testBundle.id))
                .map((newTestBundle) =>
                    (newTestBundle.category?.split('|') ?? []).forEach((category) => {
                        if (!newCategories.includes(category)) newCategories.push(category)
                    }),
                )
            setValue('categories', newCategories)

            let newMeasurements: string[] = []
            testsBundle
                ?.filter((testBundle) => newItems.includes(testBundle.id))
                .map((newTestBundle) =>
                    (newTestBundle.measurementTitle?.split('|') ?? []).forEach((mTitle) => {
                        if (!newMeasurements.includes(mTitle)) newMeasurements.push(mTitle)
                    }),
                )
            setValue('measurements', newMeasurements)

            setValue(
                'test_ids',
                newItems.map((newItem) => newItem.toString()),
            )

            let newFinalPriceDollar = 0
            testsBundle
                ?.filter((testBundle) => newItems.includes(testBundle.id))
                .map((newTestBundle) => (newFinalPriceDollar += moneyStringToNumber(newTestBundle.priceDollar ?? 0)))
            setValue('price_dollar', newFinalPriceDollar)

            let newFinalPriceRial = 0
            testsBundle
                ?.filter((testBundle) => newItems.includes(testBundle.id))
                .map((newTestBundle) => (newFinalPriceRial += moneyStringToNumber(newTestBundle.priceRial ?? 0)))
            setValue('price_rial', newFinalPriceRial)

            let newObj: AgeRangeType = { from: Infinity, to: -Infinity }
            testsBundle
                ?.filter((testBundle) => newItems.includes(testBundle.id))
                .forEach((newTestBundle) => {
                    if (newTestBundle.ageRangeFrom < newObj.from) newObj.from = newTestBundle.ageRangeFrom
                    if (newTestBundle.ageRangeTo > newObj.to) newObj.to = newTestBundle.ageRangeTo
                })
            setValue('age_range_from', newObj.from)
            setValue('age_range_to', newObj.to)
        },
        [setValue, testsBundle],
    )

    const [searchMeasurements, setSearchMeasurements] = useState<string>('')

    return (
        <>
            <TextFieldWithoutControl
                containerClassName='mb-7'
                label='انتخاب آزمون ها'
                placeholder='جستجو...'
                value={searchMeasurements}
                onChange={(event) => setSearchMeasurements(event.target.value)}
                sx={{ width: '16rem' }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <SearchNormal1 />
                        </InputAdornment>
                    ),
                }}
            />

            <DataGrid
                keepNonExistentRowsSelected
                loading={isFetching}
                className={cn(
                    'add-data-grid no-header-padding max-h-96 [&_.MuiDataGrid-columnHeaderTitleContainer:not(.MuiDataGrid-columnHeaderCheckbox_*)]:!justify-start [&_.MuiDataGrid-columnHeaderTitleContainer:not(.MuiDataGrid-columnHeaderCheckbox_*)]:!pr-5',
                    isFetching && '*:opacity-50 *:pointer-events-none',
                    isFetching && '[&_.MuiDataGrid-virtualScrollerContent]:!h-0',
                )}
                rows={
                    testsBundle?.filter((testBundle) =>
                        testBundle.title?.toLowerCase().includes(searchMeasurements.toLowerCase()),
                    ) ?? []
                }
                columns={headCells}
                checkboxSelection={true}
                rowSelectionModel={testIds}
                onRowSelectionModelChange={onChange}
                slots={{
                    baseCheckbox: CustomCheckbox,
                    columnResizeIcon: () => <></>,
                    loadingOverlay: () => <></>,
                    noRowsOverlay: () => <></>,
                }}
                hideFooter
            />

            {testIds.length > 0 && (
                <div className='flex flex-col gap-8 bg-gray-50 p-4'>
                    <div className='grid grid-cols-2 gap-3'>
                        {categories.length > 0 && (
                            <div className='flex items-center gap-2 flex-wrap h-fit'>
                                <label className='text-xs text-gray-700'>دسته‌بندی ها :</label>
                                {categories.map((item, index) => (
                                    <p
                                        key={`id-of-categories-${index}`}
                                        className='bg-gray-100 text-gray-700 text-[0.625rem] px-2 py-1 rounded-full'
                                    >
                                        {item}
                                    </p>
                                ))}
                            </div>
                        )}

                        {measurements.length > 0 && (
                            <div className='flex items-center gap-2 flex-wrap h-fit'>
                                <label className='text-xs text-gray-700'>موارد اندازه‌گیری:</label>
                                {measurements.map((item, index) => (
                                    <p
                                        key={`id-of-measurements-${index}`}
                                        className='bg-gray-100 text-gray-700 text-[0.625rem] px-2 py-1 rounded-full'
                                    >
                                        {item}
                                    </p>
                                ))}
                            </div>
                        )}

                        {(watch('age_range_from') !== Infinity || watch('age_range_to') !== -Infinity) && (
                            <div className='flex items-center gap-2 flex-wrap h-fit'>
                                <label className='text-xs text-gray-700'>رده سنی :</label>
                                <p className='bg-gray-100 text-gray-700 text-[0.625rem] px-2 py-1 rounded-full'>
                                    از {watch('age_range_from')} سال تا {watch('age_range_to')} سال
                                </p>
                            </div>
                        )}

                        {testIds.length > 0 && (
                            <div className='flex items-center gap-2 flex-wrap h-fit'>
                                <label className='text-xs text-gray-700'>تعداد آزمون‌ها:</label>
                                <p className='bg-gray-100 text-gray-700 text-[0.625rem] px-2 py-1 rounded-full'>
                                    {testIds.length} آزمون
                                </p>
                            </div>
                        )}
                    </div>

                    <div className='flex justify-end'>
                        {(price_dollar > 0 || price_rial > 0) && (
                            <div className='flex items-center gap-2 flex-wrap h-fit'>
                                <label className='text-xs text-gray-700 font-medium'>جمع کل:</label>
                                {price_dollar > 0 && (
                                    <p className='text-primary-500 text-sm'>
                                        به دلار:{' '}
                                        {price_dollar.toLocaleString('en-US', {
                                            currency: 'USD',
                                        })}
                                    </p>
                                )}
                                {price_rial > 0 && (
                                    <p className='text-primary-500 text-sm'>
                                        به تومان: {price_rial.toLocaleString('fa-IR', { currency: 'IRR' })}
                                    </p>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

function moneyStringToNumber(moneyString: string | number) {
    return parseFloat(moneyString.toString().replace(/[^0-9.]/g, ''))
}
