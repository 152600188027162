import { FC, KeyboardEventHandler, useCallback, useMemo, useState } from 'react'
import { z } from '../../../../utils/zod-translation'
import CMSLayout from '../../../../_core/components/layouts/cms-dashboard/CMSLayout'
import Section from '../../Tests/components/section'
import { TextFieldWithoutControl } from '../../AddUser/components/text-field'
import { DataGrid } from '@mui/x-data-grid'
import { cn } from '../../../../utils/cn'
import { useMutation, useQuery } from '@tanstack/react-query'
import { bookAppointment, getPatients, updateAppointment } from '../api'
import { InputAdornment } from '@mui/material'
import { AddCircle, Refresh, SearchNormal1 } from 'iconsax-react'
import CustomCheckbox from '../../../../_core/components/table/custom-checkbox'
import { Column, ColumnVariants, Sizes } from '../../../../_core/components/table/column'
import { Patient, ReservationStatus } from '../data-types'
import { getTherapists } from '../../therapists/api'
import { ReservationMethod, ReservationPaymentStatus, ReservationUrgency, Therapist } from '../../therapists/data-types'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { optionalParseInt } from '../../../../utils/optional-parse-number'
import { DeleteButton, EditButton } from '../../../../_core/components/icons'
import CustomRadioButton from '../../../../_core/components/table/custom-radio-button'
import replaceWithPersianDigits from '../../../../utils/replace-with-persian-digits'
import CalenderDialog from './calender-dialog'
import { CompactDialogState } from '../../../../_core/components/dialog-ui'
import { generateReservationStatusText } from './detail-dialog'
import Button, { ButtonSizes, ButtonVariants } from '../../../../_core/components/button'
import { useNavigate } from 'react-router-dom'
import { DialogTypes, useDialogStore } from '../../_components/Dialog'

export const TimeSchema = z.object({
    date: z.coerce.string().optional(),
    hour: z.coerce.string().optional(),
    status: z.nativeEnum(ReservationStatus).optional(),
})

export const ReservationDetailSchema = z.object({
    id: z.number().optional(),
    selected_patients_id: z.number().optional(),
    selected_therapists_id: z.number().optional(),
    payment_status: z.nativeEnum(ReservationPaymentStatus).optional(),
    urgency: z.nativeEnum(ReservationUrgency).optional(),
    method: z.nativeEnum(ReservationMethod).optional(),
    number: z.nativeEnum(ReservationMethod).optional(),
    times: z.array(TimeSchema).optional(),
})

export type ReservationDetail = z.infer<typeof ReservationDetailSchema>
export type Time = z.infer<typeof TimeSchema>

interface ReservationFormProps {
    type: 'ADD' | 'UPDATE'
    reservationDetail?: ReservationDetail
    isLoading?: boolean
}

const ReservationForm: FC<ReservationFormProps> = ({ type, reservationDetail }) => {
    const navigate = useNavigate()

    const [searchedPatient, setSearchedPatient] = useState<string>('')
    const [searchedTherapist, setSearchedTherapist] = useState<string>('')
    const [appointmentsToBeSet, setAppointmentsToBeSet] = useState<CompactDialogState<number>>(null)

    const { control, watch, setValue, handleSubmit, ...otherFormStuff } = useForm<ReservationDetail>({
        defaultValues: reservationDetail,
        resolver: zodResolver(ReservationDetailSchema),
    })

    const { remove } = useFieldArray({ control, name: 'times' })

    const {
        data: patients,
        isPending: arePatientsPending,
        isFetching: arePatientsFetching,
    } = useQuery({
        queryKey: ['patients'],
        queryFn: getPatients,
        staleTime: 0,
        gcTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
    })

    const selectedPatient = patients?.find((patient) => watch('selected_patients_id') === patient.id)

    const arePatientsLoading = useMemo(
        () => arePatientsPending || arePatientsFetching,
        [arePatientsFetching, arePatientsPending],
    )

    const {
        data: therapists,
        isPending: areTherapistsPending,
        isFetching: areTherapistsFetching,
    } = useQuery({
        queryKey: ['therapists'],
        queryFn: getTherapists,
        staleTime: 0,
        gcTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
    })

    const selectedTherapist = therapists?.find((therapist) => watch('selected_therapists_id') === therapist.id)

    const areTherapistsLoading = useMemo(
        () => areTherapistsPending || areTherapistsFetching,
        [areTherapistsFetching, areTherapistsPending],
    )

    const { setDialogState } = useDialogStore()

    const { mutate: addReservation, isPending: isAddingReservationPending } = useMutation({
        mutationKey: ['reservations'],
        mutationFn: bookAppointment,
        onSuccess: () => {
            setDialogState({
                open: true,
                title: 'ثبت نوبت (های) جدید',
                type: DialogTypes.success,
                description: 'ثبت با موفقیت انجام شد.',
            })
            navigate('/cms/directReservation')
        },
        onError: () =>
            setDialogState({
                open: true,
                title: 'خطا در ثبت نوبت (ها)',
                type: DialogTypes.error,
                description: 'ثبت انجام نشد.',
            }),
    })

    const { mutate: updateReservation, isPending: isUpdatingReservationPending } = useMutation({
        mutationKey: ['Reservation', 'Reservation-detail'],
        mutationFn: updateAppointment,
        onSuccess: () => {
            setDialogState({
                open: true,
                title: 'ویرایش نوبت (های) جدید',
                type: DialogTypes.success,
                description: 'ویرایش با موفقیت انجام شد.',
            })
            navigate('/cms/directReservation')
        },
        onError: () =>
            setDialogState({
                open: true,
                title: 'خطا در ویرایش نوبت (ها)',
                type: DialogTypes.error,
                description: 'ویرایش انجام نشد.',
            }),
    })

    const onSubmit = useCallback(
        (data: ReservationDetail) => {
            if (type === 'ADD') addReservation(data)
            if (type === 'UPDATE') updateReservation(data)
        },
        [addReservation, type, updateReservation],
    )

    const onError = useCallback(
        (e: any) => {
            console.log(e)
            setDialogState({
                open: true,
                title: 'فرم ناقص',
                type: DialogTypes.error,
                description: 'اطلاعات فرم را چک کنید.',
            })
        },
        [setDialogState],
    )

    const handleKeyDown: KeyboardEventHandler<HTMLFormElement> = (e) => {
        if (e.key === 'Enter') e.preventDefault()
    }

    const patientHeadCells = useMemo(
        () => [
            Column<Patient>({
                field: 'id',
                headerName: 'کد',
                align: 'right',
                sortable: false,
                valueGetter: (_, row) => `${row.id}`,
                headerAlign: 'left',
                size: Sizes.small,
            }),
            Column<Patient>({
                field: 'name',
                headerName: 'نام',
                align: 'right',
                sortable: false,
                valueGetter: (_, row) => `${row.name}`,
                headerAlign: 'left',
                size: Sizes.small,
            }),
            Column<Patient>({
                field: 'phone',
                headerName: 'شماره موبایل',
                sortable: false,
                valueGetter: (_, row) => `${row.phone}`,
                variant: ColumnVariants.withPhone,
                headerAlign: 'right',
                size: Sizes.small,
            }),
            Column<Patient>({
                field: 'meetingType',
                headerName: 'نوع جلسات',
                align: 'right',
                sortable: false,
                valueGetter: (_, row) => `${row.meetingType}`,
                headerAlign: 'left',
                size: Sizes.small,
            }),
            Column<Patient>({
                field: 'timeZone',
                headerName: 'موقعیت زمانی',
                align: 'right',
                sortable: false,
                valueGetter: (_, row) => `${row.timeZone}`,
                headerAlign: 'left',
                size: Sizes.small,
            }),
            Column<Patient>({
                field: 'subscriptionType',
                headerName: 'نوع اشتراک',
                align: 'right',
                sortable: false,
                valueGetter: (_, row) => `${row.subscriptionType}`,
                headerAlign: 'left',
                size: Sizes.small,
            }),
        ],
        [],
    )

    const therapistHeadCells = useMemo(
        () => [
            Column<Therapist>({
                field: 'id',
                headerName: 'کد',
                sortable: false,
                valueGetter: (_, row) => `${row.id}`,
                headerAlign: 'left',
                align: 'right',
                size: Sizes.small,
            }),
            Column<Therapist>({
                field: 'name',
                headerName: 'نام',
                sortable: false,
                valueGetter: (_, row) => `${row.name}`,
                headerAlign: 'left',
                align: 'right',
                size: Sizes.small,
            }),
            Column<Therapist>({
                field: 'phone',
                headerName: 'شماره موبایل',
                sortable: false,
                valueGetter: (_, row) => `${row.phone ?? ''}`,
                variant: ColumnVariants.withPhone,
                headerAlign: 'right',
                size: Sizes.small,
            }),
            Column<Therapist>({
                field: 'timezone',
                headerName: 'موقعیت زمانی',
                sortable: false,
                valueGetter: (_, row) => `${row.timezone ?? ''}`,
                headerAlign: 'left',
                align: 'right',
                size: Sizes.small,
            }),
            Column<Therapist>({
                field: 'status',
                headerName: 'وضعیت فعالیت',
                sortable: false,
                valueGetter: (_, row) => `${row.status ? 'فعال' : 'غیرفعال'}`,
                renderCell(props) {
                    return (
                        <span className={cn(props.row.status ? 'text-success-400' : 'text-alarm-500')}>
                            {props.value}
                        </span>
                    )
                },
                headerAlign: 'left',
                align: 'right',
                size: Sizes.small,
            }),
        ],
        [],
    )

    return (
        <CMSLayout>
            <div className='text-sm mb-5'>
                <span className='text-gray-400'>نوبت دهی / </span>
                <span className='text-gray-600 font-medium'>
                    {type === 'ADD' ? 'نوبت دهی جدید' : 'ویرایش نوبت دهی'}
                </span>
            </div>

            <FormProvider
                control={control}
                watch={watch}
                setValue={setValue}
                handleSubmit={handleSubmit}
                {...otherFormStuff}
            >
                <form
                    className='grid grid-cols-4 gap-4'
                    onKeyDown={handleKeyDown}
                    onSubmit={handleSubmit(onSubmit, onError)}
                >
                    <div className='col-span-3 flex flex-col gap-4'>
                        <Section className='relative'>
                            {selectedPatient ? (
                                <div className='text-gray-700 font-medium text-sm mb-9'>درمانجو</div>
                            ) : (
                                <TextFieldWithoutControl
                                    label='درمانجو'
                                    placeholder='جستجو...'
                                    containerClassName='w-64'
                                    onChange={(event) => setSearchedPatient(event.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <SearchNormal1 />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}

                            {selectedPatient && (
                                <DeleteButton
                                    onClick={() => setValue('selected_patients_id', undefined)}
                                    className='absolute top-[1.125rem] left-[1.125rem] size-10 cursor-pointer'
                                />
                            )}

                            <DataGrid
                                autoHeight
                                keepNonExistentRowsSelected
                                loading={arePatientsLoading}
                                className={cn(
                                    'add-data-grid max-h-96',
                                    arePatientsLoading && '*:opacity-50 *:pointer-events-none',
                                    arePatientsLoading && '[&_.MuiDataGrid-virtualScrollerContent]:!h-0',
                                )}
                                rows={
                                    selectedPatient
                                        ? [selectedPatient]
                                        : patients?.filter((item) =>
                                              item.name.toLowerCase().includes(searchedPatient.toLowerCase()),
                                          ) ?? []
                                }
                                onRowClick={(props) => setValue('selected_patients_id', optionalParseInt(props.id))}
                                columns={patientHeadCells}
                                slots={{
                                    baseCheckbox: CustomCheckbox,
                                    columnResizeIcon: () => <></>,
                                    loadingOverlay: () => <></>,
                                    noRowsOverlay: () => <></>,
                                }}
                                hideFooter
                            />
                        </Section>

                        <Section className='relative'>
                            {selectedTherapist ? (
                                <div className='text-gray-700 font-medium text-sm mb-9'>درمانگر</div>
                            ) : (
                                <TextFieldWithoutControl
                                    label='درمانگر'
                                    placeholder='جستجو...'
                                    containerClassName='w-64'
                                    onChange={(event) => setSearchedTherapist(event.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <SearchNormal1 />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}

                            {selectedTherapist && (
                                <DeleteButton
                                    onClick={() => setValue('selected_therapists_id', undefined)}
                                    className='absolute top-[1.125rem] left-[1.125rem] size-10 cursor-pointer'
                                />
                            )}

                            <DataGrid
                                autoHeight
                                keepNonExistentRowsSelected
                                loading={areTherapistsLoading}
                                className={cn(
                                    'add-data-grid max-h-96',
                                    areTherapistsLoading && '*:opacity-50 *:pointer-events-none',
                                    areTherapistsLoading && '[&_.MuiDataGrid-virtualScrollerContent]:!h-0',
                                )}
                                rows={
                                    selectedTherapist
                                        ? [selectedTherapist]
                                        : therapists?.filter((item) =>
                                              item.name.toLowerCase().includes(searchedTherapist.toLowerCase()),
                                          ) ?? []
                                }
                                onRowClick={(props) => setValue('selected_therapists_id', optionalParseInt(props.id))}
                                columns={therapistHeadCells}
                                slots={{
                                    baseCheckbox: CustomCheckbox,
                                    columnResizeIcon: () => <></>,
                                    loadingOverlay: () => <></>,
                                    noRowsOverlay: () => <></>,
                                }}
                                hideFooter
                            />
                        </Section>

                        <Section className='p-[0.875rem]'>
                            <button
                                type='button'
                                className='flex gap-3 items-center disabled:opacity-50'
                                disabled={
                                    watch('selected_therapists_id') === undefined ||
                                    watch('selected_patients_id') === undefined
                                }
                                onClick={() => setAppointmentsToBeSet([watch('times')?.length ?? 0])}
                            >
                                <AddCircle
                                    variant='Bold'
                                    className='size-10 rounded-full text-primary-500'
                                />

                                <div className='text-gray-700'>
                                    افزودن زمان و تاریخ نوبت {(watch('times')?.length ?? 0) + 1}
                                </div>
                            </button>

                            {(watch('times')?.length ?? 0) > 0 && (
                                <div className='flex flex-col divide-y divide-gray-100'>
                                    {watch('times')?.map((time, index) => (
                                        <div
                                            className='flex items-center py-4'
                                            key={index}
                                        >
                                            <div className='text-gray-800'>
                                                نوبت {index + 1}: {time.date?.replaceAll('-', '/')} - {time.hour}
                                            </div>

                                            <div
                                                className={cn(
                                                    'text-xs text-gray-400 bg-gray-100 mr-5 px-2 py-1 rounded-full',
                                                    time.status === ReservationStatus.Pending &&
                                                        'text-alarm-500 bg-[#FFE6E6]',
                                                    time.status === ReservationStatus.Cancelled &&
                                                        'text-gray-400 bg-gray-100',
                                                    time.status === ReservationStatus.Active &&
                                                        'text-[#FA8D34] bg-[#FFF0E3]',
                                                    time.status === ReservationStatus.Past &&
                                                        'text-success-500 bg-[#DAF6E8]',
                                                )}
                                            >
                                                {generateReservationStatusText(time.status)}
                                            </div>

                                            <button
                                                type='button'
                                                className='mr-auto'
                                            >
                                                <EditButton
                                                    className='size-10'
                                                    onClick={() => setAppointmentsToBeSet([index])}
                                                />
                                            </button>

                                            <button
                                                type='button'
                                                className='mr-2'
                                            >
                                                <DeleteButton
                                                    className='size-10'
                                                    onClick={() => remove(index)}
                                                />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            )}

                            <CalenderDialog
                                compactDialogState={appointmentsToBeSet}
                                setCompactDialogState={setAppointmentsToBeSet}
                            />
                        </Section>

                        <div className='flex gap-4 mt-5'>
                            <Button
                                disabled={isAddingReservationPending || isUpdatingReservationPending}
                                type='submit'
                                className='w-[9.375rem] text-gray-50'
                                size={ButtonSizes.bigger}
                            >
                                {isAddingReservationPending || isUpdatingReservationPending ? (
                                    <Refresh className='animate-spin' />
                                ) : type === 'ADD' ? (
                                    'ثبت نهایی'
                                ) : (
                                    'ثبت نهایی ویرایش'
                                )}
                            </Button>

                            <Button
                                type='button'
                                size={ButtonSizes.big}
                                className='w-[9.375rem]'
                                variant={ButtonVariants.gray}
                                onClick={() => navigate('/cms/directReservation')}
                            >
                                انصراف
                            </Button>
                        </div>
                    </div>

                    <div className='col-span-1 flex flex-col gap-2 *:rounded'>
                        <Section title='نوع ویزیت'>
                            <div className='grid grid-cols-2 gap-x-1 gap-y-5'>
                                <CustomRadioButton<ReservationDetail>
                                    label='دریافت نوبت'
                                    name='urgency'
                                    value={ReservationUrgency.normal}
                                />

                                <CustomRadioButton<ReservationDetail>
                                    label='مشاوره فوری'
                                    name='urgency'
                                    value={ReservationUrgency.urgent}
                                />
                            </div>
                        </Section>

                        <Section title='وضعیت پرداخت'>
                            <div className='grid grid-cols-2 gap-x-1 gap-y-5'>
                                <CustomRadioButton<ReservationDetail>
                                    label='پرداخت شده'
                                    name='payment_status'
                                    value={ReservationPaymentStatus.paid}
                                />

                                <CustomRadioButton<ReservationDetail>
                                    label='پرداخت نشده'
                                    name='payment_status'
                                    value={ReservationPaymentStatus.notPaid}
                                />
                            </div>
                        </Section>

                        <Section title='نوع ویزیت'>
                            <div className='grid grid-cols-2 gap-x-1 gap-y-5'>
                                <CustomRadioButton<ReservationDetail>
                                    label='مشاوره آنلاین'
                                    name='method'
                                    value={ReservationMethod.online}
                                />

                                <CustomRadioButton<ReservationDetail>
                                    label='مشاوره تلفنی'
                                    name='method'
                                    value={ReservationMethod.byPhone}
                                />

                                <CustomRadioButton<ReservationDetail>
                                    label='مشاوره حضوری'
                                    name='method'
                                    value={ReservationMethod.inPerson}
                                />
                            </div>
                        </Section>

                        <Section title='تعداد نوبت ها'>
                            <div className='grid grid-cols-2 gap-x-1 gap-y-5'>
                                {[...Array(10)].map((_, index) => (
                                    <CustomRadioButton<ReservationDetail>
                                        label={`${replaceWithPersianDigits(index + 1)} نوبت`}
                                        name='number'
                                        value={index + 1}
                                        key={index}
                                    />
                                ))}
                            </div>
                        </Section>
                    </div>
                </form>
            </FormProvider>
        </CMSLayout>
    )
}

export default ReservationForm
