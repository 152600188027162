import { useCallback, useEffect, useRef, useState } from 'react'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import { Calendar, CalendarProvider } from 'zaman'
import { CustomTextFieldProps, TextFieldWithoutControl } from '../AddUser/components/text-field'
import { cn } from '../../../utils/cn'
import { TextFieldProps } from '@mui/material'

interface CustomZamanProps<T extends FieldValues> extends Omit<TextFieldProps, 'variant'>, CustomTextFieldProps {
    control: Control<T, keyof T>
    name: Path<T>
}

function CustomZaman<T extends FieldValues>({ control, name, generateValue, ...textFieldProps }: CustomZamanProps<T>) {
    const [isOpen, setIsOpen] = useState(false)

    const open = useCallback(() => {
        setIsOpen(true)
    }, [])

    const close = useCallback(() => {
        setIsOpen(false)
    }, [])

    const container = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const buttons = container.current?.querySelectorAll('button')

        buttons?.forEach((button) => {
            button.type = 'button'
        })

        const handleClickOutside = (event: MouseEvent) => {
            if (container.current && !container.current.contains(event.target as Node)) close()
        }

        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [close])

    return (
        <CalendarProvider
            locale='fa'
            round='x2'
            accentColor='#018780'
            direction='rtl'
        >
            <Controller
                control={control}
                name={name}
                render={({ field: { onChange, value } }) => (
                    <div
                        ref={container}
                        className='zaman-container relative'
                    >
                        <TextFieldWithoutControl
                            value={generateValue?.(value) ?? value}
                            onFocus={open}
                            {...textFieldProps}
                        />

                        <div
                            className={cn(
                                'absolute z-50 mt-2 right-0 opacity-0 h-0 pointer-events-none transition-opacity',
                                isOpen && 'opacity-100 pointer-events-auto',
                            )}
                        >
                            <Calendar
                                onChange={({ value }) => onChange(value.toISOString())}
                                defaultValue={value ? new Date(value) : new Date()}
                            />
                        </div>
                    </div>
                )}
            />
        </CalendarProvider>
    )
}

export default CustomZaman

// function parseShamsiDateTime(dateTimeString?: string): Date | null {
//     // Example input: "دوشنبه- ۱۴۰۳/۰۱/۰۳- ساعت ۱۶:۰۰"

//     if (!dateTimeString) return null

//     // Extract the Shamsi date and time from the string
//     const dateRegex = /(\d{4})\/(\d{2})\/(\d{2})/ // To match the Jalali date format
//     const timeRegex = /(\d{2}):(\d{2})/ // To match the time

//     const dateMatch = dateTimeString.match(dateRegex)
//     const timeMatch = dateTimeString.match(timeRegex)

//     if (!dateMatch || !timeMatch) {
//         return null // Return null if the date or time format is incorrect
//     }

//     // Extract the date parts
//     const [, year, month, day] = dateMatch.map(Number)
//     const [, hour, minute] = timeMatch.map(Number)

//     // Construct a Shamsi date string in a parsable format (e.g., 1403-01-03 16:00)
//     const shamsiDateString = `${year}-${month}-${day} ${hour}:${minute}`

//     // Parse the Jalali date using date-fns-jalali
//     const parsedDate = parse(shamsiDateString, 'yyyy-MM-dd HH:mm', new Date())

//     // Convert to JavaScript Date
//     return toDate(parsedDate)
// }

// const persianDays = ['یکشنبه', 'دوشنبه', 'سه‌شنبه', 'چهارشنبه', 'پنج‌شنبه', 'جمعه', 'شنبه']

// function formatToShamsiDateTime(date: Date): string {
//     // Get the Persian day name
//     const dayOfWeek = date.getDay() // Sunday = 0, Saturday = 6
//     const persianDayName = persianDays[dayOfWeek]

//     // Format the Jalali date string using date-fns-jalali
//     const jalaliDate = format(date, 'yyyy/MM/dd')

//     // Format the time in 24-hour format
//     const timeString = format(date, 'HH:mm')

//     // Combine everything to get the desired output format
//     return `${persianDayName}- ${jalaliDate}- ساعت ${timeString}`
// }
