import { FC } from 'react'
import Section from './section'
import { CustomAutocomplete, CustomTextField } from '../../AddUser/components/text-field'
import { TestDetail } from '../data-types'
import { TabChildProps } from './test-form'
import CustomSwitch from '../../AddUser/components/switch'
import addCommas from '../../../../utils/add-commas'
import toEnglishNumber from '../../../../utils/to-english-number'

const Settings: FC<TabChildProps> = ({ control }) => {
    return (
        <>
            <Section
                className='p-6'
                title='هزینه آزمون و تخفیفات'
            >
                <div className='grid grid-cols-3 gap-6'>
                    <CustomTextField<TestDetail>
                        name='priceRial'
                        label='هزینه داخلی'
                        adornment='تومان'
                        control={control}
                        generateOnChange={(value) => toEnglishNumber(value.replace(/[^0-9\u06F0-\u06F9]/g, ''))}
                        generateValue={(value) => addCommas(value?.toString() ?? '')}
                    />

                    <CustomTextField<TestDetail>
                        name='priceDollar'
                        label='هزینه خارجی'
                        adornment='دلار'
                        control={control}
                        generateOnChange={(value) => toEnglishNumber(value.replace(/[^0-9\u06F0-\u06F9]/g, ''))}
                        generateValue={(value) => addCommas(value?.toString() ?? '')}
                    />

                    <CustomTextField<TestDetail>
                        name='discount'
                        label='تخفیف'
                        adornment='درصد'
                        control={control}
                        generateOnChange={(value) => {
                            const newVal = toEnglishNumber(value.replace(/[^0-9\u06F0-\u06F9]/g, ''))
                            if (newVal === '') return ''
                            if (newVal > 100) return 100
                            else if (newVal < 0) return 0
                            return newVal
                        }}
                        generateValue={(value) => value?.toString() ?? ''}
                    />
                </div>
            </Section>

            <Section
                title='تنظیمات آزمون'
                className='p-6'
            >
                <div className='grid grid-cols-2 gap-6'>
                    <CustomTextField<TestDetail>
                        name='questionCount'
                        generateOnChange={(value) => toEnglishNumber(value.replace(/[^0-9\u06F0-\u06F9]/g, ''))}
                        generateValue={(value) => value?.toString() ?? ''}
                        label='تعداد سوالات در هر صفحه'
                        control={control}
                    />

                    <CustomAutocomplete<TestDetail>
                        name='style'
                        control={control}
                        options={['CARD', 'LIST', 'TABLE']}
                        label='چیدمان آزمون'
                    />
                </div>

                <CustomSwitch
                    control={control}
                    name='showPageNumber'
                    label='نمایش شماره صفحه'
                />

                <CustomSwitch
                    control={control}
                    name='showAnsweredCount'
                    label='نمایش تعداد سوال‌های پاسخ داده شده'
                />

                <CustomSwitch
                    control={control}
                    name='showTimer'
                    label='نمایش تایمر'
                />

                <CustomSwitch
                    control={control}
                    name='showBar'
                    label='نمایش نوار وضعیت'
                />

                <CustomSwitch
                    control={control}
                    name='showSkip'
                    label='نمایش لینک گزینه ی بعدی'
                />

                <CustomSwitch
                    control={control}
                    name='showPageLinks'
                    label='نمایش لینک شماره صفحه'
                />

                <CustomSwitch
                    control={control}
                    name='showBackNextButtons'
                    label='نمایش کلیدهای قبلی و بعدی'
                />
            </Section>

            <Section
                className='p-6'
                title='تنظیمات نتیجه آزمون'
            >
                <CustomAutocomplete<TestDetail>
                    name='indicatorStyle'
                    label='چیدمان شاخص‌ها و خرده شاخص‌ها'
                    control={control}
                    options={['TREEVIEW', 'Other']}
                />

                <CustomSwitch
                    control={control}
                    name='isTherapistOnly'
                    label='نتیجه فقط با مشاور'
                />

                <CustomSwitch
                    control={control}
                    name='isAiAvailable'
                    label='هوش مصنوعی'
                />

                <CustomSwitch
                    control={control}
                    name='showIndicatorGraph'
                    label='نمایش نمودار برای شاخص‌ها'
                />

                <CustomSwitch
                    control={control}
                    name='showSubIndicatorGraph'
                    label='نمایش نمودار برای خرده شاخص‌ها'
                />
            </Section>
        </>
    )
}

export default Settings
