import { StarterKit } from '@tiptap/starter-kit'
import { FontFamily } from '@tiptap/extension-font-family'
import { TextStyle } from '@tiptap/extension-text-style'
import { Underline } from '@tiptap/extension-underline'
import { TextAlign } from '@tiptap/extension-text-align'
import { TableCell } from '@tiptap/extension-table-cell'
import { TableHeader } from '@tiptap/extension-table-header'
import { TableRow } from '@tiptap/extension-table-row'
import { Link } from '@tiptap/extension-link'
import {
    RichTextEditor,
    MenuControlsContainer,
    MenuButtonBold,
    MenuButtonItalic,
    MenuButtonUnderline,
    MenuButtonStrikethrough,
    MenuDivider,
    MenuSelectTextAlign,
    type RichTextEditorProps,
    MenuSelectFontSize,
    FontSize,
    MenuButtonAddTable,
    TableImproved,
    LinkBubbleMenuHandler,
    MenuButtonEditLink,
    MenuButtonHorizontalRule,
    MenuButtonRedo,
    MenuButtonUndo,
    LinkBubbleMenu,
} from 'mui-tiptap'
import CharacterCount from '@tiptap/extension-character-count'
import type { FC } from 'react'
import { cn } from '../../../utils/cn'

const CustomLinkExtension = Link.extend({
    inclusive: false,
})

const RichTextBox: FC<Omit<RichTextEditorProps, 'extensions'> & { error?: string; limit?: number }> = ({
    error,
    limit,
    ...field
}) => {
    return (
        <RichTextEditor
            className={cn('!flex !flex-col-reverse rich-text-box', error && 'red-border')}
            extensions={[
                StarterKit,
                Underline,
                FontFamily,
                TextAlign.configure({
                    types: ['heading', 'paragraph'],
                }),
                TextStyle,
                FontSize,
                TableImproved,
                TableRow,
                TableHeader,
                TableCell,
                CustomLinkExtension.configure({
                    autolink: true,
                    linkOnPaste: true,
                    openOnClick: false,
                }),
                LinkBubbleMenuHandler,
                CharacterCount.configure({
                    limit: limit ?? 250,
                }),
            ]}
            renderControls={() => (
                <MenuControlsContainer>
                    <MenuButtonBold />
                    <MenuButtonItalic />
                    <MenuButtonUnderline />
                    <MenuButtonStrikethrough />

                    <MenuDivider />

                    <MenuSelectFontSize />
                    <MenuSelectTextAlign />

                    <MenuDivider />

                    <MenuButtonAddTable />
                    <MenuButtonEditLink />
                    <MenuButtonHorizontalRule />

                    <MenuDivider />

                    <MenuButtonUndo />
                    <MenuButtonRedo />
                </MenuControlsContainer>
            )}
            {...field}
        >
            {(editor) => (
                <>
                    <LinkBubbleMenu />

                    {error && <label className='mt-1 mr-[14px] text-alarm-500 text-xs'>{error}</label>}

                    {limit !== Infinity && (
                        <div className='text-xs text-gray-500 mt-1 mr-[14px]'>
                            حروف: {editor?.storage.characterCount.characters()}/{limit ?? 250}
                        </div>
                    )}
                </>
            )}
        </RichTextEditor>
    )
}
export default RichTextBox

// TODO align text does not work
