import * as yup from 'yup'
import axios from 'axios'
import AuthLayout from '../../../_core/components/layouts/auth-layout/AuthLayout'
import { toast } from 'react-toastify'
import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'

interface IFormInput {
    password: string
    passwordConfirm: string
}

const CreatePasswordPage = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const id = searchParams.get('id')

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const schema = yup
        .object({
            password: yup.string().required('پسورد اجباری است.'),
            passwordConfirm: yup
                .string()
                .required('تکرار پسورد اجباری است.')
                .oneOf([yup.ref('password')], 'پسورد باید مطابقت داشته باشد'),
        })
        .required()

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<IFormInput>({ resolver: yupResolver(schema) })

    const onSubmit = async (formData: IFormInput) => {
        setIsLoading(true)

        const newData = {
            id: id,
            password: formData.password,
        }

        try {
            const response = await axios.post(
                `https://stage2-api.insight-clinic.com/controller/set_new_password_v2.php`,
                newData,
                {
                    headers: {
                        AuthorizationKey: '133079ba-9b43-11ee-b9d1-0242ac120002',
                    },
                },
            )
            const data = await response
            if (data.data.status === 'success') {
                navigate('/auth/login')
            }
        } catch (error) {
            console.log('error', error)
        } finally {
            setIsLoading(false)
        }
    }

    const isDisabled = isLoading || errors?.password !== undefined || errors.passwordConfirm !== undefined

    return (
        <AuthLayout>
            <h1 className='text-2xl text-black '>ایجاد پسورد</h1>
            <p className='text-xl text-gray-800 mt-12'>یک پسورد برای خود را وارد کنید.</p>

            <form
                className='w-full'
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className='mt-10'>
                    <label className='w-full block text-gray-400 mb-2'>پسورد</label>
                    <Controller
                        name='password'
                        control={control}
                        render={({ field: { name, onChange, value } }) => (
                            <>
                                <input
                                    type='password'
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    placeholder=' پسورد را وارد کنید ...'
                                    className='w-full h-12 flex px-4 items-center border border-gray-300 outline-none rounded-lg'
                                />
                                <p className='h-5 text-xs text-red-500 mt-1'>{errors.password?.message}</p>
                            </>
                        )}
                    />
                </div>

                <div className='mt-10'>
                    <label className='w-full block text-gray-400 mb-2'>تایید پسورد</label>
                    <Controller
                        name='passwordConfirm'
                        control={control}
                        render={({ field: { name, onChange, value } }) => (
                            <>
                                <input
                                    type='password'
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    placeholder='  تکرار پسورد را وارد کنید ...'
                                    className='w-full h-12 flex px-4 items-center border border-gray-300 outline-none rounded-lg'
                                />
                                <p className='h-5 text-xs text-red-500 mt-1'>{errors.passwordConfirm?.message}</p>
                            </>
                        )}
                    />
                </div>

                <div className='mt-12'>
                    <button
                        type='submit'
                        disabled={isDisabled}
                        className={`block w-full h-12 rounded-lg ${
                            isDisabled ? 'bg-gray-300' : 'bg-insight-300 hover:opacity-75'
                        }`}
                    >
                        {isLoading ? 'در حال ...' : 'ورود'}
                    </button>
                </div>
            </form>
        </AuthLayout>
    )
}

export default CreatePasswordPage
