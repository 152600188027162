import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import DialogUi, { NecessaryDialogProps, useCompactDialog } from '../../../../_core/components/dialog-ui'
import { Select } from '../../../../_core/components/select'
import { ArrowLeft2, ArrowRight2, TickSquare } from 'iconsax-react'
import SelectTime, { make2Digits } from '../../../../_core/components/select/time'
import { ReservationMethod } from '../../therapists/data-types'
import { ReservationDetail, Time } from './reservation-form'
import Button, { ButtonSizes, ButtonVariants } from '../../../../_core/components/button'
import { useIsMutating, useMutation } from '@tanstack/react-query'
import { useFormContext } from 'react-hook-form'
import { getAvailableWorkingHours } from '../api'
import toGregorian, { toJalaliDate } from '../../../../utils/to-gregorian'
import { AvailableWorkingHours, EnglishWeekDays, Hour, ReservationStatus } from '../data-types'
import CustomCheckbox from '../../../../_core/components/table/custom-checkbox'
import { cn } from '../../../../utils/cn'

type CompactStateType = number
const types = ['به وقت Tehran', 'به وقت UTC']
const initialTime = '00:00'
const days = generateNextNDays(730)

const CalenderDialog: FC<NecessaryDialogProps<CompactStateType>> = ({ compactDialogState, setCompactDialogState }) => {
    const { setValue, watch } = useFormContext<ReservationDetail>()
    const watchedSelectedDate = watch(`times.${compactDialogState?.[0] ?? -1}`)

    const [type, setType] = useState(types[0])
    const [time, setTime] = useState(initialTime)
    const [date, setDate] = useState(days[0])
    const [selectedDate, setSelectedDate] = useState<Time | undefined>(watchedSelectedDate)

    useEffect(() => {
        setSelectedDate(watchedSelectedDate)
    }, [watchedSelectedDate])

    const reset = useCallback(() => {
        setSelectedDate(undefined)
        setTime(initialTime)
        setType(types[0])
        setDate(days[0])
    }, [])

    useEffect(() => {
        if (compactDialogState === null) reset()
    }, [compactDialogState, reset])

    const { close } = useCompactDialog({ compactDialogState, setCompactDialogState })

    const submit = useCallback(() => {
        if (compactDialogState !== null && selectedDate !== undefined) {
            setValue(`times.${compactDialogState[0]}`, selectedDate)
            close()
        }
    }, [close, compactDialogState, selectedDate, setValue])

    const isMutating = useIsMutating({
        mutationKey: ['available-working-hours'],
    })

    return (
        <DialogUi<CompactStateType>
            dialogTitle={`افزودن تاریخ و زمان نوبت ${(watch('times')?.length ?? 0) + 1}`}
            generateTitle={(title) => <span className='text-primary-500'>{title}</span>}
            className='text-[#202020] h-[600px] max-h-[600px] min-h-[600px]'
            compactDialogState={compactDialogState}
            setCompactDialogState={setCompactDialogState}
        >
            <div
                className={cn(
                    'flex items-center justify-between gap-1 mb-5',
                    isMutating && 'opacity-50 pointer-events-none',
                )}
            >
                <Select
                    values={['به وقت Tehran', 'به وقت UTC']}
                    className='w-40 z-20'
                    value={type}
                    onChange={setType}
                />

                <div className='flex items-center gap-1'>
                    <button
                        type='button'
                        disabled={days.indexOf(date) === 0}
                        className='disabled:opacity-50'
                        onClick={() => setDate((prev) => days[days.indexOf(prev) - 7] ?? days[0])}
                    >
                        <ArrowRight2 className='size-6' />
                    </button>

                    <Select
                        values={days}
                        value={date}
                        onChange={setDate}
                        className='w-28 z-20'
                        generateValue={(date) => date.replaceAll('-', '/')}
                        generateListItem={(date) => date.replaceAll('-', '/')}
                    />

                    <SelectTime
                        value={time}
                        onChange={setTime}
                        className='w-20 z-20'
                    />

                    <button
                        type='button'
                        disabled={days.indexOf(date) === days.length - 1}
                        className='disabled:opacity-50'
                        onClick={() => setDate((prev) => days[days.indexOf(prev) + 7] ?? days[days.length - 1])}
                    >
                        <ArrowLeft2 className='size-6' />
                    </button>
                </div>
            </div>

            <Calender
                date={date}
                time={time}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                appointmentToBeSet={compactDialogState?.[0] ?? -1}
            />

            <div className='flex items-center justify-between gap-1'>
                <div className='text-gray-700 text-sm font-medium'>وضعیت نوبت {(watch('times')?.length ?? 0) + 1}:</div>

                <CustomCheckbox
                    label='برگزار نشده'
                    value={ReservationMethod.inPerson}
                    className='w-fit'
                    onChange={() => setSelectedDate((prev) => ({ ...prev, status: ReservationStatus.Pending }))}
                    checked={selectedDate?.status === ReservationStatus.Pending}
                />

                <CustomCheckbox
                    label='برگزار شده'
                    value={ReservationMethod.inPerson}
                    className='w-fit'
                    onChange={() => setSelectedDate((prev) => ({ ...prev, status: ReservationStatus.Past }))}
                    checked={selectedDate?.status === ReservationStatus.Past}
                />

                <CustomCheckbox
                    label='لغو شده'
                    value={ReservationMethod.inPerson}
                    className='w-fit'
                    onChange={() => setSelectedDate((prev) => ({ ...prev, status: ReservationStatus.Cancelled }))}
                    checked={selectedDate?.status === ReservationStatus.Cancelled}
                />

                <CustomCheckbox
                    label='درحال برگزاری'
                    value={ReservationMethod.inPerson}
                    className='w-fit'
                    onChange={() => setSelectedDate((prev) => ({ ...prev, status: ReservationStatus.Active }))}
                    checked={selectedDate?.status === ReservationStatus.Active}
                />
            </div>

            <div className='flex gap-4 mt-5'>
                <Button
                    type='button'
                    className='w-[9.375rem] text-gray-50 disabled:opacity-50'
                    size={ButtonSizes.bigger}
                    onClick={submit}
                    disabled={
                        selectedDate?.date === undefined ||
                        selectedDate?.hour === undefined ||
                        selectedDate?.status === undefined
                    }
                >
                    ثبت
                </Button>

                <Button
                    type='button'
                    size={ButtonSizes.big}
                    className='w-[9.375rem]'
                    variant={ButtonVariants.gray}
                    onClick={close}
                >
                    انصراف
                </Button>
            </div>
        </DialogUi>
    )
}

export default CalenderDialog

const weekDays: {
    [K in EnglishWeekDays]: string
} = {
    Saturday: 'شنبه',
    Sunday: 'یکشنبه',
    Monday: 'دوشنبه',
    Tuesday: 'سه شنبه',
    Wednesday: 'چهارشنبه',
    Thursday: 'پنجشنبه',
    Friday: 'جمعه',
}

interface CalenderProps {
    date: string
    time: string
    selectedDate: Time | undefined
    setSelectedDate: Dispatch<SetStateAction<Time | undefined>>
    appointmentToBeSet: CompactStateType
}

const Calender: FC<CalenderProps> = ({ date, time, selectedDate, setSelectedDate, appointmentToBeSet }) => {
    const { watch } = useFormContext<ReservationDetail>()

    const selected_therapists_id = watch('selected_therapists_id')
    const selected_patients_id = watch('selected_patients_id')

    const {
        data: availableWorkingHours,
        isPending,
        mutate,
    } = useMutation({
        mutationKey: ['available-working-hours'],
        mutationFn: getAvailableWorkingHours.bind(null, {
            id: watch('selected_therapists_id') ?? -1,
            date: toGregorian(date, '-'),
        }),
    })

    useEffect(() => {
        if (selected_therapists_id !== undefined && selected_patients_id !== undefined) mutate()
    }, [mutate, selected_patients_id, selected_therapists_id, date])

    const scrollToElement = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (scrollToElement.current) scrollToElement.current.scrollIntoView({ behavior: 'smooth' })
    }, [time])

    const populatedHours = useMemo(() => populateHours(availableWorkingHours ?? []), [availableWorkingHours])

    if (isPending) return <div className='grow flex items-center justify-center text-center'>در حال بارگذاری...</div>

    if (availableWorkingHours === undefined)
        return <div className='grow flex items-center justify-center text-center'>مشکلی پیش آمد!</div>

    return (
        <div className='mb-8 grow overflow-y-auto h-0 outline outline-[#EDEDED] outline-1 -outline-offset-1'>
            <div className='grid grid-cols-8 divide-x divide-y divide-x-reverse divide-[#EDEDED] *:py-2 *:flex *:items-center *:justify-center *:text-center *:text-sm *:font-medium *:scroll-mt-[57px] *:transition-all'>
                <div />

                {/* Table Head */}
                {[...Array(7)].map((_, index) => (
                    <div
                        key={index}
                        className='sticky top-0 z-10 bg-white shadow-[0_1px_0_0_#EDEDED]'
                    >
                        {populatedHours[index].date.split('-')[2]}
                        <br />
                        {weekDays[populatedHours[index].day_name]}
                    </div>
                ))}

                {/* Table Body */}
                {[...Array(24)].map((_, hour) =>
                    [...Array(8)].map((_, itemDate) => {
                        // First Col
                        if (itemDate === 0) return <div key={hour + ' ' + itemDate}>{make2Digits(hour) + ':00'}</div>
                        // Rest of Cols
                        else {
                            const cell = populatedHours[itemDate - 1]
                            const cellHour = cell.hours[hour]

                            const isHighlighted = cellHour.hour === time && cell.date === date
                            const className = cn(
                                isHighlighted && 'shadow-[inset_0_0_0_1px] shadow-primary-500 bg-[#F8FFFB] relative',
                            )
                            const ref = isHighlighted ? scrollToElement : undefined

                            if (cellHour.hour === selectedDate?.hour && cell.date === selectedDate?.date)
                                return (
                                    <div
                                        key={hour + ' ' + itemDate}
                                        className={className}
                                        ref={ref}
                                    >
                                        <button type='button'>
                                            <TickSquare
                                                className='text-primary-500'
                                                variant='Bold'
                                                size={24}
                                            />
                                        </button>
                                    </div>
                                )

                            const justSelectedAppointmentsExceptThisOne = watch('times')?.filter(
                                (_, index) => index !== appointmentToBeSet,
                            )

                            const isSelectedBefore = justSelectedAppointmentsExceptThisOne?.some(
                                (time) => time.date === cell.date && time.hour === cellHour.hour,
                            )

                            if (isSelectedBefore)
                                return (
                                    <div
                                        key={hour + ' ' + itemDate}
                                        className={className}
                                        ref={ref}
                                    >
                                        <TickSquare
                                            className='text-primary-500'
                                            variant='Bold'
                                            size={24}
                                        />
                                    </div>
                                )
                            else if (cellHour.status === 1)
                                return (
                                    <div
                                        key={hour + ' ' + itemDate}
                                        className={className}
                                        ref={ref}
                                    >
                                        <button
                                            type='button'
                                            className='size-6 bg-primary-50 rounded'
                                            onClick={() =>
                                                setSelectedDate((prev) => ({
                                                    date: cell.date,
                                                    hour: cellHour.hour,
                                                    status: prev?.status,
                                                }))
                                            }
                                        />
                                    </div>
                                )
                            else if (cellHour.status === 0)
                                return (
                                    <div
                                        key={hour + ' ' + itemDate}
                                        className={className}
                                        ref={ref}
                                    >
                                        <div className='size-6 bg-gray-200 rounded' />
                                    </div>
                                )
                            else
                                return (
                                    <div
                                        key={hour + ' ' + itemDate}
                                        className={className}
                                        ref={ref}
                                    />
                                )
                        }
                    }),
                )}
            </div>
        </div>
    )
}

function generateNextNDays(n: number): string[] {
    const dates: string[] = []
    const today = new Date() // Get the current date

    for (let i = 0; i < n; i++) {
        const newDate = new Date(today) // Copy the current date
        newDate.setDate(today.getDate() + i) // Add 'i' days to the date
        dates.push(toJalaliDate(newDate, '-', '-')) // Add the new date to the array
    }

    return dates
}

const populateHours = (schedule: AvailableWorkingHours[]): AvailableWorkingHours[] => {
    return schedule.map(({ date, ...day }) => ({
        date: toJalaliDate(date, '-', '-'),
        ...day,
        hours: generateFullDayHours(day.hours),
    }))
}

const generateFullDayHours = (existingHours: Hour[]): Hour[] => {
    const fullHours: Hour[] = []

    for (let i = 0; i < 24; i++) {
        const hourString = i.toString().padStart(2, '0') + ':00'
        const existingHour = existingHours.find((hour) => hour.hour === hourString)
        if (existingHour) fullHours.push(existingHour)
        else fullHours.push({ hour: hourString, status: -1 })
    }

    return fullHours
}

// TODO use proper persian words instead of numbers
