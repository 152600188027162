import { FC, useMemo } from 'react'
import FaqForm from '../components/faq-form'
import { useQuery } from '@tanstack/react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import CMSLayout from '../../../../_core/components/layouts/cms-dashboard/CMSLayout'
import { Faq } from '../data-types'
import { getFaq } from '../api'

const UpdateFaq: FC = () => {
    const navigate = useNavigate()

    const [searchParams] = useSearchParams()

    const id = useMemo(() => parseInt(searchParams.get('id') ?? '-1'), [searchParams])

    if (isNaN(id)) navigate('/cms/faqs')

    const { data, isFetching } = useQuery<Faq>({
        queryKey: ['faqs', id],
        queryFn: () => getFaq(id),
    })

    if (isFetching)
        return (
            <CMSLayout>
                <div className='w-full h-full flex justify-center items-start'>
                    <span className='loader' />
                </div>
            </CMSLayout>
        )

    return (
        <FaqForm
            type='UPDATE'
            faq={data}
        />
    )
}

export default UpdateFaq
