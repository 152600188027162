import { FC } from 'react'
import { CustomAutocomplete, CustomTextField } from '../../AddUser/components/text-field'
import { TherapistDetail } from '../data-types'
import { useFormContext } from 'react-hook-form'
import Section from '../../Tests/components/section'
import CustomDatePicker from '../../_components/DatePicker'
import UploadSection from './parts/UploadSection'
import { GalleryAdd, Video } from 'iconsax-react'
import CustomPhoneInput from '../../AddUser/components/phone-input'

const ProfileTab: FC = () => {
    const { control } = useFormContext<TherapistDetail>()

    return (
        <>
            <Section>
                <div className='grid grid-cols-3 gap-x-4 gap-y-8 mb-6'>
                    <CustomTextField<TherapistDetail>
                        control={control}
                        name='name'
                        label='نام'
                        preventNumber
                    />

                    <CustomTextField<TherapistDetail>
                        control={control}
                        name='last_name'
                        label='نام خانوادگی'
                        preventNumber
                    />

                    <CustomAutocomplete<TherapistDetail>
                        label='پیشوند'
                        name='prefix'
                        control={control}
                        options={['دکتر', 'بدون پیشوند']}
                    />

                    <CustomAutocomplete<TherapistDetail>
                        label='جنسیت'
                        name='gender'
                        control={control}
                        options={['زن', 'مرد']}
                    />

                    {/* <CustomTextField<TherapistDetail>
                        control={control}
                        name='phone'
                        label='شماره موبایل'
                        dir='ltr'
                        generateOnChange={(value) => '+' + value?.replaceAll(/[^0-9]/g, '')}
                        generateValue={(value) => value?.replaceAll(/[^0-9]/g, '')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment
                                    className='*:!border-none !ml-4 [&_.MuiTypography-root]:!pr-0'
                                    position='start'
                                >
                                    +
                                </InputAdornment>
                            ),
                        }}
                    /> */}

                    <CustomPhoneInput<TherapistDetail> name='phone' />

                    <CustomDatePicker<TherapistDetail>
                        control={control}
                        name='dob'
                        label='تاریخ تولد'
                    />

                    <CustomDatePicker<TherapistDetail>
                        control={control}
                        name='start_date'
                        label='تاریخ شروع همکاری'
                    />
                </div>

                <CustomTextField<TherapistDetail>
                    control={control}
                    name='about'
                    label='درباره من'
                    multiline
                    minRows={3}
                    maxRows={3}
                    fullWidth
                    inputProps={{ maxLength: undefined }}
                />
            </Section>

            <Section title='بارگذاری تصویر'>
                <div className='flex gap-6'>
                    <UploadSection<TherapistDetail>
                        name='photo'
                        text='تصویر با حجاب'
                        Icon={GalleryAdd}
                        size='small'
                        accept='image/png, image/jpeg, image/jpg'
                        type='image'
                    />

                    <UploadSection<TherapistDetail>
                        name='photoNoHijab'
                        text='تصویر بی حجاب'
                        Icon={GalleryAdd}
                        size='small'
                        accept='image/png, image/jpeg, image/jpg'
                        type='image'
                    />
                </div>
            </Section>

            <Section title='بارگذاری ویدئو'>
                <CustomTextField<TherapistDetail>
                    control={control}
                    name='videoTitle'
                    label='عنوان ویدئوی معرفی'
                />

                <div className='flex gap-6'>
                    <UploadSection<TherapistDetail>
                        name='video'
                        text='ویدئو با حجاب'
                        Icon={Video}
                        size='big'
                        accept='video/mkv, video/mp4'
                        type='video'
                    />

                    <UploadSection<TherapistDetail>
                        name='videoNoHijab'
                        text='ویدئو بی حجاب'
                        Icon={Video}
                        size='big'
                        accept='video/mkv, video/mp4'
                        type='video'
                    />
                </div>
            </Section>

            <Section>
                <CustomAutocomplete<TherapistDetail>
                    placeholder='با زدن Enter رویکرد درمانی جدید اضافه کنید'
                    hasThePlusIcon
                    multiple
                    label='رویکرد درمانی'
                    name='treatment_ways'
                    control={control}
                    options={[
                        'زوج درمانی',
                        'طرحواره درمانی',
                        'درمان شناختی رفتاری',
                        'درمان مبتنی بر تعهد و پذیرش',
                        'درمان هیجان مدار',
                    ]}
                    freeSolo
                />

                <CustomAutocomplete<TherapistDetail>
                    placeholder='با زدن Enter سابقه تحصیلی جدید اضافه کنید'
                    hasThePlusIcon
                    multiple
                    label='سوابق تحصیلی'
                    name='education'
                    control={control}
                    options={[
                        'کارشناسی روانشناسی بالینی',
                        'کارشناسی روانشناسی عمومی',
                        'کارشناسی ارشد روانشناسی بالینی',
                        'کارشناسی ارشد روانشناسی عمومی',
                        'دکتری روانشناسی عمومی',
                    ]}
                    freeSolo
                />

                <CustomAutocomplete<TherapistDetail>
                    placeholder='با زدن Enter سابقه شغلی جدید اضافه کنید'
                    hasThePlusIcon
                    multiple
                    label='سوابق شغلی'
                    name='cv'
                    control={control}
                    options={[]}
                    freeSolo
                />

                <CustomAutocomplete<TherapistDetail>
                    placeholder='با زدن Enter گواهینامه یا دوره جدید اضافه کنید'
                    hasThePlusIcon
                    multiple
                    label='دوره‌ها و گواهینامه‌ها'
                    name='license'
                    control={control}
                    options={[]}
                    freeSolo
                />

                <CustomAutocomplete<TherapistDetail>
                    placeholder='با زدن Enter سابقه یا افتخار جدید اضافه کنید'
                    hasThePlusIcon
                    multiple
                    label='سوابق پژوهشی و افتخارات'
                    name='research'
                    control={control}
                    options={[]}
                    freeSolo
                />
            </Section>
        </>
    )
}

export default ProfileTab
