import { useFieldArray, useFormContext } from 'react-hook-form'
import { AddCircle } from 'iconsax-react'
import CustomZaman from '../../_components/CustomZaman'
import { WorkshopType } from './WorkshopForm'
import { DeleteButton } from '../../../../_core/components/icons'
import { useCallback } from 'react'
import formatDate from '../../../../utils/format-date'

const DatetimeSection = () => {
    const { control, getValues, setValue } = useFormContext<WorkshopType>()

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'datetime',
    })

    const updateIds = useCallback(() => {
        const currentValues = getValues('datetime')

        currentValues?.forEach((item, index) => {
            item.id = index + 1
        })

        setValue('datetime', currentValues)
    }, [getValues, setValue])

    return (
        <div className='flex flex-col gap-4'>
            <button
                type='button'
                onClick={() => append({ value: '', id: fields.length })}
                className='flex items-center gap-3 w-fit'
            >
                <AddCircle
                    size={30}
                    variant='Bold'
                    className='text-primary-500'
                />

                <div className='text-gray-700 text-sm'>افزودن تاریخ</div>
            </button>

            {fields.map((datetime, index) => (
                <CustomZaman<WorkshopType>
                    generateValue={(value) => `جلسه ${index + 1}: ` + formatDate(value)}
                    key={datetime.id}
                    label='تاریخ و زمان'
                    control={control}
                    name={`datetime.${index}.value`}
                    InputProps={{
                        endAdornment: (
                            <button
                                type='button'
                                onClick={() => {
                                    remove(index)
                                    updateIds()
                                }}
                            >
                                <DeleteButton />
                            </button>
                        ),
                    }}
                />
            ))}
        </div>
    )
}

export default DatetimeSection
