import { Logo } from '../../../icons'
import { Notification, SearchNormal } from 'iconsax-react'

const CMSSidebar = () => {
    return (
        <div className='bg-primary-700 flex h-[4.375rem] pr-9 pl-16 text-white items-center shrink-0 z-[99999] relative'>
            <div className='flex gap-2 items-center ml-20'>
                <Logo className='w-14' />
                <div className='text-sm font-medium'>کلینیک اینسایت</div>
            </div>

            <div className='flex gap-1 items-center'>
                <div className='w-12 h-12 bg-white rounded-full'></div>
                <div className='flex flex-col gap-1'>
                    <div className='text-sm font-medium'>باران اکبری</div>
                    <div className='text-xs font-medium'>درمانگر</div>
                </div>
            </div>

            <div className='grow' />

            <div className='relative ml-4'>
                <input
                    className='h-10 border border-white rounded-full bg-transparent p-2 pr-11 w-72 placeholder:text-white placeholder:text-sm text-white text-sm'
                    placeholder='جستجو کنید...'
                />
                <SearchNormal className='w-4 h-4 absolute right-5 inset-y-0 my-auto pointer-events-none' />
            </div>

            <Notification className='w-6 h-6' />
        </div>
    )
}

export default CMSSidebar
