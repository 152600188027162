import Switch, { SwitchProps } from '@mui/material/Switch'
import './index.css'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import { useMemo } from 'react'
import { cn } from '../../../../../utils/cn'
import CustomCheckbox from '../../../../../_core/components/table/custom-checkbox'

function CustomSwitch<T extends FieldValues>({
    control,
    name,
    label,
    inline,
    className,
    simple,
    disabled,
    ...props
}: SwitchProps & {
    control: Control<T, keyof T>
    name: Path<T>
    label?: string
    inline?: boolean
    simple?: boolean
}) {
    const mainComponent = useMemo(
        () => (
            <Controller
                control={control}
                name={name}
                disabled={disabled}
                render={({ field: { value, ...fieldProps } }) =>
                    simple ? (
                        <CustomCheckbox
                            className='w-fit'
                            checked={value}
                            {...fieldProps}
                            {...props}
                        />
                    ) : (
                        <Switch
                            checked={value}
                            {...fieldProps}
                            {...props}
                        />
                    )
                }
            />
        ),
        [control, disabled, name, props, simple],
    )

    if (label)
        return (
            <label
                className={cn(
                    'flex items-center justify-between',
                    inline && 'flex-row-reverse justify-normal w-fit gap-3',
                    className,
                )}
            >
                <div className='text-gray-700 text-sm'>{label}</div>
                {mainComponent}
            </label>
        )

    return mainComponent
}

export default CustomSwitch
