import { FC } from 'react'
import ListPage from '../_components/list-page'
import { deleteWorkshops, getWorkshops } from './api'
import DeleteConfirmDialog from '../_components/list-page/components/dialog'
import getWorkshopColumns from './constants'
import { useQueryClient } from '@tanstack/react-query'

const Bundles: FC = () => {
    const queryClient = useQueryClient()

    return (
        <ListPage
            apiFunction={getWorkshops}
            getColumns={getWorkshopColumns}
            itemName={'کارگاه'}
            addPageLink='./workshops/add-workshop'
            queryKey={['workshops']}
            accessName='Workshops'
            generateDialog={(dialogData, setDialogData, processedRows, selectionList) => (
                <DeleteConfirmDialog
                    onClose={() => setDialogData(false)}
                    accept={
                        dialogData === 'deleteAll'
                            ? () => {
                                  deleteWorkshops(selectionList ?? [])
                                  setTimeout(() => queryClient.invalidateQueries({ queryKey: ['workshops'] }), 1000)
                              }
                            : dialogData !== false
                            ? () => {
                                  deleteWorkshops([dialogData])
                                  setTimeout(() => queryClient.invalidateQueries({ queryKey: ['workshops'] }), 1000)
                              }
                            : () => null
                    }
                    open={dialogData !== false}
                    itemToBeDeleted={
                        dialogData === 'deleteAll'
                            ? undefined
                            : dialogData !== false
                            ? processedRows.filter((row) => row.id === dialogData)[0].title
                            : undefined
                    }
                />
            )}
        />
    )
}

export default Bundles

// TODO add delete api
