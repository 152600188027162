import {
    GridColDef,
    GridColumnHeaderParams,
    GridRenderCellParams,
    GridTreeNodeWithRender,
    GridValidRowModel,
} from '@mui/x-data-grid'
import CellWithProfile from './cell-with-profile'
import { ReactNode } from 'react'
import CellWithPhone from './cell-with-phone'

export enum Sizes {
    smaller = 'smaller',
    small = 'small',
    mediumSmall = 'mediumSmall',
    normal = 'normal',
    mediumBig = 'mediumBig',
    big = 'big',
    bigger = 'bigger',
}

export enum ColumnVariants {
    withProfile = 'withProfile',
    withPhone = 'withPhone',
}

interface ColumnProps<T extends GridValidRowModel, V = any, F = V> {
    field: string
    headerName: string
    valueGetter: (_: never, row: T) => string
    renderCell?: (props: GridRenderCellParams<T, any, any, GridTreeNodeWithRender>) => JSX.Element
    variant?: ColumnVariants
    size?: Sizes
    sortable?: boolean
    renderHeader?: (params: GridColumnHeaderParams<T, V, F>) => React.ReactNode
    align?: 'center' | 'right' | 'left'
    headerAlign?: 'center' | 'right' | 'left'
    generateProfile?: (row: T) => string
}

type ColumnFinal<T extends GridValidRowModel> = {
    [K in ColumnVariants]: (props: GridRenderCellParams<T, any, any, GridTreeNodeWithRender>) => ReactNode
}

type SizeFinal = {
    [K in Sizes]: { flex: number; minWidth: number }
}

export function Column<T extends GridValidRowModel>({
    field,
    headerName,
    valueGetter,
    variant,
    size = Sizes.normal,
    sortable = true,
    renderCell,
    renderHeader,
    headerAlign,
    generateProfile,
    align = 'center',
}: ColumnProps<T>) {
    const variants: ColumnFinal<T> = {
        withProfile: (props: GridRenderCellParams<T, any, any, GridTreeNodeWithRender>) => (
            <CellWithProfile
                value={props.value}
                initial={props.row.initial}
                color={props.row.color}
                image={generateProfile ? generateProfile(props.row) : props.row.profile_picture}
            />
        ),
        withPhone: (props: GridRenderCellParams<T, any, any, GridTreeNodeWithRender>) => (
            <CellWithPhone value={props.value} />
        ),
    }

    const sizes: SizeFinal = {
        smaller: { flex: 0.5, minWidth: 50 },
        small: { flex: 1, minWidth: 100 },
        mediumSmall: { flex: 1, minWidth: 130 },
        normal: { flex: 1, minWidth: 150 },
        mediumBig: { flex: 1, minWidth: 175 },
        big: { flex: 2, minWidth: 250 },
        bigger: { flex: 3, minWidth: 350 },
    }

    const column: GridColDef<T> = {
        field,
        headerName,
        valueGetter,
        ...(renderCell ? { renderCell } : variant && { renderCell: (props) => variants[variant](props) }),
        flex: sizes[size].flex,
        disableColumnMenu: true,
        minWidth: sizes[size].minWidth,
        align,
        resizable: false,
        sortable,
        renderHeader,
        headerAlign,
    }

    return column
}
