import { Dispatch, FC, SetStateAction, createContext, useCallback, useContext, useState } from 'react'
import { TabChildProps } from './test-form'
import Section from './section'
import { Add } from 'iconsax-react'
import {
    ArrangeVerticalSquare2,
    ArrowDown,
    ArrowUp,
    DeleteButton,
    RefreshSquare,
    UploadIcon,
} from '../../../../_core/components/icons'
import { useFieldArray, useFormContext } from 'react-hook-form'
import SimpleControlledInput, { CustomTextField } from '../../AddUser/components/text-field'
import { TestDetail } from '../data-types'
import InputAdornment from '@mui/material/InputAdornment'
import { cn } from '../../../../utils/cn'
import CustomSwitch from '../../AddUser/components/switch'
import UploadSection from '../../therapists/components/parts/UploadSection'
import toEnglishNumber from '../../../../utils/to-english-number'

interface ShouldCopyLast {
    point: boolean
    answer: boolean
}

type ShouldCopyLastContextType = [
    shouldCopyLast: ShouldCopyLast,
    setShouldCopyLast: Dispatch<SetStateAction<ShouldCopyLast>>,
]

const shouldCopyLastInitialValue: ShouldCopyLastContextType = [
    {
        point: false,
        answer: false,
    },
    () => {},
]

export const ShouldCopyLastContext = createContext(shouldCopyLastInitialValue)

const Questions: FC<TabChildProps> = ({ control }) => {
    const { getValues, setValue, watch } = useFormContext<TestDetail>()

    const {
        fields: questionsFields,
        append: appendQuestion,
        remove: removeQuestion,
        move: moveQuestion,
    } = useFieldArray({
        control,
        name: 'questions',
    })

    const updateIds = useCallback(() => {
        const currentValues = getValues('questions')

        currentValues?.forEach((item, index) => {
            item.id = index + 1
        })

        setValue('questions', currentValues)
    }, [getValues, setValue])

    const [shouldCopyState, setShouldCopyLast] = useState<ShouldCopyLast>(shouldCopyLastInitialValue[0])

    return (
        <ShouldCopyLastContext.Provider value={[shouldCopyState, setShouldCopyLast]}>
            <Section className='flex items-center gap-2 flex-row p-[0.875rem]'>
                <button
                    onClick={() =>
                        appendQuestion({
                            id: questionsFields.length + 1,
                            ...((shouldCopyState.point || shouldCopyState.answer) && {
                                answers: watch(`questions.${questionsFields.length - 1}.answers`)?.map((item) => ({
                                    ...(shouldCopyState.point && { point: item.point }),
                                    ...(shouldCopyState.answer && { answer: item.answer }),
                                })),
                            }),
                        })
                    }
                    type='button'
                    className='flex gap-2 items-center'
                >
                    <div className='w-9 h-9 rounded-full bg-primary-500 flex items-center justify-center'>
                        <Add
                            size={24}
                            className='text-white'
                        />
                    </div>

                    <div className='text-gray-700'>افزودن سوال جدید</div>
                </button>
            </Section>

            {questionsFields.map((field, index) => (
                <Section
                    key={field.id}
                    className='p-6'
                >
                    <div className='flex items-center gap-3 text-gray-500'>
                        <div className='text-sm'>ردیف:</div>

                        <button
                            type='button'
                            onClick={() => {
                                moveQuestion(index, index - 1)
                                updateIds()
                            }}
                            disabled={index === 0}
                            className='enabled:text-primary-500 text-gray-200'
                        >
                            <ArrowUp className='w-8 h-8' />
                        </button>

                        <SimpleControlledInput<TestDetail>
                            control={control}
                            name={`questions.${index}.id`}
                            className='w-24 text-center p-1 border border-gray-200 rounded'
                            readOnly
                        />

                        <button
                            type='button'
                            onClick={() => {
                                moveQuestion(index, index + 1)
                                updateIds()
                            }}
                            disabled={index === questionsFields.length - 1}
                            className='enabled:text-primary-500 text-gray-200'
                        >
                            <ArrowDown className='w-8 h-8' />
                        </button>

                        <div className='grow' />

                        <button
                            type='button'
                            onClick={() => {
                                removeQuestion(index)
                                updateIds()
                            }}
                        >
                            <DeleteButton className='w-[2.625rem] h-[2.625rem]' />
                        </button>
                    </div>

                    <CustomSwitch<TestDetail>
                        control={control}
                        name={`questions.${index}.shouldAnswer`}
                        label='پاسخ اجباری است.'
                        inline
                        simple
                    />

                    <CustomTextField<TestDetail>
                        control={control}
                        name={`questions.${index}.question`}
                        label='سوال / عبارت'
                    />

                    <Answers
                        questionIndex={index}
                        isLast={index === questionsFields.length - 1}
                    />
                </Section>
            ))}

            {questionsFields.length === 0 && (
                <Section className='py-14 flex items-center p-7'>
                    <div className='text-gray-600 text-sm'>آپلود سوالات از طریق فایل CSV</div>

                    <UploadSection<TestDetail>
                        name='myData'
                        text='فایل'
                        Icon={UploadIcon}
                        size='small'
                        accept='cvs'
                        type='cvs'
                    />
                    {/* <div className='w-1/2 bg-[#F8F9FF] rounded border-dashed border border-[#123CD44D] h-52 p-[1.875rem] gap-4 flex flex-col items-center justify-center'>
                        <UploadIcon />

                        <div className='text-xs font-semibold'>
                            کشیدن و راه کردن یا{' '}
                            <button
                                type='button'
                                className='inline text-primary-500 underline'
                            >
                                انتخاب فایل
                            </button>
                        </div>

                        <div className='text-xs text-[#676767]'>ورود سوالات از طریق فایل CSV</div>
                    </div> */}
                </Section>
            )}
        </ShouldCopyLastContext.Provider>
    )
}

export default Questions

interface AnswersProps {
    questionIndex: number
    isLast: boolean
}

const Answers: FC<AnswersProps> = ({ questionIndex, isLast }) => {
    const [shouldCopyLast, setShouldCopyLast] = useContext(ShouldCopyLastContext)

    const { control, setValue, watch } = useFormContext<TestDetail>()

    const {
        fields: answerFields,
        append: appendAnswer,
        remove: removeAnswer,
    } = useFieldArray({
        control,
        name: `questions.${questionIndex}.answers`,
    })

    const reverseFields = useCallback(
        (property: keyof (typeof answerFields)[number]) => {
            const currentFields = watch(`questions.${questionIndex}.answers`) ?? []

            const reversedValues = currentFields.map((field) => field[property]).reverse()

            const newItems = currentFields.map((field, index) => ({
                ...field,
                [property]: reversedValues[index],
            }))

            setValue(`questions.${questionIndex}.answers`, newItems)
        },
        [questionIndex, setValue, watch],
    )

    return (
        <>
            <div className='flex items-center gap-3'>
                <div className='flex items-center gap-2 min-w-32'>
                    <div>نمرات:</div>

                    <button
                        type='button'
                        disabled={answerFields.length === 0}
                        className='disabled:text-gray-200 text-primary-500'
                        onClick={() => {
                            reverseFields('point')
                            // reverseFields('answer')
                        }}
                    >
                        <ArrangeVerticalSquare2 />
                    </button>

                    {isLast && (
                        <button
                            type='button'
                            onClick={() => setShouldCopyLast((current) => ({ ...current, point: !current.point }))}
                            className={cn(shouldCopyLast.point ? 'text-primary-500' : 'text-gray-200')}
                        >
                            <RefreshSquare />
                        </button>
                    )}
                </div>

                <div className='flex items-center gap-2'>
                    <div>جواب‌ها:</div>

                    <button
                        disabled={answerFields.length === 0}
                        className='disabled:text-gray-200 text-primary-500'
                        onClick={() => {
                            reverseFields('answer')
                        }}
                        type='button'
                    >
                        <ArrangeVerticalSquare2 />
                    </button>

                    {isLast && (
                        <button type='button'>
                            <RefreshSquare
                                onClick={() =>
                                    setShouldCopyLast((current) => ({ ...current, answer: !current.answer }))
                                }
                                className={cn(shouldCopyLast.answer ? 'text-primary-500' : 'text-gray-200')}
                            />
                        </button>
                    )}
                </div>
            </div>

            <button
                type='button'
                className='flex items-center gap-1 w-fit'
                onClick={() => appendAnswer({})}
            >
                <div className='w-6 h-6 rounded-full bg-primary-500 text-white flex items-center justify-center'>
                    <Add size={19} />
                </div>

                <div className='text-sm text-gray-700'>افزودن پاسخ</div>
            </button>

            {answerFields.length > 0 && (
                <div className='flex flex-col gap-2'>
                    {answerFields.map((field, answerIndex) => (
                        <div
                            className='flex gap-3 w-full'
                            key={field.id}
                        >
                            <CustomTextField<TestDetail>
                                control={control}
                                name={`questions.${questionIndex}.answers.${answerIndex}.point`}
                                label='نمره'
                                containerClassName='basis-32'
                                generateOnChange={(value) => toEnglishNumber(value.replace(/[^0-9\u06F0-\u06F9]/g, ''))}
                                generateValue={(value) => value?.toString() ?? ''}
                            />

                            <CustomTextField<TestDetail>
                                control={control}
                                name={`questions.${questionIndex}.answers.${answerIndex}.answer`}
                                label='جواب'
                                containerClassName='grow'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <button
                                                type='button'
                                                onClick={() => {
                                                    removeAnswer(answerIndex)
                                                }}
                                            >
                                                <DeleteButton className='w-[2.625rem] h-[2.625rem]' />
                                            </button>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    ))}
                </div>
            )}
        </>
    )
}
