export default function formatDate(originalDateStr: Date | string) {
    try {
        if (typeof originalDateStr === 'string') originalDateStr = new Date(originalDateStr)
    } catch (e) {
        console.log(e)
        return originalDateStr.toString()
    }

    if (!isValidDate(originalDateStr)) return ''

    const persianDateFormatter = new Intl.DateTimeFormat('fa-IR', {
        weekday: 'long',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        calendar: 'persian',
    })

    const timeFormatter = new Intl.DateTimeFormat('fa-IR', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    })

    const formattedDate = persianDateFormatter.format(originalDateStr).replace(/\u200E/g, '')
    const parts = formattedDate.split(' ')
    const dayOfWeek = parts[0].trim()
    const dateParts = parts[1].trim().split('/')

    const formattedTime = timeFormatter.format(originalDateStr)

    const finalDateString = `${dayOfWeek} - ${dateParts[0]}/${dateParts[1]}/${dateParts[2]} - ساعت ${formattedTime}`
    return finalDateString
}

function isValidDate(date: any): boolean {
    return date instanceof Date && !isNaN(date.getTime())
}
