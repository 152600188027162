import type { FC } from 'react'
import { Arrow } from '../icons'

interface testProps {}

const ColumnSortedIcon: FC<testProps> = () => {
    return <Arrow className='w-[17px] h-[17px]' />
}

export default ColumnSortedIcon
