import { GridRowId } from '@mui/x-data-grid'
import { Edit2, ImportCurve } from 'iconsax-react'
import Button, { ButtonVariants, ButtonSizes } from '../../../../_core/components/button'
import { Column, ColumnVariants, Sizes } from '../../../../_core/components/table/column'
import { cn } from '../../../../utils/cn'
import { Therapist } from '../data-types'
import Tooltip from '@mui/material/Tooltip'

const getTherapistColumns = (selectionList: GridRowId[], onClick: (id?: number) => void) => [
    Column<Therapist>({
        field: 'name',
        headerName: 'نام و نام خانوادگی',
        valueGetter: (_, row) => row.name ?? '',
        align: 'left',
        headerAlign: 'right',
        size: Sizes.small,
    }),
    Column<Therapist>({
        field: 'phone',
        headerName: 'شماره موبایل',
        valueGetter: (_, row) => row.phone ?? '',
        variant: ColumnVariants.withPhone,
        align: 'left',
        headerAlign: 'right',
        size: Sizes.small,
    }),
    Column<Therapist>({
        field: 'isActive',
        headerName: 'وضعیت حساب',
        valueGetter: (_, row) => (row.isActive ? 'فعال' : 'غیر فعال'),
        renderCell: (props) => (
            <div
                className={cn(
                    'truncate text-sm h-full flex items-center justify-start w-full',
                    props.row.isActive ? 'text-success-500' : 'text-alarm-500',
                )}
                title={props.value}
            >
                {props.value}
            </div>
        ),
        headerAlign: 'right',
        size: Sizes.small,
    }),
    Column<Therapist>({
        field: 'treatments',
        headerName: 'رویکرد درمانی',
        valueGetter: (_, row) => row.treatments ?? '',
        align: 'left',
        size: Sizes.bigger,
        headerAlign: 'right',
    }),
    Column<Therapist>({
        field: 'actions',
        headerName: '',
        valueGetter: () => '',
        renderHeader: () => (
            <>
                <Button
                    aClassName='mr-auto'
                    variant={ButtonVariants.danger}
                    size={ButtonSizes.small}
                    onClick={() => onClick()}
                    className={cn((selectionList?.length ?? 0) > 0 ? 'flex' : 'hidden')}
                >
                    حذف همه
                </Button>
            </>
        ),
        renderCell: (props) => (
            <div className='flex items-center justify-end pl-4 gap-8 w-full h-full text-gray-700'>
                <Tooltip title='دانلود رزومه'>
                    <button onClick={() => onClick(props.row.id)}>
                        <ImportCurve
                            width={24}
                            height={24}
                        />
                    </button>
                </Tooltip>
                <a
                    href={'./therapists/update-therapist?id=' + props.row.id}
                    className='flex justify-center'
                >
                    <button title='Edit'>
                        <Edit2
                            width={24}
                            height={24}
                        />
                    </button>
                </a>
            </div>
        ),
        sortable: false,
        size: Sizes.small,
    }),
]

export default getTherapistColumns
