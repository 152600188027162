import { useRef } from 'react'
import { toast } from 'react-toastify'

const useCopyToClipboard = (): [React.RefObject<HTMLInputElement>, () => void] => {
    const inputRef = useRef<HTMLInputElement>(null)

    const copyToClipboard = async () => {
        if (inputRef.current) {
            try {
                if (inputRef.current.value.length === 0) throw new Error('چیزی برای کپی کردن وجود ندارد')
                await navigator.clipboard.writeText(inputRef.current.value)
                toast.success(`کپی شد`, {
                    position: 'bottom-center',
                })
            } catch (err) {
                toast.error((err as Error)?.message ?? 'خطا در کپی کردن', {
                    position: 'bottom-center',
                })
            }
        }
    }

    return [inputRef, copyToClipboard]
}

export default useCopyToClipboard
