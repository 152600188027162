import { FC, useMemo, useState } from 'react'
import ListPage from '../_components/list-page'
import { deleteArticles, getArticles } from './api'
import DeleteConfirmDialog from '../_components/list-page/components/dialog'
import Button, { ButtonSizes, ButtonVariants } from '../../../_core/components/button'
import { GridRowId } from '@mui/x-data-grid'
import { Edit2, Trash } from 'iconsax-react'
import { Column, Sizes } from '../../../_core/components/table/column'
import { cn } from '../../../utils/cn'
import { Bundle } from './data-types'
import getDate from '../../../utils/get-date'
import { useQueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { RootState } from '../../../_core/redux/store'
import { DialogTypes, useDialogStore } from '../_components/Dialog'

const Articles: FC = () => {
    const queryClient = useQueryClient()

    const userAccessData = useSelector((state: RootState) => state.userAccess.value)
    const access = useMemo(() => userAccessData?.find((itm) => itm.formName === 'Articles'), [userAccessData])

    const getArticlesColumns = (selectionList: GridRowId[], onClick: (id?: number) => void) => [
        Column<Bundle>({
            field: 'title',
            headerName: 'عنوان مقاله',
            valueGetter: (_, row) => row.title ?? '',
            align: 'left',
            headerAlign: 'right',
        }),
        Column<Bundle>({
            field: 'date_updated',
            headerName: 'تاریخ و زمان آخرین ویرایش',
            valueGetter: (_, row) => getDate(row.date_updated ?? ''),
            size: Sizes.big,
            align: 'left',
            headerAlign: 'right',
        }),
        Column<Bundle>({
            field: 'user_id',
            headerName: 'شناسه کاربر',
            valueGetter: (_, row) => (row.user_id ? `${row.user_id}` : ''),
            align: 'left',
            headerAlign: 'right',
        }),
        Column<Bundle>({
            field: 'url',
            headerName: 'url',
            valueGetter: (_, row) => row.url ?? '',
            align: 'left',
            headerAlign: 'right',
        }),
        Column<Bundle>({
            field: 'is_active',
            headerName: 'وضعیت',
            valueGetter: (_, row) => (row.is_active ? 'فعال' : 'غیر فعال'),
            renderCell: (props) => (
                <div
                    className={cn(
                        'truncate text-sm h-full flex items-center justify-start w-full',
                        props.row.is_active ? 'text-success-500' : 'text-alarm-500',
                    )}
                    title={props.value}
                >
                    {props.value}
                </div>
            ),
            align: 'left',
            headerAlign: 'right',
        }),
        Column<Bundle>({
            field: 'actions',
            headerName: '',
            valueGetter: () => '',
            renderHeader: () =>
                access?.delete && (
                    <Button
                        aClassName='mr-auto'
                        variant={ButtonVariants.danger}
                        size={ButtonSizes.small}
                        onClick={() => onClick()}
                        className={cn('ml-4', (selectionList?.length ?? 0) > 0 ? 'flex' : 'hidden')}
                    >
                        حذف همه
                    </Button>
                ),
            renderCell: (props) => (
                <div className='flex items-center justify-end pl-4 gap-8 w-full h-full text-gray-700'>
                    {access?.update && (
                        <a
                            href={'./articles/update-article?id=' + props.row.id}
                            className='flex justify-center'
                        >
                            <button title='Edit'>
                                <Edit2
                                    width={24}
                                    height={24}
                                />
                            </button>
                        </a>
                    )}

                    {access?.delete && (
                        <button
                            title='Delete'
                            onClick={() => onClick(props.row.id)}
                        >
                            <Trash
                                width={24}
                                height={24}
                            />
                        </button>
                    )}
                </div>
            ),
            sortable: false,
        }),
    ]

    const { setDialogState } = useDialogStore()

    const [isDeleting, setIsDeleting] = useState<boolean>(false)

    return (
        <div className={cn(isDeleting && '[&_.MuiDataGrid-root]:opacity-50 [&_.MuiDataGrid-root]:pointer-events-none')}>
            <ListPage
                apiFunction={getArticles}
                getColumns={getArticlesColumns}
                itemName={'مقاله'}
                addPageLink='./articles/add-article'
                queryKey={['articles']}
                accessName='Articles'
                generateDialog={(dialogData, setDialogData, processedRows, selectionList) => (
                    <DeleteConfirmDialog
                        title='حذف مقالات'
                        itemToBeDeletedType='مقالات'
                        onClose={() => setDialogData(false)}
                        accept={
                            dialogData === 'deleteAll'
                                ? () => {
                                      setIsDeleting(true)

                                      deleteArticles(selectionList ?? [])
                                          .then((data) => {
                                              if (
                                                  (data?.[0]?.['status'] ?? '') === 'Unauthorized.' ||
                                                  (data?.[0]?.['status'] ?? '') === 'access'
                                              )
                                                  setDialogState({
                                                      open: true,
                                                      title: 'خطای دسترسی',
                                                      type: DialogTypes.error,
                                                      description: 'شما دسترسی ندارید.',
                                                  })
                                          })
                                          .finally(() => {
                                              queryClient
                                                  .invalidateQueries({
                                                      queryKey: ['articles'],
                                                  })
                                                  .then(() => setIsDeleting(false))
                                          })
                                  }
                                : dialogData !== false
                                ? () => {
                                      setIsDeleting(true)

                                      deleteArticles([dialogData])
                                          .then((data) => {
                                              if (
                                                  (data?.[0]?.['status'] ?? '') === 'Unauthorized.' ||
                                                  (data?.[0]?.['status'] ?? '') === 'access'
                                              )
                                                  setDialogState({
                                                      open: true,
                                                      title: 'خطای دسترسی',
                                                      type: DialogTypes.error,
                                                      description: 'شما دسترسی ندارید.',
                                                  })
                                          })
                                          .finally(() => {
                                              queryClient
                                                  .invalidateQueries({
                                                      queryKey: ['articles'],
                                                  })
                                                  .then(() => setIsDeleting(false))
                                          })
                                  }
                                : () => null
                        }
                        open={dialogData !== false}
                        itemToBeDeleted={
                            dialogData === 'deleteAll'
                                ? undefined
                                : dialogData !== false
                                ? processedRows.filter((row) => row.id === dialogData)[0].title
                                : undefined
                        }
                    />
                )}
            />
        </div>
    )
}

export default Articles
