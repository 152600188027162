import { FC, useMemo, useState } from 'react'
import ListPage from '../_components/list-page'
import { getReservations } from './api'
import DeleteConfirmDialog from '../_components/list-page/components/dialog'
import { Tooltip } from '@mui/material'
import { GridRowId } from '@mui/x-data-grid'
import { CloseCircle, Edit2, Eye } from 'iconsax-react'
import Button, { ButtonVariants, ButtonSizes } from '../../../_core/components/button'
import { Column, ColumnVariants, Sizes } from '../../../_core/components/table/column'
import { cn } from '../../../utils/cn'
import { PaymentStatus, Reservation, ReservationStatus } from './data-types'
import getDate from '../../../utils/get-date'
import addCommas from '../../../utils/add-commas'
import { useSelector } from 'react-redux'
import { RootState } from '../../../_core/redux/store'
import CancelFormDialog from './components/cancel-form-dialog'
import { CompactDialogState } from '../../../_core/components/dialog-ui'
import DetailDialog from './components/detail-dialog'

const Reservations: FC = () => {
    const [toBeCancelled, setToBeCancelled] = useState<CompactDialogState>(null)

    const [toBeDetailed, setToBeDetailed] = useState<CompactDialogState>(null)

    const userAccessData = useSelector((state: RootState) => state.userAccess.value)
    const testsAccess = useMemo(
        () => userAccessData?.find((itm) => itm.formName === 'DirectReservation'),
        [userAccessData],
    )

    const getReservationsColumns = (selectionList: GridRowId[], onClick: (id?: number) => void) => [
        Column<Reservation>({
            field: 'patient_name',
            headerName: 'نام درمانجو',
            valueGetter: (_, row) => `${row.patient_name}`,
            variant: ColumnVariants.withProfile,
            generateProfile(row) {
                return row.patient_photo
            },
            headerAlign: 'right',
            size: Sizes.small,
        }),
        Column<Reservation>({
            field: 'patient_phone',
            headerName: 'شماره موبایل',
            valueGetter: (_, row) => `${row.patient_phone}`,
            variant: ColumnVariants.withPhone,
            headerAlign: 'left',
            size: Sizes.small,
        }),
        Column<Reservation>({
            field: 'date_time',
            headerName: 'تاریخ و ساعت نوبت',
            valueGetter: (_, row) => `${getDate(row.date_time)}`,
            headerAlign: 'right',
            align: 'left',
            size: Sizes.small,
        }),
        Column<Reservation>({
            field: 'therapist_name',
            headerName: 'نام درمانگر',
            valueGetter: (_, row) => `${row.therapist_name}`,
            headerAlign: 'right',
            align: 'left',
            size: Sizes.small,
        }),
        Column<Reservation>({
            field: 'price_dollar',
            headerName: 'قیمت (دلار)',
            valueGetter: (_, row) => `${row.price_dollar}$`,
            size: Sizes.small,
        }),
        Column<Reservation>({
            field: 'price_toman',
            headerName: 'قیمت (تومان)',
            valueGetter: (_, row) => `${addCommas(row.price_toman.toString())} تومان`,
            size: Sizes.small,
        }),
        Column<Reservation>({
            field: 'payment_status',
            headerName: 'وضعیت پرداخت',
            valueGetter: (_, row) => (row.payment_status === PaymentStatus.Unpaid ? 'پرداخت نشده' : 'پرداخت شده'),
            renderCell: (props) => (
                <div
                    className={cn(
                        'truncate text-sm h-full flex items-center justify-center w-full',
                        props.row.payment_status === PaymentStatus.Unpaid ? 'text-alarm-500' : 'text-success-500',
                    )}
                    title={props.value}
                >
                    {props.value}
                </div>
            ),
            size: Sizes.small,
        }),
        Column<Reservation>({
            field: 'reservation_status',
            headerName: 'وضعیت نوبت',
            valueGetter: (_, row) => {
                if (row.reservation_status === ReservationStatus.Pending) return 'برگزار نشده'
                else if (row.reservation_status === ReservationStatus.Cancelled) return 'لغو شده'
                else if (row.reservation_status === ReservationStatus.Active) return 'درحال برگزاری'
                else if (row.reservation_status === ReservationStatus.Past) return 'برگزار شده'
                else return 'نامشخص'
            },
            renderCell: (props) => (
                <div
                    className={cn(
                        'truncate text-sm h-full flex items-center justify-center w-full text-gray-400',
                        props.row.reservation_status === ReservationStatus.Pending && 'text-alarm-500',
                        props.row.reservation_status === ReservationStatus.Cancelled && 'text-gray-400',
                        props.row.reservation_status === ReservationStatus.Active && 'text-[#FA8D34]',
                        props.row.reservation_status === ReservationStatus.Past && 'text-success-500',
                    )}
                    title={props.value}
                >
                    {props.value}
                </div>
            ),
            size: Sizes.small,
        }),
        Column<Reservation>({
            field: 'actions',
            headerName: '',
            valueGetter: () => '',
            renderHeader: () => (
                <>
                    <Button
                        aClassName='mr-auto'
                        variant={ButtonVariants.danger}
                        size={ButtonSizes.small}
                        onClick={() => onClick()}
                        className={cn((selectionList?.length ?? 0) > 0 ? 'flex' : 'hidden')}
                    >
                        حذف همه
                    </Button>
                </>
            ),
            renderCell: (props) => (
                <div className='flex items-center justify-end pl-4 gap-8 w-full h-full text-gray-700'>
                    <Tooltip title='کنسل نوبت'>
                        <button
                            disabled={
                                !(testsAccess?.delete && props.row.reservation_status === ReservationStatus.Pending)
                            }
                            onClick={() => onClick(props.row.id)}
                            className='disabled:text-gray-300'
                        >
                            <CloseCircle
                                width={24}
                                height={24}
                            />
                        </button>
                    </Tooltip>

                    {testsAccess?.update && (
                        <a
                            href={'./directReservation/update?id=' + props.row.id}
                            className='flex justify-center'
                        >
                            <button title='Edit'>
                                <Edit2
                                    width={24}
                                    height={24}
                                />
                            </button>
                        </a>
                    )}

                    <button
                        title='Details'
                        onClick={() => setToBeDetailed([props.row.id])}
                    >
                        <Eye
                            width={24}
                            height={24}
                        />
                    </button>
                </div>
            ),
            sortable: false,
            size: Sizes.mediumBig,
        }),
    ]

    return (
        <>
            <ListPage
                apiFunction={getReservations}
                getColumns={getReservationsColumns}
                itemName={'نوبت'}
                addPageLink='./directReservation/add'
                queryKey={['reservations']}
                accessName='DirectReservation'
                generateID={(row) => row.id}
                generateDialog={(dialogData, setDialogData, processedRows, selectionList) => (
                    <DeleteConfirmDialog
                        acceptButtonsTitle='لغو نوبت'
                        generateMessage={(itemsToBeDeleted) => `آیا از لغو نوبت برای ${itemsToBeDeleted} مطمئن هستید؟ `}
                        title='لغو نوبت'
                        hint='شما تا ۲۴ ساعت قبل از برگزاری جلسه؛ امکان لغو نوبت را دارید.'
                        onClose={() => setDialogData(false)}
                        accept={
                            dialogData === 'deleteAll'
                                ? () => setToBeCancelled(selectionList ?? [])
                                : dialogData !== false
                                ? () => setToBeCancelled([dialogData])
                                : () => null
                        }
                        open={dialogData !== false}
                        itemToBeDeleted={
                            dialogData === 'deleteAll'
                                ? undefined
                                : dialogData !== false
                                ? processedRows.filter((row) => row.id === dialogData)[0].patient_name
                                : undefined
                        }
                    />
                )}
            />

            <CancelFormDialog
                compactDialogState={toBeCancelled}
                setCompactDialogState={setToBeCancelled}
            />

            <DetailDialog
                compactDialogState={toBeDetailed}
                setCompactDialogState={setToBeDetailed}
            />
        </>
    )
}

export default Reservations
