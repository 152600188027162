import {
    TextFieldProps,
    TextField,
    Theme,
    createTheme,
    ThemeProvider,
    InputAdornment,
    Popper,
    styled,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import rtlPlugin from 'stylis-plugin-rtl'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import { prefixer } from 'stylis'
import './index.css'
import { Control, Controller, FieldValues, Path, useFormContext } from 'react-hook-form'
import { DetailedHTMLProps, InputHTMLAttributes, KeyboardEventHandler, ReactNode } from 'react'
import { cn } from '../../../../../utils/cn'
import { Add } from 'iconsax-react'

const theme = (outerTheme: Theme) =>
    createTheme({
        direction: 'rtl',
        palette: {
            mode: outerTheme.palette.mode,
        },
    })

const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
})

export interface CustomTextFieldProps {
    adornment?: ReactNode
    withoutControl?: boolean
    containerClassName?: string
    generateOnChange?: (value: string) => string | number
    generateValue?: (value: string) => string
    preventNumber?: boolean
}

export function CustomTextField<T extends FieldValues>({
    adornment,
    withoutControl = false,
    containerClassName = '',
    generateOnChange,
    generateValue,
    readOnly,
    inputProps,
    ...props
}: Omit<TextFieldProps, 'variant'> & {
    control: Control<T, keyof T>
    name: Path<T>
    readOnly?: boolean
} & CustomTextFieldProps) {
    return (
        <Controller
            name={props.name ?? ''}
            control={props.control}
            render={({ field: { onChange, value, ref, ...otherFields }, fieldState: { error } }) => (
                <TextFieldWithoutControl
                    {...(!withoutControl && {
                        ...{
                            ...(generateOnChange === undefined
                                ? { onChange }
                                : {
                                      onChange: (event) => onChange(generateOnChange(event.target.value)),
                                  }),
                        },
                        inputRef: ref,
                        value: generateValue ? generateValue(value) : value,
                        error: !!error,
                        helperText: error ? error.message : null,
                        ...otherFields,
                    })}
                    adornment={adornment}
                    containerClassName={containerClassName}
                    label={props.label}
                    {...props}
                    inputProps={{ ...inputProps, readOnly }}
                />
            )}
        />
    )
}

export function TextFieldWithoutControl({
    adornment,
    containerClassName = '',
    generateOnChange,
    preventNumber,
    ...props
}: Omit<TextFieldProps, 'variant'> & CustomTextFieldProps) {
    const handleKeyPress: KeyboardEventHandler<HTMLDivElement> = (event) => {
        if (preventNumber) {
            if (event.key === ' ') return
            else if (event.which < 48 || event.which > 57) return
            else event.preventDefault()
        }
    }

    return (
        <CacheProvider value={cacheRtl}>
            <ThemeProvider theme={theme}>
                <div
                    dir='rtl'
                    className={containerClassName}
                >
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        size='medium'
                        fullWidth
                        label={props.label}
                        variant='outlined'
                        onKeyDown={preventNumber ? handleKeyPress : undefined}
                        {...props}
                        {...{ inputProps: { maxLength: props.multiline ? Infinity : Infinity, ...props.inputProps } }}
                        // {...{ inputProps: { maxLength: props.multiline ? 250 : 150, ...props.inputProps } }}
                        {...(adornment && {
                            InputProps: {
                                endAdornment: <InputAdornment position='end'>{adornment}</InputAdornment>,
                            },
                        })}
                    />
                </div>
            </ThemeProvider>
        </CacheProvider>
    )
}

interface CustomAutocompleteProps {
    hasThePlusIcon?: boolean
    popupIcon?: ReactNode
}

export function CustomAutocomplete<T extends FieldValues>({
    adornment,
    containerClassName = '',
    name,
    control,
    options,
    isPending,
    multiple,
    disabled,
    freeSolo,
    listElevated,
    hasThePlusIcon,
    insideTextField,
    popupIcon,
    ...props
}: Omit<TextFieldProps, 'variant'> & {
    control: Control<T, keyof T>
    name: Path<T>
    options: string[]
    isPending?: boolean
    multiple?: boolean
    freeSolo?: boolean
    insideTextField?: boolean
    listElevated?: boolean
} & CustomTextFieldProps &
    CustomAutocompleteProps) {
    const { watch } = useFormContext<T>()

    return (
        <Controller
            disabled={disabled}
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <Autocomplete
                    {...(hasThePlusIcon && {
                        popupIcon: (
                            <div className='w-5 h-5 bg-primary-500 text-white flex items-center justify-center rounded-full'>
                                <Add size={20} />
                            </div>
                        ),
                    })}
                    {...(popupIcon && {
                        popupIcon,
                    })}
                    {...field}
                    classes={{
                        inputRoot: '!pr-[16px]',
                        root: cn(isPending && 'pointer-events-none opacity-50', containerClassName),
                    }}
                    options={options}
                    disableClearable
                    PopperComponent={listElevated ? ElevatedPopper : undefined}
                    multiple={multiple}
                    freeSolo={freeSolo}
                    onChange={(_, value) => field.onChange(value)}
                    renderInput={({ InputLabelProps, ...params }) => (
                        <TextFieldWithoutControl
                            {...params}
                            {...(insideTextField && {
                                inputProps: {
                                    ...params.inputProps,
                                    className: params.inputProps.className + ' no-notch',
                                    value: watch(name),
                                },
                            })}
                            disabled={isPending}
                            InputLabelProps={{ shrink: true }}
                            size='medium'
                            fullWidth
                            {...props}
                            error={error ? true : false}
                            helperText={error?.message}
                        />
                    )}
                />
            )}
        />
    )
}

const ElevatedPopper = styled(Popper)({
    zIndex: '999999999 !important',
})

interface SimpleControlledInputProps<T extends FieldValues>
    extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    control: Control<T, keyof T>
    name: Path<T>
    generateOnChange?: (value: string) => string | number
    generateValue?: (value: string) => string
}

function SimpleControlledInput<T extends FieldValues>({
    control,
    name,
    generateValue,
    generateOnChange,
    ...otherProps
}: SimpleControlledInputProps<T>) {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value, ...otherControllerProps } }) => (
                <input
                    {...(generateValue ? { value: generateValue(value) } : { value: value })}
                    {...(generateOnChange
                        ? { onChange: (event) => onChange(generateOnChange(event.target.value)) }
                        : { onChange: (event) => onChange(event.target.value) })}
                    {...otherControllerProps}
                    {...otherProps}
                />
            )}
        />
    )
}

export default SimpleControlledInput
