import { DataGrid, DataGridProps, GridValidRowModel } from '@mui/x-data-grid'
import { cn } from '../../../utils/cn'
import CustomCheckbox from './custom-checkbox'
import './Table.css'
import ColumnSortedIcon from './column-sorted-icon'
import createCache from '@emotion/cache'
import rtlPlugin from 'stylis-plugin-rtl'
import { CacheProvider, ThemeProvider, useTheme } from '@emotion/react'
import { Stack, createTheme } from '@mui/material'
import { arSD } from '@mui/material/locale'
import { prefixer } from 'stylis'
import { useEffect, useMemo, useRef, useState } from 'react'
import CustomFooter from './custom-footer'

const cacheRtl = createCache({
    key: 'data-grid-rtl-demo',
    stylisPlugins: [prefixer, rtlPlugin],
})

interface CustomFooterProps {
    noFooter?: boolean
}

const Table = <R extends GridValidRowModel = any>({
    className,
    noFooter,
    ...props
}: DataGridProps<R> & React.RefAttributes<HTMLDivElement> & CustomFooterProps) => {
    const existingTheme = useTheme()

    const theme = useMemo(
        () =>
            createTheme({}, arSD, existingTheme, {
                direction: 'rtl',
            }),
        [existingTheme],
    )

    const [scrollIndex, setScrollIndex] = useState(1)
    const tableRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const gridElement = tableRef.current?.querySelector('.MuiDataGrid-virtualScroller')

        const handleScroll = () => {
            if (gridElement) {
                const { scrollTop, clientHeight } = gridElement
                const scrolled = Math.trunc(scrollTop / clientHeight) + 1
                setScrollIndex(scrolled)
            }
        }

        gridElement?.addEventListener('scroll', handleScroll)

        return () => {
            gridElement?.removeEventListener('scroll', handleScroll)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableRef.current])

    return (
        <CacheProvider value={cacheRtl}>
            <ThemeProvider theme={theme}>
                <DataGrid
                    // TODO forward ref
                    ref={tableRef}
                    rowHeight={60}
                    getRowSpacing={() => ({ bottom: 4 })}
                    scrollbarSize={4}
                    className={cn(className, '')}
                    slots={{
                        baseCheckbox: CustomCheckbox,
                        columnSortedDescendingIcon: ColumnSortedIcon,
                        columnSortedAscendingIcon: ColumnSortedIcon,
                        footer: () => (
                            <CustomFooter
                                index={scrollIndex}
                                shouldHide={noFooter}
                            />
                        ),
                        noRowsOverlay: () => (
                            <Stack
                                height='100%'
                                alignItems='center'
                                justifyContent='center'
                            >
                                چیزی یافت نشد
                            </Stack>
                        ),
                    }}
                    checkboxSelection
                    disableRowSelectionOnClick
                    {...props}
                />
            </ThemeProvider>
        </CacheProvider>
    )
}

export default Table

/* TODOs
make loading and no-results states more prettier
100 row limit
*/
