import { FC, useCallback, useMemo } from 'react'
import {
    ExcludedDate,
    Fee,
    Hour,
    TherapistDetail,
    WorkingDay,
    ActiveHoliday,
    ReservationHistory,
    ReservationPaymentStatus,
    ReservationMethod,
    ReservationType,
    ReservationStatus,
} from '../data-types'
import { z } from '../../../../utils/zod-translation'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import CMSLayout from '../../../../_core/components/layouts/cms-dashboard/CMSLayout'
import { Tabs, TabsContent, TabsTrigger, useTabStore } from '../../../../_core/components/tabs'
import { addTherapist as addTherapistAPI, updateTherapist as updateTherapistAPI } from '../api'
import { cn } from '../../../../utils/cn'
import Button, { ButtonSizes, ButtonVariants } from '../../../../_core/components/button'
import { Refresh } from 'iconsax-react'
import ProfileTab from './profile-tab'
import StatusTab from './status-tab'
import RightsTab from './rights-tab'
import StateSidebar from './state-sidebar'
import zFile from '../../../../utils/z-file-validation'
import DownloadCVSidebar from './download-cv-sidebar'
import { isValidPhoneNumber } from 'libphonenumber-js'
import HistoryTab from './history-tab'

const ReservationHistorySchema: z.ZodType<ReservationHistory> = z.object({
    id: z.coerce.number().optional(),
    photo: z.coerce.string().optional(),
    name: z.coerce.string().optional(),
    description: z.coerce.string().optional(),
    phone: z.coerce.string().optional(),
    reserveDate: z.coerce.string().optional(),
    reserved: z.coerce.string().optional(),
    type: z.nativeEnum(ReservationType).optional(),
    timezone: z.coerce.string().optional(),
    price: z.coerce.string().optional(),
    trackingCode: z.coerce.string().optional(),
    method: z.nativeEnum(ReservationMethod).optional(),
    status: z.nativeEnum(ReservationStatus).optional(),
    paymentStatus: z.nativeEnum(ReservationPaymentStatus).optional(),
    cancelDate: z.coerce.string().optional(),
    canceler: z.coerce.string().optional(),
})

// Define ActiveHoliday schema
const ActiveHolidaySchema: z.ZodType<ActiveHoliday> = z.object({
    date: z.coerce.string().optional(),
})

// Define Hour schema
const HourSchema: z.ZodType<Hour> = z.object({
    from: z.coerce.string().optional(),
    to: z.coerce.string().optional(),
})

// Define WorkingDay schema
const WorkingDaySchema: z.ZodType<WorkingDay> = z.object({
    id: z.coerce.number().optional(),
    day: z.coerce.number().optional(),
    status: z.coerce.boolean().optional(),
    hours: z.array(HourSchema).optional(),
})

// Define ExcludedDate schema
const ExcludedDateSchema: z.ZodType<ExcludedDate> = z.object({
    date: z.coerce.string().optional(),
    from: z.coerce.string().optional(),
    to: z.coerce.string().optional(),
    new: z.coerce.boolean().optional(),
})

// Define Fee schema
const FeeSchema: z.ZodType<Fee> = z.object({
    startDate: z.coerce.string().optional(),
    insideFee: z.coerce.string().optional(),
    insideFeeUnit: z.coerce.string().optional(),
    outsideFee: z.coerce.string().optional(),
    outsideFeeUnit: z.coerce.string().optional(),
    new: z.coerce.boolean().optional(),
})

// Define TherapistDetail schema
export const TherapistDetailSchema = z.object({
    id: z.coerce.number().optional(),
    name: z.string().regex(/^[^\d\u0660-\u0669]*$/, 'فقط می‌تواند حروف باشد'),
    last_name: z.string().regex(/^[^\d\u0660-\u0669]*$/, 'فقط می‌تواند حروف باشد'),
    phone: z.coerce
        .string()
        .min(1, 'این فیلد اجباری است')
        .refine((value) => isValidPhoneNumber(value), 'شماره موبایل اشتباه است'),
    treatments: z.coerce.string().optional(),
    about: z.string(),
    education: z.array(z.coerce.string().optional()).optional(),
    job: z.coerce.string().optional(),
    license: z.array(z.coerce.string().optional()).optional(),
    research: z.array(z.coerce.string().optional()).optional(),
    photo: zFile({
        allowedTypes: ['image/jpeg', 'image/jpg', 'image/png'],
        typeError: 'فرمت تصویر jpeg یا png باشد.',
        maxSize: 400000,
        sizeError: 'حجم تصویر حداکثر ۴MB باشد.',
    }).optional(),
    treatment_ways: z.array(z.coerce.string().optional()).optional(),
    cv: z.array(z.coerce.string().optional()).optional(),
    dob: z.string(),
    start_date: z.string(),
    gender: z.coerce.string().optional(),
    prefix: z.coerce.string().optional(),
    photoNoHijab: zFile({
        allowedTypes: ['image/jpeg', 'image/jpg', 'image/png'],
        typeError: 'فرمت تصویر jpeg یا png باشد.',
        maxSize: 400000,
        sizeError: 'حجم تصویر حداکثر ۴MB باشد.',
    }).optional(),
    video: zFile({
        allowedTypes: ['video/mp4', 'video/mkv'],
        typeError: 'فرمت ویدئو mp4 یا mkv باشد.',
        maxSize: 4000000,
        sizeError: 'حجم ویدئو حداکثر ۴۰MB باشد.',
    }).optional(),
    videoNoHijab: zFile({
        allowedTypes: ['video/mp4', 'video/mkv'],
        typeError: 'فرمت ویدئو mp4 یا mkv باشد.',
        maxSize: 4000000,
        sizeError: 'حجم ویدئو حداکثر ۴۰MB باشد.',
    }).optional(),
    timezone: z.coerce.string().optional(),
    accountStatus: z.coerce.boolean().optional(),
    excludedDates: z.array(ExcludedDateSchema).optional(),
    activeHolidays: z.array(ActiveHolidaySchema).optional(), // Define a more specific schema if possible
    workingDays: z.array(WorkingDaySchema).optional(),
    fees: z.array(FeeSchema).optional(),
    videoTitle: z.coerce.string().optional(),
    reservationHistorySchema: z.array(ReservationHistorySchema).optional(),
})

interface TabType {
    title: string
    value: string
    Children: FC
    sidebars: (FC | false)[]
}

interface TherapistFormProps {
    type: 'ADD' | 'UPDATE'
    therapists?: TherapistDetail
}

const TherapistForm: FC<TherapistFormProps> = ({ therapists, type }) => {
    const tabs: TabType[] = useMemo(
        () => [
            {
                title: 'پروفایل',
                value: 'profile',
                Children: ProfileTab,
                sidebars: [type === 'UPDATE' && DownloadCVSidebar],
            },
            {
                title: 'وضعیت فعالیت',
                value: 'status',
                Children: StatusTab,
                sidebars: [StateSidebar, type === 'UPDATE' && DownloadCVSidebar],
            },
            {
                title: 'حقوق و دستمزد',
                value: 'rights',
                Children: RightsTab,
                sidebars: [type === 'UPDATE' && DownloadCVSidebar],
            },
            {
                title: 'سوابق نوبت‌دهی',
                value: 'history',
                Children: HistoryTab,
                sidebars: [type === 'UPDATE' && DownloadCVSidebar],
            },
        ],
        [type],
    )

    const navigate = useNavigate()

    const { control, handleSubmit, watch, ...otherFormStuff } = useForm<TherapistDetail>({
        defaultValues: therapists,
        mode: 'onChange',
        resolver: zodResolver(TherapistDetailSchema),
    })

    const { mutate: addTherapist, isPending: isAddingTherapistPending } = useMutation({
        mutationKey: ['therapist', 'therapist-detail'],
        mutationFn: addTherapistAPI,
    })

    const { mutate: updateTherapist, isPending: isUpdatingTherapistPending } = useMutation({
        mutationKey: ['therapist', 'therapist-detail'],
        mutationFn: updateTherapistAPI,
    })

    const onSubmit = useCallback(
        ({ treatment_ways, education, cv, license, research, ...data }: TherapistDetail) => {
            const transformedData = {
                treatment_ways: treatment_ways?.join('|'),
                education: education?.join('|'),
                cv: cv?.join('|'),
                license: license?.join('|'),
                research: research?.join('|'),
                ...data,
            }

            if (type === 'ADD') addTherapist(transformedData)
            if (type === 'UPDATE') updateTherapist(transformedData)
        },
        [addTherapist, type, updateTherapist],
    )

    const { value, setValue } = useTabStore()

    const currentTabsSidebarsLength = useMemo(
        () => tabs.find((tab) => tab.value === value)?.sidebars.filter((sidebar) => sidebar).length,
        [tabs, value],
    )

    const onError = useCallback(() => {
        setValue(tabs[0].value)
    }, [setValue, tabs])

    return (
        <CMSLayout>
            <div className='text-sm mb-5'>
                <span className='text-gray-400'>درمانگران / </span>
                <span className='text-gray-600 font-medium'>{type === 'ADD' ? 'درمانگر جدید' : 'ویرایش درمانگر'}</span>
            </div>

            <Tabs initialValue={tabs[0].value}>
                <FormProvider
                    control={control}
                    watch={watch}
                    handleSubmit={handleSubmit}
                    {...otherFormStuff}
                >
                    <div className='flex mb-3 gap-2'>
                        {tabs.map((tab) => (
                            <TabsTrigger
                                className={cn(
                                    'basis-40 rounded border border-[#EDEDED] p-3 text-center',
                                    'data-[open=false]:bg-white data-[open=true]:bg-primary-500 data-[open=false]:text-gray-300 data-[open=true]:text-white',
                                )}
                                key={tab.value}
                                value={tab.value}
                            >
                                {tab.title}
                            </TabsTrigger>
                        ))}
                    </div>

                    <form
                        className='grid grid-cols-4 gap-4'
                        onSubmit={handleSubmit(onSubmit, onError)}
                    >
                        <div
                            className={cn(
                                currentTabsSidebarsLength && currentTabsSidebarsLength > 0
                                    ? 'col-span-3'
                                    : 'col-span-4',
                            )}
                        >
                            {tabs.map(({ Children, ...tab }) => (
                                <TabsContent
                                    className='flex flex-col gap-3'
                                    key={tab.value}
                                    value={tab.value}
                                >
                                    <Children />
                                </TabsContent>
                            ))}

                            <div className='flex gap-4 mt-5'>
                                <Button
                                    disabled={isAddingTherapistPending || isUpdatingTherapistPending}
                                    type='submit'
                                    className='w-[9.375rem] text-gray-50'
                                    size={ButtonSizes.bigger}
                                >
                                    {isAddingTherapistPending || isUpdatingTherapistPending ? (
                                        <Refresh className='animate-spin' />
                                    ) : (
                                        'ثبت'
                                    )}
                                </Button>

                                <Button
                                    type='button'
                                    size={ButtonSizes.big}
                                    className='w-[9.375rem]'
                                    variant={ButtonVariants.gray}
                                    onClick={() => navigate('/cms/therapists')}
                                >
                                    انصراف
                                </Button>
                            </div>
                        </div>

                        <div className='col-span-1 flex flex-col gap-2'>
                            {tabs.map(({ sidebars, ...tab }) => (
                                <TabsContent
                                    className='flex flex-col gap-2'
                                    key={tab.value}
                                    value={tab.value}
                                >
                                    {sidebars.map((Sidebar) => Sidebar && <Sidebar key={Sidebar.name} />)}
                                </TabsContent>
                            ))}
                        </div>
                    </form>
                </FormProvider>
            </Tabs>
        </CMSLayout>
    )
}

export default TherapistForm
