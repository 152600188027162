import { ReactNode, FC, DetailedHTMLProps, HTMLAttributes } from 'react'
import { cn } from '../../../../utils/cn'

interface SectionProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    title?: string
    children?: ReactNode
}

const Section: FC<SectionProps> = ({ children, title, className, ...otherProps }) => {
    return (
        <div
            className={cn('bg-white p-7 gap-8 flex flex-col', className)}
            {...otherProps}
        >
            {title && <div className='font-medium text-base text-gray-700'>{title}</div>}
            {children}
        </div>
    )
}

export default Section
