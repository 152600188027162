import InputAdornment from '@mui/material/InputAdornment'
import { CustomAutocomplete, CustomTextField, CustomTextFieldProps } from '../AddUser/components/text-field'
import { TextFieldProps } from '@mui/material/TextField'
import { Control, FieldValues, Path } from 'react-hook-form'
import { ArrowDown2 } from 'iconsax-react'

function AutoCompleteTextField<T extends FieldValues>({
    control,
    name,
    autocompleteOptions,
    autocompleteName,
    label,
    ...props
}: Omit<TextFieldProps, 'variant' | 'InputProps'> & {
    control: Control<T, keyof T>
    name: Path<T>
    autocompleteOptions: string[]
    autocompleteName: Path<T>
} & CustomTextFieldProps) {
    return (
        <CustomTextField<T>
            control={control}
            name={name}
            label={label}
            InputProps={{
                endAdornment: (
                    <InputAdornment position='end'>
                        <CustomAutocomplete<T>
                            popupIcon={<ArrowDown2 size={16} />}
                            insideTextField
                            control={control}
                            name={autocompleteName}
                            options={autocompleteOptions}
                        />
                    </InputAdornment>
                ),
            }}
            {...props}
        />
    )
}

export default AutoCompleteTextField
