import { FC, SVGProps, useId, useState } from 'react'
import { cn } from '../../../../../utils/cn'
import { CloseCircle, Icon, Trash } from 'iconsax-react'
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form'
import createURL from '../../../../../utils/read-url'

interface UploadSectionProps<T extends FieldValues> {
    size: 'small' | 'big'
    Icon: Icon | FC<SVGProps<SVGSVGElement>>
    text: string
    name: Path<T>
    accept: string
    type: 'image' | 'video' | 'cvs'
}

function UploadSection<T extends FieldValues>({ size, Icon, text, name, accept, type }: UploadSectionProps<T>) {
    const id = useId()

    const { control, resetField } = useFormContext<T>()

    const [cantLoad, setCantLoad] = useState(false)

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <div className='flex flex-col gap-3'>
                    {(!value || cantLoad) && (
                        <label
                            htmlFor={id}
                            className={cn(
                                'rounded border border-dashed border-gray-300 flex items-center justify-center text-center gap-7 flex-col cursor-pointer',
                                size === 'small' && 'w-[9.5rem] h-[10.125rem]',
                                size === 'big' && 'w-[13.0625rem] h-[13.9375rem]',
                            )}
                        >
                            <div className='w-16 h-16 bg-gray-100 border border-gray-300 rounded-full flex items-center justify-center text-gray-800'>
                                <Icon
                                    size={24}
                                    height={24}
                                />
                            </div>

                            <div className='text-xs text-primary-500'>بارگذاری {text}</div>

                            <input
                                accept={accept}
                                id={id}
                                multiple={false}
                                type='file'
                                className='hidden'
                                onChange={(e) => {
                                    onChange(e.target.files?.item(0) ?? undefined)
                                    setCantLoad(false)
                                }}
                            />
                        </label>
                    )}

                    {value && !cantLoad && (
                        <div
                            className={cn(
                                'rounded border border-gray-300 flex items-center justify-center text-center gap-7 flex-col overflow-hidden relative',
                                size === 'small' && 'w-[9.5rem] h-[10.125rem]',
                                size === 'big' && 'w-[13.0625rem] h-[13.9375rem]',
                            )}
                        >
                            {type === 'image' && (
                                <img
                                    src={createURL([value]) as string}
                                    alt=''
                                    className='w-16 h-16 object-cover object-center rounded-full'
                                    onError={() => {
                                        setCantLoad(true)
                                    }}
                                />
                            )}

                            {type === 'video' && (
                                <video
                                    controls
                                    src={createURL([value]) as string}
                                    className='w-full h-full object-cover object-center'
                                    onError={() => {
                                        setCantLoad(true)
                                    }}
                                />
                            )}

                            {type === 'image' && (
                                <button
                                    onClick={() => resetField(name)}
                                    className='text-xs text-alarm-500'
                                >
                                    حذف {text}
                                </button>
                            )}

                            {type === 'video' && (
                                <button
                                    onClick={() => resetField(name)}
                                    className='text-alarm-500 bg-white rounded-sm h-5 w-5 flex items-center justify-center absolute left-3 top-3'
                                >
                                    <Trash size={14} />
                                </button>
                            )}
                        </div>
                    )}

                    {error && (
                        <div
                            className={cn(
                                'flex items-center gap-1 text-gray-500',
                                error && 'text-alarm-500',
                                size === 'small' && 'w-[9.5rem]',
                                size === 'big' && 'w-[13.0625rem]',
                            )}
                        >
                            <CloseCircle
                                size={14}
                                height={14}
                                className='shrink-0'
                            />
                            <div className='text-[0.625rem] grow'>{error?.message}</div>
                        </div>
                    )}
                </div>
            )}
        />
    )
}

export default UploadSection
