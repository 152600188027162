import { DetailedHTMLProps, HTMLAttributes, MouseEventHandler, ReactNode, useCallback } from 'react'
import { cn } from '../../../utils/cn'
import { GridRowId } from '@mui/x-data-grid'
import { CloseCircle } from 'iconsax-react'

type DefaultCompactState = GridRowId

/// null: the dialog is closed
/// number: the dialog is opened with the id of the reservation to be cancelled
/// number[]: the dialog is opened with the ids of the reservations to be cancelled
export type CompactDialogState<T = DefaultCompactState> = null | T[]

export interface NecessaryDialogProps<T = DefaultCompactState> {
    compactDialogState: CompactDialogState<T>
    setCompactDialogState: (value: CompactDialogState<T>) => void
}

interface DialogUIProps<T = DefaultCompactState>
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
        NecessaryDialogProps<T> {
    dialogTitle: string
    generateTitle?: (title: string) => ReactNode
}

function DialogUI<T = DefaultCompactState>({
    compactDialogState,
    setCompactDialogState,
    className,
    dialogTitle,
    children,
    generateTitle,
    ...otherProps
}: DialogUIProps<T>) {
    const { close, preventClose } = useCompactDialog({ compactDialogState, setCompactDialogState })

    return (
        <div
            onClick={close}
            className={cn(
                'fixed inset-0 bg-primary-600/45 opacity-0 pointer-events-none transition-opacity z-[99999] flex items-center justify-center',
                compactDialogState && 'opacity-100 pointer-events-auto',
            )}
        >
            <div
                className={cn(
                    'w-[560px] h-[415px] max-w-[560px] max-h-[415px] bg-white p-7 pt-[1.125rem] flex flex-col overflow-y-auto',
                    className,
                )}
                onClick={preventClose}
                {...otherProps}
            >
                <div className='flex justify-between gap-1 items-center mb-4 text-gray-700'>
                    <div className='w-6 h-6' />

                    <div className='font-medium'>{generateTitle ? generateTitle(dialogTitle) : dialogTitle}</div>

                    <CloseCircle
                        className='w-6 h-6 cursor-pointer'
                        onClick={close}
                    />
                </div>

                <hr className='h-px border-none bg-gray-100 mb-7 shrink-0' />

                <div className='flex flex-col justify-between grow'>{children}</div>
            </div>
        </div>
    )
}

export default DialogUI

interface useCompactDialogProps {}

export const useCompactDialog = <T = DefaultCompactState,>({
    setCompactDialogState,
}: NecessaryDialogProps<T> & useCompactDialogProps) => {
    const close = useCallback(() => setCompactDialogState(null), [setCompactDialogState])

    const preventClose: MouseEventHandler<HTMLDivElement> = useCallback((e) => e.stopPropagation(), [])

    return { close, preventClose }
}
