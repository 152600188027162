import { AddUserRequest, AddUserResult } from '../interfaces'

export const addUser = async (addUserRequest: AddUserRequest): Promise<AddUserResult> => {
    return await fetch('https://stage2-api.insight-clinic.com/controller/add_user_v2.php', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            AuthorizationKey: localStorage.getItem('token') ?? '',
        },
        body: JSON.stringify(addUserRequest),
    }).then((res) => res.json() as Promise<AddUserResult>)
}

export const updateUser = async (addUserRequest: AddUserRequest): Promise<AddUserResult> => {
    return await fetch('https://stage2-api.insight-clinic.com/controller/update_user_v2.php', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            AuthorizationKey: localStorage.getItem('token') ?? '',
        },
        body: JSON.stringify(addUserRequest),
    }).then((res) => res.json() as Promise<AddUserResult>)
}
